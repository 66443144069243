export var GET_ALL_SETTING_STORE_CALL = 'GET_ALL_SETTING_STORE_CALL';
export var GET_ALL_SETTING_STORE_DONE = 'GET_ALL_SETTING_STORE_DONE';
export var GET_ALL_SETTING_STORE_FAIL = 'GET_ALL_SETTING_STORE_FAIL';
export var GET_POST_STORE_CALL = 'GET_POST_STORE_CALL';
export var GET_POST_STORE_DONE = 'GET_POST_STORE_DONE';
export var GET_POST_STORE_FAIL = 'GET_POST_STORE_FAIL';
export var SET_POST_STORE_CALL = 'SET_POST_STORE_CALL';
export var SET_POST_STORE_DONE = 'SET_POST_STORE_DONE';
export var SET_POST_STORE_FAIL = 'SET_POST_STORE_FAIL';
export var GET_GROUPIDS_STORE_CALL = 'GET_GROUPIDS_STORE_CALL';
export var GET_GROUPIDS_STORE_DONE = 'GET_GROUPIDS_STORE_DONE';
export var GET_GROUPIDS_STORE_FAIL = 'GET_GROUPIDS_STORE_FAIL';
export var SET_GROUPIDS_STORE_CALL = 'SET_GROUPIDS_STORE_CALL';
export var SET_GROUPIDS_STORE_DONE = 'SET_GROUPIDS_STORE_DONE';
export var SET_GROUPIDS_STORE_FAIL = 'SET_GROUPIDS_STORE_FAIL';
export var GET_OPEN_IMAGE_PICKER_STORE_CALL = 'GET_OPEN_IMAGE_PICKER_STORE_CALL';
export var GET_OPEN_IMAGE_PICKER_STORE_DONE = 'GET_OPEN_IMAGE_PICKER_STORE_DONE';
export var GET_OPEN_IMAGE_PICKER_STORE_FAIL = 'GET_OPEN_IMAGE_PICKER_STORE_FAIL';
export var SET_OPEN_IMAGE_PICKER_STORE_CALL = 'SET_OPEN_IMAGE_PICKER_STORE_CALL';
export var SET_OPEN_IMAGE_PICKER_STORE_DONE = 'SET_OPEN_IMAGE_PICKER_STORE_DONE';
export var SET_OPEN_IMAGE_PICKER_STORE_FAIL = 'SET_OPEN_IMAGE_PICKER_STORE_FAIL';
export var GET_SEARCH_HISTORY_LIST_STORE_CALL = 'GET_SEARCH_HISTORY_LIST_STORE_CALL';
export var GET_SEARCH_HISTORY_LIST_STORE_DONE = 'GET_SEARCH_HISTORY_LIST_STORE_DONE';
export var GET_SEARCH_HISTORY_LIST_STORE_FAIL = 'GET_SEARCH_HISTORY_LIST_STORE_FAIL';
export var SET_SEARCH_HISTORY_LIST_STORE_CALL = 'SET_SEARCH_HISTORY_LIST_STORE_CALL';
export var SET_SEARCH_HISTORY_LIST_STORE_DONE = 'SET_SEARCH_HISTORY_LIST_STORE_DONE';
export var SET_SEARCH_HISTORY_LIST_STORE_FAIL = 'SET_SEARCH_HISTORY_LIST_STORE_FAIL';
export var GET_BAR_CODE_IS_ROW_STORE_CALL = 'GET_BAR_CODE_IS_ROW_STORE_CALL';
export var GET_BAR_CODE_IS_ROW_STORE_DONE = 'GET_BAR_CODE_IS_ROW_STORE_DONE';
export var GET_BAR_CODE_IS_ROW_STORE_FAIL = 'GET_BAR_CODE_IS_ROW_STORE_FAIL';
export var SET_BAR_CODE_IS_ROW_STORE_CALL = 'SET_BAR_CODE_IS_ROW_STORE_CALL';
export var SET_BAR_CODE_IS_ROW_STORE_DONE = 'SET_BAR_CODE_IS_ROW_STORE_DONE';
export var SET_BAR_CODE_IS_ROW_STORE_FAIL = 'SET_BAR_CODE_IS_ROW_STORE_FAIL';
export var GET_ALLOWS_EDITING_STORE_CALL = 'GET_ALLOWS_EDITING_STORE_CALL';
export var GET_ALLOWS_EDITING_STORE_DONE = 'GET_ALLOWS_EDITING_STORE_DONE';
export var GET_ALLOWS_EDITING_STORE_FAIL = 'GET_ALLOWS_EDITING_STORE_FAIL';
export var SET_ALLOWS_EDITING_STORE_CALL = 'SET_ALLOWS_EDITING_STORE_CALL';
export var SET_ALLOWS_EDITING_STORE_DONE = 'SET_ALLOWS_EDITING_STORE_DONE';
export var SET_ALLOWS_EDITING_STORE_FAIL = 'SET_ALLOWS_EDITING_STORE_FAIL';
export var GET_IS_TWO_POST_STORE_CALL = 'GET_IS_TWO_POST_STORE_CALL';
export var GET_IS_TWO_POST_STORE_DONE = 'GET_IS_TWO_POST_STORE_DONE';
export var GET_IS_TWO_POST_STORE_FAIL = 'GET_IS_TWO_POST_STORE_FAIL';
export var SET_IS_TWO_POST_STORE_CALL = 'SET_IS_TWO_POST_STORE_CALL';
export var SET_IS_TWO_POST_STORE_DONE = 'SET_IS_TWO_POST_STORE_DONE';
export var SET_IS_TWO_POST_STORE_FAIL = 'SET_IS_TWO_POST_STORE_FAIL';
export var GET_IDENTITY_STORE_CALL = 'GET_IDENTITY_STORE_CALL';
export var GET_IDENTITY_STORE_DONE = 'GET_IDENTITY_STORE_DONE';
export var GET_IDENTITY_STORE_FAIL = 'GET_IDENTITY_STORE_FAIL';
export var SET_IDENTITY_STORE_CALL = 'SET_IDENTITY_STORE_CALL';
export var SET_IDENTITY_STORE_DONE = 'SET_IDENTITY_STORE_DONE';
export var SET_IDENTITY_STORE_FAIL = 'SET_IDENTITY_STORE_FAIL';
export var GET_STORE_BY_KEY_CALL = 'GET_STORE_BY_KEY_CALL';
export var GET_STORE_BY_KEY_DONE = 'GET_STORE_BY_KEY_DONE';
export var GET_STORE_BY_KEY_FAIL = 'GET_STORE_BY_KEY_FAIL';
export var SET_STORE_BY_KEY_CALL = 'SET_STORE_BY_KEY_CALL';
export var SET_STORE_BY_KEY_DONE = 'SET_STORE_BY_KEY_DONE';
export var SET_STORE_BY_KEY_FAIL = 'SET_STORE_BY_KEY_FAIL';
export var GET_STORE_NULL = 'GET_STORE_NULL';