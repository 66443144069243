import { createAction } from 'redux-actions';
import * as types from '../../store/types';
export var getMeCall = createAction(types.GET_ME_CALL);
export var getMeDone = createAction(types.GET_ME_DONE);
export var getMeFail = createAction(types.GET_ME_FAIL);
export var getUnreadCountCall = createAction(types.GET_UNREAD_COUNT_CALL);
export var getUnreadCountDone = createAction(types.GET_UNREAD_COUNT_DONE);
export var getUnreadCountFail = createAction(types.GET_UNREAD_COUNT_FAIL);
export var getAvatarUploadInfoCall = createAction(types.GET_AVATAR_UPLOAD_INFO_CALL);
export var getAvatarUploadInfoDone = createAction(types.GET_AVATAR_UPLOAD_INFO_DONE);
export var getAvatarUploadInfoFail = createAction(types.GET_AVATAR_UPLOAD_INFO_FAIL);
export var uploadAvatarCall = createAction(types.UPLOAD_AVATAR_CALL);
export var uploadAvatarDone = createAction(types.UPLOAD_AVATAR_DONE);
export var uploadAvatarFail = createAction(types.UPLOAD_AVATAR_FAIL);
export var changePasswordCall = createAction(types.CHANGE_PASSWORD_CALL);
export var changePasswordDone = createAction(types.CHANGE_PASSWORD_DONE);
export var changePasswordFail = createAction(types.CHANGE_PASSWORD_FAIL);
export var updateMember = createAction(types.UPDATE_MEMBER);
export var setMemberSelectType = createAction(types.SET_MEMBER_SELECT_TYPE);