export var INIT_NEW_NOTE = 'INIT_NEW_NOTE';
export var INIT_NEW_NOTE_CALL = 'INIT_NEW_NOTE_CALL';
export var RESET_NEW_NOTE = 'RESET_NEW_NOTE';
export var RESET_NEW_NOTE_CALL = 'RESET_NEW_NOTE_CALL';
export var INIT_EDIT_NOTE = 'INIT_EDIT_NOTE';
export var INIT_EDIT_NOTE_CALL = 'INIT_EDIT_NOTE_CALL';
export var UPDATE_NEW_NOTE = 'UPDATE_NEW_NOTE';
export var UPDATE_NEW_NOTE_ASSET = 'UPDATE_NEW_NOTE_ASSET';
export var REMOVE_NEW_NOTE_ASSET = 'REMOVE_NEW_NOTE_ASSET';
export var REMOVE_NEW_NOTE_ASSET_IMAGE = 'REMOVE_NEW_NOTE_ASSET_IMAGE';
export var CONFIRM_NEW_NOTE_FOODSTUFF = 'CONFIRM_NEW_NOTE_FOODSTUFF';
export var ADD_NEW_NOTE_SIXGROUP = 'ADD_NEW_NOTE_SIXGROUP';
export var ADD_NEW_NOTE_CALORIE = 'ADD_NEW_NOTE_CALORIE';
export var ADD_NEW_NOTE_EMPTY_ASSET = 'ADD_NEW_NOTE_EMPTY_ASSET';
export var CREATE_NOTE_CALL = 'CREATE_NOTE_CALL';
export var CREATE_NOTE_DONE = 'CREATE_NOTE_DONE';
export var CREATE_NOTE_FAIL = 'CREATE_NOTE_FAIL';
export var DELETE_NOTE_CALL = 'DELETE_NOTE_CALL';
export var DELETE_NOTE_DONE = 'DELETE_NOTE_DONE';
export var DELETE_NOTE_FAIL = 'DELETE_NOTE_FAIL';
export var DELETE_NOTE_ASSET_CALL = 'DELETE_NOTE_ASSET_CALL';
export var DELETE_NOTE_ASSET_DONE = 'DELETE_NOTE_ASSET_DONE';
export var DELETE_NOTE_ASSET_FAIL = 'DELETE_NOTE_ASSET_FAIL';
export var GET_MEDIA_UPLOAD_INFO_CALL = 'GET_MEDIA_UPLOAD_INFO_CALL';
export var GET_MEDIA_UPLOAD_INFO_DONE = 'GET_MEDIA_UPLOAD_INFO_DONE';
export var GET_MEDIA_UPLOAD_INFO_FAIL = 'GET_MEDIA_UPLOAD_INFO_FAIL';
export var PROCESS_IMAGE_CALL = 'PROCESS_IMAGE_CALL';
export var PROCESS_IMAGE_DONE = 'PROCESS_IMAGE_DONE';
export var PROCESS_IMAGE_FAIL = 'PROCESS_IMAGE_FAIL';
export var GET_NOTES_DONE = 'GET_NOTES_DONE';
export var START_SELECT_MEDIA = 'START_SELECT_MEDIA';
export var RESTART_SELECT_MEDIA = 'RESTART_SELECT_MEDIA';
export var SELECT_MEDIA = 'SELECT_MEDIA';
export var FINISH_SELECT_MEDIA = 'FINISH_SELECT_MEDIA';
export var SELECT_MEDIA_CALL = 'SELECT_MEDIA_CALL';
export var SELECT_MEDIA_DONE = 'SELECT_MEDIA_DONE';
export var SELECT_MEDIA_FAIL = 'SELECT_MEDIA_FAIL';
export var UPLOAD_ALL_ASSETS_CALL = 'UPLOAD_ALL_ASSETS_CALL';
export var UPLOAD_ALL_ASSETS_DONE = 'UPLOAD_ALL_ASSETS_DONE';
export var UPLOAD_ALL_ASSETS_FAIL = 'UPLOAD_ALL_ASSETS_FAIL';
export var UPLOAD_ASSET_DONE = 'UPLOAD_ASSET_DONE';
export var GET_HISTORY_ASSETS_CALL = 'GET_HISTORY_ASSETS_CALL';
export var GET_HISTORY_ASSETS_DONE = 'GET_HISTORY_ASSETS_DONE';
export var GET_HISTORY_ASSETS_FAIL = 'GET_HISTORY_ASSETS_FAIL';
export var EDIT_FOOD_STUFFS = 'EDIT_FOOD_STUFFS';
export var EDIT_SIX_GROUP = 'EDIT_SIX_GROUP';
export var EDIT_CALORIE = 'EDIT_CALORIE';
export var REMOVE_SIX_GROUP = 'REMOVE_SIX_GROUP';
export var REMOVE_CALORIE = 'REMOVE_CALORIE';
export var SET_CURRENT_ASSET_INDEX = 'SET_CURRENT_ASSET_INDEX';
export var INIT_SELECTED_FOOD_STUFFS = 'INIT_SELECTED_FOOD_STUFFS';
export var INIT_SELECTED_SIX_GROUP = 'INIT_SELECTED_SIX_GROUP';
export var INIT_SELECTED_CALORIE = 'INIT_SELECTED_CALORIE';
export var ADD_FOOD_STUFF = 'ADD_FOOD_STUFF';
export var UPDATE_FOOD_STUFF = 'UPDATE_FOOD_STUFF';
export var REMOVE_FOOD_STUFF = 'REMOVE_FOOD_STUFF';
export var MARK_DESTROY_ASSET = 'MARK_DESTROY_ASSET';
export var UPDATE_NOTE_CALL = 'UPDATE_NOTE_CALL';
export var UPDATE_NOTE_DONE = 'UPDATE_NOTE_DONE';
export var UPDATE_NOTE_FAIL = 'UPDATE_NOTE_FAIL';
export var EDIT_MOVE_ITEMS = 'EDIT_MOVE_ITEMS';
export var ADD_ACTIVITY_ITEMS = 'ADD_ACTIVITY_ITEMS';
export var UPDATE_ACTIVITY_ITEMS = 'UPDATE_ACTIVITY_ITEMS';
export var REMOVE_ACTIVITY_ITEMS = 'REMOVE_ACTIVITY_ITEMS';
export var CONFIRM_NEW_NOTE_ACTIVITY_ITEMS = 'CONFIRM_NEW_NOTE_ACTIVITY_ITEMS';
export var ADD_ACTIVITY_SESSIONS = 'ADD_ACTIVITY_SESSIONS';
export var UPDATE_ACTIVITY_SESSIONS = 'UPDATE_ACTIVITY_SESSIONS';
export var REMOVE_ACTIVITY_SESSIONS = 'REMOVE_ACTIVITY_SESSIONS';
export var CONFIRM_NEW_NOTE_ACTIVITY_SESSIONS = 'CONFIRM_NEW_NOTE_ACTIVITY_SESSIONS';
export var PREPARE_FOR_UPDATE_NOTE = 'PREPARE_FOR_UPDATE_NOTE';
export var GET_NOTE_TAGS_CALL = 'GET_NOTE_TAGS_CALL';
export var GET_NOTE_TAGS_DONE = 'GET_NOTE_TAGS_DONE';
export var GET_NOTE_TAGS_FAIL = 'GET_NOTE_TAGS_FAIL';
export var UPDATE_COMMENT_CALL = 'UPDATE_COMMENT_CALL';
export var UPDATE_COMMENT_DONE = 'UPDATE_COMMENT_DONE';
export var UPDATE_COMMENT_FAIL = 'UPDATE_COMMENT_FAIL';
export var DELETE_COMMENT_CALL = 'DELETE_COMMENT_CALL';
export var DELETE_COMMENT_DONE = 'DELETE_COMMENT_DONE';
export var DELETE_COMMENT_FAIL = 'DELETE_COMMENT_FAIL';
export var SET_NEW_NOTE_CALL = 'SET_NEW_NOTE_CALL';
export var SET_NEW_NOTE_DONE = 'SET_NEW_NOTE_DONE';
export var SET_NEW_NOTE_FAIL = 'SET_NEW_NOTE_FAIL';