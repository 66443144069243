import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import { Observable } from 'rxjs';
import { article } from '../../services';
import './RxOperators';
import * as actions from '../actions';
import * as types from '../../store/types';
import { getMember } from '../../store/selectors';
export var openArticle = function openArticle(action$, store) {
  return action$.ofType(types.OPEN_ARTICLE_CALL).switchMap(function (_ref) {
    var payload = _ref.payload;
    return Observable.fromPromise(article.getArticle(payload)).switchMap(function (res) {
      return [actions.openArticleDone(res), actions.openWebViewPage({
        item: _objectSpread({}, res, {
          type: 'article'
        })
      })];
    }).catch(function (err) {
      return Observable.of(actions.openArticleFail(err));
    });
  });
};
export var getArticleListCall = function getArticleListCall(action$, store) {
  return action$.ofType(types.GET_ARTICLE_LIST_CALL).switchMap(function (_ref2) {
    var payload = _ref2.payload;
    return Observable.fromPromise(article.getArticleList(payload)).map(function (res) {
      return actions.getArticleListDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getArticleListFail(err));
    });
  });
};
export var getSixGroupDetail = function getSixGroupDetail(action$, store) {
  return action$.ofType(types.GET_SIX_GROUP_DETAIL_CALL).switchMap(function (_ref3) {
    var payload = _ref3.payload;
    return Observable.fromPromise(article.getSixGroupDetail(payload)).map(function (res) {
      return actions.getSixGroupDetailDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getSixGroupDetailFail(err));
    });
  });
};
export var setArticleQuestionCompleteCall = function setArticleQuestionCompleteCall(action$, store) {
  return action$.ofType(types.SET_ARTICLE_QUESTION_COMPLETE_CALL).switchMap(function (_ref4) {
    var payload = _ref4.payload;
    return Observable.fromPromise(article.setArticleQuestionComplete(payload)).switchMap(function (res) {
      var _getMember = getMember(store.value),
          id = _getMember.id;

      return [actions.setArticleQuestionCompleteDone(res), actions.getArticleListCall({
        id: id
      })];
    }).catch(function (err) {
      return Observable.of(actions.setArticleQuestionCompleteFail(err));
    });
  });
};