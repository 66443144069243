import { createAction } from 'redux-actions';
import * as types from '../../store/types';
export var getFactoryListCall = createAction(types.GET_FACTORY_LIST_CALL);
export var getFactoryListDone = createAction(types.GET_FACTORY_LIST_DONE);
export var getFactoryListFail = createAction(types.GET_FACTORY_LIST_FAIL);
export var getFactoryCourseCall = createAction(types.GET_FACTORY_COURSE_CALL);
export var getFactoryCourseDone = createAction(types.GET_FACTORY_COURSE_DONE);
export var getFactoryCourseFail = createAction(types.GET_FACTORY_COURSE_FAIL);
export var getFactoryCoachListCall = createAction(types.GET_FACTORY_COACH_LIST_CALL);
export var getFactoryCoachListDone = createAction(types.GET_FACTORY_COACH_LIST_DONE);
export var getFactoryCoachListFail = createAction(types.GET_FACTORY_COACH_LIST_FAIL);
export var getFactoryClientInfoCall = createAction(types.GET_FACTORY_CLIENT_INFO_CALL);
export var getFactoryClientInfoDone = createAction(types.GET_FACTORY_CLIENT_INFO_DONE);
export var getFactoryClientInfoFail = createAction(types.GET_FACTORY_CLIENT_INFO_FAIL);
export var showBarCodeModal = createAction(types.SHOW_BAR_CODE_MODAL);
export var closeBarCodeModal = createAction(types.CLOSE_BAR_CODE_MODAL);
export var getReserveClassesCall = createAction(types.GET_RESERVE_CLASSES_CALL);
export var getReserveClassesDone = createAction(types.GET_RESERVE_CLASSES_DONE);
export var getReserveClassesFail = createAction(types.GET_RESERVE_CLASSES_FAIL);
export var reserveClassesCall = createAction(types.RESERVE_CLASSES_CALL);
export var reserveClassesDone = createAction(types.RESERVE_CLASSES_DONE);
export var reserveClassesFail = createAction(types.RESERVE_CLASSES_FAIL);
export var cancelReserveClassesCall = createAction(types.CANCEL_RESERVE_CLASSES_CALL);
export var cancelReserveClassesDone = createAction(types.CANCEL_RESERVE_CLASSES_DONE);
export var cancelReserveClassesFail = createAction(types.CANCEL_RESERVE_CLASSES_FAIL);