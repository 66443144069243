import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _objectWithoutProperties from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import _toConsumableArray from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import { Observable } from 'rxjs';
import _ from 'lodash';
import { device } from '../../services';
import { getMember } from '../../store/selectors';
import './RxOperators';
import * as actions from '../actions';
import * as types from '../../store/types';
import { Util } from '../../utils';
var getCurrentRouteName = Util.getCurrentRouteName;

var getBadgeTotal = function getBadgeTotal(store) {
  return store.value.ui.badges.total;
};

var parseUrl = function parseUrl(url) {
  var isArticle = url.match('article/');
  var isFbBack = url.match(/access_token=([^&]*)/);
  var value;

  if (isFbBack) {
    value = isFbBack[1];
  } else if (isArticle) {
    value = url.split('article/')[1];
  }

  return {
    isFbBack: isFbBack,
    isArticle: isArticle,
    value: value
  };
};

var parseChatroomId = function parseChatroomId(id) {
  var idAsNumber = _.toNumber(id);

  return _.isNaN(idAsNumber) ? id : idAsNumber;
};

export var handleOpenAppCall = function handleOpenAppCall(action$, store) {
  return action$.ofType(types.HANDLE_OPEN_APP_CALL).switchMap(function (_ref) {
    var _ref$payload = _ref.payload,
        url = _ref$payload.url,
        path = _ref$payload.path,
        queryParams = _ref$payload.queryParams,
        coldStart = _ref$payload.coldStart;

    var _parseUrl = parseUrl(url),
        isFbBack = _parseUrl.isFbBack,
        isArticle = _parseUrl.isArticle,
        value = _parseUrl.value;

    if (isFbBack) {
      return [actions.handleOpenAppDone(), actions.fbTokenLoginCall({
        token: value
      })];
    } else if (isArticle) {
      var memberFetched = getMember(store.value);

      if (memberFetched.id) {
        return [actions.handleOpenAppDone(), actions.openArticleCall({
          id: value
        })];
      } else {
        return Observable.concat( // wait for member fetched
        action$.ofType(types.GET_ME_DONE).take(1), Observable.of(actions.handleOpenAppCall({
          url: url,
          path: path,
          queryParams: queryParams,
          coldStart: coldStart
        })));
      }
    } else {
      return [actions.handleOpenAppDone()];
    }
  }).catch(function (err) {
    return Observable.of(actions.handleOpenAppFail(err));
  });
};
export var handlePushNotification = function handlePushNotification(action$, store) {
  return action$.ofType(types.HANDLE_PUSH_NOTIFICATION_CALL).switchMap(function (_ref2) {
    var payload = _ref2.payload;
    var memberFetched = getMember(store.value);

    if (memberFetched.id) {
      return payload.origin === 'selected' ? Observable.of(actions.handleSelectPushNotificationCall(payload)) : Observable.of(actions.handleReceivePushNotificationCall(payload));
    } else {
      return Observable.concat( // wait for member fetched
      action$.ofType(types.GET_ME_DONE).take(1), Observable.of(actions.handlePushNotificationCall(payload)));
    }
  }).catch(function (err) {
    return Observable.of(actions.handlePushNotificationFail(err));
  });
};
export var handleSelectPushNotification = function handleSelectPushNotification(action$, store) {
  return action$.ofType(types.HANDLE_SELECT_PUSH_NOTIFICATION_CALL).switchMap(function (_ref3) {
    var _ref3$payload = _ref3.payload,
        origin = _ref3$payload.origin,
        data = _ref3$payload.data,
        remote = _ref3$payload.remote;
    var type = data.type,
        chatRoomId = data.chatRoomId,
        gotoTab = data.gotoTab;
    var currentChatroomId = store.value.chat.id;
    var routeName = getCurrentRouteName(store.value.nav);
    var inChatroom = routeName === 'ChatPage';
    var inGoToChatroom = currentChatroomId && chatRoomId && currentChatroomId === parseChatroomId(chatRoomId);
    var newActions = [];

    switch (type) {
      case 'chatMessage':
        if (inGoToChatroom) {
          //Homepage handleResumeApp會處理
          newActions = [actions.empty()];
        } else if (inChatroom) {
          //等Chat關閉後才換chat
          return Observable.concat(Observable.of(actions.pageGoBack()), action$.ofType(types.CLOSE_CHAT_DONE).take(1), Observable.of(actions.openChatCall({
            id: parseChatroomId(chatRoomId)
          })), Observable.of(actions.handleSelectPushNotificationDone()));
        } else {
          newActions = [actions.openChatCall({
            id: parseChatroomId(chatRoomId)
          })];
        }

        break;

      default:
        newActions = [actions.getNotificationsCall({
          newer: true
        }), actions.openNotificationTab()];
        break;
    }

    return [].concat(_toConsumableArray(newActions), [actions.handleSelectPushNotificationDone()]);
  }).catch(function (err) {
    return Observable.of(actions.handleSelectPushNotificationFail(err));
  });
};
export var handleReceivePushNotification = function handleReceivePushNotification(action$, store) {
  return action$.ofType(types.HANDLE_RECEIVE_PUSH_NOTIFICATION_CALL).switchMap(function (_ref4) {
    var _ref4$payload = _ref4.payload,
        origin = _ref4$payload.origin,
        data = _ref4$payload.data,
        remote = _ref4$payload.remote;
    var type = data.type,
        chatRoomId = data.chatRoomId,
        gotoTab = data.gotoTab,
        unreads = data.unreads;
    var routeName = getCurrentRouteName(store.value.nav);
    var currentChatroomId = store.value.chat.id;
    var inGoToChatroom = currentChatroomId && chatRoomId && currentChatroomId === parseChatroomId(chatRoomId);
    var inNotificationTab = routeName === 'NotificationTab';
    var newActions;

    switch (true) {
      case inGoToChatroom:
        newActions = [actions.empty()];
        break;

      case gotoTab === 'notification' && inNotificationTab:
        newActions = [actions.getUnreadCountDone({
          unreadCount: {
            unreads: unreads
          }
        }), actions.setAppBadgeCall(), actions.getNotificationsCall({
          newer: true
        })];
        break;

      case _.isObject(unreads):
        newActions = [actions.getUnreadCountDone({
          unreadCount: {
            unreads: unreads
          }
        }), actions.setAppBadgeCall()];
        break;

      default:
        newActions = [actions.empty()];
        break;
    }

    return newActions;
  }).catch(function (err) {
    return Observable.of(actions.handleReceivePushNotificationFail(err));
  });
};
export var updateDeviceInfo = function updateDeviceInfo(action$, store) {
  return action$.ofType(types.UPDATE_DEVICE_INFO_CALL).switchMap(function (_ref5) {
    var payload = _ref5.payload;
    var memberFetched = getMember(store.value);

    if (memberFetched.id) {
      var _device$getInfo = device.getInfo(),
          appOwnership = _device$getInfo.appOwnership,
          deviceInfo = _objectWithoutProperties(_device$getInfo, ["appOwnership"]);

      var isBrokenCameraDevice = device.isBrokenCameraDevice;
      return Observable.concat(Observable.of(actions.updateBrokenCameraDeviceCall(isBrokenCameraDevice)), Observable.defer(function () {
        return device.registerForPushNotificationsAsync();
      }).switchMap(function (result) {
        return appOwnership === 'expo' ? Observable.empty() : Observable.of(actions.uploadDeviceInfoCall(_objectSpread({}, result, deviceInfo)));
      }));
    } else {
      return Observable.concat( // wait for member fetched
      action$.ofType(types.GET_ME_DONE).take(1), Observable.of(actions.updateDeviceInfoCall()));
    }
  }).catch(function (err) {
    return Observable.of(actions.updateDeviceInfoFail(err));
  });
};
export var setAppBadgeCall = function setAppBadgeCall(action$, store) {
  return action$.ofType(types.SET_APP_BADGE_CALL).switchMap(function () {
    var number = getBadgeTotal(store);
    return Observable.fromPromise(device.setBadgeNumberAsync(number)).map(function () {
      return actions.setAppBadgeDone();
    });
  }).catch(function (err) {
    return Observable.of(actions.setAppBadgeFail(err));
  });
};
export var checkForUpdateCall = function checkForUpdateCall(action$, store) {
  return action$.ofType(types.CHECK_FOR_UPDATE_CALL).switchMap(function (_ref6) {
    var userRun = _ref6.payload.userRun;
    var showLoading = userRun;
    return Observable.concat(Observable.of(showLoading ? actions.showLoading() : actions.empty()), Observable.fromPromise(device.checkForUpdate({
      userRun: userRun
    })).map(function () {
      return actions.checkForUpdateDone();
    }), Observable.of(showLoading ? actions.hideLoading() : actions.empty()));
  }).catch(function (err) {
    return Observable.concat(Observable.of(actions.checkForUpdateFail(err)), Observable.of(actions.hideLoading()));
  });
};