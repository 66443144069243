import { Observable } from 'rxjs';
import { share } from '../../services';
import './RxOperators';
import * as actions from '../actions';
import * as types from '../types';
export var nativeShareCall = function nativeShareCall(action$, store) {
  return action$.ofType(types.NATIVE_SHARE_CALL).switchMap(function (_ref) {
    var payload = _ref.payload;
    return Observable.fromPromise(share.nativeShare(payload)) //Observable.fromPromise(share.weChatShare(payload))
    .map(function (res) {
      return actions.nativeShareDone(res);
    }).catch(function (err) {
      return Observable.of(actions.nativeShareFail(err));
    });
  });
};