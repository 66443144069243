import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import { Observable } from 'rxjs';
import { profile } from '../../services';
import * as actions from '../actions';
import * as types from '../../store/types';
import './RxOperators';
var BODY = 'body';
var NUTRITION = 'nutrition';
var MOVE = 'move';

var getObservableForData = function getObservableForData(type, store, dateType) {
  var dateParam = dateType === 'year' ? {
    dur: 'monthly'
  } : {};
  var clientId = store.value.profile.clientId;

  var param = _objectSpread({}, dateParam, {
    client_id: clientId
  });

  var api;
  var actionDone;
  var actionFail;

  switch (type) {
    case BODY:
      api = profile.getBodyData;
      actionDone = actions.getBodyDataDone;
      actionFail = actions.getBodyDataFail;
      break;

    case NUTRITION:
      api = profile.getNutrition;
      actionDone = actions.getNutritionDone;
      actionFail = actions.getNutritionFail;
      break;

    case MOVE:
      api = profile.getMoveData;
      actionDone = actions.getMoveDataDone;
      actionFail = actions.getMoveDataFail;
      break;

    default:
      break;
  }

  return Observable.fromPromise(api(param)).map(function (res) {
    return actionDone({
      type: type,
      clientId: clientId,
      param: param,
      data: res
    });
  }).catch(function (err) {
    return Observable.of(actionFail(err));
  });
};

var getObservableForDataMore = function getObservableForDataMore(type, store, payload) {
  var clientId = store.value.profile.clientId;

  var param = _objectSpread({}, payload, {
    client_id: clientId
  });

  var api;
  var actionDone;
  var actionFail;

  switch (type) {
    case BODY:
      api = profile.getBodyData;
      actionDone = actions.getBodyDataMoreDone;
      actionFail = actions.getBodyDataMoreFail;
      break;

    case NUTRITION:
      api = profile.getNutrition;
      actionDone = actions.getNutritionMoreDone;
      actionFail = actions.getNutritionMoreFail;
      break;

    case MOVE:
      api = profile.getMoveData;
      actionDone = actions.getMoveDataMoreDone;
      actionFail = actions.getMoveDataMoreFail;
      break;

    default:
      break;
  }

  return Observable.fromPromise(api(param)).map(function (res) {
    return actionDone({
      type: type,
      clientId: clientId,
      newData: res,
      param: payload
    });
  }).catch(function (err) {
    return Observable.of(actionFail(err));
  });
};

export var getBodyData = function getBodyData(action$, store) {
  return action$.ofType(types.GET_BODY_DATA_CALL).switchMap(function (_ref) {
    var dateType = _ref.payload.dateType;
    return getObservableForData(BODY, store, dateType);
  });
};
export var getBodyDataMore = function getBodyDataMore(action$, store) {
  return action$.ofType(types.GET_BODY_DATA_MORE_CALL).switchMap(function (_ref2) {
    var payload = _ref2.payload;
    return getObservableForDataMore(BODY, store, payload);
  });
};
export var getNutrition = function getNutrition(action$, store) {
  return action$.ofType(types.GET_NUTRITION_CALL).switchMap(function (_ref3) {
    var dateType = _ref3.payload.dateType;
    return getObservableForData(NUTRITION, store, dateType);
  });
};
export var getNutritionaMore = function getNutritionaMore(action$, store) {
  return action$.ofType(types.GET_NUTRITION_MORE_CALL).switchMap(function (_ref4) {
    var payload = _ref4.payload;
    return getObservableForDataMore(NUTRITION, store, payload);
  });
};
export var getMoveData = function getMoveData(action$, store) {
  return action$.ofType(types.GET_MOVE_DATA_CALL).switchMap(function (_ref5) {
    var dateType = _ref5.payload.dateType;
    return getObservableForData(MOVE, store, dateType);
  });
};
export var getMoveMore = function getMoveMore(action$, store) {
  return action$.ofType(types.GET_MOVE_DATA_MORE_CALL).switchMap(function (_ref6) {
    var payload = _ref6.payload;
    return getObservableForDataMore(MOVE, store, payload);
  });
};
export var removeData = function removeData(action$, store) {
  return action$.ofType(types.REMOVE_DATA_CALL).switchMap(function (_ref7) {
    var _ref7$payload = _ref7.payload,
        reLoadData = _ref7$payload.reLoadData,
        removeData = _ref7$payload.removeData;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(profile.removeData(removeData)).switchMap(function (res) {
      return [actions.removeDataDone(), reLoadData()];
    }).catch(function (err) {
      return Observable.of(actions.removeDataFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};