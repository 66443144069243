import _taggedTemplateLiteral from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\nquery ($status: String) {\n  groupClasses: userGroupClasses(status: $status) {\n    id\n    # status\n    kind\n    clients: registered_clients {\n      id\n      name\n      \n    }\n  }\n}\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { gql } from 'apollo-boost';
export var groupClasses = gql(_templateObject());