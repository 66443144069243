import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import { Observable } from 'rxjs';
import { integral } from '../../services';
import './RxOperators';
import * as actions from '../actions';
import * as types from '../types';
export var getShopIntegralIndexCall = function getShopIntegralIndexCall(action$, store) {
  return action$.ofType(types.GET_SHOP_INTEGRAL_INDEX_CALL).switchMap(function (_ref) {
    var payload = _ref.payload,
        loadingType = _ref.payload.loadingType;
    return Observable.fromPromise(integral.getShopIgIndex(payload)).map(function (res) {
      return actions.getShopIntegralIndexDone(_objectSpread({}, res, {
        loadingType: loadingType
      }));
    }).catch(function (err) {
      return Observable.of(actions.getShopIntegralIndexFail({
        message: err
      }));
    });
  });
};
export var getIgGoodsCartCall = function getIgGoodsCartCall(action$, store) {
  return action$.ofType(types.GET_IG_GOODS_CART_CALL).switchMap(function (_ref2) {
    var _ref2$payload = _ref2.payload,
        selectId = _ref2$payload.selectId,
        loadingType = _ref2$payload.loadingType;
    return Observable.fromPromise(integral.getIgGoodsCart()).map(function (res) {
      return actions.getIgGoodsCartDone({
        res: res,
        selectId: selectId,
        loadingType: loadingType
      });
    }).catch(function (err) {
      return Observable.of(actions.getIgGoodsCartFail(err));
    });
  });
};
export var igSettlementCall = function igSettlementCall(action$, store) {
  return action$.ofType(types.IG_SETTLEMENT_CALL).switchMap(function (_ref3) {
    var payload = _ref3.payload;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(integral.igSettlement(payload)).map(function (res) {
      return actions.igSettlementDone(res);
    }).catch(function (err) {
      return Observable.of(actions.igSettlementFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var igAddOrderCall = function igAddOrderCall(action$, store) {
  return action$.ofType(types.IG_ADD_ORDER_CALL).switchMap(function (_ref4) {
    var payload = _ref4.payload;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(integral.igAddOrder(payload)).switchMap(function (res) {
      var data = res.data;
      var _data$order = data.order,
          order = _data$order === void 0 ? {} : _data$order;
      var id = order.id,
          igo_trans_fee = order.igo_trans_fee;
      var needPay = igo_trans_fee !== 0;
      return [actions.igAddOrderDone(data), needPay ? actions.getWxPayDataCall({
        id: id,
        type: 'integral'
      }) : actions.openShopPayPage({
        isIgType: true
      })];
    }).catch(function (err) {
      return Observable.of(actions.igAddOrderFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var getIntegralListCall = function getIntegralListCall(action$, store) {
  return action$.ofType(types.GET_INTEGRAL_INFO_LIST_CALL).switchMap(function (_ref5) {
    var payload = _ref5.payload,
        tabKey = _ref5.payload.tabKey;
    return Observable.fromPromise(integral.getIntegralList(payload)).map(function (res) {
      return actions.getIntegralListDone(_objectSpread({}, res, {
        tabKey: tabKey
      }));
    }).catch(function (err) {
      return Observable.of(actions.getIntegralListFail({
        err: err,
        tabKey: tabKey
      }));
    });
  });
};
export var getIntegralTaskCall = function getIntegralTaskCall(action$, store) {
  return action$.ofType(types.GET_INTEGRAL_TASK_CALL).switchMap(function (_ref6) {
    var payload = _ref6.payload;
    return Observable.fromPromise(integral.getIntegralTask()).map(function (res) {
      return actions.getIntegralTaskDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getIntegralTaskFail(err));
    });
  });
};
export var getIntegralRuleCall = function getIntegralRuleCall(action$, store) {
  return action$.ofType(types.GET_INTEGRAL_RULE_CALL).switchMap(function (_ref7) {
    var payload = _ref7.payload;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(integral.getIntegralRule(payload)).map(function (res) {
      return actions.getIntegralRuleDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getIntegralRuleFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var addIntegralCall = function addIntegralCall(action$, store) {
  return action$.ofType(types.ADD_INTEGRAL_CALL).switchMap(function (_ref8) {
    var _ref8$payload = _ref8.payload,
        param = _ref8$payload.param,
        reLoad = _ref8$payload.reLoad;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(integral.addIntegral(param)).switchMap(function (res) {
      return [reLoad ? actions.getIntegralTaskCall({
        loadingType: 'refresh'
      }) : actions.empty(), actions.addIntegralDone(res)];
    }).catch(function (err) {
      return Observable.of(actions.addIntegralFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};