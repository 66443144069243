export var GET_MEDIA_POSTS_CALL = 'GET_MEDIA_POSTS_CALL';
export var GET_MEDIA_POSTS_DONE = 'GET_MEDIA_POSTS_DONE';
export var GET_MEDIA_POSTS_FAIL = 'GET_MEDIA_POSTS_FAIL';
export var GET_TAB_POSTS_CALL = 'GET_TAB_POSTS_CALL';
export var GET_TAB_POSTS_DONE = 'GET_TAB_POSTS_DONE';
export var GET_TAB_POSTS_FAIL = 'GET_TAB_POSTS_FAIL';
export var GET_SOCIAL_POSTS_CALL = 'GET_SOCIAL_POSTS_CALL';
export var GET_SOCIAL_POSTS_DONE = 'GET_SOCIAL_POSTS_DONE';
export var GET_SOCIAL_POSTS_FAIL = 'GET_SOCIAL_POSTS_FAIL';
export var GET_SOCIAL_PROFILE_CALL = 'GET_SOCIAL_PROFILE_CALL';
export var GET_SOCIAL_PROFILE_DONE = 'GET_SOCIAL_PROFILE_DONE';
export var GET_SOCIAL_PROFILE_FAIL = 'GET_SOCIAL_PROFILE_FAIL';
export var GET_MY_PROFILE_CALL = 'GET_MY_PROFILE_CALL';
export var GET_MY_PROFILE_DONE = 'GET_MY_PROFILE_DONE';
export var GET_MY_PROFILE_FAIL = 'GET_MY_PROFILE_FAIL';
export var GET_MY_POSTS_CALL = 'GET_MY_POSTS_CALL';
export var GET_MY_POSTS_DONE = 'GET_MY_POSTS_DONE';
export var GET_MY_POSTS_FAIL = 'GET_MY_POSTS_FAIL';
export var GET_MY_KEPT_POSTS_CALL = 'GET_MY_KEPT_POSTS_CALL';
export var GET_MY_KEPT_POSTS_DONE = 'GET_MY_KEPT_POSTS_DONE';
export var GET_MY_KEPT_POSTS_FAIL = 'GET_MY_KEPT_POSTS_FAIL';
export var GET_POST_CALL = 'GET_POST_CALL';
export var GET_POST_DONE = 'GET_POST_DONE';
export var GET_POST_FAIL = 'GET_POST_FAIL';
export var GET_PROFILE_POSTS_CALL = 'GET_PROFILE_POSTS_CALL';
export var GET_PROFILE_POSTS_DONE = 'GET_PROFILE_POSTS_DONE';
export var GET_PROFILE_POSTS_FAIL = 'GET_PROFILE_POSTS_FAIL';
export var DELETE_POST_CALL = 'DELETE_POST_CALL';
export var DELETE_POST_DONE = 'DELETE_POST_DONE';
export var DELETE_POST_FAIL = 'DELETE_POST_FAIL';
export var CREATE_POST_COMMENT_CALL = 'CREATE_POST_COMMENT_CALL';
export var CREATE_POST_COMMENT_DONE = 'CREATE_POST_COMMENT_DONE';
export var CREATE_POST_COMMENT_FAIL = 'CREATE_POST_COMMENT_FAIL';
export var TOGGLE_POST_LIKE_CALL = 'TOGGLE_POST_LIKE_CALL';
export var TOGGLE_POST_LIKE_DONE = 'TOGGLE_POST_LIKE_DONE';
export var TOGGLE_POST_LIKE_FAIL = 'TOGGLE_POST_LIKE_FAIL';
export var TOGGLE_POST_KEEP_CALL = 'TOGGLE_POST_KEEP_CALL';
export var TOGGLE_POST_KEEP_DONE = 'TOGGLE_POST_KEEP_DONE';
export var TOGGLE_POST_KEEP_FAIL = 'TOGGLE_POST_KEEP_FAIL';
export var INSERT_POST_COMMENT = 'INSERT_POST_COMMENT';
export var TOGGLE_POST_LIKE = 'TOGGLE_POST_LIKE';
export var TOGGLE_FOLLOW = 'TOGGLE_FOLLOW';
export var SELECT_POST = 'SELECT_POST';
export var OPEN_POST_CALL = 'OPEN_POST_CALL';
export var OPEN_POST_DONE = 'OPEN_POST_DONE';
export var OPEN_POST_FAIL = 'OPEN_POST_FAIL';
export var OPEN_SOCIAL_PROFILE_CALL = 'OPEN_SOCIAL_PROFILE_CALL';
export var OPEN_SOCIAL_PROFILE_DONE = 'OPEN_SOCIAL_PROFILE_DONE';
export var OPEN_SOCIAL_PROFILE_FAIL = 'OPEN_SOCIAL_PROFILE_FAIL';
export var OPEN_POST_NOTIFICATION_CALL = 'OPEN_POST_NOTIFICATION_CALL';
export var OPEN_POST_NOTIFICATION_DONE = 'OPEN_POST_NOTIFICATION_DONE';
export var OPEN_POST_NOTIFICATION_FAIL = 'OPEN_POST_NOTIFICATION_FAIL';
export var TOGGLE_FOLLOW_CALL = 'TOGGLE_FOLLOW_CALL';
export var TOGGLE_FOLLOW_DONE = 'TOGGLE_FOLLOW_DONE';
export var TOGGLE_FOLLOW_FAIL = 'TOGGLE_FOLLOW_FAIL';
export var GET_TAG_POSTS_CALL = 'GET_TAG_POSTS_CALL';
export var GET_TAG_POSTS_DONE = 'GET_TAG_POSTS_DONE';
export var GET_TAG_POSTS_FAIL = 'GET_TAG_POSTS_FAIL';
export var OPEN_TAG_POSTS_CALL = 'OPEN_TAG_POSTS_CALL';
export var OPEN_TAG_POSTS_DONE = 'OPEN_TAG_POSTS_DONE';
export var OPEN_TAG_POSTS_FAIL = 'OPEN_TAG_POSTS_FAIL';
export var GET_SOCIAL_SEARCH_CALL = 'GET_SOCIAL_SEARCH_CALL';
export var GET_SOCIAL_SEARCH_DONE = 'GET_SOCIAL_SEARCH_DONE';
export var GET_SOCIAL_SEARCH_FAIL = 'GET_SOCIAL_SEARCH_FAIL';
export var GET_GROUP_POSTS_CALL = 'GET_GROUP_POSTS_CALL';
export var GET_GROUP_POSTS_DONE = 'GET_GROUP_POSTS_DONE';
export var GET_GROUP_POSTS_FAIL = 'GET_GROUP_POSTS_FAIL';
export var SET_GROUP_ID = 'SET_GROUP_ID';
export var SET_SOCIAL_MAIN_TAB_INDEX = 'SET_SOCIAL_MAIN_TAB_INDEX';
export var SET_SOCIAL_TAB_INDEX = 'SET_SOCIAL_TAB_INDEX';
export var OPEN_MODAL_REPORT = 'OPEN_MODAL_REPORT';
export var CLOSE_MODAL_REPORT = 'CLOSE_MODAL_REPORT';
export var REPORT_CALL = 'REPORT_CALL';
export var REPORT_DONE = 'REPORT_DONE';
export var REPORT_FAIL = 'REPORT_FAIL';
export var BLOCK_CALL = 'BLOCK_CALL';
export var BLOCK_DONE = 'BLOCK_DONE';
export var BLOCK_FAIL = 'BLOCK_FAIL';
export var UN_BLOCK_CALL = 'UN_BLOCK_CALL';
export var UN_BLOCK_DONE = 'UN_BLOCK_DONE';
export var UN_BLOCK_FAIL = 'UN_BLOCK_FAIL';
export var CREATE_POST = 'CREATE_POST';