import api from '../api';
export var getQuestionList = function getQuestionList(payload) {
  return api.getShopApi('/buyer/quest_list', payload);
};
export var getQuestion = function getQuestion(payload) {
  return api.getShopApi('/buyer/get_quest_with_anwser', payload);
};
export var sendQuestion = function sendQuestion(payload) {
  return api.postShopApi('/buyer/question_answers_angelina', payload, true);
};
export var newSendQuestion = function newSendQuestion(payload) {
  return api.getShopApi('/buyer/quest_answer_save', payload);
};
export var getQuestionResult = function getQuestionResult(payload) {
  return api.getShopApi('/buyer/quest_result', payload);
};
export var getCofitQuestion = function getCofitQuestion(payload) {
  return api.get("/v4/surveys/".concat(payload.id), payload);
};