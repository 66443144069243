import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";

var _handleActions;

import { handleActions } from 'redux-actions';
import * as types from '../../store/types';
var initialState = {
  isImagePickerOpening: false,
  pickerModalTitle: '',
  showHistory: true,
  showHeader: true,
  showCameraSendPage: false,
  assetIndex: 0
};
export default handleActions((_handleActions = {}, _defineProperty(_handleActions, types.OPEN_IMAGE_PICKER_MODAL, function (state, _ref) {
  var _ref$payload = _ref.payload,
      assetIndex = _ref$payload.assetIndex,
      pickerModalTitle = _ref$payload.pickerModalTitle,
      showHistory = _ref$payload.showHistory,
      showHeader = _ref$payload.showHeader,
      showDraft = _ref$payload.showDraft;
  return {
    isImagePickerOpening: !state.isImagePickerOpening,
    pickerModalTitle: pickerModalTitle,
    showHistory: showHistory,
    showHeader: showHeader,
    showDraft: showDraft,
    showCameraSendPage: false,
    assetIndex: assetIndex
  };
}), _defineProperty(_handleActions, types.CLOSE_IMAGE_PICKER_MODAL, function (state, _ref2) {
  var payload = _ref2.payload;
  return initialState;
}), _defineProperty(_handleActions, types.OPEN_CAMERA_SEND_PAGE, function (state, _ref3) {
  var payload = _ref3.payload;
  return _objectSpread({}, state, {
    showCameraSendPage: true
  });
}), _defineProperty(_handleActions, types.CLOSE_CAMERA_SEND_PAGE, function (state, _ref4) {
  var payload = _ref4.payload;
  return _objectSpread({}, state, {
    showCameraSendPage: false
  });
}), _handleActions), initialState);