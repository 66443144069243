import appLocaleData from "react-intl/locale-data/zh";
import zhMessages from "../locales/zh-TW.json";

const TwLan = {
  messages: {
    ...zhMessages
  },
  antd: null,
  locale: 'zh-Hans-TW',
  data: appLocaleData
};
export default TwLan;
