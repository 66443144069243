import lib from '../../platform';
import logger from './logger';
var pfMiddlewares = lib.pfMiddlewares;
var middlewares = [];
middlewares.concat(pfMiddlewares);

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

export default middlewares;