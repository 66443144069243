import { createAction } from 'redux-actions';
import * as types from '../../store/types';
export var getEssaysCall = createAction(types.GET_ESSAYS_CALL);
export var getEssaysDone = createAction(types.GET_ESSAYS_DONE);
export var getEssaysFail = createAction(types.GET_ESSAYS_FAIL);
export var getEssaysCategoryCall = createAction(types.GET_ESSAYS_CATEGORY_CALL);
export var getEssaysCategoryDone = createAction(types.GET_ESSAYS_CATEGORY_DONE);
export var getEssaysCategoryFail = createAction(types.GET_ESSAYS_CATEGORY_FAIL);
export var getEssaysTagCall = createAction(types.GET_ESSAYS_TAG_CALL);
export var getEssaysTagDone = createAction(types.GET_ESSAYS_TAG_DONE);
export var getEssaysTagFail = createAction(types.GET_ESSAYS_TAG_FAIL);