export default {
  common: {
    all: '全部',
    button: {
      add: '新增',
      edit: '编辑',
      orderby: '排序',
      complete: '完成',
      completed: '已完成',
      next: '下一步',
      submit: '提交',
      ok: '确认',
      no: '否',
      close: '关闭',
      cancel: '取消',
      update: '更新',
      delete: '删除',
      send: '发送',
      join: '加入',
      back: '返回',
      skip: '忽略',
      save: '保存',
      select: '请选择',
      list: '功能清单',
      copy: '复制',
      reload: '重新读取',
      rotate: '旋转',
      more: '查看更多',
      default: '默认',
      manage: '管理'
    },
    enterMessage: '输入信息...',
    foodseperator: '、',
    of: '的',
    inputPlaceholder: '请输入{{content}}',
    loading: '读取中',
    empty: '目前还没有资料',
    localeNames: {
      zh_TW: '中文',
      en: 'English'
    },
    message: {
      title: '通知',
      serverError: '目前网络或者服务器可能有错误，请稍后再试',
      noNet: '目前无网络连接，请确认网络连接状态',
      isNew: '您目前使用的是最新版',
      update: '有更新\n版本{{appVersion}}\n请按下确认立刻更新',
      backStep: '回上一步将无法保存目前编辑内容',
      postError: '您的内容含有敏感讯息，请重新编辑',
      deleteCheck: '确定要删除吗？',
      cancelCheck: '确定要取消吗？'
    },
    time: {
      title: '时间',
      selectDate: '请选择日期',
      select: '请选择时间',
      second: '秒',
      minute: '分钟',
      today: '今天'
    },
    tabs: {
      home: {
        title: '首页',
        label: '首页'
      },
      diary: {
        title: '日记',
        label: '日记'
      },
      information: {
        title: '消息',
        label: '消息'
      },
      coach: {
        title: '专家',
        label: '专家',
        anTitle: '朋友'
      },
      social: {
        title: '社群',
        label: '社群'
      },
      explore: {
        title: '探索',
        label: '探索'
      },
      notification: {
        title: '通知',
        label: '通知'
      },
      profile: {
        title: '我的',
        label: '我的'
      },
      client: {
        title: '我的客户',
        label: '我的客户',
        ffTitle: '我的会员'
      },
      dialog: {
        title: '客户对话',
        label: '客户对话',
        ffTitle: '会员对话'
      },
      setting: {
        title: '设置',
        label: '设置'
      },
      shop: {
        title: '商城',
        label: '商城'
      }
    },
    unit: {
      pound: '磅',
      kg: '公斤',
      cm: '公分',
      day: '天',
      kcal: '大卡',
      servings: '份',
      group: '组',
      times: '次',
      number: '{{unit}}数',
      ml: '毫升',
      gram: '公克',
      calorie: '热量',
      each: '每{{unit}}'
    }
  },
  food: {
    normalServing: '标准份',
    weight: '重量',
    number: '数量',
    info: '食物明细',
    mealByOrder: {
      1: '早餐',
      2: '早上点心',
      3: '午餐',
      4: '下午点心',
      5: '晚餐',
      6: '宵夜',
      7: '点心'
    }
  },
  dataChart: {
    title: '数据记录',
    health: '身体',
    healthTitle: '身体数据',
    nutrition: '营养',
    move: '运动',
    week: '周',
    month: '月',
    year: '年',
    M: '月',
    Y: '年',
    addData: '新增数据',
    noData: '目前暂无数据',
    notShow: '不出现',
    entered: '输入',
    notEntered: '未输入',
    errorTitle: '输入错误',
    errorText: '{{title}}数据请在{{inputMin}}~{{inputMax}}范围内',
    list: '数据列表'
  },
  login: {
    login: '登录',
    register: '注册',
    fbLogin: 'Facebook 登录',
    fbRegister: 'Facebook 注册',
    wechatLogin: 'WeChat 登录',
    wechatRegister: 'WeChat 注册',
    passwordLogin: '帐号密码登录',
    account: '帐号',
    password: '密码',
    name: '昵称',
    selectLanguage: '请选择语言',
    or: '或',
    accountPlaceholder: '请输入 Email',
    passwordPlaceholder: '至少八位数',
    namePlaceholder: '请输入昵称',
    toRegister: '还没有帐号？ 注册',
    toLogin: '已经有帐号了吗？ 登录',
    loginErrorTitle: '验证错误',
    loginErrorMessage: '请确认帐号或密码是否正确',
    loginErrorMessageEmail: '请启动Email验证信',
    loginErrorOverDays: '您的帐号目前并非有效帐号',
    loginErrorEmailFormat: '请输入有效的 Email',
    loginErrorPasswordMissing: '请输入密码',
    noPermission: '您的帐号目前并非有效帐号',
    investigation: '您的帐号正在审核中',
    registerTitle: '验证信已寄出',
    registerMessage: '点击Email中的链接\n就可以注册成功喽！',
    registerErrorTitle: '帐号已存在',
    registerErrorMessage: '此帐号已经被注册了\n请使用其他帐号注册',
    phone: {
      title: '手机号登录',
      codeLogin: '手机验证码登录',
      passwordLogin: '手机密码登录',
      number: '手机号',
      code: '验证码',
      numberPlaceholder: '输入手机号',
      codePlaceholder: '输入验证码',
      pwdPlaceholder: '输入密码',
      countryCode: '手机区号',
      getCode: '获取验证码',
      codeError: '验证码错误',
      pwdError: '手机号或密码错误',
      reGetCode: '重新获取验证码',
      getCodeError: '获取验证码错误'
    },
    bind: {
      title: '绑定',
      codeLogin: '手机验证码绑定',
      passwordLogin: '手机密码绑定'
    }
  },
  forget: {
    title: '忘记密码?',
    titleMessage: '请输入您的Email，我们会将重置密码链接寄至您的Email信箱。',
    ffTitleMessage: '请输入您的身份证号，我们会将重置密码链接寄至您的Email信箱。',
    sendPassword: '重置密码',
    sendTitle: '重置链接已寄出',
    sendMessage: '请依照邮件中的指示，\n重新设置您的密码。',
    notExist: '此Email尚未被注册'
  },
  target: {
    title: '目标设置',
    projectTarget: '计划目标',
    end: '目标设置完成！',
    start: '开始',
    gender: '生理性别',
    male: '男',
    female: '女',
    birthday: '生日',
    nowWeight: '现在体重',
    nowHeight: '现在身高',
    water: '目标饮水',
    waterPlaceholder: '请输入饮水目标或使用系统计算目标',
    reSetWater: '重新计算饮水目标',
    calorie: '目标热量',
    targetWeight: '目标体重',
    warningWeight: '目标体重请小于当前体重',
    warningBmi: '目标体重太轻了！可能造成复胖或影响健康',
    activity: {
      title: '活动程度',
      low: {
        title: '低度',
        text: '主要从事静态活动，睡觉、静卧或悠闲的坐著。如：坐著看书、看电视...等。'
      },
      slow: {
        title: '稍低度',
        text: '主要从事轻度活动，且一天中约1小时从事不激烈的动态活动，如散步。'
      },
      mid: {
        title: '中度',
        text: '从事活动强度为中度的活动，如：游戏、唱歌，且一天中约1小时从事较强动态活动，如：快步走。'
      },
      high: {
        title: '高度',
        text: '身体活动程度较正常快速或激烈、消耗热量较多，一天至少一小时激烈的训练运动。例如：打球、骑脚踏车、有氧运动、重量训练等运动。'
      }
    },
    warningLoss: '减重速率太快了！可能造成复胖或影响健康',
    warningCalorie: '低于基础代谢',
    calorieTitle: '每天需摄取热量',
    monthKg: '每月减少',
    addMonthKg: '每月增加',
    weeklyKg: '每周减少',
    addWeeklyKg: '每周增加',
    targetDay: '预计需要时间',
    targetSet: '冲刺期间帮您设置',
    PlanCountdown: '计划倒数',
    ResetPlan: '重新设置',
    targetIntake: '目标摄取',
    welcome: '欢迎',
    welcomeText: '在这短短的时间内，Cofit将会与你一起努力，让你越吃越健康！',
    lossWeight: '减重',
    addMuscle: '增肌',
    addWeight: '增重',
    keep: '维持',
    select: '选择计划目标',
    warn: '连线期间的目标体重与摄取热量，将由系统自动计算，如需修改请与营养师讨论。',
    targetEnd: {
      default: {
        text1: '恭喜你输入完基本资料，我们已经计算出来属于你的个人化饮食目标啰！',
        text2: '热量与六大类食物份量都是根据刚刚所填写的所有资料演算而来，每天的完成度越高，距离自己的体重目标也越近，加油！'
      },
      child: {
        text1: '恭喜您输入完宝贝的基本资料，我们已经计算出属于孩子个人化的饮食目标唷！',
        text2: '热量与六大类份量都是根据刚刚所填写的资料演算而来的，每天饮食、运动、生活习惯等的完成率越高，对于提升长高潜力及体重的维持越有帮助喔！'
      },
      age65: {
        text1: '年长者身体的肌肉含量会直接影响到死亡风险，因此接下来计划会著重在减少体脂率，或是增加肌肉量，让您同时拥有身材与健康！',
        text2: '热量与营养素目标都是根据刚刚所填写的所有资料演算而来，每天的完成度越高，变健康的速度越快，加油！'
      },
      bmi18: {
        text1: '您的BMI与体重目前落在过瘦体位范围，因此接下来计划会著重在减少体脂率，或是增加肌肉量，让您的身材曲线更加明显！',
        text2: '热量与营养素目标都是根据刚刚所填写的所有资料演算而来，每天的完成度越高，达到目标的速度越快，加油！'
      },
      bmi24: {
        text1: '您的BMI与体重目前落在正常体位范围，因此接下来计划会著重在减少体脂率，对您的曲线雕塑会更加明显哦！',
        text2: '热量与营养素目标都是根据刚刚所填写的所有资料演算而来，每天的完成度越高，达到目标的速度越快，加油！'
      }
    }
  },
  registrationCode: {
    title: '加入群组',
    titleMessage: '营养师将能浏览您以下资料：基本资料、身体、营养数据，并能够与您对话、修改个人目标，以及针对您的饮食进行评论和计算。',
    inputPlaceholder: '请输入群组ID',
    modalTitle: '加入成功！',
    modalMessage: '已经成功加入，准备好成为更好的自己了吗？',
    modalText: '准备好了！',
    error: '群组ID错误',
    errorMessage: '群组ID错误'
  },
  setting: {
    account: '帐号',
    title: '设置',
    language: '语言',
    switchLanguage: '切换语言',
    eula: '使用者条款(EULA)',
    privacyPolicy: '隐私权政策',
    version: '程序版本',
    logout: '退出',
    phoneName: '手机名称',
    system: '系统版本',
    update: '检查程序更新',
    appSetting: '前往系统设置',
    appStore: '前往AppStore',
    web: '官方网站',
    blockList: '封锁列表',
    defaultOpenImage: '默认新增日记开启照片选择器',
    defaultIsPost: '默认公開贴文到社群',
    defaultEditing: '默认开启裁切照片功能',
    defaultIsTwoPost: '默认开启社群双页模式',
    defaultOpenBarCode: '默认App设优先开启条码页',
    defaultPedometer: '预设同步计步资料',
    issue: '问题反馈',
    issuePlaceholder: '请简述您遇到的问题',
    accountPlaceholder: '请输入帐号(可空白)',
    agree: '继续进行即表示您同意'
  },
  customerService: {
    title: '客服',
    ask: '我想询问「{{what}}」'
  },
  bodyAttrs: {
    calorie: '摄取热量',
    height: '身高',
    weight: '体重',
    body_fat_mass_percentage: '体脂率',
    waist_circumference: '腰围',
    hip_circumference: '臀围',
    muscle_mass: '肌肉重',
    bmi: 'BMI',
    waist_hip_ratio: '腰臀比',
    total_body_water_percentage: '身体水份率',
    systolic_blood_pressure: '收缩压 SBP',
    diastolic_blood_pressure: '舒张压 DBP',
    pulse: '脉搏',
    blood_sugar: '血糖',
    total_cholesterol: '总胆固醇 TC',
    high_density_lipoprotein: '高密度脂蛋白胆固醇 HDL-C',
    low_density_lipoprotein_cholesterol: '低密度脂蛋白胆固醇 LDL-C',
    triglyceride: '三酸甘油酯 TG',
    blood_pressure: '血压',
    muscle_mass_percentage: '肌肉率',
    lean_body_mass: '去脂体重',
    fruit: '水果',
    fruits: '水果',
    vegetables: '蔬菜',
    grains: '谷薯',
    meat: '鱼肉蛋豆',
    milk: '乳品',
    oil: '油脂',
    meat_beans_low_fat: '低脂鱼肉蛋豆',
    meat_beans_medium_fat: '中脂鱼肉蛋豆',
    meat_beans_high_fat: '高脂鱼肉蛋豆',
    meat_beans_super_high_fat: '超高脂鱼肉蛋豆',
    milk_skim: '脱脂乳品',
    milk_low_fat: '低脂乳品',
    milk_whole_fat: '全脂乳品',
    measurement: '比例尺',
    water: '饮水',
    water_intake: '饮水'
  },
  moveAttrs: {
    training_volume: '训练量',
    calorie: '运动消耗热量'
  },
  self: {
    title: '个人资料',
    name: '姓名',
    preferredName: '昵称',
    selfIntroduction: '自我介绍',
    noSelfIntroduction: '还没有自我介绍喔～'
  },
  child: {
    basicTitle: '宝贝基本资料',
    estimateTitle: '预测宝贝身高',
    name: '宝贝姓名',
    namePlaceholder: '请输入宝贝姓名',
    changeNote: '切换日记',
    targetDay: '成长课程将进行',
    targetHeight: '宝贝预测身高',
    father: '爸爸身高',
    mom: '妈妈身高'
  },
  chat: {
    loadMore: '载入更多讯息',
    send: '传送给 {{name}}',
    disabled: '对话已关闭',
    members: '成员',
    lastOnlineAt: '最后上线 {{lastOnlineAt}}前',
    addChat: '新增聊天',
    addGroup: '新增群组',
    enterGroup: '加入群组',
    shopChat: '商城客服',
    leaveChats: '退出聊天',
    addMembers: '增加新成员',
    showChatId: '显示群组ID',
    mute: '关闭通知',
    unMute: '开启通知',
    isMute: '已关闭通知',
    isUnMute: '已开启通知',
    chatId: '群组ID',
    leaveCheck: '确定要退出吗？'
  },
  plan: {
    title: '计划选择',
    more: '更多计划',
    isBuy: '已经加入了吗？',
    freeTitle: '免费试用饮食记录功能',
    try: '先试用看看'
  },
  move: {
    train: '训练',
    device: '训练设备',
    duration: '持续时间',
    repeat: '重复',
    fire: '燃烧',
    routine: '计划',
    aerobic: '有氧',
    workout: '肌力',
    custom: '自订',
    addCustom: '新增自订项目',
    addCustomPlaceholder: '请输入自订项目名称',
    calorieInfo: '燃烧 {{calorie}}大卡',
    aerobicInfo: '持续{{dur}}分钟, 燃烧 {{calorie}}大卡',
    workoutInfo: '{{set}}组, 重复{{rep}}次, 每组{{weight}}公斤',
    sessionInfo: '{{name}} {{reps}}次, 休息{{restTime}}秒, {{weight}}',
    sessionItem: '持续{{dur}}分钟, 燃烧 {{calorie}}大卡, {{numericText}}',
    fatigue: {
      title: '疲劳程度',
      numeric4: '轻松写意',
      numeric5: '超有感',
      numeric6: '濒临极限',
      select: '请选择疲劳程度'
    },
    remark: {
      title: '备注',
      placeholder: '例如膝盖发出声音...'
    },
    plan: {
      title: '第 {{group}} 组',
      prev: '上一组',
      next: '下一组'
    },
    load: '负重',
    restTime: '休息时间',
    list: {
      title: '运动清单'
    }
  },
  diary: {
    move: '运动',
    life: '生活',
    diag: '诊断',
    info: '{{genderText}}, {{age}}岁, {{weight}} 公斤',
    over: '超过 / 千卡',
    remain: '剩下 / 千卡',
    haveEat: '已经摄取',
    moveReduction: '运动消耗',
    fullData: '详细数据'
  },
  article: {
    list: '文章列表',
    title: '课程文章',
    newArticle: '最新文章',
    sixgroup: '六大类说明',
    readMore: '继续阅读'
  },
  note: {
    punch: {
      punchText: '在 {{name}}'
    },
    open: '展开',
    close: '收起',
    light: {
      red: '红灯',
      green: '绿灯',
      yellow: '黄灯'
    },
    add: {
      addCard: '新增食物卡',
      addPost: '公开贴文到社群',
      postTo: '公开贴文到',
      addTags: '新增标签',
      addTagName: '标注人名',
      addLocation: '新增地点',
      defaultName: '未命名食物',
      album: '相簿',
      openAlbum: '开启相簿',
      openCamera: '开启相机',
      history: '最近',
      photo: '照片',
      camera: '相机',
      upload: '上传',
      video: '视频',
      draft: '草稿',
      saveDraft: '草稿',
      saveDraftSuccess: '储存草稿成功',
      processing: '处理中',
      picker: '选取照片',
      fastAdd: '快速加入',
      custom: '自定义',
      noDataMessage: '请上传照片或记录文字',
      diag: {
        placeholder: "描述一下诊断结果吧！",
        date: '记录日期',
        time: '记录时间'
      },
      life: {
        placeholder: "描述一下您的心情吧！",
        date: '记录日期',
        time: '记录时间'
      },
      move: {
        placeholder: "描述一下您的运动状况吧！",
        date: '运动日期',
        time: '运动时间',
        addName: '请输入运动名称'
      },
      food: {
        placeholder: "描述一下您的饮食吧！",
        date: '用餐日期',
        time: '用餐时间',
        addName: '请输入食物名称',
        name: '食物名称'
      },
      water: {
        placeholder: "描述一下您的饮水状况吧！",
        date: '记录日期',
        time: '记录时间',
        inputPlaceholder: '请输入饮水量'
      }
    },
    edit: {
      lightPlaceholder: '红、黄灯请留下原因及具体改善方式唷！',
      save: '保存并通知',
      allPlaceholder: '整餐建议'
    },
    delete: {
      title: '删除这项食物记录',
      image: {
        title: '删除照片',
        message: '确定要删除这张照片吗？'
      },
      food: {
        title: '删除这张照片与食物记录'
      }
    }
  },
  comment: {
    noComment: '目前没有任何留言！'
  },
  imagePicker: {
    noPermission: '无法读取您的照片',
    remind: '请前往设置确认照片与相机权限是否开启'
  },
  camera: {
    noPermission: '无法读取您的相机',
    remind: '请前往设置确认相机与麦克风权限是否开启'
  },
  location: {
    noPermission: '无法读取您的位置',
    remind: '请前往设置确认位置权限是否开启'
  },
  social: {
    title: '社群',
    likes: '{{likesCount}} 个人喜欢',
    kept: '收藏',
    keptPosts: '收藏列表',
    info: {
      add: {
        life: '生活记录'
      },
      articles: {
        move: '健身新知',
        nutrition: '运动营养'
      }
    },
    profile: {
      edit: '编辑个人资料',
      post: '贴文',
      unFollow: '取消关注',
      follower: '粉丝',
      following: '关注',
      latest: '最新'
    },
    tab: {
      hot: '热门',
      interesting: '趣闻',
      activity: '活动',
      recommend: '好物'
    }
  },
  question: {
    title: '文章作答',
    multiple: '（复选）',
    success: '恭喜您答对本篇文章！',
    list: '问卷列表',
    test: '开始测试',
    newSend: '立即提交',
    next: '下一题',
    error: '请选择答案',
    overchoice: '选择过多的选项',
    maximun: '上限',
    choice_unit: '项',
    start: '开始'
  },
  nutrition: {
    sixgroup: '六大类',
    threeMacroSummary: '热量及三大营养素',
    low: '低脂',
    medium: '中脂',
    high: '高脂',
    super: '超高脂',
    whole: '全脂',
    off: '脱脂',
    protein: ' 蛋白质',
    cho: '醣类',
    fat: '脂质',
    sugar: '糖',
    alcohol: '酒精'
  },
  database: {
    title: '资料库',
    food: '食物资料库',
    brand: '品牌',
    government: '政府',
    move: '运动资料库'
  },
  client: {
    myClient: '我的客户',
    star: '我的最爱',
    base: '基本资料',
    data: '客户数据',
    chat: '开启对话',
    namePlaceholder: '请输入用户姓名',
    setName: '姓名(客户输入)'
  },
  ffCommon: {
    title: '我的场馆',
    login: {
      accountPlaceholder: '身份证号',
      passwordPlaceholder: '生日，例如:19900101'
    },
    coach: {
      title: '教练',
      about: '关于我',
      expertise: '我的专业',
      license: '证照',
      male: '男性教练',
      female: '女性教练'
    },
    course: {
      title: '课程',
      group: '团课'
    },
    info: {
      title: '资讯',
      openMap: '是否要开启地图',
      time: '营业时间',
      address: '地址',
      phone: '电话'
    },
    profileInfo: {
      title: '会籍资讯',
      phone: '手机',
      venue: '会籍场馆',
      memberCode: '会员编号',
      startTime: '开始时间',
      endTime: '结束时间',
      courseVenue: '课程场馆',
      courseName: '课程名称',
      remainingCourse: '剩余堂数',
      lockerVenue: '置物柜场馆',
      lockerNumber: '置物柜号码'
    },
    card: {
      user: '会员卡',
      staff: '员工卡',
      codeRemind: '点击条码或QR Code可放大',
      remind: '功能说明',
      remindText1: '1.条码页面可透过摇动手机来显示\n',
      remindText2: '2.条码读取后将会暂存在手机中，如果无网络或服务器忙碌中还是可显示暂存条码\n',
      remindText3: '3.开启条码页面会尝试将屏幕亮度调到最大,关闭会回复原始设置\n',
      remindText4: '4.点击条码或QR Code可放大,再次点击缩小\n',
      remindText5: '5.可至设置页设置开App优先开启条码页功能\n'
    }
  },
  notification: {
    allRead: '全部已读'
  },
  search: {
    title: '搜索',
    user: '专家',
    client: '用户',
    tag: '标签',
    clearHistory: '清除历史搜索记录',
    emptyHistory: '目前没有搜索记录'
  },
  group: {
    rank: '群组排行榜',
    greenLightRatios: '绿灯比例',
    movementNumbers: '运动标签数',
    completedQuestionNumbers: '课程完成数',
    greenLightCheckInNumbers: '绿灯好食打卡数',
    exerciseCheckInNumbers: '运动打卡数',
    weightDifferences: '体重变化',
    social: '群组社群墙',
    tag: '群组标签',
    survey: '問券'
  },
  program: {
    title: '方案'
  },
  invoice: {
    title: '电子发票',
    info: '电子发票明细'
  },
  share: {
    title: '分享',
    saveImage: '保存到相簿'
  },
  report: {
    title: '举报',
    post: '贴文',
    placeholder: '请输入举报的原因',
    subTitle: '举报后我们将会审核贴文内容\n封锁用户后在公开社群上将无法看到该用户贴文',
    send: '发送举报',
    block: '封锁',
    unBlock: '解除封锁',
    success: '检举成功，我们将会审核贴文内容'
  },
  editPassword: {
    title: '修改密码',
    setPassword: '设置密码',
    inputOld: '输入目前密码',
    inputNew: '输入新密码',
    inputCheck: '再次输入新密码',
    button: '确定修改密码',
    setButton: '确定设置密码',
    inputCheckError: '两次新密码不同',
    oldPasswordError: '目前密码错误',
    success: '修改密码成功'
  },
  editEmail: {
    title: '修改Email',
    placeholder: '请输入新的Email',
    error: '您输入的Email已被使用',
    prompt: '请先修改您的Email'
  },
  editComment: {
    title: '修改留言',
    placeholder: '请输入新的留言'
  },
  wechat: {
    noApp: '您尚未安装微信'
  },
  classOrders: {
    title: '我的课程',
    reserve: '已预约课程',
    nutrition: '营养课程',
    dateRange: '开始日期：{{start}}\n结束日期：{{end}}',
    end: '课程已结束',
    diffWeek: '课程第 {{diffWeek}} 周'
  },
  barCode: {
    title: '条码',
    scanner: '扫描条码'
  },
  classReserve: {
    title: '预约课程',
    timePlaceholder: '请选择预约时间',
    add: '确定新增预约',
    date: '预约日期',
    time: '预约时间',
    reserve: '立即预约'
  },
  shop: {
    buyGift: {
      goods: '满赠商品',
      item: '当前商品满{{amount}}元即赠送商品',
      select: '点击选择赠送商品',
      price: '赠品,原价:{{priceText}}'
    },
    goods: {
      service: '服务',
      details: '商品详情',
      all: '查看全部'
    },
    menu: {
      title: '更多',
      addMe: '加入我们',
      sellShare: '分销推荐'
    },
    type: {
      recommend: '推荐',
      new: '新品',
      set: '套盒',
      deduce: '满折',
      freegood: '免费试用',
      buyGift: '满赠',
      lifegood: '团购-生活'
    },
    shoppingCart: {
      title: '购物车',
      success: '成功加入购物车',
      selectAll: '全选',
      settlement: '结算'
    },
    add: {
      title: '确认订单',
      cart: '加入购物车',
      pay: '订单支付',
      buy: '立即购买',
      send: '提交订单',
      again: '重新支付',
      number: '购买数量',
      inventory: '库存 {{inventory}}件',
      notInventory: '商品没有库存了'
    },
    pay: {
      title: '支付方式',
      wxPay: '微信支付',
      detail: '支付明细',
      sum: '总计',
      amount: '商品金额',
      freight: '运费',
      nofreight: '免运费',
      nopurchase: '免加价',
      purchase: '加价购',
      success: '支付成功',
      error: '支付失败',
      viewOrder: '查看订单',
      backShop: '返回商城',
      massage: '给商家留言',
      msgPlaceholder: '填写内容'
    },
    purchaser: {
      title: '收货地址',
      placeholder: '请填写收货地址',
      check: '请填写完整地址',
      name: '收货人',
      phone: '电话号码',
      area: '所在区域',
      address: '详细地址',
      setDefault: '设置为默认收货信息',
      contact: '联系人'
    },
    order: {
      title: '订单',
      info: '订单详情',
      myOrder: '我的订单',
      all: '查看全部订单',
      cancel: '已取消',
      pay: '待付款',
      ship: '待发货',
      receipt: '待收货',
      evaluation: '待评价',
      time: '下单时间：',
      amountText: '共{{amount}}件商品',
      orderConfirm: '确认收货',
      orderConfirmCheck: '确认已经收货了吗？',
      orderCancel: '取消订单',
      apply: '申请开票中',
      applySuccess: '审核成功',
      applyFail: '审核失败',
      reApply: '重新审核',
      applyAudit: '审核中',
      view: '查看',
      orderRefund: '退款',
      orderRefundCheck: '确认退款？',
      refunding: '退款中',
      refunded: '退款完成'
    },
    returnGoods: {
      title: '退货申请',
      text: '退款/售后',
      cancel: '取消退货',
      ship: '填写退货物流',
      check: '待商家确认',
      refund: '待退款',
      number: '退货数量',
      content: '退货申请说明',
      contentPlaceholder: '请填写退货申请说明',
      shipTitle: '物流公司',
      shipTitlePlaceholder: '请选择物流公司',
      shipNumber: '物流单号',
      shipNumberPlaceholder: '请填写物流单号',
      orderReturnList: '退货申请',
      orderReturnLog: '退货记录',
      complete: '退款完成',
      shopAddrsInfo: '商家收货信息'
    },
    transport: {
      company: '快递公司',
      code: '快递单号'
    },
    evaluate: {
      title: '评价',
      list: '评价列表',
      buyer: '整体评价：',
      description: '描述相符：',
      service: '服务态度：',
      ship: '发货速度：',
      info: '评语：',
      check: '匿名评价',
      good: '好评',
      mid: '中评',
      bad: '差评'
    },
    integral: {
      myIntegral: '我的积分',
      order: '积分订单',
      title: '积分',
      balance: '余额',
      error: '积分不足',
      buy: '立即兑换',
      rule: '积分规则',
      info: '积分明细',
      activity: '积分活动',
      get: '已获取',
      cost: '已消耗',
      date: '日期',
      id: '序号',
      getName: '参与项目',
      value: '积分值',
      getValue: '获得积分',
      remark: '备注',
      action: '动作',
      run: '前往进行',
      isSign: '您今天已经完成签到了哦',
      ruleTitle: '获取积分规则',
      tagMessage: '[有人@你]【拜托帮我点一下，你也🈶💰💰】'
    },
    invoice: {
      add: '申请开票',
      noNeed: '无需发票',
      person: '个人',
      general: '增值税普票',
      special: '增值税专票',
      order: '订单编号',
      pay: '发票金额',
      addr: '发票寄送地址',
      company: '公司名称',
      numberId: '纳税人识别号',
      companyAddr: '注册地址',
      companyPhone: '注册电话',
      bank: '开户行',
      bankId: '银行帐号',
      review: '我們會在一個工作日內完成审核'
    }
  },
  video: {
    play: '播放',
    recordStart: '按住开始录音',
    recordEnd: '松开结束录音',
    prompt: '往上滑取消录音',
    cancel: '松开 取消录音'
  },
  anApp: {
    companyPageTitle: '美聚',
    companyListTitle: '合作医院',
    companyActivity: '精彩活动',
    questTitle: '肌肤评测'
  }
};