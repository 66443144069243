import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";

var _handleActions;

import _ from 'lodash';
import { combineActions, handleActions } from 'redux-actions';
import * as types from '../../store/types';
export default handleActions((_handleActions = {}, _defineProperty(_handleActions, types.GET_FOOD_STUFF_GROUPS_DONE, function (state, _ref) {
  var payload = _ref.payload;
  return _objectSpread({}, state, {
    groups: payload
  });
}), _defineProperty(_handleActions, types.INIT_FOOD_STUFFS, function (state, _ref2) {
  var payload = _ref2.payload;
  return _objectSpread({}, state, {
    foodStuffs: []
  });
}), _defineProperty(_handleActions, types.GET_FOOD_STUFFS_CALL, function (state, _ref3) {
  var _ref3$payload = _ref3.payload,
      type = _ref3$payload.type,
      category = _ref3$payload.category;
  var isDifferent = state.foodStuffsInfo.type !== type || state.foodStuffsInfo.category !== category;
  return _objectSpread({}, state, {
    foodStuffsInfo: {
      type: type,
      category: category,
      status: 'loading',
      isDifferent: isDifferent
    },
    foodStuffs: isDifferent ? [] : state.foodStuffs
  });
}), _defineProperty(_handleActions, types.SEARCH_FOOD_STUFFS_CALL, function (state, _ref4) {
  var payload = _ref4.payload;
  var isDifferent = state.foodStuffsInfo.keyword !== payload.keyword;
  return _objectSpread({}, state, {
    foodStuffsInfo: {
      keyword: payload.keyword,
      status: 'loading',
      isDifferent: isDifferent
    },
    foodStuffs: isDifferent ? [] : state.foodStuffs
  });
}), _defineProperty(_handleActions, types.SEARCH_FOOD_STUFFS_FAIL, function (state, _ref5) {
  var payload = _ref5.payload;
  return _objectSpread({}, state, {
    foodStuffsInfo: _objectSpread({}, state.foodStuffsInfo, {
      status: 'fail'
    }),
    foodStuffs: []
  });
}), _defineProperty(_handleActions, combineActions(types.GET_FOOD_STUFFS_DONE, types.SEARCH_FOOD_STUFFS_DONE), function (state, _ref6) {
  var _ref6$payload = _ref6.payload,
      results = _ref6$payload.results,
      current_page = _ref6$payload.current_page;

  var info = _objectSpread({}, state.foodStuffsInfo, {
    status: 'success',
    currentPage: current_page
  });

  var isDifferent = state.foodStuffsInfo.isDifferent;
  return _objectSpread({}, state, {
    foodStuffsInfo: info,
    foodStuffs: isDifferent ? results : state.foodStuffs.concat(results)
  });
}), _defineProperty(_handleActions, combineActions(types.GET_FOOD_STUFFS_FAIL, types.SEARCH_FOOD_STUFFS_FAIL), function (state, _ref7) {
  var payload = _ref7.payload;
  return _objectSpread({}, state, {
    foodStuffsInfo: _objectSpread({}, state.foodStuffsInfo, {
      status: 'fail'
    })
  });
}), _handleActions), {
  groups: [],
  foodStuffsInfo: {
    type: '',
    category: '',
    keyword: '',
    status: '',
    isDifferent: false,
    currentPage: ''
  },
  foodStuffs: []
});