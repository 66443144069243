import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _handleActions;

import _ from 'lodash';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import * as types from '../../store/types';
var initialState = {
  invoices: [],
  showLoading: true
};
export default handleActions((_handleActions = {}, _defineProperty(_handleActions, types.GET_INVOICES_CALL, function (state, _ref) {
  var payload = _ref.payload;
  return initialState;
}), _defineProperty(_handleActions, types.GET_INVOICES_DONE, function (state, _ref2) {
  var invoices = _ref2.payload.invoices;
  return produce(state, function (draft) {
    draft.invoices = invoices;
    draft.showLoading = false;
  });
}), _defineProperty(_handleActions, types.GET_INVOICES_FAIL, function (state, _ref3) {
  var payload = _ref3.payload;
  return produce(state, function (draft) {
    draft.showLoading = false;
  });
}), _handleActions), initialState);