import { Observable } from 'rxjs';
import { activity } from '../../services';
import * as actions from '../actions';
import * as types from '../../store/types';
export var getActivityItemGroupsCall = function getActivityItemGroupsCall(action$) {
  return action$.ofType(types.GET_ACTIVITY_ITEM_GROUPS_CALL).switchMap(function (_ref) {
    var payload = _ref.payload;
    return Observable.fromPromise(activity.getActivityItems()).map(function (res) {
      return actions.getActivityItemGroupsDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getActivityItemGroupsFail(err));
    });
  });
};
export var getRoutinesCall = function getRoutinesCall(action$) {
  return action$.ofType(types.GET_ROUTINES_CALL).switchMap(function (_ref2) {
    var payload = _ref2.payload;
    return Observable.fromPromise(activity.getRoutines()).map(function (res) {
      return actions.getRoutinesDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getRoutinesFail(err));
    });
  });
};
export var getMyActivityItemsCall = function getMyActivityItemsCall(action$) {
  return action$.ofType(types.GET_MY_ACTIVITY_ITEMS_CALL).switchMap(function (_ref3) {
    var payload = _ref3.payload;
    return Observable.fromPromise(activity.getMyActivityItems()).map(function (res) {
      return actions.getMyActivityItemsDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getMyActivityItemsFail(err));
    });
  });
};
export var addMyActivityItemCall = function addMyActivityItemCall(action$) {
  return action$.ofType(types.ADD_MY_ACTIVITY_ITEM_CALL).switchMap(function (_ref4) {
    var payload = _ref4.payload;
    return Observable.fromPromise(activity.addMyActivityItem(payload)).map(function (res) {
      return actions.addMyActivityItemDone(res);
    }).catch(function (err) {
      return Observable.of(actions.addMyActivityItemFail(err));
    });
  });
};
export var deleteMyActivityItemCall = function deleteMyActivityItemCall(action$) {
  return action$.ofType(types.DELETE_MY_ACTIVITY_ITEM_CALL).switchMap(function (_ref5) {
    var payload = _ref5.payload;
    return Observable.fromPromise(activity.deleteMyActivityItem(payload)).map(function (res) {
      return actions.deleteMyActivityItemDone(payload);
    }).catch(function (err) {
      return Observable.of(actions.deleteMyActivityItemFail(err));
    });
  });
};