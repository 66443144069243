import { createAction } from 'redux-actions';
import * as types from '../../store/types';
export var getBodyDataCall = createAction(types.GET_BODY_DATA_CALL);
export var getBodyDataDone = createAction(types.GET_BODY_DATA_DONE);
export var getBodyDataFail = createAction(types.GET_BODY_DATA_FAIL);
export var getBodyDataMoreCall = createAction(types.GET_BODY_DATA_MORE_CALL);
export var getBodyDataMoreDone = createAction(types.GET_BODY_DATA_MORE_DONE);
export var getBodyDataMoreFail = createAction(types.GET_BODY_DATA_MORE_FAIL);
export var getNutritionCall = createAction(types.GET_NUTRITION_CALL);
export var getNutritionDone = createAction(types.GET_NUTRITION_DONE);
export var getNutritionFail = createAction(types.GET_NUTRITION_FAIL);
export var getNutritionMoreCall = createAction(types.GET_NUTRITION_MORE_CALL);
export var getNutritionMoreDone = createAction(types.GET_NUTRITION_MORE_DONE);
export var getNutritionMoreFail = createAction(types.GET_NUTRITION_MORE_FAIL);
export var getMoveDataCall = createAction(types.GET_MOVE_DATA_CALL);
export var getMoveDataDone = createAction(types.GET_MOVE_DATA_DONE);
export var getMoveDataFail = createAction(types.GET_MOVE_DATA_FAIL);
export var getMoveDataMoreCall = createAction(types.GET_MOVE_DATA_MORE_CALL);
export var getMoveDataMoreDone = createAction(types.GET_MOVE_DATA_MORE_DONE);
export var getMoveDataMoreFail = createAction(types.GET_MOVE_DATA_MORE_FAIL);
export var setScrollEnabled = createAction(types.SET_SCROLL_ENABLED);
export var setDateType = createAction(types.SET_DATE_TYPE);
export var setProfileClient = createAction(types.SET_PROFILE_CLIENT);
export var removeDataCall = createAction(types.REMOVE_DATA_CALL);
export var removeDataDone = createAction(types.REMOVE_DATA_DONE);
export var removeDataFail = createAction(types.REMOVE_DATA_FAIL);