import { createAction } from 'redux-actions';
import * as types from '../types';
export var getQuestionListCall = createAction(types.GET_QUESTION_LIST_CALL);
export var getQuestionListDone = createAction(types.GET_QUESTION_LIST_DONE);
export var getQuestionListFail = createAction(types.GET_QUESTION_LIST_FAIL);
export var getQuestionCall = createAction(types.GET_QUESTION_CALL);
export var getQuestionDone = createAction(types.GET_QUESTION_DONE);
export var getQuestionFail = createAction(types.GET_QUESTION_FAIL);
export var sendQuestionCall = createAction(types.SEND_QUESTION_CALL);
export var sendQuestionDone = createAction(types.SEND_QUESTION_DONE);
export var sendQuestionFail = createAction(types.SEND_QUESTION_FAIL);
export var newSendQuestionCall = createAction(types.NEW_SEND_QUESTION_CALL);
export var newSendQuestionDone = createAction(types.NEW_SEND_QUESTION_DONE);
export var newSendQuestionFail = createAction(types.NEW_SEND_QUESTION_FAIL);
export var getQuestionResultCall = createAction(types.GET_QUESTION_RESULT_CALL);
export var getQuestionResultDone = createAction(types.GET_QUESTION_RESULT_DONE);
export var getQuestionResultFail = createAction(types.GET_QUESTION_RESULT_FAIL);
export var getCofitQuestionCall = createAction(types.GET_COFIT_QUESTION_CALL);
export var getCofitQuestionDone = createAction(types.GET_COFIT_QUESTION_DONE);
export var getCofitQuestionFail = createAction(types.GET_COFIT_QUESTION_FAIL);
export var openCofitQuestionPage = createAction(types.OPEN_QUESTION_COFIT_PAGE_DONE);