import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";

var _handleActions;

import _ from 'lodash';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import * as types from '../types';

var formatSurvey = function formatSurvey(surveys) {
  return _.map(surveys, function (sur) {
    return _objectSpread({}, sur, {
      kind: sur.question_type,
      title: sur.question_name,
      options: _.map(sur.survey_choices, function (choice) {
        return choice.select = {
          text: choice.sub_choice_content ? choice.sub_choice_content : choice.choice_content,
          choice_group: choice.sub_choice_content ? choice.choice_content : null,
          is_free_answer: choice.is_free_answer,
          select: false
        };
      })
    });
  });
};

export default handleActions((_handleActions = {}, _defineProperty(_handleActions, types.GET_QUESTION_CALL, function (state, _ref) {
  var payload = _ref.payload;
  return produce(state, function (draft) {
    draft.showLoading = true;
  });
}), _defineProperty(_handleActions, types.GET_QUESTION_DONE, function (state, _ref2) {
  var _ref2$payload = _ref2.payload,
      data = _ref2$payload.data,
      questLvlIndex = _ref2$payload.questLvlIndex;
  return produce(state, function (draft) {
    var hasNext = data.hasNext,
        objs = data.objs;

    if (objs.length !== 0) {
      var questionData = objs[0];
      var quest_name = questionData.quest_name,
          quest_purpose = questionData.quest_purpose,
          questPropertiesList = questionData.questPropertiesList,
          questQuestionsList = questionData.questQuestionsList;
      var questions = [];

      if (questPropertiesList.length !== 0) {
        var questPropertiesOptions = {
          question_title: quest_purpose,
          questionChoiceList: questPropertiesList,
          showStartButton: true
        };
        questions = [questPropertiesOptions].concat(_toConsumableArray(questQuestionsList));
      } else {
        questions = questQuestionsList;
      }

      questions = _.map(questions, function (question) {
        var questionChoiceList = question.questionChoiceList,
            question_title = question.question_title;

        var options = _.map(questionChoiceList, function (option) {
          var question_choice_dsc = option.question_choice_dsc,
              quest_property_name = option.quest_property_name;
          var text = question_choice_dsc || quest_property_name;
          return _objectSpread({}, option, {
            text: text
          });
        });

        return _objectSpread({}, question, {
          title: question_title,
          options: options
        });
      });
      draft.showLoading = false;
      draft.questionDataList[questLvlIndex] = {
        hasNext: hasNext === 1,
        title: quest_name,
        questions: questions
      };
    }
  });
}), _defineProperty(_handleActions, types.GET_QUESTION_FAIL, function (state, _ref3) {
  var payload = _ref3.payload;
  return produce(state, function (draft) {
    draft.showLoading = false;
  });
}), _defineProperty(_handleActions, types.GET_QUESTION_RESULT_DONE, function (state, _ref4) {
  var _ref4$payload = _ref4.payload,
      code = _ref4$payload.code,
      data = _ref4$payload.data;
  return produce(state, function (draft) {
    if (code === 200) {
      var newestQuestResult = data.newestQuestResult,
          title = data.title;
      draft.newestQuestResult = newestQuestResult;
      draft.resultTitle = title;
    }
  });
}), _defineProperty(_handleActions, types.GET_COFIT_QUESTION_DONE, function (state, _ref5) {
  var payload = _ref5.payload;
  console.log(payload);
  return produce(state, function (draft) {
    var _payload$survey = payload.survey,
        name = _payload$survey.name,
        notice_info = _payload$survey.data.notice_info,
        survey_questions = payload.survey_questions;
    draft.newestQuestResult = [];
    draft.resultTitle = name;
    draft.questionDataList = {
      hasNext: false,
      questions: formatSurvey([survey_questions[0]].concat(_toConsumableArray(survey_questions[0]['sub_survey_questions']))),
      title: name,
      greetingText: notice_info
    };
  });
}), _handleActions), {
  showLoading: true,
  resultTitle: undefined,
  newestQuestResult: [],
  questionDataList: [{
    hasNext: false,
    questions: [],
    title: ','
  }]
});