export var GET_PRICINGS_CALL = 'GET_PRICINGS_CALL';
export var GET_PRICINGS_DONE = 'GET_PRICINGS_DONE';
export var GET_PRICINGS_FAIL = 'GET_PRICINGS_FAIL';
export var SET_PRICINGS = 'SET_PRICINGS';
export var REGISTER_CODE_CALL = 'REGISTER_CODE_CALL';
export var REGISTER_CODE_DONE = 'REGISTER_CODE_DONE';
export var REGISTER_CODE_FAIL = 'REGISTER_CODE_FAIL';
export var CHECK_REGISTER_CODE_CALL = 'CHECK_REGISTER_CODE_CALL';
export var CHECK_REGISTER_CODE_DONE = 'CHECK_REGISTER_CODE_DONE';
export var CHECK_REGISTER_CODE_FAIL = 'CHECK_REGISTER_CODE_FAIL';
export var RESET_REGISTER_CODE_ERROR = 'RESET_REGISTER_CODE_ERROR';