import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _ from 'lodash';
import { tagPattern, renderTagMemberText } from './Tag';
export var getMessagesWithAvatar = function getMessagesWithAvatar(_ref) {
  var chatroom = _ref.chatroom,
      messages = _ref.messages;
  var _chatroom$members = chatroom.members,
      members = _chatroom$members === void 0 ? [] : _chatroom$members,
      isService = chatroom.isService,
      serviceAvatar = chatroom.avatar_thumbnail_url,
      avatarIcon = chatroom.avatar;
  return _.map(messages, function (msg) {
    var gid = msg.user._id,
        mediaUrl = msg.mediaUrl,
        mediaType = msg.mediaType;
    var member = _.find(members, {
      gid: gid
    }) || {
      nick_name: '',
      id: ''
    };
    var name = member.nick_name,
        id = member.id,
        memberAvatar = member.avatar_thumbnail_url,
        type = member.class_name;
    var avatar = isService ? serviceAvatar : memberAvatar;
    var user = {
      _id: gid,
      avatar: avatar,
      avatarIcon: avatarIcon,
      name: name,
      id: id,
      type: type,
      isService: isService
    };
    return _objectSpread({}, msg, {
      image: mediaUrl,
      user: user,
      mediaUrl: mediaUrl,
      mediaType: mediaType
    });
  });
};