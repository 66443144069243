import _taggedTemplateLiteral from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n    fragment ClientSurmmariesFields on ConsultingClientSummary {\n      id\n      date\n      height\n      weight\n      bmi\n      created_at\n      updated_at\n      client_id\n      waist_circumference\n      hip_circumference\n      waist_hip_ratio\n      body_fat_mass\n      body_fat_mass_percentage\n      lean_body_mass\n      lean_body_mass_percentage\n      total_body_water\n      bmr\n      bee\n      af\n      systolic_blood_pressure\n      diastolic_blood_pressure\n      pulse\n      blood_sugar\n      total_cholesterol\n      triglyceride\n      low_density_lipoprotein_cholesterol\n      high_density_lipoprotein\n      cholesterol\n      uric_acid\n      edema\n      ee\n      blood\n      sf\n      af_bk\n      source_type\n      muscle_mass_percentage\n    }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { gql } from 'apollo-boost';
export var CLIENT_SUMMARIES_ALL_FIELDS = gql(_templateObject());