export var GET_CLIENT_CALL = 'GET_CLIENT_CALL';
export var GET_CLIENT_DONE = 'GET_CLIENT_DONE';
export var GET_CLIENT_FAIL = 'GET_CLIENT_FAIL';
export var GET_CLIENT_ME_CALL = 'GET_CLIENT_ME_CALL';
export var GET_CLIENT_ME_DONE = 'GET_CLIENT_ME_DONE';
export var GET_CLIENT_ME_FAIL = 'GET_CLIENT_ME_FAIL';
export var GET_FF_CLIENT_ME_CALL = 'GET_FF_CLIENT_ME_CALL';
export var GET_FF_CLIENT_ME_DONE = 'GET_FF_CLIENT_ME_DONE';
export var GET_FF_CLIENT_ME_FAIL = 'GET_FF_CLIENT_ME_FAIL';
export var UPDATE_CLIENT_CALL = 'UPDATE_CLIENT_CALL';
export var UPDATE_CLIENT_DONE = 'UPDATE_CLIENT_DONE';
export var UPDATE_CLIENT_FAIL = 'UPDATE_CLIENT_FAIL';
export var SET_CLIENT_ID = 'SET_CLIENT_ID';
export var GET_CLIENTS_DONE = 'GET_CLIENTS_DONE';
export var GET_SOCIAL_CLIENT_ME_CALL = 'GET_SOCIAL_CLIENT_ME_CALL';
export var GET_SOCIAL_CLIENT_ME_DONE = 'GET_SOCIAL_CLIENT_ME_DONE';
export var GET_SOCIAL_CLIENT_ME_FAIL = 'GET_SOCIAL_CLIENT_ME_FAIL';