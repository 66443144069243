import React, {Component} from "react";
import {connect} from "react-redux";
import { translate } from 'react-i18next';
import {Avatar, Popover} from "antd";
import {Link} from "react-router-dom";
import { bindActionCreators } from 'redux'
import * as actionCreators from 'store/actions'
import { getUser } from 'store/selectors'

class UserInfo extends Component {

  render() {
    const { user, t } = this.props
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li><Link to="/profile">{t('setting:account')}</Link></li>
        <li onClick={() => this.props.actions.webLogoutCall()}>{t('setting:logout')}</li>
      </ul>
    );

    return (
      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
               trigger="click">
        <Avatar src={user.avatar_thumbnail_url}
                className="gx-avatar gx-pointer" alt=""/>
      </Popover>
    )
  }
}

const mapStateToProps = (state) => {
  const user = getUser(state.user)
  return { user }
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actionCreators }, dispatch),
  }
}

export default translate()(connect(mapStateToProps, mapDispatchToProps)(UserInfo))
