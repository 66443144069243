import api from '../api';
export var getArticle = function getArticle(_ref) {
  var id = _ref.id;
  return api.get("/v4/courses/".concat(id));
};
export var getArticleList = function getArticleList(_ref2) {
  var id = _ref2.id;
  return api.get('/v4/group_class_activities/courses', {
    client_id: id
  });
};
export var getSixGroupDetail = function getSixGroupDetail(param) {
  return api.get('/v4/public/six_group_courses', param);
};
export var setArticleQuestionComplete = function setArticleQuestionComplete(_ref3) {
  var courseId = _ref3.courseId;
  return api.post("/v4/courses/".concat(courseId, "/complete"));
};