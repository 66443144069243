export const setItemAsync = async (key, value) => {
  await null;
  return localStorage.setItem(key, value);
}

export const getItemAsync = async (key) => {
  await null;
  return localStorage.getItem(key)
}

export const removeItemAsync = async (key) => {
  await null;
  return localStorage.removeItem(key)
}
