import api from '../api';
import lib from '../platform';
var pfConfig = lib.pfConfig,
    pfSession = lib.pfSession;
var configApi = pfConfig.api;
var signInPath = configApi.signInPath,
    resetPasswordPath = configApi.resetPasswordPath,
    signOutPath = configApi.signOutPath,
    registerPath = configApi.registerPath,
    appName = configApi.appName,
    testAppName = configApi.testAppName,
    isTestShop = configApi.isTestShop;
var app_name = isTestShop ? testAppName : appName;
export var accountLogin = function accountLogin(_ref) {
  var account = _ref.account,
      password = _ref.password;
  return api.post(signInPath, {
    app_name: app_name,
    source: 'cofit',
    identity_number: account,
    password: password,
    birthday: password
  }).then(function (data) {
    return data.access_token;
  });
};
export var emailLogin = function emailLogin(_ref2) {
  var account = _ref2.account,
      password = _ref2.password;
  return api.post(signInPath, {
    app_name: app_name,
    source: 'cofit',
    email: account,
    password: password
  }).then(function (data) {
    return data.access_token;
  });
};
export var getFBTokenAsync = function getFBTokenAsync() {
  return pfSession.getFBTokenAsync();
};
export var getWechatCodeAsync = function getWechatCodeAsync() {
  return pfSession.getWechatCodeAsync();
};
export var getAppleTokenAsync = function getAppleTokenAsync() {
  return pfSession.getAppleTokenAsync();
};
export var fbLogin = function fbLogin(token) {
  return api.get('/v4/auth/facebook_access_token/callback', {
    app_name: app_name,
    access_token: token
  }).then(function (data) {
    return data.access_token;
  });
};
export var wechatLogin = function wechatLogin(_ref3) {
  var code = _ref3.code,
      mobileRequired = _ref3.mobileRequired;
  return api.post('/v4/clients/wechat_code_sign_in', {
    app_name: app_name,
    code: code,
    mobile_required: mobileRequired
  });
};
export var bindWechatId = function bindWechatId(_ref4) {
  var identityId = _ref4.identityId;
  return api.post('/v4/clients/bind_wechat_identity', {
    app_name: app_name,
    identity_id: identityId
  });
};
export var appleLogin = function appleLogin(_ref5) {
  var code = _ref5.code,
      mobileRequired = _ref5.mobileRequired,
      client_info = _ref5.client_info;
  return api.post('/v4/clients/apple_code_sign_in', {
    app_name: app_name,
    code: code,
    client_info: client_info,
    mobile_required: mobileRequired
  });
};
export var bindAppleId = function bindAppleId(_ref6) {
  var identityId = _ref6.identityId;
  return api.post('/v4/clients/bind_apple_identity', {
    app_name: app_name,
    identity_id: identityId
  });
};
export var register = function register(_ref7) {
  var account = _ref7.account,
      password = _ref7.password,
      name = _ref7.name;
  return api.post(registerPath, {
    app_name: app_name,
    source: 'cofit',
    email: account,
    password: password,
    name: name,
    last_name: name,
    first_name: '',
    nick_name: name
  });
};
export var resetPassword = function resetPassword(_ref8) {
  var inputValue = _ref8.inputValue;
  return api.post(resetPasswordPath, {
    app_name: app_name,
    source: 'cofit',
    email: inputValue,
    identity_number: inputValue
  });
};
export var logout = function logout(_ref9) {
  var uuid = _ref9.uuid,
      appPackageName = _ref9.appPackageName;
  return api.post(signOutPath, {
    app_name: app_name,
    device_info: {
      uuid: uuid,
      appPackageName: appPackageName
    }
  });
};
export var shopLogin = function shopLogin(param) {
  return api.postShopApi('/loginSaveInfo', param, true);
};
export var mobileLogin = function mobileLogin(_ref10) {
  var mobile = _ref10.mobile,
      password = _ref10.password;
  return api.post('/v4/clients/mobile_sign_in', {
    app_name: app_name,
    mobile: mobile,
    password: password
  });
};
export var mobileRegister = function mobileRegister(_ref11) {
  var mobile = _ref11.mobile,
      password = _ref11.password;
  return api.post('/v4/clients/register_mobile', {
    app_name: app_name,
    mobile: mobile,
    password: password
  });
};
export var mobileSmsLogin = function mobileSmsLogin(_ref12) {
  var mobile = _ref12.mobile,
      code = _ref12.code;
  return api.post('/v4/clients/register_mobile_with_code', {
    app_name: app_name,
    mobile: mobile,
    code: code
  });
};
export var setMobilePassword = function setMobilePassword(_ref13) {
  var mobile = _ref13.mobile,
      password = _ref13.password;
  return api.post('/v4/clients/set_mobile_password', {
    app_name: app_name,
    mobile: mobile,
    password: password
  });
};