import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _ from 'lodash';
import moment from 'moment';
import { Observable } from 'rxjs';
import './RxOperators';
import * as actions from '../actions';
import * as types from '../../store/types';
import { post } from '../../services';
import { getMember } from '../../store/selectors';
import lib from '../../platform';
var pfConfig = lib.pfConfig;
var isProType = pfConfig.isProType,
    configType = pfConfig.common.type,
    _pfConfig$post = pfConfig.post,
    defaultTabIndex = _pfConfig$post.defaultTabIndex,
    defaultTabKey = _pfConfig$post.defaultTabKey,
    defaultPostType = _pfConfig$post.defaultPostType;

var parseData = function parseData(obj) {
  _.forEach(obj, function (record, idx) {
    obj[idx].publishedAt = moment(record.published_at).format('YYYY-MM-DD HH:mm');

    if (record.object.type === 'FoodNote') {
      obj[idx].object.calorie = record.object.calorie ? Math.round(record.object.calorie, 0) : '';
    }
  });

  return obj;
};

export var getSocialPostsCall = function getSocialPostsCall(action$, store) {
  return action$.ofType(types.GET_SOCIAL_POSTS_CALL).switchMap(function (_ref) {
    var payload = _ref.payload,
        type = _ref.payload.type;
    return Observable.fromPromise(post.getSocialPosts(payload)).map(function (_ref2) {
      var data = _ref2.data,
          more = _ref2.more;
      var posts = parseData(data);
      return actions.getSocialPostsDone({
        posts: posts,
        type: type,
        more: more
      });
    }).catch(function (err) {
      return Observable.of(actions.getSocialPostsFail(err));
    });
  });
};
export var getMyPosts = function getMyPosts(action$, store) {
  return action$.ofType(types.GET_MY_POSTS_CALL).switchMap(function (_ref3) {
    var payload = _ref3.payload,
        type = _ref3.payload.type;
    return Observable.fromPromise(post.getMyPosts(payload)).map(function (_ref4) {
      var data = _ref4.data,
          more = _ref4.more;
      var posts = parseData(data);
      return actions.getMyPostsDone({
        posts: posts,
        type: type,
        more: more
      });
    }).catch(function (err) {
      return Observable.of(actions.getMyPostsFail(err));
    });
  });
};
export var getMyKeptPostsCall = function getMyKeptPostsCall(action$, store) {
  return action$.ofType(types.GET_MY_KEPT_POSTS_CALL).switchMap(function (_ref5) {
    var payload = _ref5.payload,
        type = _ref5.payload.type;
    return Observable.fromPromise(post.getMyKeptPosts(payload)).map(function (_ref6) {
      var data = _ref6.data,
          more = _ref6.more;
      var posts = parseData(data);
      return actions.getMyKeptPostsDone({
        posts: posts,
        type: type,
        more: more
      });
    }).catch(function (err) {
      return Observable.of(actions.getMyKeptPostsFail(err));
    });
  });
};
export var getTagPosts = function getTagPosts(action$, store) {
  return action$.ofType(types.GET_TAG_POSTS_CALL).switchMap(function (_ref7) {
    var payload = _ref7.payload,
        _ref7$payload = _ref7.payload,
        tagId = _ref7$payload.tagId,
        type = _ref7$payload.type;
    return Observable.fromPromise(post.getTagPosts(payload)).map(function (_ref8) {
      var data = _ref8.data,
          more = _ref8.more;
      var posts = parseData(data);
      return actions.getTagPostsDone({
        tagId: tagId,
        posts: posts,
        type: type,
        more: more
      });
    }).catch(function (err) {
      return Observable.of(actions.getTagPostsFail(err));
    });
  });
};
export var getTabPosts = function getTabPosts(action$, store) {
  return action$.ofType(types.GET_TAB_POSTS_CALL).switchMap(function (_ref9) {
    var payload = _ref9.payload,
        _ref9$payload = _ref9.payload,
        tabKey = _ref9$payload.tabKey,
        type = _ref9$payload.type;
    var param = payload;
    var getTabPosts;

    switch (tabKey) {
      case 'following':
        getTabPosts = post.getFollowingPosts;
        break;

      case 'latest':
        getTabPosts = post.getLatestPosts;
        break;

      case 'activity':
        getTabPosts = post.getChannelPosts;
        param = _objectSpread({}, param, {
          target_type: 'Channel',
          target_id: 38
        });
        break;

      case 'recommend':
        getTabPosts = post.getChannelPosts;
        param = _objectSpread({}, param, {
          target_type: 'Channel',
          target_id: 39
        });
        break;

      default:
        getTabPosts = post.getFollowingPosts;
        break;
    }

    return Observable.fromPromise(getTabPosts(param)).map(function (_ref10) {
      var data = _ref10.data,
          more = _ref10.more;
      var posts = parseData(data);
      return actions.getTabPostsDone({
        tabKey: tabKey,
        posts: posts,
        type: type,
        more: more
      });
    }).catch(function (err) {
      return Observable.of(actions.getTabPostsFail(err));
    });
  });
};
export var getMediaPosts = function getMediaPosts(action$, store) {
  return action$.ofType(types.GET_MEDIA_POSTS_CALL).switchMap(function (_ref11) {
    var payload = _ref11.payload,
        type = _ref11.payload.type;
    return Observable.fromPromise(post.getMediaPosts(payload)).map(function (_ref12) {
      var data = _ref12.data,
          more = _ref12.more;
      var posts = parseData(data);
      return actions.getMedaiPostsDone({
        posts: posts,
        type: type,
        more: more
      });
    }).catch(function (err) {
      return Observable.of(actions.getMedaiPostsFail(err));
    });
  });
};
export var getPost = function getPost(action$, store) {
  return action$.ofType(types.GET_POST_CALL).switchMap(function (_ref13) {
    var payload = _ref13.payload;
    return Observable.fromPromise(post.getPost(payload)).map(function (res) {
      return actions.getPostDone(parseData([res]));
    }).catch(function (err) {
      return Observable.of(actions.getPostFail(err));
    });
  });
};
export var deletePostCall = function deletePostCall(action$, store) {
  return action$.ofType(types.DELETE_POST_CALL).switchMap(function (_ref14) {
    var _ref14$payload = _ref14.payload,
        id = _ref14$payload.id,
        goBack = _ref14$payload.goBack,
        reloadProfile = _ref14$payload.reloadProfile;
    var memberId = store.value.member.id;
    return Observable.fromPromise(post.deletePost({
      id: id
    })).concatMap(function (res) {
      return [actions.deletePostDone({
        id: id
      }), goBack ? actions.pageGoBack() : actions.empty(), reloadProfile ? actions.getMyProfileCall({
        id: memberId
      }) : actions.empty()];
    }).catch(function (err) {
      return Observable.of(actions.deletePostFail(err));
    });
  });
};
export var togglePostLike = function togglePostLike(action$, store) {
  return action$.ofType(types.TOGGLE_POST_LIKE_CALL).switchMap(function (_ref15) {
    var _ref15$payload = _ref15.payload,
        liked = _ref15$payload.liked,
        id = _ref15$payload.id,
        selectType = _ref15$payload.selectType,
        userId = _ref15$payload.userId,
        userType = _ref15$payload.userType,
        tagId = _ref15$payload.tagId;
    var member = getMember(store.value);
    var member_id = member.id,
        avatar_thumbnail_url = member.avatar_thumbnail_url,
        nick_name = member.nick_name;
    var member_type = configType;
    var likedMember = {
      member_id: member_id,
      member_type: member_type,
      avatar_thumbnail_url: avatar_thumbnail_url,
      nick_name: nick_name
    };
    return Observable.concat(Observable.of(actions.togglePostLike({
      likedMember: likedMember,
      liked: liked,
      id: id,
      selectType: selectType,
      userId: userId,
      userType: userType,
      tagId: tagId
    })), Observable.fromPromise(post.togglePostLike({
      liked: liked,
      id: id
    })).map(function (res) {
      return actions.togglePostLikeDone(res);
    }).catch(function (err) {
      return Observable.of(actions.togglePostLikeFail(err));
    }));
  });
};
export var togglePostKeep = function togglePostKeep(action$, store) {
  return action$.ofType(types.TOGGLE_POST_KEEP_CALL).switchMap(function (_ref16) {
    var _ref16$payload = _ref16.payload,
        kept = _ref16$payload.kept,
        id = _ref16$payload.id;
    return Observable.fromPromise(post.togglePostKeep({
      kept: kept,
      id: id
    })).map(function (res) {
      return actions.togglePostKeepDone(res);
    }).catch(function (err) {
      return Observable.of(actions.togglePostKeepFail(err));
    });
  });
};
export var createPostComment = function createPostComment(action$, store) {
  return action$.ofType(types.CREATE_POST_COMMENT_CALL).switchMap(function (_ref17) {
    var _ref17$payload = _ref17.payload,
        content = _ref17$payload.content,
        id = _ref17$payload.id,
        selectType = _ref17$payload.selectType,
        userId = _ref17$payload.userId,
        userType = _ref17$payload.userType,
        tagId = _ref17$payload.tagId,
        _tag_members = _ref17$payload.tagMembers;
    var member = getMember(store.value);
    var sender_id = member.id,
        avatar_thumbnail_url = member.avatar_thumbnail_url,
        sender_name = member.nick_name;
    var sender_type = configType;
    var comment = {
      sender_id: sender_id,
      sender_type: sender_type,
      sender_name: sender_name,
      content: content,
      sender_avatar_thumbnail_url: avatar_thumbnail_url,
      _uid: Math.random().toString(16).substring(7)
    };
    var newCommentParam = {
      comment: comment,
      id: id,
      selectType: selectType,
      userId: userId,
      userType: userType,
      tagId: tagId
    };
    return Observable.concat(Observable.of(actions.insertPostComment(newCommentParam)), Observable.fromPromise(post.createComment({
      content: content,
      id: id,
      _tag_members: _tag_members
    })).map(function (res) {
      return actions.createPostCommentDone({
        newComment: res,
        postData: newCommentParam
      });
    }).catch(function (err) {
      return Observable.of(actions.createPostCommentFail(err));
    }));
  });
};
export var openPost = function openPost(action$, store) {
  return action$.ofType(types.OPEN_POST_CALL).concatMap(function (_ref18) {
    var payload = _ref18.payload,
        _ref18$payload = _ref18.payload,
        mode = _ref18$payload.mode,
        selectAasset = _ref18$payload.selectAasset,
        isFocusComment = _ref18$payload.isFocusComment;
    return Observable.concat(Observable.of(actions.selectPost(payload)), Observable.of(actions.openPostPage({
      mode: mode,
      selectAasset: selectAasset,
      isFocusComment: isFocusComment
    })), Observable.of(actions.openPostDone()));
  }).catch(function (err) {
    return Observable.of(actions.openPostFail(err));
  });
};
export var openSocialProfile = function openSocialProfile(action$, store) {
  return action$.ofType(types.OPEN_SOCIAL_PROFILE_CALL).concatMap(function (_ref19) {
    var _ref19$payload = _ref19.payload,
        id = _ref19$payload.id,
        type = _ref19$payload.type,
        name = _ref19$payload.name;
    return Observable.concat(Observable.of(actions.getSocialProfileCall({
      id: id,
      type: type
    })), Observable.of(actions.getProfilePostsCall({
      id: id,
      userType: type,
      type: 'first'
    })), Observable.of(actions.openSocialProfilePage({
      name: name
    })), Observable.of(actions.openSocialProfileDone()));
  }).catch(function (err) {
    return Observable.of(actions.openSocialProfileFail(err));
  });
};
export var openPostNotification = function openPostNotification(action$, store) {
  return action$.ofType(types.OPEN_POST_NOTIFICATION_CALL).switchMap(function (_ref20) {
    var payload = _ref20.payload,
        selectId = _ref20.payload.selectId;
    return Observable.fromPromise(post.getPost({
      id: selectId
    })).concatMap(function (res) {
      return [actions.openPostNotificationDone(res), actions.openPostCall(payload)];
    }).catch(function (err) {
      return Observable.of(actions.openPostNotificationFail(err));
    });
  });
};
export var getSocialProfile = function getSocialProfile(action$, store) {
  return action$.ofType(types.GET_SOCIAL_PROFILE_CALL).switchMap(function (_ref21) {
    var _ref21$payload = _ref21.payload,
        type = _ref21$payload.type,
        id = _ref21$payload.id;
    var callService = type === 'User' ? post.getSocialUser : post.getSocialClient;
    return Observable.fromPromise(callService({
      id: id
    })).map(function (res) {
      return actions.getSocialProfileDone(_objectSpread({}, res, {
        type: type
      }));
    }).catch(function (err) {
      return Observable.of(actions.getSocialProfileFail(err));
    });
  });
};
export var getProfilePosts = function getProfilePosts(action$, store) {
  return action$.ofType(types.GET_PROFILE_POSTS_CALL).switchMap(function (_ref22) {
    var payload = _ref22.payload,
        _ref22$payload = _ref22.payload,
        userType = _ref22$payload.userType,
        id = _ref22$payload.id,
        type = _ref22$payload.type;
    var callService = userType === 'User' ? post.getUserPosts : post.getClientPosts;
    return Observable.fromPromise(callService(payload)).map(function (_ref23) {
      var data = _ref23.data,
          more = _ref23.more;
      return actions.getProfilePostsDone({
        posts: parseData(data),
        userType: userType,
        id: id,
        type: type,
        more: more
      });
    }).catch(function (err) {
      return Observable.of(actions.getProfilePostsFail(err));
    });
  });
};
export var getMyProfile = function getMyProfile(action$, store) {
  return action$.ofType(types.GET_MY_PROFILE_CALL).switchMap(function (_ref24) {
    var id = _ref24.payload.id;
    var callService = isProType ? post.getSocialUser : post.getSocialClient;
    return Observable.fromPromise(callService({
      id: id
    })).map(function (res) {
      return actions.getMyProfileDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getSocialProfileFail(err));
    });
  });
};
export var toggleFollow = function toggleFollow(action$, store) {
  return action$.ofType(types.TOGGLE_FOLLOW_CALL).switchMap(function (_ref25) {
    var _ref25$payload = _ref25.payload,
        follow = _ref25$payload.follow,
        id = _ref25$payload.id,
        type = _ref25$payload.type;
    var callService = type === 'User' ? post.toggleFollowUser : post.toggleFollowClient;
    var member = getMember(store.value);
    var member_id = member.id,
        avatar_thumbnail_url = member.avatar_thumbnail_url,
        nick_name = member.nick_name;
    var member_type = configType;
    var followerMember = {
      member_id: member_id,
      member_type: member_type,
      avatar_thumbnail_url: avatar_thumbnail_url,
      nick_name: nick_name
    };
    return Observable.concat(Observable.of(actions.toggleFollow({
      id: id,
      type: type,
      follow: follow,
      followerMember: followerMember
    })), Observable.fromPromise(callService({
      follow: follow,
      id: id
    })).concatMap(function (res) {
      return [actions.toggleFollowDone(res), actions.getMeCall({
        isLogin: false
      })];
    }).catch(function (err) {
      return Observable.of(actions.toggleFollowFail(err));
    }));
  });
};
export var openTagPosts = function openTagPosts(action$, store) {
  return action$.ofType(types.OPEN_TAG_POSTS_CALL).concatMap(function (_ref26) {
    var _ref26$payload = _ref26.payload,
        tagId = _ref26$payload.tagId,
        type = _ref26$payload.type,
        name = _ref26$payload.name;
    return [actions.getTagPostsCall({
      tagId: tagId,
      type: type
    }), actions.openSocialTagPage({
      tagId: tagId,
      name: name
    }), actions.openTagPostsDone()];
  }).catch(function (err) {
    return Observable.of(actions.openTagPostsFail(err));
  });
};
export var getSocialSearch = function getSocialSearch(action$, store) {
  return action$.ofType(types.GET_SOCIAL_SEARCH_CALL).switchMap(function (_ref27) {
    var payload = _ref27.payload;
    return Observable.fromPromise(post.getSocialSearch(payload)).map(function (res) {
      return actions.getSocialSearchDone(_objectSpread({}, res, payload));
    }).catch(function (err) {
      return Observable.of(actions.getSocialSearchFail(err));
    });
  });
};
export var getGroupPosts = function getGroupPosts(action$, store) {
  return action$.ofType(types.GET_GROUP_POSTS_CALL).switchMap(function (_ref28) {
    var payload = _ref28.payload,
        _ref28$payload = _ref28.payload,
        type = _ref28$payload.type,
        groupClassId = _ref28$payload.groupClassId;
    return Observable.fromPromise(post.getGroupPosts(payload)).map(function (_ref29) {
      var data = _ref29.data,
          more = _ref29.more;
      var posts = parseData(data);
      return actions.getGroupPostsDone({
        posts: posts,
        type: type,
        more: more,
        groupClassId: groupClassId
      });
    }).catch(function (err) {
      return Observable.of(actions.getGroupPostsFail(err));
    });
  });
};
export var report = function report(action$, store) {
  return action$.ofType(types.REPORT_CALL).switchMap(function (_ref30) {
    var payload = _ref30.payload;
    return Observable.fromPromise(post.report(payload)).switchMap(function (res) {
      return [actions.reportDone(res), actions.showI18nMessageCall({
        message: 'report:success',
        type: 'success',
        icon: 'success',
        position: 'center'
      })];
    }).catch(function (err) {
      return Observable.of(actions.reportFail(err));
    });
  });
};
export var block = function block(action$, store) {
  return action$.ofType(types.BLOCK_CALL).switchMap(function (_ref31) {
    var payload = _ref31.payload;
    return Observable.fromPromise(post.block(payload)).concatMap(function (res) {
      return [actions.blockDone(res), actions.getMeCall({
        isLogin: false
      })];
    }).catch(function (err) {
      return Observable.of(actions.blockFail(err));
    });
  });
};
export var unBlock = function unBlock(action$, store) {
  return action$.ofType(types.UN_BLOCK_CALL).switchMap(function (_ref32) {
    var payload = _ref32.payload;
    return Observable.fromPromise(post.unBlock(payload)).concatMap(function (res) {
      return [actions.unBlockDone(res), actions.getMeCall({
        isLogin: false
      })];
    }).catch(function (err) {
      return Observable.of(actions.unBlockFail(err));
    });
  });
};
export var openSociaPageCall = function openSociaPageCall(action$, store) {
  return action$.ofType(types.OPEN_SOCIA_PAGE_CALL).switchMap(function (_ref33) {
    var _ref33$payload = _ref33.payload,
        isPost = _ref33$payload.isPost,
        isGroup = _ref33$payload.isGroup,
        groupIds = _ref33$payload.groupIds;
    var groupClassId = isPost ? 'socialPosts' : groupIds[groupIds.length - 1];
    var getGroupPosts = isGroup ? actions.getGroupPostsCall({
      type: 'refresh',
      groupClassId: groupClassId
    }) : actions.empty();
    return [actions.setSocialMainTabIndex({
      mainTabIndex: 0
    }), actions.setSocialTabIndex({
      tabIndex: defaultTabIndex,
      tabKey: defaultTabKey,
      postType: defaultPostType
    }), actions.setGroupId({
      groupClassId: groupClassId
    }), getGroupPosts, actions.openSociaPageDone()];
  });
};