import lib from '../platform';
var pfCommonStyle = lib.pfCommonStyle;
var activeTintColor = pfCommonStyle.activeTintColor;
export default {
  moveGroups: [{
    key: 'calorie',
    color: activeTintColor
  }, {
    key: 'training_volume',
    color: activeTintColor
  }]
};