import { createAction } from 'redux-actions';
import * as types from '../../store/types';
export var openServiceChatCall = createAction(types.OPEN_SERVICE_CHAT_CALL);
export var openChatCall = createAction(types.OPEN_CHAT_CALL);
export var openChatDone = createAction(types.OPEN_CHAT_DONE);
export var openChatFail = createAction(types.OPEN_CHAT_FAIL);
export var closeChatCall = createAction(types.CLOSE_CHAT_CALL);
export var closeChatDone = createAction(types.CLOSE_CHAT_DONE);
export var closeChatFail = createAction(types.CLOSE_CHAT_FAIL);
export var initSocketCall = createAction(types.INIT_SOCKET_CALL);
export var initSocketDone = createAction(types.INIT_SOCKET_DONE);
export var initSocketFail = createAction(types.INIT_SOCKET_FAIL);
export var initSocketMultipleCall = createAction(types.INIT_SOCKET_MULTIPLE_CALL);
export var initSocketMultipleDone = createAction(types.INIT_SOCKET_MULTIPLE_DONE);
export var initSocketMultipleFail = createAction(types.INIT_SOCKET_MULTIPLE_FAIL);
export var openSocketCall = createAction(types.OPEN_SOCKET_CALL);
export var openSocketDone = createAction(types.OPEN_SOCKET_DONE);
export var openSocketFail = createAction(types.OPEN_SOCKET_FAIL);
export var joinChatroomCall = createAction(types.JOIN_CHATROOM_CALL);
export var joinChatroomDone = createAction(types.JOIN_CHATROOM_DONE);
export var joinChatroomFail = createAction(types.JOIN_CHATROOM_FAIL);
export var fetchedMultipleChats = createAction(types.FETCHED_MULTIPLE_CHATS);
export var fetchedMultipleChatRooms = createAction(types.FETCHED_MULTIPLE_CHAT_ROOMS);
export var initMultipleChatCall = createAction(types.INIT_MULTIPLE_CHAT_CALL);
export var initMultipleChatDone = createAction(types.INIT_MULTIPLE_CHAT_DONE);
export var initMultipleChatFail = createAction(types.INIT_MULTIPLE_CHAT_FAIL);
export var joinMultipleCall = createAction(types.JOIN_MULTIPLE_CALL);
export var joinMultipleDone = createAction(types.JOIN_MULTIPLE_DONE);
export var joinMultipleFail = createAction(types.JOIN_MULTIPLE_FAIL);
export var closeSocketCall = createAction(types.CLOSE_SOCKET_CALL);
export var closeSocketDone = createAction(types.CLOSE_SOCKET_DONE);
export var closeSocketFail = createAction(types.CLOSE_SOCKET_FAIL);
export var sendMessageCall = createAction(types.SEND_MESSAGE_CALL);
export var sendMessageDone = createAction(types.SEND_MESSAGE_DONE);
export var sendMessageFail = createAction(types.SEND_MESSAGE_FAIL);
export var insertMessage = createAction(types.INSERT_MESSAGE);
export var fetchMessagesCall = createAction(types.FETCH_MESSAGES_CALL);
export var fetchMessagesDone = createAction(types.FETCH_MESSAGES_DONE);
export var fetchMessagesFail = createAction(types.FETCH_MESSAGES_FAIL);
export var openChatPage = createAction(types.OPEN_CHAT_PAGE);
export var receiveNewMessage = createAction(types.RECEIVE_NEW_MESSAGE);
export var multipleReceiveNewMessage = createAction(types.MULTIPLE_RECEIVE_NEW_MESSAGE);
export var multipleUpdateReadAtCall = createAction(types.MULTIPLE_UPDATE_READ_AT_CALL);
export var multipleUpdateReadAtDone = createAction(types.MULTIPLE_UPDATE_READ_AT_DONE);
export var multipleUpdateReadAtFail = createAction(types.MULTIPLE_UPDATE_READ_AT_FAIL);
export var updateReadAtCall = createAction(types.UPDATE_READ_AT_CALL);
export var updateReadAtDone = createAction(types.UPDATE_READ_AT_DONE);
export var updateReadAtFail = createAction(types.UPDATE_READ_AT_FAIL);
export var updateChatRoomLoadMore = createAction(types.UPDATE_CHATROOM_LOAD_MORE);
export var increaseUnreadCount = createAction(types.INCREASE_UNREAD_COUNT);
export var resetUnreadCount = createAction(types.RESET_UNREAD_COUNT);
export var updateLocalReadAt = createAction(types.UPDATE_LOCAL_READ_AT);
export var getChatRoomsCall = createAction(types.GET_CHAT_ROOMS_CALL);
export var getChatRoomsDone = createAction(types.GET_CHAT_ROOMS_DONE);
export var getChatRoomsFail = createAction(types.GET_CHAT_ROOMS_FAIL);
export var getChatMediaUploadInfoCall = createAction(types.GET_CHAT_MEDIA_UPLOAD_INFO_CALL);
export var getChatMediaUploadInfoDone = createAction(types.GET_CHAT_MEDIA_UPLOAD_INFO_DONE);
export var getChatMediaUploadInfoFail = createAction(types.GET_CHAT_MEDIA_UPLOAD_INFO_FAIL);
export var finishChatSelectMedia = createAction(types.FINISH_CHAT_SELECT_MEDIA);
export var uploadChatAssetCall = createAction(types.UPLOAD_CHAT_ASSET_CALL);
export var uploadChatAssetDone = createAction(types.UPLOAD_CHAT_ASSET_DONE);
export var uploadChatAssetFail = createAction(types.UPLOAD_CHAT_ASSET_FAIL);
export var createAttachmentCall = createAction(types.CREATE_ATTACHMENT_CALL);
export var createAttachmentDone = createAction(types.CREATE_ATTACHMENT_DONE);
export var createAttachmentFail = createAction(types.CREATE_ATTACHMENT_FAIL);
export var reConnectSocketCall = createAction(types.RECONNECT_SOCKET_CALL);
export var reConnectSocketDone = createAction(types.RECONNECT_SOCKET_DONE);
export var reConnectSocketFail = createAction(types.RECONNECT_SOCKET_FAIL);
export var updateChatRoom = createAction(types.UPDATE_CHATROOM);
export var issueReturnCall = createAction(types.ISSUE_RETURN_CALL);
export var issueReturnDone = createAction(types.ISSUE_RETURN_DONE);
export var issueReturnFail = createAction(types.ISSUE_RETURN_FAIL);
export var addChatsCall = createAction(types.ADD_CHATS_CALL);
export var addChatsDone = createAction(types.ADD_CHATS_DONE);
export var addChatsFail = createAction(types.ADD_CHATS_FAIL);
export var initSocketECCall = createAction(types.INIT_SOCKETEC_CALL);
export var initSocketECDone = createAction(types.INIT_SOCKETEC_DONE);
export var initSocketECFail = createAction(types.INIT_SOCKETEC_FAIL);
export var openSocketECCall = createAction(types.OPEN_SOCKETEC_CALL);
export var openSocketECDone = createAction(types.OPEN_SOCKETEC_DONE);
export var openSocketECFail = createAction(types.OPEN_SOCKETEC_FAIL);
export var leaveChatsCall = createAction(types.LEAVE_CHATS_CALL);
export var leaveChatsDone = createAction(types.LEAVE_CHATS_DONE);
export var leaveChatsFail = createAction(types.LEAVE_CHATS_FAIL);
export var addChatsMembersCall = createAction(types.ADD_CHATS_MEMBERS_CALL);
export var addChatsMembersDone = createAction(types.ADD_CHATS_MEMBERS_DONE);
export var addChatsMembersFail = createAction(types.ADD_CHATS_MEMBERS_FAIL);
export var enterChatsCall = createAction(types.ENTER_CHATS_CALL);
export var enterChatsDone = createAction(types.ENTER_CHATS_DONE);
export var enterChatsFail = createAction(types.ENTER_CHATS_FAIL);
export var muteChatsCall = createAction(types.MUTE_CHATS_CALL);
export var muteChatsDone = createAction(types.MUTE_CHATS_DONE);
export var muteChatsFail = createAction(types.MUTE_CHATS_FAIL);
export var unMuteChatsCall = createAction(types.UN_MUTE_CHATS_CALL);
export var unMuteChatsDone = createAction(types.UN_MUTE_CHATS_DONE);
export var unMuteChatsFail = createAction(types.UN_MUTE_CHATS_FAIL);
export var selectChat = createAction(types.SELECT_CHAT);