export var GET_ME_CALL = 'GET_ME_CALL';
export var GET_ME_DONE = 'GET_ME_DONE';
export var GET_ME_FAIL = 'GET_ME_FAIL';
export var GET_UNREAD_COUNT_CALL = 'GET_UNREAD_COUNT_CALL';
export var GET_UNREAD_COUNT_DONE = 'GET_UNREAD_COUNT_DONE';
export var GET_UNREAD_COUNT_FAIL = 'GET_UNREAD_COUNT_FAIL';
export var GET_AVATAR_UPLOAD_INFO_CALL = 'GET_AVATAR_UPLOAD_INFO_CALL';
export var GET_AVATAR_UPLOAD_INFO_DONE = 'GET_AVATAR_UPLOAD_INFO_DONE';
export var GET_AVATAR_UPLOAD_INFO_FAIL = 'GET_AVATAR_UPLOAD_INFO_FAIL';
export var UPLOAD_AVATAR_CALL = 'UPLOAD_AVATAR_CALL';
export var UPLOAD_AVATAR_DONE = 'UPLOAD_AVATAR_DONE';
export var UPLOAD_AVATAR_FAIL = 'UPLOAD_AVATAR_FAIL';
export var CHANGE_PASSWORD_CALL = 'CHANGE_PASSWORD_CALL';
export var CHANGE_PASSWORD_DONE = 'CHANGE_PASSWORD_DONE';
export var CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';
export var UPDATE_MEMBER = 'UPDATE_MEMBER';
export var SET_MEMBER_SELECT_TYPE = 'SET_MEMBER_SELECT_TYPE';