import { createAction } from 'redux-actions';
import * as types from '../../store/types';
export var checkLoginCall = createAction(types.CHECK_LOGIN_CALL);
export var checkLoginDone = createAction(types.CHECK_LOGIN_DONE);
export var checkLoginFail = createAction(types.CHECK_LOGIN_FAIL);
export var webEmailLoginCall = createAction(types.WEB_EMAIL_LOGIN_CALL);
export var emailLoginCall = createAction(types.EMAIL_LOGIN_CALL);
export var emailLoginDone = createAction(types.EMAIL_LOGIN_DONE);
export var emailLoginFail = createAction(types.EMAIL_LOGIN_FAIL);
export var accountLoginCall = createAction(types.ACCOUNT_LOGIN_CALL);
export var accountLoginDone = createAction(types.ACCOUNT_LOGIN_DONE);
export var accountLoginFail = createAction(types.ACCOUNT_LOGIN_FAIL);
export var wechatLoginCheckCall = createAction(types.WECHAT_LOGIN_CHECK_CALL);
export var wechatLoginCheckDone = createAction(types.WECHAT_LOGIN_CHECK_DONE);
export var wechatLoginCheckFail = createAction(types.WECHAT_LOGIN_CHECK_FAIL);
export var wechatLoginCall = createAction(types.WECHAT_LOGIN_CALL);
export var wechatLoginDone = createAction(types.WECHAT_LOGIN_DONE);
export var wechatLoginFail = createAction(types.WECHAT_LOGIN_FAIL);
export var bindWechatIdCall = createAction(types.BIND_WECHAT_ID_CALL);
export var bindWechatIdDone = createAction(types.BIND_WECHAT_ID_DONE);
export var bindWechatIdFail = createAction(types.BIND_WECHAT_ID_FAIL);
export var registerCall = createAction(types.REGISTER_CALL);
export var registerDone = createAction(types.REGISTER_DONE);
export var registerFail = createAction(types.REGISTER_FAIL);
export var webLogoutCall = createAction(types.WEB_LOGOUT_CALL);
export var logoutCall = createAction(types.LOGOUT_CALL);
export var logoutDone = createAction(types.LOGOUT_DONE);
export var logoutFail = createAction(types.LOGOUT_FAIL);
export var fbLoginCall = createAction(types.FB_LOGIN_CALL);
export var fbLoginDone = createAction(types.FB_LOGIN_DONE);
export var fbLoginFail = createAction(types.FB_LOGIN_FAIL);
export var fbTokenLoginCall = createAction(types.FB_TOKEN_LOGIN_CALL);
export var appleLoginCall = createAction(types.APPLE_LOGIN_CALL);
export var appleLoginDone = createAction(types.APPLE_LOGIN_DONE);
export var appleLoginFail = createAction(types.APPLE_LOGIN_FAIL);
export var bindAppleIdCall = createAction(types.BIND_APPLE_ID_CALL);
export var bindAppleIdDone = createAction(types.BIND_APPLE_ID_DONE);
export var bindAppleIdFail = createAction(types.BIND_APPLE_ID_FAIL);
export var setToken = createAction(types.SET_TOKEN);
export var setShopToken = createAction(types.SET_SHOP_TOKEN);
export var storeTokenCall = createAction(types.STORE_TOKEN_CALL);
export var storeTokenDone = createAction(types.STORE_TOKEN_DONE);
export var storeTokenFail = createAction(types.STORE_TOKEN_FAIL);
export var resetPasswordCall = createAction(types.RESET_PASSWORD_CALL);
export var resetPasswordDone = createAction(types.RESET_PASSWORD_DONE);
export var resetPasswordFail = createAction(types.RESET_PASSWORD_FAIL);
export var resetLogin = createAction(types.RESET_LOGIN);
export var changePageType = createAction(types.CHANGE_PAGE_TYPE);
export var shopLoginCall = createAction(types.SHOP_LOGIN_CALL);
export var shopLoginDone = createAction(types.SHOP_LOGIN_DONE);
export var shopLoginFail = createAction(types.SHOP_LOGIN_FAIL);
export var mobileLoginCall = createAction(types.MOBILE_LOGIN_CALL);
export var mobileLoginDone = createAction(types.MOBILE_LOGIN_DONE);
export var mobileLoginFail = createAction(types.MOBILE_LOGIN_FAIL);
export var mobileRegisterCall = createAction(types.MOBILE_REGISTER_CALL);
export var mobileRegisterDone = createAction(types.MOBILE_REGISTER_DONE);
export var mobileRegisterFail = createAction(types.MOBILE_REGISTER_FAIL);
export var mobileSmsLoginCall = createAction(types.MOBILE_SMS_LOGIN_CALL);
export var mobileSmsLoginDone = createAction(types.MOBILE_SMS_LOGIN_DONE);
export var mobileSmsLoginFail = createAction(types.MOBILE_SMS_LOGIN_FAIL);
export var setMobilePasswordCall = createAction(types.SET_MOBILE_PASSWOED_CALL);
export var setMobilePasswordDone = createAction(types.SET_MOBILE_PASSWOED_DONE);
export var setMobilePasswordFail = createAction(types.SET_MOBILE_PASSWOED_FAIL);