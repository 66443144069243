import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _handleActions;

import _ from 'lodash';
import produce from 'immer';
import { combineActions, handleActions } from 'redux-actions';
import * as types from '../../store/types';
var initialGroup = {
  groupRanks: undefined,
  groupTags: [],
  showLoading: false
};
export default handleActions((_handleActions = {}, _defineProperty(_handleActions, types.GET_RANKS_CALL, function (state, _ref) {
  var payload = _ref.payload;
  return produce(state, function (draft) {
    draft.groupRanks = undefined;
    draft.showLoading = true;
  });
}), _defineProperty(_handleActions, types.GET_RANKS_DONE, function (state, _ref2) {
  var payload = _ref2.payload;
  return produce(state, function (draft) {
    draft.groupRanks = payload;
    draft.showLoading = false;
  });
}), _defineProperty(_handleActions, types.GET_GROUP_TAGS_CALL, function (state, _ref3) {
  var payload = _ref3.payload;
  return produce(state, function (draft) {
    draft.showLoading = true;
  });
}), _defineProperty(_handleActions, types.GET_GROUP_TAGS_DONE, function (state, _ref4) {
  var data = _ref4.payload.data;
  return produce(state, function (draft) {
    draft.groupTags = data;
    draft.showLoading = false;
  });
}), _defineProperty(_handleActions, combineActions(types.GET_RANKS_CALL, types.GET_GROUP_TAGS_CALL), function (state) {
  return produce(state, function (draft) {
    draft.showLoading = true;
  });
}), _defineProperty(_handleActions, combineActions(types.GET_RANKS_FAIL, types.GET_GROUP_TAGS_FAIL), function (state) {
  return produce(state, function (draft) {
    draft.showLoading = false;
  });
}), _handleActions), initialGroup);