import api from '../api';
import Socket from 'socket.io-client';
import { Observable } from 'rxjs';
var io = Socket(api.socketUrl, {
  transports: ['websocket'],
  autoConnect: false,
  timeout: 10000
});
export var getStreams = function getStreams() {
  var connect$ = Observable.fromEvent(io, 'connect');
  var disconnect$ = Observable.fromEvent(io, 'disconnect');
  var reconnect$ = Observable.fromEvent(io, 'reconnect');
  var connectError$ = Observable.fromEvent(io, 'connect_error');
  var newMessage$ = Observable.fromEvent(io, 'v1.5:new:message');
  var chatRoomUpdate$ = Observable.fromEvent(io, 'v1.6:update:chatRoom');
  var fetchedMessages$ = Observable.fromEvent(io, 'v1.6:fetched:messages');
  var fetchedMessagesNew$ = Observable.fromEvent(io, 'v1.7:fetched:messages');
  var fetchedEnd$ = Observable.fromEvent(io, 'v1.6:fetched:end');
  var fetchedMultiple$ = Observable.fromEvent(io, 'v1.6:fetched:multiple');
  return {
    connect$: connect$,
    disconnect$: disconnect$,
    reconnect$: reconnect$,
    connectError$: connectError$,
    newMessage$: newMessage$,
    chatRoomUpdate$: chatRoomUpdate$,
    fetchedMessages$: fetchedMessages$,
    fetchedMessagesNew$: fetchedMessagesNew$,
    fetchedEnd$: fetchedEnd$,
    fetchedMultiple$: fetchedMultiple$
  };
};
export var connect = function connect(_ref) {
  var chatRoomId = _ref.id,
      gid = _ref.gid;
  io.connect();
};
export var joinChatroom = function joinChatroom(_ref2) {
  var chatRoomId = _ref2.id,
      gid = _ref2.gid;
  io.emit('v1.5:join', {
    chatRoomId: chatRoomId,
    gid: gid
  });
};
export var joinMultiple = function joinMultiple(_ref3) {
  var chatRoomIds = _ref3.chatRoomIds,
      gid = _ref3.gid;
  io.emit('v1.6:joinMultiple', {
    chatRoomIds: chatRoomIds,
    gid: gid
  });
};
export var disconnect = function disconnect() {
  return io.disconnect();
};
export var sendMessage = function sendMessage(message) {
  return new Promise(function (resolve) {
    return io.emit('v1.5:publish:message', message, function (_id) {
      return resolve(_id);
    });
  });
};
export var fetchMessages = function fetchMessages(query) {
  var event = query.returnChatRoomId ? 'v1.7:fetch:messages' : 'v1.6:fetch:messages';
  return io.emit(event, query);
};
export var updateReadAt = function updateReadAt(query) {
  return io.emit('v1.5:update:readAt', query);
};