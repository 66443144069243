import { createAction } from 'redux-actions';
import * as types from '../../store/types';
export var getRanksCall = createAction(types.GET_RANKS_CALL);
export var getRanksDone = createAction(types.GET_RANKS_DONE);
export var getRanksFail = createAction(types.GET_RANKS_FAIL);
export var openRankPageCall = createAction(types.OPEN_RANK_PAGE_CALL);
export var openRankPageDone = createAction(types.OPEN_RANK_PAGE_DONE);
export var openRankPageFail = createAction(types.OPEN_RANK_PAGE_FAIL);
export var getGroupTagsCall = createAction(types.GET_GROUP_TAGS_CALL);
export var getGroupTagsDone = createAction(types.GET_GROUP_TAGS_DONE);
export var getGroupTagsFail = createAction(types.GET_GROUP_TAGS_FAIL);