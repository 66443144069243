import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _handleActions;

import { combineActions, handleActions } from 'redux-actions';
import * as types from '../../store/types';
import _ from 'lodash';
import produce from 'immer';
export default handleActions((_handleActions = {}, _defineProperty(_handleActions, types.GET_ESSAYS_DONE, function (state, _ref) {
  var payload = _ref.payload;
  return produce(state, function (draft) {
    draft.essaysList = payload;
  });
}), _defineProperty(_handleActions, types.GET_ESSAYS_CATEGORY_CALL, function (state, _ref2) {
  var payload = _ref2.payload;
  return produce(state, function (draft) {
    draft.essaysIsLoading = true;
    draft.essaysCategory = [];
  });
}), _defineProperty(_handleActions, types.GET_ESSAYS_CATEGORY_DONE, function (state, _ref3) {
  var payload = _ref3.payload;
  return produce(state, function (draft) {
    draft.essaysIsLoading = false;
    draft.essaysCategory = payload;
  });
}), _defineProperty(_handleActions, types.GET_ESSAYS_TAG_CALL, function (state, _ref4) {
  var payload = _ref4.payload;
  return produce(state, function (draft) {
    draft.essaysTagIsLoading = true;
    draft.essaysTagList = payload;
  });
}), _defineProperty(_handleActions, types.GET_ESSAYS_TAG_DONE, function (state, _ref5) {
  var payload = _ref5.payload;
  return produce(state, function (draft) {
    draft.essaysTagIsLoading = false;
    draft.essaysTagList = payload;
  });
}), _handleActions), {
  essaysList: [],
  essaysCategory: [],
  essaysIsLoading: false,
  essaysTagIsLoading: false,
  essaysTagList: []
});