import _ from 'lodash';
import { Observable } from 'rxjs';
import { notification } from '../../services';
import './RxOperators';
import * as actions from '../actions';
import * as types from '../../store/types';
import lib from '../../platform';
var pfConfig = lib.pfConfig;
var isProApp = pfConfig.isProApp;
export var getNotifications = function getNotifications(action$, store) {
  return action$.ofType(types.GET_NOTIFICATIONS_CALL).switchMap(function (_ref) {
    var _ref$payload = _ref.payload,
        newer = _ref$payload.newer,
        older = _ref$payload.older,
        showRefreshing = _ref$payload.showRefreshing;
    var _store$value$notifica = store.value.notification,
        newestTimestamp = _store$value$notifica.newestTimestamp,
        oldestTimestamp = _store$value$notifica.oldestTimestamp;
    var params = {
      before: older ? oldestTimestamp : null,
      after: newer ? newestTimestamp : null
    };
    return Observable.concat(Observable.of(actions.startGettingNotifications({
      showRefreshing: showRefreshing
    })), Observable.fromPromise(notification.getNotifications(params)).switchMap(function (notifications) {
      var endReached = older && _.isEmpty(notifications);

      var clients = _.chain(notifications).filter('record_client_id').groupBy('record_client_id').map(function (n, id) {
        return {
          id: id,
          dates: _.uniq(_.compact(_.map(n, 'record_diary_date')))
        };
      }).value();

      return [clients.length !== 0 ? actions.getDiariesCall({
        clients: clients
      }) : actions.empty(), actions.getNotificationsDone({
        notifications: notifications,
        endReached: endReached
      }), actions.stopGettingNotifications(), newer ? actions.clearNotificationTabBadge() : actions.empty(), actions.setAppBadgeCall()];
    }).catch(function (err) {
      return Observable.concat(Observable.of(actions.getNotificationsFail(err)), Observable.of(actions.stopGettingNotifications()));
    }));
  });
};
export var openNotificationCall = function openNotificationCall(action$, store) {
  return action$.ofType(types.OPEN_NOTIFICATION_CALL).switchMap(function (_ref2) {
    var _ref2$payload$item = _ref2.payload.item,
        id = _ref2$payload$item.id,
        record_id = _ref2$payload$item.record_id,
        record_type = _ref2$payload$item.record_type,
        asset = _ref2$payload$item.asset,
        clientId = _ref2$payload$item.sender_id;
    var notifications = store.value.notification.notifications;
    var ids = [];

    if (asset) {
      ids = _.map(_.filter(notifications, {
        record_id: record_id,
        record_type: record_type
      }), 'id');
    } else {
      ids = [id];
    }

    var setClientId = isProApp ? actions.setClientId(clientId) : actions.empty();
    return Observable.concat(Observable.of(setClientId), Observable.of(actions.setNotificationsRead({
      ids: ids
    })), Observable.fromPromise(notification.setRead(ids)).map(function () {
      return actions.openNotificationDone();
    }).catch(function (err) {
      return Observable.of(actions.openNotificationFail(err));
    }));
  });
};
export var readAllNotificationsCall = function readAllNotificationsCall(action$, store) {
  return action$.ofType(types.READ_ALL_NOTIFICATIONS_CALL).switchMap(function () {
    var notifications = store.value.notification.notifications;

    var ids = _.map(notifications, 'id');

    return Observable.concat(Observable.of(actions.setNotificationsRead({
      ids: ids
    })), Observable.fromPromise(notification.setRead(ids)).map(function () {
      return actions.readAllNotificationsDone();
    }).catch(function (err) {
      return Observable.of(actions.readAllNotificationsFail(err));
    }));
  });
};