import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _handleActions;

import _ from 'lodash';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import * as types from '../../store/types';
var initialState = {
  locations: [],
  showLoading: true,
  firstSearch: true
};
export default handleActions((_handleActions = {}, _defineProperty(_handleActions, types.SHOW_SEARCH_LOCATION_LOADING, function (state, _ref) {
  var payload = _ref.payload;
  return produce(state, function (draft) {
    draft.showLoading = true;
    draft.locations = [];
  });
}), _defineProperty(_handleActions, types.GET_SEARCH_LOCATION_DONE, function (state, _ref2) {
  var _ref2$payload = _ref2.payload,
      status = _ref2$payload.status,
      results = _ref2$payload.results,
      provider = _ref2$payload.provider,
      pois = _ref2$payload.pois;
  return produce(state, function (draft) {
    draft.showLoading = false;
    draft.firstSearch = false;

    if (provider === 'google' && results) {
      draft.locations = results;
    }

    if (provider === 'amap' && pois) {
      draft.locations = pois;
    }
  });
}), _defineProperty(_handleActions, types.GET_SEARCH_LOCATION_FAIL, function (state, _ref3) {
  var payload = _ref3.payload;
  return produce(state, function (draft) {
    draft.showLoading = false;
    draft.firstSearch = false;
    draft.locations = [];
  });
}), _handleActions), initialState);