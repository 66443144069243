import { createAction } from 'redux-actions';
import * as types from '../../store/types';
export var openNotificationTab = createAction(types.OPEN_NOTIFICATION_TAB);
export var openNotificationPage = createAction(types.OPEN_NOTIFICATION_PAGE);
export var getNotificationsCall = createAction(types.GET_NOTIFICATIONS_CALL);
export var getNotificationsDone = createAction(types.GET_NOTIFICATIONS_DONE);
export var getNotificationsFail = createAction(types.GET_NOTIFICATIONS_FAIL);
export var openNotificationCall = createAction(types.OPEN_NOTIFICATION_CALL);
export var openNotificationDone = createAction(types.OPEN_NOTIFICATION_DONE);
export var openNotificationFail = createAction(types.OPEN_NOTIFICATION_FAIL);
export var startGettingNotifications = createAction(types.START_GETTING_NOTIFICATIONS);
export var stopGettingNotifications = createAction(types.STOP_GETTING_NOTIFICATIONS);
export var setNotificationsRead = createAction(types.SET_NOTIFICATIONS_READ);
export var readAllNotificationsCall = createAction(types.READ_ALL_NOTIFICATIONS_CALL);
export var readAllNotificationsDone = createAction(types.READ_ALL_NOTIFICATIONS_DONE);
export var readAllNotificationsFail = createAction(types.READ_ALL_NOTIFICATIONS_FAIL);