import { webSocket } from "rxjs/webSocket";
import { Subject } from 'rxjs';
import lib from '../platform';
var pfConfig = lib.pfConfig;
var wsUrl = pfConfig.api.wsUrl;
var open$ = new Subject();
var close$ = new Subject();
var error$ = new Subject();
var ws = webSocket({
  url: wsUrl,
  openObserver: open$,
  closeObserver: close$,
  subject: error$
});
export var getStreams = function getStreams() {
  var newMessage$ = ws;
  var connect$ = open$;
  return {
    newMessage$: newMessage$,
    connect$: connect$,
    error$: error$,
    close$: close$
  };
};
export var sendMessage = function sendMessage(message) {
  return new Promise(function (resolve) {
    return ws.next(message);
  });
};
export var disconnect = function disconnect() {
  return new Promise(function (resolve) {
    return ws.complete();
  });
};