import { LegalNotice, UserTerms } from './docs'

export default {
  common: {
    type: 'User',
    webUrl: 'https://www.cofit.me/contact_us.html',
    // userIcon: require('../../../assets/common/cofit_user.png'),
    userTerms: {
      content: UserTerms,
      type: 'html',
      hidden: false,
    },
    notice: {
      content: LegalNotice,
      type: 'html',
      hidden: false,
    },
    moveIsFirst: false,
    useJpush: false,
    showAddChatHeader: false,
    showProgramTab: false,
    showRoutines: false,
    showFFInfo: false,
    showInvoice: false,
    enableShake: false,
    enableCheckUpdate: true,
    showSocialInfo: false,
    handGetArticle: false,
    handleGetMyPosts: true,
    hiddenHistory: true,
    defaultShowPostSwitch: false,
    useBarCode: false,
    showClassReserve: false,
    showAboutWeb: false,
    dsn: 'https://4e0e256eb1bb4ff4a311626695830399@sentry.io/1271625',
    appNavigator: 'ProAppNavigator',
    navNextState: 'ProNextState',
  },
  post: {
    tabIndex: 0,
  },
  store: {
    defaultIsPost: true,
    defaultIsOpenImagePicker: true,
    defaultBarCodeIsRowStore: false,
    defaultEditing: false,
    defaultIsTwoPost: true,
  },
  proSetting: {
    clientOnlyAddMove: false,
    showFFProfileInfo: false,
  },
  login: {
    enableWechatLogin: false,
    enableFbLogin: false,
    enableRegister: false,
    accountLogin: false,
    showEmailRegister: false,
    // logo: require('../../../assets/proapp/logo_wide.png'),
    fbAppId: '',
    wechatAppId: '',
  },
  styles: {
    mainBackgroundColor: '#00b1c9',
  },
  locales: {
    dataChartTitle: 'dataChart:title',
    coachTabTitle: 'tabs.dialog.title',
  },
  api: {
    apiUrl: 'https://pro.cofit.me/api',
    socketUrl: 'https://pepper.cofit.me',
    signInPath: '/v4/users/sign_in',
    resetPasswordPath: '/v4/users/reset_password',
    signOutPath: '/v4/users/sign_out',
    provider: 'google',
  },
  devApi: {
    apiUrl: 'https://pro.cofit.me/api',
    socketUrl: 'https://pepper.cofit.me',
    signInPath: '/v4/users/sign_in',
  }
}
