import _toConsumableArray from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import { Observable } from 'rxjs';
import _ from 'lodash';
import { socket, chat as ChatService } from '../../services';
import './RxOperators';
import * as actions from '../actions';
import * as types from '../../store/types';

var getGid = function getGid(store) {
  return store.value.member.gid;
};

var getChatRoomIds = function getChatRoomIds(store) {
  var chatrooms = store.value.chat.chatrooms;
  return _.map(chatrooms, 'id');
};

export var initMultipleChat = function initMultipleChat(action$, store) {
  return action$.ofType(types.GET_ME_DONE).mergeMap(function () {
    var chatRoomIds = getChatRoomIds(store);
    var gid = getGid(store);
    var socketInitialized = store.value.chat.socketInitialized;
    return socketInitialized ? Observable.of(actions.empty()) : [actions.initSocketMultipleCall({}), actions.openSocketCall({}), actions.joinMultipleCall({
      chatRoomIds: chatRoomIds,
      gid: gid
    })];
  }).catch(function (err) {
    return Observable.concat(Observable.of(actions.initMultipleChatFail(err)));
  });
};
export var initSocketMultiple = function initSocketMultiple(action$, store) {
  return action$.ofType(types.INIT_SOCKET_MULTIPLE_CALL).switchMap(function () {
    var _socket$getStreams = socket.getStreams(),
        connect$ = _socket$getStreams.connect$,
        disconnect$ = _socket$getStreams.disconnect$,
        reconnect$ = _socket$getStreams.reconnect$,
        connectError$ = _socket$getStreams.connectError$,
        chatRoomUpdate$ = _socket$getStreams.chatRoomUpdate$,
        newMessage$ = _socket$getStreams.newMessage$,
        fetchedMessages$ = _socket$getStreams.fetchedMessages$,
        fetchedMessagesNew$ = _socket$getStreams.fetchedMessagesNew$,
        fetchedEnd$ = _socket$getStreams.fetchedEnd$,
        fetchedMultiple$ = _socket$getStreams.fetchedMultiple$;

    return Observable.merge(newMessage$.mergeMap(function (newMessage) {
      var messages = ChatService.formatIncomingMessages([newMessage]);
      var id = newMessage.chatRoomId;
      var selectedChatRoomId = store.value.chat.selectedChatRoomId;
      return [actions.multipleReceiveNewMessage({
        id: id,
        messages: messages
      }), actions.increaseUnreadCount({
        id: id
      })];
    }), fetchedMessages$.mergeMap(function (newMessages) {
      var messages = ChatService.formatIncomingMessages(newMessages);
      var firstMsg = newMessages[0];
      var id = firstMsg && firstMsg.chatRoomId;
      return [actions.receiveNewMessage({
        id: id,
        messages: messages
      })];
    }), fetchedMessagesNew$.mergeMap(function (_ref) {
      var id = _ref.chatRoomId,
          newMessages = _ref.messages;
      var messages = ChatService.formatIncomingMessages(newMessages);
      return [actions.receiveNewMessage({
        id: id,
        messages: messages
      })];
    }), fetchedEnd$.mergeMap(function (_ref2) {
      var chatRoomId = _ref2.chatRoomId;
      return [actions.updateChatRoomLoadMore({
        chatRoomId: chatRoomId
      })];
    }), fetchedMultiple$.mergeMap(function (_ref3) {
      var chatRooms = _ref3.chatRooms,
          chats = _ref3.chats;

      var formattedChats = _.map(chats, function (chat) {
        return _objectSpread({}, chat, {
          messages: ChatService.formatIncomingMessages(chat.messages)
        });
      });

      var unreadChatRooms = _.chain(chatRooms).filter(function (chatRoom) {
        return chatRoom.unreadCount > 0;
      }).filter(function (chatRoom) {
        var chat = _.find(chats, {
          chatRoomId: chatRoom.id
        });

        return !chat || chat.messages.length < chatRoom.unreadCount;
      }).value();

      var fetchMoreActions = _.map(unreadChatRooms, function (_ref4) {
        var id = _ref4.id;
        return actions.fetchMessagesCall({
          id: id,
          returnChatRoomId: true
        });
      });

      return [actions.fetchedMultipleChats({
        chatRooms: chatRooms,
        chats: formattedChats
      }), actions.fetchedMultipleChatRooms({
        chatRooms: chatRooms
      })].concat(_toConsumableArray(fetchMoreActions));
    }), connectError$.mergeMap(function (messages) {
      console.log('connect error');
      return Observable.of(actions.hideLoading());
    }), chatRoomUpdate$.mergeMap(function (chatroom) {
      return Observable.of(actions.updateChatRoom(chatroom));
    }), reconnect$.mergeMap(function (messages) {
      return Observable.empty();
    }), connect$.mergeMap(function (messages) {
      return Observable.empty();
    }), disconnect$.mergeMap(function (messages) {
      return Observable.of(actions.hideLoading());
    })).takeUntil(action$.ofType(types.CLOSE_SOCKET_DONE));
  }).catch(function (err) {
    return Observable.concat(Observable.of(actions.initSocketMultipleFail(err)));
  });
};
export var joinMultiple = function joinMultiple(action$, store) {
  return action$.ofType(types.JOIN_MULTIPLE_CALL).map(function (_ref5) {
    var payload = _ref5.payload;
    // console.log(payload)
    socket.joinMultiple(payload);
    return actions.joinMultipleDone();
  }).catch(function (err) {
    return Observable.of(actions.joinMultipleFail(err));
  });
};
export var selectChat = function selectChat(action$, store) {
  return action$.ofType(types.SELECT_CHAT).switchMap(function (_ref6) {
    var id = _ref6.payload.id;
    var gid = getGid(store);
    var chatrooms = store.value.chat.chatrooms;

    var chatRoom = _.find(chatrooms, {
      id: id
    });

    var query = {
      chatRoomId: id,
      gid: gid,
      returnChatRoomId: true
    };

    if (chatRoom && chatRoom.loadMore && chatRoom.messages.length == 0) {
      return Observable.of(socket.fetchMessages(query)).map(function () {
        return actions.fetchMessagesDone();
      });
    } else {
      return Observable.of(actions.fetchMessagesDone());
    }
  }).catch(function (err) {
    return Observable.concat(Observable.of(actions.fetchMessagesFail(err)));
  });
};
export var multipleUpdateReadAt = function multipleUpdateReadAt(action$, store) {
  return action$.ofType(types.MULTIPLE_UPDATE_READ_AT_CALL).switchMap(function (_ref7) {
    var id = _ref7.payload.id;
    var chatrooms = store.value.chat.chatrooms;

    var chatRoom = _.find(chatrooms, {
      id: id
    });

    var chatRoomReadAt = chatRoom && chatRoom.readAt;

    var latestMessage = _.first(chatRoom.messages);

    var messageCreatedAt = latestMessage && latestMessage.createdAt.toISOString(); // console.log(chatRoomReadAt)
    // console.log(messageCreatedAt)

    if (chatRoomReadAt && chatRoomReadAt < messageCreatedAt) {
      return [actions.updateLocalReadAt({
        id: id
      }), actions.updateReadAtCall({
        id: id
      }), actions.resetUnreadCount({
        id: id
      })];
    } else {
      return Observable.of(actions.empty());
    }
  }).catch(function (err) {
    return Observable.of(actions.multipleUpdateReadAtFail(err));
  });
};