import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";

var _handleActions;

import { combineActions, handleActions } from 'redux-actions';
import * as types from '../../store/types';
import _ from 'lodash';
import produce from 'immer';
import { getSelectedPost } from '../../store/selectors';
import lib from '../../platform';
var pfConfig = lib.pfConfig;
var _pfConfig$post = pfConfig.post,
    defaultTabIndex = _pfConfig$post.defaultTabIndex,
    defaultTabKey = _pfConfig$post.defaultTabKey,
    defaultPostType = _pfConfig$post.defaultPostType;

var uniqPostsId = function uniqPostsId(_ref) {
  var list = _ref.list;
  return _.chain(list).uniqBy('id').value();
};

var defaultSearch = {
  data: [],
  more: false,
  page: 1,
  searchValue: ''
};
var defaultReportData = {
  report: {
    id: undefined,
    type: undefined,
    reason: ''
  },
  block: {
    id: undefined,
    type: undefined,
    isBlock: false
  }
};
var defaultTabPosts = {
  posts: [],
  more: false,
  showLoading: false,
  showRefreshing: false,
  showLoadMoreing: false,
  scrollToTop: false
};

var updatePost = function updatePost(_ref2) {
  var draft = _ref2.draft,
      selectType = _ref2.selectType,
      id = _ref2.id,
      post = _ref2.post;

  if (selectType !== 'socialPosts' && selectType !== 'groupPosts') {
    var isSelectSocialPosts = draft.isSelectSocialPosts,
        groupPosts = draft.groupPosts,
        selectGroupId = draft.selectGroupId,
        socialPosts = draft.socialPosts;

    if (!isSelectSocialPosts) {
      var _ref3 = _.find(groupPosts, {
        selectGroupId: selectGroupId
      }) || {
        posts: []
      },
          posts = _ref3.posts;

      var groupPostIndex = _.findIndex(posts, {
        id: id
      });

      if (groupPostIndex !== -1) posts[groupPostIndex] = post;
    } else {
      var socialPostIndex = _.findIndex(socialPosts, {
        id: id
      });

      if (socialPostIndex !== -1) socialPosts[socialPostIndex] = post;
    }
  }
};

var initialState = {
  scrollToTop: false,
  socialPosts: [],
  socialPostsMore: false,
  myPosts: [],
  myPostsMore: false,
  myKeptPosts: [],
  myKeptPostsMore: false,
  myProfile: undefined,
  tagPosts: [],
  tagId: undefined,
  tabPosts: {
    following: defaultTabPosts,
    latest: defaultTabPosts,
    interesting: defaultTabPosts,
    activity: defaultTabPosts,
    recommend: defaultTabPosts
  },
  showLoading: false,
  showRefreshing: false,
  showLoadMoreing: false,
  selectType: undefined,
  selectId: undefined,
  notificationPost: [],
  socialProfile: {
    id: undefined,
    type: undefined,
    profiles: [],
    profilesPosts: []
  },
  search: {
    showLoading: false,
    user: defaultSearch,
    client: defaultSearch,
    tag: defaultSearch,
    shop: defaultSearch
  },
  isSelectSocialPosts: true,
  selectGroupId: 'socialPosts',
  groupPosts: [],
  mainTabIndex: 0,
  tabIndex: defaultTabIndex,
  tabKey: defaultTabKey,
  postType: defaultPostType,
  reportModalShow: false,
  reportData: defaultReportData
};
export default handleActions((_handleActions = {}, _defineProperty(_handleActions, combineActions(types.GET_SOCIAL_POSTS_CALL, types.GET_MY_POSTS_CALL, types.GET_PROFILE_POSTS_CALL, types.GET_TAG_POSTS_CALL, types.GET_GROUP_POSTS_CALL, types.GET_MY_KEPT_POSTS_CALL), function (state, _ref4) {
  var _ref4$payload = _ref4.payload,
      type = _ref4$payload.type,
      scrollToTop = _ref4$payload.scrollToTop;
  return produce(state, function (draft) {
    draft.scrollToTop = scrollToTop === true;
    draft.showLoading = true;
    draft.showRefreshing = type === 'refresh';
    draft.showLoadMoreing = type === 'loadMore';
  });
}), _defineProperty(_handleActions, types.GET_SOCIAL_POSTS_DONE, function (state, _ref5) {
  var _ref5$payload = _ref5.payload,
      posts = _ref5$payload.posts,
      type = _ref5$payload.type,
      more = _ref5$payload.more;
  return produce(state, function (draft) {
    if (type === 'loadMore') {
      var list = _.concat(draft.socialPosts, posts);

      draft.socialPosts = uniqPostsId({
        list: list
      });
    } else {
      draft.socialPosts = uniqPostsId({
        list: posts
      });
    }

    draft.socialPostsMore = more;
    draft.showLoading = false;
    draft.showRefreshing = false;
    draft.showLoadMoreing = false;
    draft.scrollToTop = false;
  });
}), _defineProperty(_handleActions, types.GET_TAB_POSTS_CALL, function (state, _ref6) {
  var _ref6$payload = _ref6.payload,
      tabKey = _ref6$payload.tabKey,
      type = _ref6$payload.type,
      scrollToTop = _ref6$payload.scrollToTop;
  return produce(state, function (draft) {
    var selectTabPosts = draft.tabPosts[tabKey];
    selectTabPosts.scrollToTop = scrollToTop === true;
    selectTabPosts.showLoading = true;
    selectTabPosts.showRefreshing = type === 'refresh';
    selectTabPosts.showLoadMoreing = type === 'loadMore';
  });
}), _defineProperty(_handleActions, types.GET_TAB_POSTS_DONE, function (state, _ref7) {
  var _ref7$payload = _ref7.payload,
      tabKey = _ref7$payload.tabKey,
      posts = _ref7$payload.posts,
      type = _ref7$payload.type,
      more = _ref7$payload.more;
  return produce(state, function (draft) {
    var tabPosts = draft.tabPosts[tabKey];

    if (type === 'loadMore') {
      var list = _.concat(tabPosts.posts, posts);

      tabPosts.posts = uniqPostsId({
        list: list
      });
    } else {
      tabPosts.posts = uniqPostsId({
        list: posts
      });
    }

    tabPosts.more = more;
    tabPosts.showLoading = false;
    tabPosts.showRefreshing = false;
    tabPosts.showLoadMoreing = false;
    tabPosts.scrollToTop = false;
  });
}), _defineProperty(_handleActions, types.GET_PROFILE_POSTS_DONE, function (state, _ref8) {
  var payload = _ref8.payload,
      _ref8$payload = _ref8.payload,
      posts = _ref8$payload.posts,
      userType = _ref8$payload.userType,
      id = _ref8$payload.id,
      type = _ref8$payload.type,
      more = _ref8$payload.more;
  return produce(state, function (draft) {
    var index = _.findIndex(draft.socialProfile.profilesPosts, {
      userType: userType,
      id: id
    });

    if (index === -1) {
      draft.socialProfile.profilesPosts.push(payload);
    } else {
      if (type === 'loadMore') {
        var list = _.concat(draft.socialProfile.profilesPosts[index].posts, posts);

        draft.socialProfile.profilesPosts[index].posts = uniqPostsId({
          list: list
        });
      } else {
        draft.socialProfile.profilesPosts[index].posts = uniqPostsId({
          list: posts
        });
      }

      draft.socialProfile.profilesPosts[index].more = more;
    }

    draft.showLoading = false;
    draft.showRefreshing = false;
    draft.showLoadMoreing = false;
  });
}), _defineProperty(_handleActions, types.GET_MY_POSTS_DONE, function (state, _ref9) {
  var _ref9$payload = _ref9.payload,
      posts = _ref9$payload.posts,
      type = _ref9$payload.type,
      more = _ref9$payload.more;
  return produce(state, function (draft) {
    if (type === 'loadMore') {
      var list = _.concat(draft.myPosts, posts);

      draft.myPosts = uniqPostsId({
        list: list
      });
    } else {
      draft.myPosts = uniqPostsId({
        list: posts
      });
    }

    draft.myPostsMore = more;
    draft.showLoading = false;
    draft.showRefreshing = false;
    draft.showLoadMoreing = false;
    draft.scrollToTop = false;
  });
}), _defineProperty(_handleActions, types.GET_MY_KEPT_POSTS_DONE, function (state, _ref10) {
  var _ref10$payload = _ref10.payload,
      posts = _ref10$payload.posts,
      type = _ref10$payload.type,
      more = _ref10$payload.more;
  return produce(state, function (draft) {
    if (type === 'loadMore') {
      var list = _.concat(draft.myKeptPosts, posts);

      draft.myKeptPosts = uniqPostsId({
        list: list
      });
    } else {
      draft.myKeptPosts = uniqPostsId({
        list: posts
      });
    }

    draft.myKeptPostsMore = more;
    draft.showLoading = false;
    draft.showRefreshing = false;
    draft.showLoadMoreing = false;
    draft.scrollToTop = false;
  });
}), _defineProperty(_handleActions, types.GET_TAG_POSTS_DONE, function (state, _ref11) {
  var _ref11$payload = _ref11.payload,
      tagId = _ref11$payload.tagId,
      posts = _ref11$payload.posts,
      type = _ref11$payload.type,
      more = _ref11$payload.more;
  return produce(state, function (draft) {
    var index = _.findIndex(draft.tagPosts, {
      tagId: tagId
    });

    if (index === -1) {
      draft.tagPosts.push({
        tagId: tagId,
        posts: posts,
        more: more
      });
    } else {
      if (type === 'loadMore') {
        var list = _.concat(draft.tagPosts[index].posts, posts);

        draft.tagPosts[index].posts = uniqPostsId({
          list: list
        });
      } else {
        draft.tagPosts[index].posts = uniqPostsId({
          list: posts
        });
      }

      draft.tagPosts[index].more = more;
    }

    draft.showLoading = false;
    draft.showRefreshing = false;
    draft.showLoadMoreing = false;
  });
}), _defineProperty(_handleActions, combineActions(types.GET_SOCIAL_POSTS_FAIL, types.GET_MY_POSTS_FAIL, types.GET_PROFILE_POSTS_FAIL, types.GET_TAG_POSTS_FAIL, types.GET_GROUP_POSTS_FAIL), function (state, _ref12) {
  var payload = _ref12.payload;
  return _objectSpread({}, state, {
    showLoading: false,
    showRefreshing: false,
    showLoadMoreing: false,
    scrollToTop: false
  });
}), _defineProperty(_handleActions, types.DELETE_POST_DONE, function (state, _ref13) {
  var id = _ref13.payload.id;
  return produce(state, function (draft) {
    _.remove(draft.socialPosts, {
      id: id
    });

    _.remove(draft.myPosts, {
      id: id
    });

    _.forEach(draft.groupPosts, function (_ref14) {
      var posts = _ref14.posts;

      _.remove(posts, {
        id: id
      });
    });

    _.forEach(draft.socialProfile.profilesPosts, function (_ref15) {
      var posts = _ref15.posts;

      _.remove(posts, {
        id: id
      });
    });
  });
}), _defineProperty(_handleActions, types.UPDATE_COMMENT_DONE, function (state, _ref16) {
  var _ref16$payload = _ref16.payload,
      id = _ref16$payload.id,
      content = _ref16$payload.content,
      recordType = _ref16$payload.recordType,
      postData = _ref16$payload.postData;

  if (recordType === 'Post') {
    return produce(state, function (draft) {
      var post = getSelectedPost(_objectSpread({
        draft: draft
      }, postData));

      if (post) {
        var comments = post.comments;
        var comment = _.find(comments, {
          id: id
        }) || {};
        comment.content = content;
      }
    });
  }
}), _defineProperty(_handleActions, types.DELETE_COMMENT_DONE, function (state, _ref17) {
  var _ref17$payload = _ref17.payload,
      id = _ref17$payload.id,
      recordType = _ref17$payload.recordType,
      postData = _ref17$payload.postData;
  return produce(state, function (draft) {
    if (recordType === 'Post') {
      var post = getSelectedPost(_objectSpread({
        draft: draft
      }, postData));

      if (post) {
        var comments = post.comments;

        _.remove(comments, {
          id: id
        });
      }
    }
  });
}), _defineProperty(_handleActions, types.CREATE_POST_COMMENT_DONE, function (state, _ref18) {
  var _ref18$payload = _ref18.payload,
      newComment = _ref18$payload.newComment,
      postData = _ref18$payload.postData;
  return produce(state, function (draft) {
    var post = getSelectedPost(_objectSpread({
      draft: draft
    }, postData));

    if (post) {
      var _uid = postData.comment._uid;
      var comments = post.comments;
      var comment = _.find(comments, {
        _uid: _uid
      }) || {};
      comment.id = newComment.id;
    }
  });
}), _defineProperty(_handleActions, types.INSERT_POST_COMMENT, function (state, _ref19) {
  var _ref19$payload = _ref19.payload,
      comment = _ref19$payload.comment,
      id = _ref19$payload.id,
      selectType = _ref19$payload.selectType,
      userId = _ref19$payload.userId,
      userType = _ref19$payload.userType,
      tagId = _ref19$payload.tagId;
  return produce(state, function (draft) {
    var post = getSelectedPost({
      draft: draft,
      id: id,
      selectType: selectType,
      userId: userId,
      userType: userType,
      tagId: tagId
    });

    if (post) {
      post.comments.push(comment);
      post.comments_count = post.comments.length;
      updatePost({
        draft: draft,
        selectType: selectType,
        id: id,
        post: post
      });
    }
  });
}), _defineProperty(_handleActions, types.TOGGLE_POST_LIKE, function (state, _ref20) {
  var _ref20$payload = _ref20.payload,
      liked = _ref20$payload.liked,
      id = _ref20$payload.id,
      likedMember = _ref20$payload.likedMember,
      selectType = _ref20$payload.selectType,
      userId = _ref20$payload.userId,
      userType = _ref20$payload.userType,
      tagId = _ref20$payload.tagId;
  return produce(state, function (draft) {
    var post = getSelectedPost({
      draft: draft,
      id: id,
      selectType: selectType,
      userId: userId,
      userType: userType,
      tagId: tagId
    });

    if (post) {
      post.liked = liked;
      post.likes_count = liked ? post.likes_count + 1 : _.max([post.likes_count - 1, 0]);

      if (liked) {
        post.liked_members.push(likedMember);
      } else {
        _.remove(post.liked_members, {
          member_id: likedMember.member_id
        });
      }

      updatePost({
        draft: draft,
        selectType: selectType,
        id: id,
        post: post
      });
    }
  });
}), _defineProperty(_handleActions, types.TOGGLE_POST_KEEP_CALL, function (state, _ref21) {
  var _ref21$payload = _ref21.payload,
      kept = _ref21$payload.kept,
      id = _ref21$payload.id,
      selectType = _ref21$payload.selectType,
      userId = _ref21$payload.userId,
      userType = _ref21$payload.userType,
      tagId = _ref21$payload.tagId;
  return produce(state, function (draft) {
    var post = getSelectedPost({
      draft: draft,
      id: id,
      selectType: selectType,
      userId: userId,
      userType: userType,
      tagId: tagId
    });

    if (post) {
      post.kept = kept;
      updatePost({
        draft: draft,
        selectType: selectType,
        id: id,
        post: post
      });

      if (!kept && selectType === 'myKeptPosts') {
        post.hidden = true;
      }
    }
  });
}), _defineProperty(_handleActions, types.SELECT_POST, function (state, _ref22) {
  var _ref22$payload = _ref22.payload,
      selectType = _ref22$payload.selectType,
      selectId = _ref22$payload.selectId,
      userId = _ref22$payload.userId,
      userType = _ref22$payload.userType,
      tagId = _ref22$payload.tagId;
  return produce(state, function (draft) {
    draft.selectType = selectType;
    draft.selectId = selectId;

    if (selectType === 'profilePosts') {
      draft.socialProfile.id = userId;
      draft.socialProfile.type = userType;
    }

    if (selectType === 'tagPosts') {
      draft.tagId = tagId;
    }
  });
}), _defineProperty(_handleActions, types.GET_SOCIAL_PROFILE_CALL, function (state, _ref23) {
  var _ref23$payload = _ref23.payload,
      id = _ref23$payload.id,
      type = _ref23$payload.type;
  return produce(state, function (draft) {
    if (draft.socialProfile.id !== id || draft.socialProfile.type !== type) {
      draft.socialProfile.id = id;
      draft.socialProfile.type = type;
    }
  });
}), _defineProperty(_handleActions, types.GET_SOCIAL_PROFILE_DONE, function (state, _ref24) {
  var payload = _ref24.payload,
      _ref24$payload = _ref24.payload,
      id = _ref24$payload.id,
      type = _ref24$payload.type;
  return produce(state, function (draft) {
    var profileIndex = _.findIndex(draft.socialProfile.profiles, {
      id: id,
      type: type
    });

    if (profileIndex === -1) {
      draft.socialProfile.profiles.push(payload);
    } else {
      draft.socialProfile.profiles[profileIndex] = payload;
    }
  });
}), _defineProperty(_handleActions, types.GET_MY_PROFILE_DONE, function (state, _ref25) {
  var payload = _ref25.payload;
  return produce(state, function (draft) {
    draft.myProfile = payload;
  });
}), _defineProperty(_handleActions, types.TOGGLE_FOLLOW, function (state, _ref26) {
  var _ref26$payload = _ref26.payload,
      id = _ref26$payload.id,
      type = _ref26$payload.type,
      follow = _ref26$payload.follow,
      followerMember = _ref26$payload.followerMember;
  return produce(state, function (draft) {
    var profile = _.find(draft.socialProfile.profiles, {
      id: id,
      type: type
    });

    if (profile) {
      profile.followed_by_me = follow;

      if (follow) {
        profile.followers_count++;
        profile.follower_members.push(followerMember);
      } else {
        profile.followers_count--;

        _.remove(profile.follower_members, {
          member_id: followerMember.member_id
        });
      }
    }
  });
}), _defineProperty(_handleActions, types.UPDATE_CLIENT_DONE, function (state, _ref27) {
  var client = _ref27.payload.client;
  return produce(state, function (draft) {
    var id = client.id,
        nick_name = client.nick_name,
        self_intro = client.self_intro,
        avatar_thumbnail_url = client.avatar_thumbnail_url;
    var type = 'Client';

    var socialProfile = _.find(draft.socialProfile.profiles, {
      id: id,
      type: type
    });

    if (socialProfile) {
      socialProfile.nick_name = nick_name;
      socialProfile.self_intro = self_intro;
      socialProfile.avatar_thumbnail_url = avatar_thumbnail_url;
    }
  });
}), _defineProperty(_handleActions, types.UPDATE_USER_DONE, function (state, _ref28) {
  var user = _ref28.payload.user;
  return produce(state, function (draft) {
    var id = user.id,
        nick_name = user.nick_name,
        self_intro = user.self_intro,
        avatar_thumbnail_url = user.avatar_thumbnail_url;
    var type = 'User';

    var socialProfile = _.find(draft.socialProfile.profiles, {
      id: id,
      type: type
    });

    if (socialProfile) {
      socialProfile.nick_name = nick_name;
      socialProfile.self_intro = self_intro;
      socialProfile.avatar_thumbnail_url = avatar_thumbnail_url;
    }

    if (draft.myProfile) {
      draft.myProfile.nick_name = nick_name;
      draft.myProfile.self_intro = self_intro;
      draft.myProfile.avatar_thumbnail_url = avatar_thumbnail_url;
    }
  });
}), _defineProperty(_handleActions, types.OPEN_POST_NOTIFICATION_DONE, function (state, _ref29) {
  var payload = _ref29.payload,
      id = _ref29.payload.id;
  return produce(state, function (draft) {
    var index = _.findIndex(draft.notificationPost, {
      id: id
    });

    if (index === -1) {
      draft.notificationPost.push(payload);
    } else {
      draft.notificationPost[index] = payload;
    }
  });
}), _defineProperty(_handleActions, types.CREATE_NOTE_CALL, function (state) {
  return produce(state, function (draft) {
    draft.scrollToTop = true;
  });
}), _defineProperty(_handleActions, types.CREATE_POST, function (state, _ref30) {
  var post = _ref30.payload.post;
  return produce(state, function (draft) {
    var list = _.concat(post, draft.socialPosts);

    draft.socialPosts = uniqPostsId({
      list: list
    });
    draft.scrollToTop = false;
  });
}), _defineProperty(_handleActions, types.CREATE_NOTE_FAIL, function (state) {
  return produce(state, function (draft) {
    draft.scrollToTop = false;
  });
}), _defineProperty(_handleActions, combineActions(types.GET_SOCIAL_SEARCH_CALL, types.GET_SHOP_SEARCH_CALL), function (state, _ref31) {
  var _ref31$payload = _ref31.payload,
      type = _ref31$payload.type,
      isMore = _ref31$payload.isMore;
  return produce(state, function (draft) {
    if (!isMore) {
      draft.search.showLoading = true;
      draft.search[type] = defaultSearch;
    }
  });
}), _defineProperty(_handleActions, combineActions(types.GET_SOCIAL_SEARCH_FAIL, types.GET_SHOP_SEARCH_FAIL), function (state, _ref32) {
  var payload = _ref32.payload;
  return produce(state, function (draft) {
    draft.search.showLoading = false;
  });
}), _defineProperty(_handleActions, types.GET_SOCIAL_SEARCH_DONE, function (state, _ref33) {
  var payload = _ref33.payload,
      _ref33$payload = _ref33.payload,
      data = _ref33$payload.data,
      type = _ref33$payload.type,
      isMore = _ref33$payload.isMore;
  return produce(state, function (draft) {
    if (isMore) {
      var newData = _.concat(draft.search[type].data, data);

      if (type === 'tag') {
        newData = _.chain(newData).uniqBy('id').value();
      } else {
        newData = _.chain(newData).uniqBy('member_id').value();
      }

      draft.search[type] = _objectSpread({}, payload, {
        data: newData
      });
    } else {
      draft.search[type] = payload;
    }

    draft.search.showLoading = false;
  });
}), _defineProperty(_handleActions, types.GET_SHOP_SEARCH_DONE, function (state, _ref34) {
  var _ref34$payload = _ref34.payload,
      data = _ref34$payload.data,
      type = _ref34$payload.type,
      searchValue = _ref34$payload.searchValue,
      isMore = _ref34$payload.isMore;
  return produce(state, function (draft) {
    var list_goods = data.list_goods,
        hasNext = data.hasNext;

    if (isMore) {
      var list = _.concat(draft.search[type].data, list_goods);

      draft.search[type].data = uniqPostsId({
        list: list
      });
    } else {
      draft.search[type].data = uniqPostsId({
        list: list_goods
      });
    }

    draft.search[type].searchValue = searchValue;
    draft.search[type].more = hasNext === 1;
    draft.search.showLoading = false;
  });
}), _defineProperty(_handleActions, types.SET_GROUP_ID, function (state, _ref35) {
  var groupClassId = _ref35.payload.groupClassId;
  return produce(state, function (draft) {
    draft.isSelectSocialPosts = groupClassId === 'socialPosts';
    draft.selectGroupId = groupClassId;
  });
}), _defineProperty(_handleActions, types.GET_GROUP_POSTS_CALL, function (state, _ref36) {
  var selectGroupId = _ref36.payload.groupClassId;
  return produce(state, function (draft) {
    var index = _.findIndex(draft.groupPosts, {
      selectGroupId: selectGroupId
    });

    if (index === -1) {
      draft.groupPosts.push({
        selectGroupId: selectGroupId,
        posts: [],
        more: false
      });
    }
  });
}), _defineProperty(_handleActions, types.GET_GROUP_POSTS_DONE, function (state, _ref37) {
  var _ref37$payload = _ref37.payload,
      posts = _ref37$payload.posts,
      type = _ref37$payload.type,
      more = _ref37$payload.more,
      selectGroupId = _ref37$payload.groupClassId;
  return produce(state, function (draft) {
    var selectGroupPosts = _.find(draft.groupPosts, {
      selectGroupId: selectGroupId
    }) || {
      posts: []
    };

    if (type === 'loadMore') {
      var list = _.concat(selectGroupPosts.posts, posts);

      selectGroupPosts.posts = uniqPostsId({
        list: list
      });
    } else {
      selectGroupPosts.posts = uniqPostsId({
        list: posts
      });
    }

    selectGroupPosts.more = more;
    draft.showLoading = false;
    draft.showRefreshing = false;
    draft.showLoadMoreing = false;
    draft.scrollToTop = false;
  });
}), _defineProperty(_handleActions, types.SET_SOCIAL_MAIN_TAB_INDEX, function (state, _ref38) {
  var mainTabIndex = _ref38.payload.mainTabIndex;
  return produce(state, function (draft) {
    draft.mainTabIndex = mainTabIndex;
  });
}), _defineProperty(_handleActions, types.SET_SOCIAL_TAB_INDEX, function (state, _ref39) {
  var _ref39$payload = _ref39.payload,
      tabIndex = _ref39$payload.tabIndex,
      tabKey = _ref39$payload.tabKey,
      postType = _ref39$payload.postType;
  return produce(state, function (draft) {
    draft.tabIndex = tabIndex;
    draft.tabKey = tabKey;
    draft.postType = postType;
  });
}), _defineProperty(_handleActions, types.OPEN_MODAL_REPORT, function (state, _ref40) {
  var reportData = _ref40.payload.reportData;
  return produce(state, function (draft) {
    draft.reportModalShow = true;
    draft.reportData = reportData;
  });
}), _defineProperty(_handleActions, combineActions(types.REPORT_DONE, types.REPORT_FAIL, types.CLOSE_MODAL_REPORT), function (state) {
  return produce(state, function (draft) {
    draft.reportModalShow = false;
    draft.reportData = defaultReportData;
  });
}), _defineProperty(_handleActions, types.LOGOUT_DONE, function (state) {
  return initialState;
}), _handleActions), initialState);