import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";

var _handleActions;

import _ from 'lodash';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import * as types from '../../store/types';
var initialState = {
  isImagesViewerOpening: false,
  viewerImages: [],
  viewerImageIndex: 0
};
export default handleActions((_handleActions = {}, _defineProperty(_handleActions, types.IMAGES_VIEWER_OPEN_DONE, function (state, _ref) {
  var _ref$payload = _ref.payload,
      viewerImages = _ref$payload.viewerImages,
      viewerImageIndex = _ref$payload.viewerImageIndex;
  return {
    isImagesViewerOpening: !state.isImagesViewerOpening,
    viewerImages: viewerImages,
    viewerImageIndex: viewerImageIndex
  };
}), _defineProperty(_handleActions, types.IMAGES_VIEWER_CLOSE, function (state) {
  return _objectSpread({}, state, {
    isImagesViewerOpening: false
  });
}), _defineProperty(_handleActions, types.LOGOUT_DONE, function (state) {
  return initialState;
}), _handleActions), initialState);