import i18n from 'i18next'
import { reactI18nextModule } from 'react-i18next'
import { storage } from 'cflib/services'
import { en, zh_TW, zh_CN, jp } from 'cflib/i18n/locales'

const locale = 'zh-Hant'
const languageDetector = {
  type: 'languageDetector',
  async: true, // flags below detection to be async
  detect: (callback) => {
    storage.getItemAsync('languageCode')
      .then((langugeCode) => {
        if (langugeCode === null) {
          let lng = locale.substr(0, 7)

          switch (lng) {
            case 'en':
              callback('en')
              break
            case 'zh-Hant':
              callback('zh_TW')
              break
            case 'zh-Hans':
              callback('zh_CN')
              break
            case 'jp':
              callback('jp')
              break
            default:
              callback('zh_TW')
              break;
          }
        } else {
          callback(langugeCode)
        }
      })
  },
  init: () => { },
  cacheUserLanguage: (lng) => {
    storage.setItemAsync('languageCode', lng)
  },
}

i18n
  .use(languageDetector)
  .use(reactI18nextModule)
  .init({
    fallbackLng: {
      default: ['zh_TW'],
    },
    resources: {
      en,
      zh_TW,
      zh_CN,
      jp,
    },
    // have a common namespace used around the full app
    ns: ['common'],
    defaultNS: 'common',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false, // not needed for react as it does escape per default to prevent xss!
    },
  })

export default i18n
