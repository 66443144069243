import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import api from '../api';

var getQueryObject = function getQueryObject() {
  var payload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _payload$count = payload.count,
      count = _payload$count === void 0 ? 20 : _payload$count,
      _payload$beginCount = payload.beginCount,
      beginCount = _payload$beginCount === void 0 ? 0 : _payload$beginCount;

  var params = _objectSpread({}, payload, {
    count: count,
    begin_count: beginCount
  });

  return params;
};

export var getShopIgIndex = function getShopIgIndex(payload) {
  return api.getShopApi('/integral/index', getQueryObject(payload));
};
export var getIgGoodsCart = function getIgGoodsCart(payload) {
  return api.getShopApi('/integral/integral_cart', payload);
};
export var addIgGoodsCart = function addIgGoodsCart(payload) {
  return api.postShopApi('/integral/exchange1', payload);
};
export var updateIgGoodsCart = function updateIgGoodsCart(payload) {
  return api.postShopApi('/integral/adjust_count', payload);
};
export var removeIgGoodsCart = function removeIgGoodsCart(payload) {
  return api.postShopApi('/integral/cart_remove', payload);
};
export var igSettlement = function igSettlement(payload) {
  return api.getShopApi('/integral/exchange2', payload);
};
export var igAddOrder = function igAddOrder(payload) {
  return api.postShopApi('/integral/exchange3', payload);
};
export var getIgOrderList = function getIgOrderList(payload) {
  return api.getShopApi('/buyer/integral_order_list', getQueryObject(payload));
};
export var getIgShipDetail = function getIgShipDetail(payload) {
  return api.getShopApi('/buyer/integral_ship_detail', payload);
};
export var orderIgConfirm = function orderIgConfirm(payload) {
  return api.postShopApi('/buyer/integral_order_cofirm_save', payload);
};
export var getIntegralList = function getIntegralList(payload) {
  return api.getShopApi('/getIntegralList', getQueryObject(payload));
};
export var getIntegralTask = function getIntegralTask(payload) {
  return api.getShopApi('/getIntegralTask', payload);
};
export var getIntegralRule = function getIntegralRule(payload) {
  return api.getShopApi('/integral_rule_list', payload);
};
export var addIntegral = function addIntegral(payload) {
  return api.postShopApi('/addIntegral', payload);
};