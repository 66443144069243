import { createLogger } from 'redux-logger';
export default createLogger({
  collapsed: function collapsed(getState, action, logEntry) {
    return true;
  },
  colors: {
    title: function title(action) {
      return action.error || action.payload && action.payload.error ? 'red' : 'inherit';
    }
  }
});