import React from "react";
import { translate } from 'react-i18next';
import {Button, Checkbox, Form, Input, message} from "antd";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import { bindActionCreators } from 'redux'
import * as actionCreators from '../store/actions'
import CircularProgress from "components/CircularProgress/index";

const FormItem = Form.Item;

class SignIn extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // this.props.showAuthLoader();
        this.props.actions.webEmailLoginCall(values);
      }
    });
  };

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.isSignedIn) {
      this.props.history.push('/');
    }
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    const {showMessage, loader, alertMessage, t} = this.props;

    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content gx-p-4">
              <div className="gx-app-logo-content-bg">
                {/* <img src="https://via.placeholder.com/272x395" alt='Neature'/> */}
              </div>
              <div className="gx-app-logo-wid">
                <h1>COFIT 專家平台</h1>
                <p>BlahBlahBlah</p>
                <p>很厲害的功能</p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require("assets/images/logo_wide.png")}/>
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
                <FormItem>
                  {getFieldDecorator('email', {
                    rules: [{
                      required: true, type: 'email', message: t('login:loginErrorEmailFormat'),
                    }],
                  })(
                    <Input placeholder="Email"/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    rules: [{required: true, message: t('login:loginErrorPasswordMissing') }],
                  })(
                    <Input type="password" placeholder="Password"/>
                  )}
                </FormItem>
                {/* <FormItem>
                  {getFieldDecorator('remember', {
                    valuePropName: 'checked',
                    initialValue: true,
                  })(
                    <Checkbox><IntlMessages id="appModule.iAccept"/></Checkbox>
                  )}
                  <span className="gx-signup-form-forgot gx-link"><IntlMessages
                    id="appModule.termAndCondition"/></span>
                </FormItem> */}
                <FormItem className="gx-mt-4">
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    {t('login:login')}
                  </Button>
                  {/* <span><IntlMessages id="app.userAuth.or"/></span> <Link to="/signup"><IntlMessages
                  id="app.userAuth.signUp"/></Link> */}
                </FormItem>
              </Form>
            </div>
            {loader ?
              <div className="gx-loader-view">
                <CircularProgress/>
              </div> : null}
            {showMessage ?
              message.error(alertMessage.toString()) : null}
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({auth, session}) => {
  const {loader, alertMessage, showMessage} = {};
  const { isSignedIn } = session

  return {loader, alertMessage, showMessage, isSignedIn}
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actionCreators }, dispatch),
  }
}

export default translate()(connect(mapStateToProps, mapDispatchToProps)(WrappedNormalLoginForm));
