import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _handleActions;

import _ from 'lodash';
import { handleActions } from 'redux-actions';
import * as types from '../../store/types';
import produce from 'immer';
export default handleActions((_handleActions = {}, _defineProperty(_handleActions, types.GET_ME_DONE, function (state, _ref) {
  var payload = _ref.payload;
  var type = payload.type,
      id = payload.id,
      gid = payload.gid,
      avatar_thumbnail_url = payload.avatar_thumbnail_url,
      _payload$body_attrs = payload.body_attrs,
      body_attrs = _payload$body_attrs === void 0 ? {
    weight: 60
  } : _payload$body_attrs,
      nick_name = payload.nick_name;
  return produce(state, function (draft) {
    draft.type = type;
    draft.id = id;
    draft.gid = gid;
    draft.avatar_thumbnail_url = avatar_thumbnail_url;
    draft.nickName = nick_name;
    draft.capitalizedType = _.capitalize(type);
    draft.memberSelectType = type;
    draft.body_attrs = body_attrs;
  });
}), _defineProperty(_handleActions, types.SET_MEMBER_SELECT_TYPE, function (state, _ref2) {
  var memberSelectType = _ref2.payload.memberSelectType;
  return produce(state, function (draft) {
    draft.memberSelectType = memberSelectType;
  });
}), _handleActions), {
  capitalizedType: undefined,
  type: undefined,
  id: undefined,
  gid: undefined,
  avatar_thumbnail_url: undefined,
  memberSelectType: undefined,
  body_attrs: {}
});