import { Observable } from 'rxjs';
import { stopSubmit, startSubmit } from 'redux-form';
import { getClient } from '../../store/selectors';
import './RxOperators';
import { explore } from '../../services';
import * as actions from '../actions';
import * as types from '../../store/types';
import moment from 'moment';
export var getPricings = function getPricings(action$, store) {
  return action$.ofType(types.GET_PRICINGS_CALL).switchMap(function (_ref) {
    var payload = _ref.payload;
    return Observable.fromPromise(explore.getPricings(payload)).map(function (res) {
      return actions.getPricingsDone({
        pricings: res
      });
    }).catch(function (err) {
      return Observable.of(actions.getPricingsFail(err));
    });
  });
};
export var checkRegisterCodeCall = function checkRegisterCodeCall(action$, store) {
  return action$.ofType(types.CHECK_REGISTER_CODE_CALL).switchMap(function (_ref2) {
    var code = _ref2.payload.code;
    return Observable.concat(Observable.fromPromise(explore.checkRegisterCode({
      registration_code: code
    })).switchMap(function (res) {
      return Observable.of(actions.registerCodeCall({
        res: res,
        code: code
      }));
    }).catch(function (err) {
      return Observable.concat(Observable.of(actions.checkRegisterCodeFail(err)));
    }));
  });
};
export var registerCode = function registerCode(action$, store) {
  return action$.ofType(types.REGISTER_CODE_CALL).switchMap(function (_ref3) {
    var payload = _ref3.payload;
    var res = payload.res,
        code = payload.code;
    var data = res.data,
        type = res.type;
    var kind = data.kind;
    var isChild = kind === 'child_nutrition';
    var param = {
      registration_code: code,
      care_receiver: isChild ? {
        name: '',
        birthday: moment().subtract(10, 'y').format('YYYY-MM-DD')
      } : undefined
    };
    return Observable.concat(Observable.of(startSubmit('registrationCode')), Observable.fromPromise(explore.registerCode(param)).switchMap(function (res) {
      return Observable.concat(Observable.of(stopSubmit('registrationCode')), Observable.of(actions.getClientMeCall({
        isLogin: true
      })), Observable.of(actions.registerCodeDone({
        type: type
      })));
    }).catch(function (err) {
      return Observable.concat(Observable.of(stopSubmit('registrationCode')), Observable.of(actions.registerCodeFail(err)));
    }));
  });
};