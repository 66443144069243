import _taggedTemplateLiteral from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n    fragment SurveysAllFields on Survey {\n      id\n      name\n      time\n      target_type\n      data\n      created_at\n      updated_at\n      footer\n      questions_count\n      provider\n      notification_sent_at\n    }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { gql } from 'apollo-boost';
export var SURVEYS_ALL_FIELDS = gql(_templateObject());