import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import { Observable } from 'rxjs';
import { diary } from '../../services';
import './RxOperators';
import * as actions from '../actions';
import * as types from '../../store/types';
import { getMember } from '../../store/selectors';
export var getDiary = function getDiary(action$, store) {
  return action$.ofType(types.GET_DIARY_CALL).concatMap(function (_ref) {
    var _ref$payload = _ref.payload,
        clientId = _ref$payload.clientId,
        date = _ref$payload.date,
        dates = _ref$payload.dates;
    return Observable.fromPromise(diary.getDiary(clientId, date, dates)).switchMap(function (_ref2) {
      var notes = _ref2.notes,
          foodRecords = _ref2.food_records,
          targets = _ref2.targets,
          activity = _ref2.activity;
      return [actions.getNotesDone({
        notes: notes
      }), actions.getFoodRecordsDone({
        foodRecords: foodRecords,
        activity: activity
      }), actions.getTargetsDone({
        targets: targets
      })];
    }).catch(function (err) {
      return Observable.of(actions.getDiaryFail(err));
    });
  });
};
export var getDiariesCall = function getDiariesCall(action$, store) {
  return action$.ofType(types.GET_DIARIES_CALL).concatMap(function (_ref3) {
    var clients = _ref3.payload.clients;
    return Observable.concat(Observable.fromPromise(diary.getDiaries(clients)).switchMap(function (_ref4) {
      var notes = _ref4.notes,
          foodRecords = _ref4.food_records,
          targets = _ref4.targets,
          activity = _ref4.activity;
      return [actions.getNotesDone({
        notes: notes
      }), actions.getFoodRecordsDone({
        foodRecords: foodRecords,
        activity: activity
      }), actions.getTargetsDone({
        targets: targets
      })];
    }).catch(function (err) {
      return Observable.of(actions.getDiariesFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var openMeal = function openMeal(action$, store) {
  return action$.ofType(types.OPEN_MEAL_CALL).switchMap(function (_ref5) {
    var _ref5$payload = _ref5.payload,
        order = _ref5$payload.order,
        mode = _ref5$payload.mode,
        noteIdToOpen = _ref5$payload.noteIdToOpen,
        isFocusComment = _ref5$payload.isFocusComment;
    return [actions.selectMeal({
      order: order,
      noteIdToOpen: noteIdToOpen
    }), actions.openMeal({
      mode: mode,
      isFocusComment: isFocusComment
    })];
  });
};
export var openAsset = function openAsset(action$, store) {
  return action$.ofType(types.OPEN_ASSET_CALL).switchMap(function (_ref6) {
    var asset = _ref6.payload.asset;
    return [actions.setAsset({
      asset: asset
    }), actions.openAssetPage()];
  });
};
export var createComment = function createComment(action$, store) {
  return action$.ofType(types.CREATE_COMMENT_CALL).switchMap(function (_ref7) {
    var _ref7$payload = _ref7.payload,
        record = _ref7$payload.record,
        content = _ref7$payload.comment;
    var member = getMember(store.value);

    if (member.id) {
      var avatar_thumbnail_url = member.avatar_thumbnail_url,
          sender_name = member.nick_name;
      var comment = {
        sender_name: sender_name,
        content: content,
        sender_avatar_thumbnail_url: avatar_thumbnail_url,
        _uid: Math.random().toString(16).substring(7) // get 8 digit hex string

      };
      return Observable.concat(Observable.of(actions.insertComment({
        record: record,
        comment: comment
      })), Observable.fromPromise(diary.createComment({
        record: record,
        content: content
      })).map(function (newComment) {
        return actions.createCommentDone({
          record: record,
          comment: _objectSpread({}, comment, newComment)
        });
      }).catch(function (err) {
        return Observable.of(actions.createCommentFail(err));
      }));
    } else {
      return Observable.of(actions.createCommentFail('no member'));
    }
  }).catch(function (err) {
    return Observable.of(actions.createCommentFail(err));
  });
};
export var getDiaryDates = function getDiaryDates(action$, store) {
  return action$.ofType(types.GET_DIARY_DATES_CALL).concatMap(function (_ref8) {
    var _ref8$payload = _ref8.payload;
    _ref8$payload = _ref8$payload === void 0 ? {} : _ref8$payload;
    var clientId = _ref8$payload.clientId,
        before = _ref8$payload.before,
        after = _ref8$payload.after;
    return Observable.fromPromise(diary.getDiaryDates(clientId, after, before)).map(function (dates) {
      return actions.getDiaryDatesDone({
        clientId: clientId,
        dates: dates
      });
    }).catch(function (err) {
      return Observable.of(actions.getDiaryDatesFail(err));
    });
  }).catch(function (err) {
    return Observable.of(actions.getDiaryDatesFail(err));
  });
};