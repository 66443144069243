import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";

var _handleActions;

import { handleActions } from 'redux-actions';
import * as types from '../../store/types';
export default handleActions((_handleActions = {}, _defineProperty(_handleActions, types.GET_PRICINGS_DONE, function (state, _ref) {
  var pricings = _ref.payload.pricings;
  return _objectSpread({}, state, {
    pricings: pricings
  });
}), _defineProperty(_handleActions, types.SET_PRICINGS, function (state, _ref2) {
  var payload = _ref2.payload;
  return _objectSpread({}, state, {
    selectPricing: payload
  });
}), _defineProperty(_handleActions, types.CHECK_REGISTER_CODE_CALL, function (state, _ref3) {
  var payload = _ref3.payload;
  return _objectSpread({}, state, {
    displayError: false
  });
}), _defineProperty(_handleActions, types.CHECK_REGISTER_CODE_FAIL, function (state, _ref4) {
  var payload = _ref4.payload;
  return _objectSpread({}, state, {
    displayError: true
  });
}), _defineProperty(_handleActions, types.ENTER_CHATS_CALL, function (state, _ref5) {
  var payload = _ref5.payload;
  return _objectSpread({}, state, {
    displayError: false
  });
}), _defineProperty(_handleActions, types.ENTER_CHATS_FAIL, function (state, _ref6) {
  var payload = _ref6.payload;
  return _objectSpread({}, state, {
    displayError: true
  });
}), _defineProperty(_handleActions, types.REGISTER_CODE_CALL, function (state, _ref7) {
  var payload = _ref7.payload;
  return _objectSpread({}, state, {
    displayError: false
  });
}), _defineProperty(_handleActions, types.REGISTER_CODE_FAIL, function (state, _ref8) {
  var payload = _ref8.payload;
  return _objectSpread({}, state, {
    displayError: true
  });
}), _defineProperty(_handleActions, types.RESET_REGISTER_CODE_ERROR, function (state, _ref9) {
  var payload = _ref9.payload;
  return _objectSpread({}, state, {
    displayError: false
  });
}), _handleActions), {
  pricings: [],
  selectPricing: {},
  displayError: false
});