import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import axios from 'axios';
export default function request(method, url, data, headers) {
  var otherConfigs = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
  return axios(_objectSpread({
    url: url,
    method: method,
    headers: headers,
    data: method !== 'GET' ? data : null,
    params: method === 'GET' ? data : null
  }, otherConfigs)).then(function (res) {
    return Promise.resolve(res.data);
  }).catch(function (err) {
    return (
      /* const rejection = err.response ?
        new Error(`${err.response.status}: ${err.response.data.message || url}`) :
        err */
      Promise.reject(err)
    );
  });
}