import { Observable } from 'rxjs';
import { target } from '../../services';
import './RxOperators';
import * as actions from '../actions';
import * as types from '../../store/types';
export var getTargets = function getTargets(action$, store) {
  return action$.ofType(types.GET_TARGETS_CALL).switchMap(function (_ref) {
    var payload = _ref.payload;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(target.getTargets()).map(function (_ref2) {
      var targets = _ref2.targets;
      return actions.getTargetsDone({
        targets: targets
      });
    }).catch(function (err) {
      return Observable.of(actions.getTargetsFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var setTarget = function setTarget(action$, store) {
  return action$.ofType(types.SET_TARGET_CALL).switchMap(function (_ref3) {
    var _ref3$payload = _ref3.payload,
        clientParam = _ref3$payload.clientParam,
        targetParam = _ref3$payload.targetParam,
        goTargetPage = _ref3$payload.goTargetPage;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.of(clientParam ? actions.updateClientCall(clientParam) : actions.empty()), Observable.fromPromise(target.setTarget(targetParam)).switchMap(function (res) {
      return [actions.setTargetDone(res), actions.getTargetsDone({
        targets: [res]
      }), goTargetPage ? actions.openTargetPage() : actions.pageGoDiary()];
    }).catch(function (err) {
      return Observable.of(actions.setTargetFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};