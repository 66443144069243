import _taggedTemplateLiteral from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\nquery ($id: ID!) {\n  user: users(id: [$id]) {\n    groupClasses: group_classes {\n      id\n      kind\n      name\n      started_at\n      finished_at\n      chats: chats {\n        id\n        chat_room_id\n        avatar_url\n      }\n      registered_clients: registered_clients {\n        id\n        name \n      }      \n    }\n  }\n}\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { gql } from 'apollo-boost';
export var GET_USER_INFO = gql(_templateObject());