import { createAction } from 'redux-actions';
import * as types from '../../store/types';
export var getMedaiPostsCall = createAction(types.GET_MEDIA_POSTS_CALL);
export var getMedaiPostsDone = createAction(types.GET_MEDIA_POSTS_DONE);
export var getMedaiPostsFail = createAction(types.GET_MEDIA_POSTS_FAIL);
export var getTabPostsCall = createAction(types.GET_TAB_POSTS_CALL);
export var getTabPostsDone = createAction(types.GET_TAB_POSTS_DONE);
export var getTabPostsFail = createAction(types.GET_TAB_POSTS_FAIL);
export var getSocialPostsCall = createAction(types.GET_SOCIAL_POSTS_CALL);
export var getSocialPostsDone = createAction(types.GET_SOCIAL_POSTS_DONE);
export var getSocialPostsFail = createAction(types.GET_SOCIAL_POSTS_FAIL);
export var getSocialProfileCall = createAction(types.GET_SOCIAL_PROFILE_CALL);
export var getSocialProfileDone = createAction(types.GET_SOCIAL_PROFILE_DONE);
export var getSocialProfileFail = createAction(types.GET_SOCIAL_PROFILE_FAIL);
export var getMyProfileCall = createAction(types.GET_MY_PROFILE_CALL);
export var getMyProfileDone = createAction(types.GET_MY_PROFILE_DONE);
export var getMyProfileFail = createAction(types.GET_MY_PROFILE_FAIL);
export var getProfilePostsCall = createAction(types.GET_PROFILE_POSTS_CALL);
export var getProfilePostsDone = createAction(types.GET_PROFILE_POSTS_DONE);
export var getProfilePostsFail = createAction(types.GET_PROFILE_POSTS_FAIL);
export var getMyPostsCall = createAction(types.GET_MY_POSTS_CALL);
export var getMyPostsDone = createAction(types.GET_MY_POSTS_DONE);
export var getMyPostsFail = createAction(types.GET_MY_POSTS_FAIL);
export var getMyKeptPostsCall = createAction(types.GET_MY_KEPT_POSTS_CALL);
export var getMyKeptPostsDone = createAction(types.GET_MY_KEPT_POSTS_DONE);
export var getMyKeptPostsFail = createAction(types.GET_MY_KEPT_POSTS_FAIL);
export var getPostCall = createAction(types.GET_POST_CALL);
export var getPostDone = createAction(types.GET_POST_DONE);
export var getPostFail = createAction(types.GET_POST_FAIL);
export var deletePostCall = createAction(types.DELETE_POST_CALL);
export var deletePostDone = createAction(types.DELETE_POST_DONE);
export var deletePostFail = createAction(types.DELETE_POST_FAIL);
export var createPostCommentCall = createAction(types.CREATE_POST_COMMENT_CALL);
export var createPostCommentDone = createAction(types.CREATE_POST_COMMENT_DONE);
export var createPostCommentFail = createAction(types.CREATE_POST_COMMENT_FAIL);
export var insertPostComment = createAction(types.INSERT_POST_COMMENT);
export var togglePostLikeCall = createAction(types.TOGGLE_POST_LIKE_CALL);
export var togglePostLikeDone = createAction(types.TOGGLE_POST_LIKE_DONE);
export var togglePostLikeFail = createAction(types.TOGGLE_POST_LIKE_FAIL);
export var togglePostLike = createAction(types.TOGGLE_POST_LIKE);
export var togglePostKeepCall = createAction(types.TOGGLE_POST_KEEP_CALL);
export var togglePostKeepDone = createAction(types.TOGGLE_POST_KEEP_DONE);
export var togglePostKeepFail = createAction(types.TOGGLE_POST_KEEP_FAIL);
export var selectPost = createAction(types.SELECT_POST);
export var openPostCall = createAction(types.OPEN_POST_CALL);
export var openPostDone = createAction(types.OPEN_POST_DONE);
export var openPostFail = createAction(types.OPEN_POST_FAIL);
export var openSocialProfileCall = createAction(types.OPEN_SOCIAL_PROFILE_CALL);
export var openSocialProfileDone = createAction(types.OPEN_SOCIAL_PROFILE_DONE);
export var openSocialProfileFail = createAction(types.OPEN_SOCIAL_PROFILE_FAIL);
export var openPostNotificationCall = createAction(types.OPEN_POST_NOTIFICATION_CALL);
export var openPostNotificationDone = createAction(types.OPEN_POST_NOTIFICATION_DONE);
export var openPostNotificationFail = createAction(types.OPEN_POST_NOTIFICATION_FAIL);
export var toggleFollowCall = createAction(types.TOGGLE_FOLLOW_CALL);
export var toggleFollowDone = createAction(types.TOGGLE_FOLLOW_DONE);
export var toggleFollowFail = createAction(types.TOGGLE_FOLLOW_FAIL);
export var toggleFollow = createAction(types.TOGGLE_FOLLOW);
export var getTagPostsCall = createAction(types.GET_TAG_POSTS_CALL);
export var getTagPostsDone = createAction(types.GET_TAG_POSTS_DONE);
export var getTagPostsFail = createAction(types.GET_TAG_POSTS_FAIL);
export var openTagPostsCall = createAction(types.OPEN_TAG_POSTS_CALL);
export var openTagPostsDone = createAction(types.OPEN_TAG_POSTS_DONE);
export var openTagPostsFail = createAction(types.OPEN_TAG_POSTS_FAIL);
export var getSocialSearchCall = createAction(types.GET_SOCIAL_SEARCH_CALL);
export var getSocialSearchDone = createAction(types.GET_SOCIAL_SEARCH_DONE);
export var getSocialSearchFail = createAction(types.GET_SOCIAL_SEARCH_FAIL);
export var getGroupPostsCall = createAction(types.GET_GROUP_POSTS_CALL);
export var getGroupPostsDone = createAction(types.GET_GROUP_POSTS_DONE);
export var getGroupPostsFail = createAction(types.GET_GROUP_POSTS_FAIL);
export var setGroupId = createAction(types.SET_GROUP_ID);
export var setSocialTabIndex = createAction(types.SET_SOCIAL_TAB_INDEX);
export var setSocialMainTabIndex = createAction(types.SET_SOCIAL_MAIN_TAB_INDEX);
export var openModalReport = createAction(types.OPEN_MODAL_REPORT);
export var closeModalReport = createAction(types.CLOSE_MODAL_REPORT);
export var reportCall = createAction(types.REPORT_CALL);
export var reportDone = createAction(types.REPORT_DONE);
export var reportFail = createAction(types.REPORT_FAIL);
export var blockCall = createAction(types.BLOCK_CALL);
export var blockDone = createAction(types.BLOCK_DONE);
export var blockFail = createAction(types.BLOCK_FAIL);
export var unBlockCall = createAction(types.UN_BLOCK_CALL);
export var unBlockDone = createAction(types.UN_BLOCK_DONE);
export var unBlockFail = createAction(types.UN_BLOCK_FAIL);
export var createPost = createAction(types.CREATE_POST);