import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";

var _handleActions;

import _ from 'lodash';
import produce from 'immer';
import { handleActions, combineActions } from 'redux-actions';
import * as types from '../../store/types';

var getClientProfile = function getClientProfile(state, client_id) {
  var profiles = state.profiles;
  return _.find(profiles, {
    client_id: client_id
  });
};

var initialProfile = {
  profiles: [],
  clientId: undefined,
  scrollEnabled: true,
  dateIndex: 0,
  dateType: 'week'
};
var initialProfileData = {
  bodyData: {
    month: undefined,
    year: undefined
  },
  nutritionData: {
    month: undefined,
    year: undefined
  },
  moveData: {
    month: undefined,
    year: undefined
  },
  moreData: undefined
};
export default handleActions((_handleActions = {}, _defineProperty(_handleActions, combineActions(types.GET_BODY_DATA_DONE, types.GET_NUTRITION_DONE, types.GET_MOVE_DATA_DONE), function (state, _ref) {
  var _ref$payload = _ref.payload,
      type = _ref$payload.type,
      clientId = _ref$payload.clientId,
      data = _ref$payload.data,
      param = _ref$payload.param;
  return produce(state, function (draft) {
    var profile = getClientProfile(draft, clientId);

    if (!profile) {
      profile = _objectSpread({}, initialProfileData, {
        client_id: clientId
      });
      draft.profiles.push(profile);
    }

    var _profile = profile,
        bodyData = _profile.bodyData,
        nutritionData = _profile.nutritionData,
        moveData = _profile.moveData;
    var selectData;

    switch (type) {
      case 'body':
        selectData = bodyData;
        break;

      case 'nutrition':
        selectData = nutritionData;
        break;

      case 'move':
        selectData = moveData;
        break;

      default:
        break;
    }

    if (param && param.dur === 'monthly') {
      selectData.year = data;
    } else {
      selectData.month = data;
    }
  });
}), _defineProperty(_handleActions, combineActions(types.GET_BODY_DATA_MORE_CALL, types.GET_NUTRITION_MORE_CALL, types.GET_MOVE_DATA_MORE_CALL), function (state, _ref2) {
  var payload = _ref2.payload;
  return produce(state, function (draft) {
    var profile = getClientProfile(draft, state.clientId);
    profile.moreData = undefined;
  });
}), _defineProperty(_handleActions, combineActions(types.GET_BODY_DATA_MORE_DONE, types.GET_NUTRITION_MORE_DONE, types.GET_MOVE_DATA_MORE_DONE), function (state, _ref3) {
  var _ref3$payload = _ref3.payload,
      type = _ref3$payload.type,
      clientId = _ref3$payload.clientId,
      newData = _ref3$payload.newData,
      param = _ref3$payload.param;
  return produce(state, function (draft) {
    var profile = getClientProfile(draft, clientId);
    var dateType = param.dur === 'monthly' ? 'year' : 'month';
    profile.moreData = _objectSpread({}, newData[param.attrs], {
      attrs: param.attrs
    });
    var moreData = profile.moreData,
        bodyData = profile.bodyData,
        nutritionData = profile.nutritionData,
        moveData = profile.moveData;
    var selectData;

    switch (type) {
      case 'body':
        selectData = bodyData;
        break;

      case 'nutrition':
        selectData = nutritionData;
        break;

      case 'move':
        selectData = moveData;
        break;

      default:
        break;
    }

    var _selectData$dateType$ = selectData[dateType][param.attrs],
        end_cursor = _selectData$dateType$.end_cursor,
        data = _selectData$dateType$.data;
    selectData[dateType][param.attrs] = {
      concatDate: end_cursor,
      data: data.concat(moreData.data),
      more: moreData.more,
      end_cursor: moreData.end_cursor
    };
  });
}), _defineProperty(_handleActions, types.SET_SCROLL_ENABLED, function (state, _ref4) {
  var payload = _ref4.payload;
  return _objectSpread({}, state, {
    scrollEnabled: payload
  });
}), _defineProperty(_handleActions, types.SET_DATE_TYPE, function (state, _ref5) {
  var _ref5$payload = _ref5.payload,
      dateIndex = _ref5$payload.dateIndex,
      dateType = _ref5$payload.dateType;
  return _objectSpread({}, state, {
    dateIndex: dateIndex,
    dateType: dateType
  });
}), _defineProperty(_handleActions, types.SET_PROFILE_CLIENT, function (state, _ref6) {
  var payload = _ref6.payload;
  return _objectSpread({}, state, {
    clientId: payload
  });
}), _defineProperty(_handleActions, types.LOGOUT_DONE, function (state) {
  return initialProfile;
}), _handleActions), initialProfile);