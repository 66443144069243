import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import { Observable } from 'rxjs';
import { searchLocation } from '../../services';
import './RxOperators';
import * as actions from '../actions';
import * as types from '../../store/types';
export var getSearchLocationCall = function getSearchLocationCall(action$, store) {
  return action$.ofType(types.GET_SEARCH_LOCATION_CALL).switchMap(function (_ref) {
    var payload = _ref.payload,
        provider = _ref.payload.provider;
    return Observable.fromPromise(searchLocation.getSearchLocation(payload)).map(function (res) {
      return actions.getSearchLocationDone(_objectSpread({}, res, {
        provider: provider
      }));
    }).catch(function (err) {
      return Observable.of(actions.getSearchLocationFail(err));
    });
  });
};