import { Observable } from 'rxjs';
import './RxOperators';
import * as actions from '../actions';
import * as types from '../../store/types';
import { message } from '../../services';
export var showI18nMessageCall = function showI18nMessageCall(action$, store) {
  return action$.ofType(types.SHOW_I18N_MESSAGE_CALL).switchMap(function (_ref) {
    var payload = _ref.payload;
    return Observable.fromPromise(message.showI18nMessage(payload)).map(function () {
      return actions.checkForUpdateDone();
    });
  }).catch(function (err) {
    return Observable.of(actions.showI18nMessageFail(err));
  });
};