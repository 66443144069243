export var GET_QUESTION_LIST_CALL = 'GET_QUESTION_LIST_CALL';
export var GET_QUESTION_LIST_DONE = 'GET_QUESTION_LIST_DONE';
export var GET_QUESTION_LIST_FAIL = 'GET_QUESTION_LIST_FAIL';
export var GET_QUESTION_CALL = 'GET_QUESTION_CALL';
export var GET_QUESTION_DONE = 'GET_QUESTION_DONE';
export var GET_QUESTION_FAIL = 'GET_QUESTION_FAIL';
export var SEND_QUESTION_CALL = 'SEND_QUESTION_CALL';
export var SEND_QUESTION_DONE = 'SEND_QUESTION_DONE';
export var SEND_QUESTION_FAIL = 'SEND_QUESTION_FAIL';
export var NEW_SEND_QUESTION_CALL = 'NEW_SEND_QUESTION_CALL';
export var NEW_SEND_QUESTION_DONE = 'NEW_SEND_QUESTION_DONE';
export var NEW_SEND_QUESTION_FAIL = 'NEW_SEND_QUESTION_FAIL';
export var GET_QUESTION_RESULT_CALL = 'GET_QUESTION_RESULT_CALL';
export var GET_QUESTION_RESULT_DONE = 'GET_QUESTION_RESULT_DONE';
export var GET_QUESTION_RESULT_FAIL = 'GET_QUESTION_RESULT_FAIL';
export var GET_COFIT_QUESTION_CALL = 'GET_COFIT_QUESTION_CALL';
export var GET_COFIT_QUESTION_DONE = 'GET_COFIT_QUESTION_DONE';
export var GET_COFIT_QUESTION_FAIL = 'GET_COFIT_QUESTION_FAIL';
export var OPEN_QUESTION_COFIT_PAGE_DONE = 'OPEN_QUESTION_COFIT_PAGE_DONE';