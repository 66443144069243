export var GET_FACTORY_LIST_CALL = 'GET_FACTORY_LIST_CALL';
export var GET_FACTORY_LIST_DONE = 'GET_FACTORY_LIST_DONE';
export var GET_FACTORY_LIST_FAIL = 'GET_FACTORY_LIST_FAIL';
export var GET_FACTORY_COURSE_CALL = 'GET_FACTORY_COURSE_CALL';
export var GET_FACTORY_COURSE_DONE = 'GET_FACTORY_COURSE_DONE';
export var GET_FACTORY_COURSE_FAIL = 'GET_FACTORY_COURSE_FAIL';
export var GET_FACTORY_COACH_LIST_CALL = 'GET_FACTORY_COACH_LIST_CALL';
export var GET_FACTORY_COACH_LIST_DONE = 'GET_FACTORY_COACH_LIST_DONE';
export var GET_FACTORY_COACH_LIST_FAIL = 'GET_FACTORY_COACH_LIST_FAIL';
export var GET_FACTORY_CLIENT_INFO_CALL = 'GET_FACTORY_CLIENT_INFO_CALL';
export var GET_FACTORY_CLIENT_INFO_DONE = 'GET_FACTORY_CLIENT_INFO_DONE';
export var GET_FACTORY_CLIENT_INFO_FAIL = 'GET_FACTORY_CLIENT_INFO_FAIL';
export var SHOW_BAR_CODE_MODAL = 'SHOW_BAR_CODE_MODAL';
export var CLOSE_BAR_CODE_MODAL = 'CLOSE_BAR_CODE_MODAL';
export var GET_RESERVE_CLASSES_CALL = 'GET_RESERVE_CLASSES_CALL';
export var GET_RESERVE_CLASSES_DONE = 'GET_RESERVE_CLASSES_DONE';
export var GET_RESERVE_CLASSES_FAIL = 'GET_RESERVE_CLASSES_FAIL';
export var RESERVE_CLASSES_CALL = 'RESERVE_CLASSES_CALL';
export var RESERVE_CLASSES_DONE = 'RESERVE_CLASSES_DONE';
export var RESERVE_CLASSES_FAIL = 'RESERVE_CLASSES_FAIL';
export var CANCEL_RESERVE_CLASSES_CALL = 'CANCEL_RESERVE_CLASSES_CALL';
export var CANCEL_RESERVE_CLASSES_DONE = 'CANCEL_RESERVE_CLASSES_DONE';
export var CANCEL_RESERVE_CLASSES_FAIL = 'CANCEL_RESERVE_CLASSES_FAIL';