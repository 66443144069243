export var CHANGE_DATE = 'CHANGE_DATE';
export var SELECT_MEAL = 'SELECT_MEAL';
export var OPEN_MEAL = 'OPEN_MEAL';
export var OPEN_MEAL_CALL = 'OPEN_MEAL_CALL';
export var OPEN_ASSET_CALL = 'OPEN_ASSET_CALL';
export var SET_ASSET = 'SET_ASSET';
export var OPEN_ASSET_PAGE = 'OPEN_ASSET_PAGE';
export var SET_DIARY_CLIENT = 'SET_DIARY_CLIENT';
export var OPEN_DIARY_DETAIL = 'OPEN_DIARY_DETAIL';
export var OPEN_ITEM_SUMMARY = 'OPEN_ITEM_SUMMARY';
export var GET_DIARY_CALL = 'GET_DIARY_CALL';
export var GET_DIARY_DONE = 'GET_DIARY_DONE';
export var GET_DIARY_FAIL = 'GET_DIARY_FAIL';
export var GET_DIARIES_CALL = 'GET_DIARIES_CALL';
export var GET_DIARIES_DONE = 'GET_DIARIES_DONE';
export var GET_DIARIES_FAIL = 'GET_DIARIES_FAIL';
export var GET_DIARY_DATES_CALL = 'GET_DIARY_DATES_CALL';
export var GET_DIARY_DATES_DONE = 'GET_DIARY_DATES_DONE';
export var GET_DIARY_DATES_FAIL = 'GET_DIARY_DATES_FAIL';
export var CREATE_COMMENT_CALL = 'CREATE_COMMENT_CALL';
export var INSERT_COMMENT = 'INSERT_COMMENT';
export var CREATE_COMMENT_DONE = 'CREATE_COMMENT_DONE';
export var CREATE_COMMENT_FAIL = 'CREATE_COMMENT_FAIL';