import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";

var _sessionHandlers;

import _ from 'lodash';
import produce from 'immer';
import * as types from '../../../store/types';
var sessionHandlers = (_sessionHandlers = {}, _defineProperty(_sessionHandlers, types.ADD_ACTIVITY_SESSIONS, function (state, _ref) {
  var sessions = _ref.payload.sessions;
  return produce(state, function (draft) {
    draft.selectedActivitySessions.push(sessions);
  });
}), _defineProperty(_sessionHandlers, types.UPDATE_ACTIVITY_SESSIONS, function (state, _ref2) {
  var _ref2$payload = _ref2.payload,
      added_at = _ref2$payload.added_at,
      items = _ref2$payload.items,
      sessionData = _ref2$payload.sessionData,
      session = _ref2$payload.session;
  return produce(state, function (draft) {
    var _state$selectedActivi = state.selectedActivitySessions,
        selectedActivitySessions = _state$selectedActivi === void 0 ? [] : _state$selectedActivi;

    var index = _.findIndex(selectedActivitySessions, {
      added_at: added_at
    });

    if (index !== -1) {
      if (!_.isEmpty(session)) {
        draft.selectedActivitySessions[index] = session;
      } else {
        draft.selectedActivitySessions[index].items = items;
      }

      draft.selectedActivitySessions[index] = _objectSpread({}, draft.selectedActivitySessions[index], sessionData);
    }
  });
}), _defineProperty(_sessionHandlers, types.REMOVE_ACTIVITY_SESSIONS, function (state, _ref3) {
  var payload = _ref3.payload;
  var selectedActivitySessions = state.selectedActivitySessions;

  var index = _.findIndex(state.selectedActivitySessions, {
    added_at: payload
  });

  if (index > -1) {
    selectedActivitySessions = [].concat(_toConsumableArray(selectedActivitySessions.slice(0, index)), _toConsumableArray(selectedActivitySessions.slice(index + 1)), [_objectSpread({}, selectedActivitySessions[index], {
      _destroy: true
    })]);
  }

  return _objectSpread({}, state, {
    selectedActivitySessions: selectedActivitySessions
  });
}), _defineProperty(_sessionHandlers, types.CONFIRM_NEW_NOTE_ACTIVITY_SESSIONS, function (state) {
  var selectedActivitySessions = state.selectedActivitySessions;
  return _objectSpread({}, state, {
    sessions: selectedActivitySessions,
    calorie: _.reduce(_.reject(selectedActivitySessions, ['_destroy', true]), function (sum, n) {
      return sum + (n.measure_calorie || 0);
    }, 0)
  });
}), _sessionHandlers);
export default sessionHandlers;