import _ from 'lodash';
import lib from '../platform';
var device = lib.pfDevice,
    update = lib.pfUpdate;
var brokenCameraDevices = ['FS8009'];
var noHttpsDevices = ['HTC_D10u'];
var samsungDevices = ['SM', 'LG'];
var _device$model = device.model,
    model = _device$model === void 0 ? '' : _device$model;
export var getInfo = function getInfo() {
  return device.getDeviceInfo();
};
export var registerForPushNotificationsAsync = function registerForPushNotificationsAsync() {
  return device.registerForPushNotificationsAsync();
};
export var registerForAndroidPushNotificationsAsync = function registerForAndroidPushNotificationsAsync() {
  return device.registerForAndroidPushNotificationsAsync();
};
export var registerForCameraAsync = function registerForCameraAsync() {
  return device.registerForCameraAsync();
};
export var registerForCameraRollAsync = function registerForCameraRollAsync() {
  return device.registerForCameraRollAsync();
};
export var setBadgeNumberAsync = function setBadgeNumberAsync(number) {
  return device.setBadgeNumberAsync(number);
};
export var isBrokenCameraDevice = _.includes(brokenCameraDevices, model);
export var isNoHttpsDevice = _.includes(noHttpsDevices, model);
export var isSamsungDevice = _.some(samsungDevices, function (device) {
  return model.indexOf(device) >= 0;
});
export var decimalKeyboard = isSamsungDevice ? 'decimal-pad' : 'decimal-pad';
export var handleOpenAppSettings = function handleOpenAppSettings() {
  return device.handleOpenAppSettings();
};
export var checkForUpdate = function checkForUpdate(param) {
  return update.checkForUpdate(param);
};