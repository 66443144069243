import { Observable } from 'rxjs';
import { group } from '../../services';
import './RxOperators';
import * as actions from '../actions';
import * as types from '../../store/types';
export var openRankPage = function openRankPage(action$, store) {
  return action$.ofType(types.OPEN_RANK_PAGE_CALL).concatMap(function (_ref) {
    var group_class_id = _ref.payload.groupClassId;
    return [actions.getRanksCall({
      group_class_id: group_class_id
    }), actions.openRankPage()];
  }).catch(function (err) {
    return Observable.of(actions.openRankPageFail(err));
  });
};
export var getRanks = function getRanks(action$, store) {
  return action$.ofType(types.GET_RANKS_CALL).switchMap(function (_ref2) {
    var payload = _ref2.payload;
    return Observable.fromPromise(group.getRanks(payload)).map(function (res) {
      return actions.getRanksDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getRanksFail(err));
    });
  });
};
export var openSocialGroupPageCall = function openSocialGroupPageCall(action$, store) {
  return action$.ofType(types.OPEN_SOCIAL_GROUP_PAGE_CALL).switchMap(function (_ref3) {
    var groupClassId = _ref3.payload.groupClassId;
    return [actions.setSocialMainTabIndex({
      mainTabIndex: 0
    }), actions.setGroupId({
      groupClassId: groupClassId
    }), actions.getGroupPostsCall({
      type: 'first',
      groupClassId: groupClassId
    }), actions.openSocialGroupPageDone()];
  }).catch(function (err) {
    return Observable.of(actions.openSocialGroupPageFail(err));
  });
};
export var openGroupTagsPageCall = function openGroupTagsPageCall(action$, store) {
  return action$.ofType(types.OPEN_GROUP_TAG_PAGE_CALL).switchMap(function (_ref4) {
    var groupClassId = _ref4.payload.groupClassId;
    return [actions.getGroupTagsCall({
      groupClassId: groupClassId
    }), actions.openGroupTagsPageDone()];
  }).catch(function (err) {
    return Observable.of(actions.openGroupTagsPageFail(err));
  });
};
export var getGroupTagsCall = function getGroupTagsCall(action$, store) {
  return action$.ofType(types.GET_GROUP_TAGS_CALL).switchMap(function (_ref5) {
    var payload = _ref5.payload;
    return Observable.fromPromise(group.getGroupTags(payload)).map(function (res) {
      return actions.getGroupTagsDone(res);
    }).catch(function (err) {
      return Observable.of(actionsgetGroupTagsFail(err));
    });
  });
};