import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";

var _handleActions;

import _ from 'lodash';
import { handleActions } from 'redux-actions';
import * as types from '../../../store/types';
export default handleActions((_handleActions = {}, _defineProperty(_handleActions, types.GET_HISTORY_ASSETS_CALL, function (state) {
  return _objectSpread({}, state, {
    showLoading: true
  });
}), _defineProperty(_handleActions, types.GET_HISTORY_ASSETS_DONE, function (state, _ref) {
  var newAssets = _ref.payload.assets;

  var assets = _.orderBy(_.uniqBy([].concat(_toConsumableArray(newAssets), _toConsumableArray(state.assets)), function (a) {
    return "".concat(a.class_name, "-").concat(a.id);
  }), ['created_at'], ['desc']);

  return _objectSpread({}, state, {
    showLoading: false,
    assets: assets,
    oldestTimestamp: _.last(assets) ? _.last(assets).created_at : undefined
  });
}), _defineProperty(_handleActions, types.GET_HISTORY_ASSETS_FAIL, function (state) {
  return _objectSpread({}, state, {
    showLoading: false
  });
}), _handleActions), {
  assets: [],
  oldestTimestamp: undefined,
  showLoading: true
});