import lib from '../platform';
var pfConfig = lib.pfConfig,
    pfAnalytics = lib.pfAnalytics;
var Analytics = pfAnalytics.Analytics,
    ScreenHit = pfAnalytics.ScreenHit,
    Event = pfAnalytics.Event,
    PageHit = pfAnalytics.PageHit;
var _pfConfig$common = pfConfig.common,
    enableAnalytic = _pfConfig$common.enableAnalytic,
    gaKey = _pfConfig$common.gaKey;
var analytics = undefined;

if (enableAnalytic) {
  analytics = new Analytics(gaKey, null, {
    debug: false
  });
  analytics.hit(new PageHit('openApp')).catch(function (e) {
    console.log(e);
  });
}

export var getAnalytics = function getAnalytics() {
  if (!analytics) {
    analytics = new Analytics(gaKey, null, {
      debug: false
    });
  }

  return analytics;
};
export var setAnalytics = function setAnalytics(_ref) {
  var type = _ref.type,
      log = _ref.log;

  if (enableAnalytic) {
    try {
      switch (type) {
        case 'ScreenHit':
          analytics.hit(new ScreenHit(log)).catch(function (e) {
            console.log(e);
          });
          break;

        case 'PageHit':
          analytics.hit(new PageHit(log)).catch(function (e) {
            console.log(e);
          });
          break;

        case 'Event':
          var category = log.category,
              action = log.action,
              label = log.label,
              value = log.value;
          analytics.event(new Event(category, action, label, value)).catch(function (e) {
            console.log(e);
          });
          break;

        default:
          break;
      }
    } catch (error) {
      console.log(error);
    }
  }
};