import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";

var _handleActions;

import _ from 'lodash';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import * as types from '../../store/types';
var initialState = {
  clientId: undefined,
  clients: []
};

var getFormatClientBodyAttrs = function getFormatClientBodyAttrs(client) {
  var body_attrs = client.body_attrs;
  var formatBodyAttrs = body_attrs || {};

  _.forEach(body_attrs, function (attr, index) {
    if (_.isNull(attr) || _.isUndefined(attr)) {
      formatBodyAttrs[index] = 0;
    }
  });

  return _objectSpread({}, client, {
    body_attrs: formatBodyAttrs
  });
};

export default handleActions((_handleActions = {}, _defineProperty(_handleActions, types.SET_CLIENT_ID, function (state, _ref) {
  var payload = _ref.payload;
  return produce(state, function (draft) {
    draft.clientId = payload;
  });
}), _defineProperty(_handleActions, types.GET_CLIENTS_DONE, function (state, _ref2) {
  var payload = _ref2.payload;
  return produce(state, function (draft) {
    var clients = _.uniqBy(_.concat(payload, draft.clients), 'id');

    draft.clients = _.map(clients, function (client) {
      return getFormatClientBodyAttrs(client);
    });
  });
}), _defineProperty(_handleActions, types.GET_CLIENT_DONE, function (state, _ref3) {
  var payload = _ref3.payload;
  return produce(state, function (draft) {
    var clients = _.uniqBy(_.concat(payload, draft.clients), 'id');

    draft.clients = _.map(clients, function (client) {
      return getFormatClientBodyAttrs(client);
    });
  });
}), _defineProperty(_handleActions, types.LOGOUT_DONE, function (state) {
  return initialState;
}), _defineProperty(_handleActions, types.UPDATE_CLIENT_DONE, function (state, _ref4) {
  var client = _ref4.payload.client;
  return produce(state, function (draft) {
    var index = _.findIndex(draft.clients, {
      id: client.id
    });

    draft.clients[index] = _objectSpread({}, draft.clients[index], getFormatClientBodyAttrs(client));
  });
}), _defineProperty(_handleActions, types.UPDATE_CLIENT_CALL, function (state, _ref5) {
  var _ref5$payload = _ref5.payload,
      key = _ref5$payload.key,
      value = _ref5$payload.value,
      firstUpdate = _ref5$payload.firstUpdate;
  return produce(state, function (draft) {
    var index = _.findIndex(draft.clients, {
      id: state.clientId
    });

    var client = draft.clients[index] || {};

    if (firstUpdate) {
      if (key === 'top') {
        draft.clients[index] = _objectSpread({}, client, value);
      } else {
        draft.clients[index] = _objectSpread({}, client, _defineProperty({}, key, _objectSpread({}, client[key], value)));
      }
    }
  });
}), _handleActions), initialState);