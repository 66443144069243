import { Observable } from 'rxjs';
import { stopSubmit, startSubmit, reset } from 'redux-form';
import { session, storage, device } from '../../services';
import lib from '../../platform';
import './RxOperators';
import * as actions from '../actions';
import * as types from '../../store/types';
import _ from 'lodash';
var pfConfig = lib.pfConfig;
var isIos = pfConfig.isIos;
export var checkLogin = function checkLogin(action$, store) {
  return action$.ofType(types.CHECK_LOGIN_CALL).switchMap(function () {
    return Observable.fromPromise(storage.getItemAsync('accessToken')).switchMap(function (accessToken) {
      if (accessToken) {
        console.log('%c GOT TOKEN FROM STORAGE', 'color: green', accessToken);
        return [actions.setToken({
          accessToken: accessToken
        }), actions.getMeCall({
          isLogin: true
        }), actions.checkLoginDone(), actions.getUnreadCountCall(), actions.updateDeviceInfoCall(), actions.getAllSettingStoreCall()];
      } else {
        console.log('%c CAN NOT FIND TOKEN', 'color: red');
        return Observable.of(actions.checkLoginFail({
          error: true,
          message: 'cant not find token'
        }));
      }
    }).catch(function (err) {
      return Observable.of(actions.checkLoginFail(err));
    });
  });
};
export var accountLogin = function accountLogin(action$, store) {
  return action$.ofType(types.ACCOUNT_LOGIN_CALL).switchMap(function () {
    var state = store.value;
    var account = '';
    var password = '';

    if (state.form && state.form && state.form.login && state.form.login.values) {
      var _state$form$login$val = state.form.login.values,
          loginAcctont = _state$form$login$val.account,
          loginPassword = _state$form$login$val.password;
      account = _.trim(loginAcctont);
      password = _.trim(loginPassword);
    }

    return Observable.concat(Observable.of(startSubmit('login')), Observable.fromPromise(session.accountLogin({
      account: account,
      password: password
    })).switchMap(function (accessToken) {
      return [reset('login'), stopSubmit('login'), actions.setToken({
        accessToken: accessToken
      }), actions.accountLoginDone(), actions.getUnreadCountCall(), actions.getMeCall({
        isLogin: true
      }), actions.storeTokenCall({
        accessToken: accessToken
      }), actions.updateDeviceInfoCall()];
    }).catch(function (err) {
      return Observable.concat(Observable.of(stopSubmit('login')), Observable.of(actions.accountLoginFail(err)));
    }));
  });
};
export var emailLogin = function emailLogin(action$, store) {
  return action$.ofType(types.EMAIL_LOGIN_CALL).switchMap(function () {
    var state = store.value;
    var account = '';
    var password = '';

    if (state.form && state.form && state.form.login && state.form.login.values) {
      var _state$form$login$val2 = state.form.login.values,
          loginAcctont = _state$form$login$val2.account,
          loginPassword = _state$form$login$val2.password;
      account = _.trim(loginAcctont);
      password = _.trim(loginPassword);
    }

    return Observable.concat(Observable.of(startSubmit('login')), Observable.fromPromise(session.emailLogin({
      account: account,
      password: password
    })).switchMap(function (accessToken) {
      return [reset('login'), stopSubmit('login'), actions.setToken({
        accessToken: accessToken
      }), actions.emailLoginDone(), actions.getUnreadCountCall(), actions.getMeCall({
        isLogin: true
      }), actions.storeTokenCall({
        accessToken: accessToken
      }), actions.updateDeviceInfoCall()];
    }).catch(function (err) {
      return Observable.concat(Observable.of(stopSubmit('login')), Observable.of(actions.emailLoginFail(err)));
    }));
  });
};
export var webEmailLogin = function webEmailLogin(action$, store) {
  return action$.ofType(types.WEB_EMAIL_LOGIN_CALL).switchMap(function (_ref) {
    var _ref$payload = _ref.payload,
        email = _ref$payload.email,
        password = _ref$payload.password;
    return Observable.concat(Observable.fromPromise(session.emailLogin({
      account: email,
      password: password
    })).switchMap(function (accessToken) {
      return [actions.setToken({
        accessToken: accessToken
      }), actions.emailLoginDone(), actions.getUnreadCountCall(), actions.getMeCall({
        isLogin: true
      }), actions.storeTokenCall({
        accessToken: accessToken
      }), actions.updateDeviceInfoCall()];
    }).catch(function (err) {
      return Observable.concat(Observable.of(actions.emailLoginFail(err)));
    }));
  });
};
export var fbTokenLogin = function fbTokenLogin(action$, store) {
  return action$.ofType(types.FB_TOKEN_LOGIN_CALL).switchMap(function (_ref2) {
    var token = _ref2.payload.token;
    return Observable.fromPromise(session.fbLogin(token)).switchMap(function (accessToken) {
      return [actions.setToken({
        accessToken: accessToken
      }), actions.fbLoginDone(), actions.getUnreadCountCall(), actions.getMeCall({
        isLogin: true
      }), actions.storeTokenCall({
        accessToken: accessToken
      }), actions.updateDeviceInfoCall()];
    }).catch(function (err) {
      return Observable.of(actions.fbLoginFail(err));
    });
  });
};
export var fbLogin = function fbLogin(action$, store) {
  return action$.ofType(types.FB_LOGIN_CALL).switchMap(function () {
    var showLoading = isIos ? actions.showLoading() : actions.empty();
    return Observable.concat(Observable.of(showLoading), Observable.fromPromise(session.getFBTokenAsync()).switchMap(function (token) {
      return Observable.fromPromise(session.fbLogin(token)).switchMap(function (accessToken) {
        return [actions.setToken({
          accessToken: accessToken
        }), actions.fbLoginDone(), actions.getUnreadCountCall(), actions.getMeCall({
          isLogin: true
        }), actions.storeTokenCall({
          accessToken: accessToken
        }), actions.updateDeviceInfoCall()];
      });
    }).catch(function (err) {
      return Observable.of(actions.fbLoginFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var wechatLoginCheckCall = function wechatLoginCheckCall(action$, store) {
  return action$.ofType(types.WECHAT_LOGIN_CHECK_CALL).switchMap(function (_ref3) {
    var mobileRequired = _ref3.payload.mobileRequired;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(session.getWechatCodeAsync()).switchMap(function (res) {
      var token = res.token;
      var newActions = [actions.wechatLoginCheckDone(res)];

      if (token) {
        newActions.push(actions.wechatLoginCall({
          code: token,
          mobileRequired: mobileRequired
        }));
      } else {
        newActions.push(actions.hideLoading());
        newActions.push(actions.wechatLoginCheckFail('notToken'));
      }

      return newActions;
    }).catch(function (err) {
      return Observable.concat(Observable.of(actions.wechatLoginCheckFail(err)), Observable.of(actions.hideLoading()));
    }));
  });
};
export var wechatLogin = function wechatLogin(action$, store) {
  return action$.ofType(types.WECHAT_LOGIN_CALL).switchMap(function (_ref4) {
    var payload = _ref4.payload;
    return Observable.concat(Observable.fromPromise(session.wechatLogin(payload)).switchMap(function (res) {
      var accessToken = res.access_token,
          message = res.message,
          identity_id = res.identity_id;

      if (message === 'mobile_not_bind') {
        var params = {
          isPhoneType: true,
          isPhonePwdPage: false,
          isBindType: true,
          bindType: 'weChat',
          identityId: identity_id
        };
        return [actions.openPasswordLoginPage(params)];
      } else {
        return [actions.setToken({
          accessToken: accessToken
        }), actions.wechatLoginDone(), actions.getUnreadCountCall(), actions.getMeCall({
          isLogin: true
        }), actions.storeTokenCall({
          accessToken: accessToken
        }), actions.updateDeviceInfoCall()];
      }
    }).catch(function (err) {
      return Observable.of(actions.wechatLoginFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var bindWechatIdCall = function bindWechatIdCall(action$, store) {
  return action$.ofType(types.BIND_WECHAT_ID_CALL).switchMap(function (_ref5) {
    var payload = _ref5.payload;
    return Observable.concat(Observable.fromPromise(session.bindWechatId(payload)).map(function (res) {
      return actions.bindWechatIdDone(res);
    }).catch(function (err) {
      return Observable.of(actions.bindWechatIdFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var bindAppleIdCall = function bindAppleIdCall(action$, store) {
  return action$.ofType(types.BIND_APPLE_ID_CALL).switchMap(function (_ref6) {
    var payload = _ref6.payload;
    return Observable.concat(Observable.fromPromise(session.bindAppleId(payload)).map(function (res) {
      return actions.bindAppleIdDone(res);
    }).catch(function (err) {
      return Observable.of(actions.bindAppleIdFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var appleLoginCall = function appleLoginCall(action$, store) {
  return action$.ofType(types.APPLE_LOGIN_CALL).switchMap(function (_ref7) {
    var mobileRequired = _ref7.payload.mobileRequired;
    return Observable.fromPromise(session.getAppleTokenAsync()).switchMap(function (appleToken) {
      var fullName = appleToken.fullName,
          authorizationCode = appleToken.authorizationCode;
      var param = {
        mobileRequired: mobileRequired,
        code: authorizationCode,
        client_info: fullName
      };
      return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(session.appleLogin(param)).switchMap(function (res) {
        var accessToken = res.access_token,
            message = res.message,
            identity_id = res.identity_id;

        if (message === 'mobile_not_bind') {
          var params = {
            isPhoneType: true,
            isPhonePwdPage: false,
            isBindType: true,
            bindType: 'apple',
            identityId: identity_id
          };
          return [actions.appleLoginDone(appleToken), actions.openPasswordLoginPage(params)];
        } else {
          return [actions.setToken({
            accessToken: accessToken
          }), actions.appleLoginDone(appleToken), actions.getUnreadCountCall(), actions.getMeCall({
            isLogin: true
          }), actions.storeTokenCall({
            accessToken: accessToken
          }), actions.updateDeviceInfoCall()];
        }
      }), Observable.of(actions.hideLoading()));
    }).catch(function (err) {
      return Observable.of(actions.appleLoginFail(err));
    });
  });
};
export var storeToken = function storeToken(action$, store) {
  return action$.ofType(types.STORE_TOKEN_CALL).switchMap(function (_ref8) {
    var accessToken = _ref8.payload.accessToken;
    return Observable.fromPromise(storage.setItemAsync('accessToken', accessToken)).map(function () {
      return actions.storeTokenDone();
    }).catch(function () {
      return Observable.of(actions.storeTokenFail());
    });
  });
};
export var logout = function logout(action$, store) {
  return action$.ofType(types.LOGOUT_CALL).switchMap(function () {
    var _device$getInfo = device.getInfo(),
        uuid = _device$getInfo.uuid,
        appPackageName = _device$getInfo.appPackageName;

    return Observable.concat(Observable.fromPromise(storage.removeItemAsync('accessToken')).map(function () {
      return actions.logoutDone();
    }).catch(function () {
      return Observable.of(actions.logoutFail());
    }), Observable.fromPromise(session.logout({
      uuid: uuid,
      appPackageName: appPackageName
    })).map(function () {
      return actions.empty();
    }).catch(function () {
      return Observable.of(actions.logoutFail());
    }));
  });
};
export var webLogout = function webLogout(action$, store) {
  return action$.ofType(types.WEB_LOGOUT_CALL).switchMap(function () {
    return Observable.fromPromise(storage.removeItemAsync('accessToken')).map(function () {
      return actions.logoutDone();
    }).catch(function () {
      return Observable.of(actions.logoutFail());
    });
  });
};
export var register = function register(action$, store) {
  return action$.ofType(types.REGISTER_CALL).switchMap(function () {
    var state = store.value;
    var account = '';
    var password = '';
    var name = '';

    if (state.form && state.form && state.form.login && state.form.login.values) {
      var _state$form$login$val3 = state.form.login.values,
          registerAcctont = _state$form$login$val3.account,
          registerPassword = _state$form$login$val3.password,
          registerName = _state$form$login$val3.name;
      account = _.trim(registerAcctont);
      password = _.trim(registerPassword);
      name = _.trim(registerName);
    }

    return Observable.concat(Observable.of(startSubmit('login')), Observable.fromPromise(session.register({
      account: account,
      password: password,
      name: name
    })).switchMap(function (res) {
      return [reset('login'), stopSubmit('login'), actions.registerDone(), actions.changePageType()];
    }).catch(function (err) {
      return Observable.concat(Observable.of(actions.registerFail(err)), Observable.of(stopSubmit('login')));
    }));
  });
};
export var resetPassword = function resetPassword(action$, store) {
  return action$.ofType(types.RESET_PASSWORD_CALL).switchMap(function () {
    var inputValue = store.value.form.forgetPassword.values.inputValue;
    return Observable.concat(Observable.of(startSubmit('forgetPassword')), Observable.fromPromise(session.resetPassword({
      inputValue: inputValue
    })).switchMap(function (res) {
      return [reset('forgetPassword'), stopSubmit('forgetPassword'), actions.resetPasswordDone()];
    }).catch(function (err) {
      return Observable.concat(Observable.of(actions.resetPasswordFail(err)), Observable.of(stopSubmit('forgetPassword')));
    }));
  });
};
export var shopLogin = function shopLogin(action$, store) {
  return action$.ofType(types.SHOP_LOGIN_CALL).switchMap(function (_ref9) {
    var payload = _ref9.payload;
    return Observable.fromPromise(session.shopLogin(payload)).map(function (res) {
      return actions.shopLoginDone(res);
    }).catch(function () {
      return Observable.of(actions.shopLoginFail());
    });
  });
};
export var setMobilePasswordCall = function setMobilePasswordCall(action$, store) {
  return action$.ofType(types.SET_MOBILE_PASSWOED_CALL).switchMap(function (_ref10) {
    var _ref10$payload = _ref10.payload,
        data = _ref10$payload.data,
        isOpenTargetBasicPage = _ref10$payload.isOpenTargetBasicPage,
        isChild = _ref10$payload.isChild;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(session.setMobilePassword(data)).switchMap(function (res) {
      return [actions.setMobilePasswordDone(res), isOpenTargetBasicPage ? actions.openTargetBasicPage({
        fromIntro: true,
        isChild: isChild
      }) : actions.openHomeTab()];
    }).catch(function (err) {
      return Observable.of(actions.setMobilePasswordFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var mobileSmsLogin = function mobileSmsLogin(action$, store) {
  return action$.ofType(types.MOBILE_SMS_LOGIN_CALL).switchMap(function (_ref11) {
    var payload = _ref11.payload;
    var mobile = payload.mobile,
        code = payload.code,
        isBindType = payload.isBindType,
        bindType = payload.bindType,
        identityId = payload.identityId;
    return Observable.concat(Observable.of(startSubmit('login')), Observable.fromPromise(session.mobileSmsLogin({
      mobile: mobile,
      code: code
    })).switchMap(function (res) {
      var accessToken = res.access_token;
      var newActions = [reset('login'), stopSubmit('login'), actions.setToken({
        accessToken: accessToken
      }), actions.mobileSmsLoginDone(res), actions.getUnreadCountCall(), actions.getMeCall({
        isLogin: true
      }), actions.storeTokenCall({
        accessToken: accessToken
      }), actions.updateDeviceInfoCall()];

      if (isBindType) {
        switch (bindType) {
          case 'weChat':
            newActions.push(actions.bindWechatIdCall({
              identityId: identityId
            }));
            break;

          case 'apple':
            newActions.push(actions.bindAppleIdCall({
              identityId: identityId
            }));
            break;

          default:
            break;
        }
      }

      return newActions;
    }).catch(function (err) {
      return Observable.concat(Observable.of(actions.showI18nMessageCall({
        message: 'login:phone.codeError',
        type: 'danger',
        icon: 'warning'
      })), Observable.of(stopSubmit('login')), Observable.of(actions.mobileSmsLoginFail(err)));
    }));
  });
};
export var mobileLogin = function mobileLogin(action$, store) {
  return action$.ofType(types.MOBILE_LOGIN_CALL).switchMap(function (_ref12) {
    var payload = _ref12.payload;
    var isBindType = payload.isBindType,
        bindType = payload.bindType,
        identityId = payload.identityId;
    return Observable.concat(Observable.of(startSubmit('login')), Observable.fromPromise(session.mobileLogin(payload)).switchMap(function (_ref13) {
      var accessToken = _ref13.access_token;
      var newActions = [reset('login'), stopSubmit('login'), actions.setToken({
        accessToken: accessToken
      }), actions.mobileLoginDone(), actions.getUnreadCountCall(), actions.getMeCall({
        isLogin: true
      }), actions.storeTokenCall({
        accessToken: accessToken
      }), actions.updateDeviceInfoCall()];

      if (isBindType) {
        switch (bindType) {
          case 'weChat':
            newActions.push(actions.bindWechatIdCall({
              identityId: identityId
            }));
            break;

          case 'apple':
            newActions.push(actions.bindAppleIdCall({
              identityId: identityId
            }));
            break;

          default:
            break;
        }
      }

      return newActions;
    }).catch(function (err) {
      var _err$response = err.response,
          response = _err$response === void 0 ? {} : _err$response;
      var status = response.status;
      var message = '';

      if (status === 400 || status === 404) {
        message = 'login:phone.pwdError';
      } else {
        message = 'message.serverError';
      }

      return Observable.concat(Observable.of(actions.showI18nMessageCall({
        message: message,
        type: 'danger',
        icon: 'warning'
      })), Observable.of(stopSubmit('login')), Observable.of(actions.mobileLoginFail(err)));
    }));
  });
};