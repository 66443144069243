import { createStore, applyMiddleware, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { routerMiddleware, connectRouter} from 'connected-react-router'
import { createEpicMiddleware } from 'redux-observable'
import { createBrowserHistory } from 'history'
import pfReducers from '../appRedux/reducers'
import rootReducer from 'cflib/store/reducers'
import middlewares from 'cflib/store/middlewares'
import rootEpic from 'cflib/store/epics'

export const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const epicMiddleware = createEpicMiddleware();

middlewares.push(routeMiddleware)
const historyReducer = { router: connectRouter(history)}
export default function configureStore(preloadedState) {
  const store = createStore(
    combineReducers({ ...rootReducer, ...historyReducer, ...pfReducers}),
    composeWithDevTools(applyMiddleware(...middlewares, epicMiddleware)),
  )

  epicMiddleware.run(rootEpic);

  if (module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = require('cflib/store/reducers').default
      store.replaceReducer(nextRootReducer)
    })
  }
  return store
}
