import { createAction } from 'redux-actions';
import * as types from '../types';
export var getShopIndexCall = createAction(types.GET_SHOP_INDEX_CALL);
export var getShopIndexDone = createAction(types.GET_SHOP_INDEX_DONE);
export var getShopIndexFail = createAction(types.GET_SHOP_INDEX_FAIL);
export var getWxPayDataCall = createAction(types.GET_WXPAY_DATA_CALL);
export var getWxPayDataDone = createAction(types.GET_WXPAY_DATA_DONE);
export var getWxPayDataFail = createAction(types.GET_WXPAY_DATA_FAIL);
export var getGoodsCartCall = createAction(types.GET_GOODS_CART_CALL);
export var getGoodsCartDone = createAction(types.GET_GOODS_CART_DONE);
export var getGoodsCartFail = createAction(types.GET_GOODS_CART_FAIL);
export var addGoodsCartCall = createAction(types.ADD_GOODS_CART_CALL);
export var addGoodsCartDone = createAction(types.ADD_GOODS_CART_DONE);
export var addGoodsCartFail = createAction(types.ADD_GOODS_CART_FAIL);
export var updateGoodsCartCall = createAction(types.UPDATE_GOODS_CART_CALL);
export var updateGoodsCartDone = createAction(types.UPDATE_GOODS_CART_DONE);
export var updateGoodsCartFail = createAction(types.UPDATE_GOODS_CART_FAIL);
export var removeGoodsCartCall = createAction(types.REMOVE_GOODS_CART_CALL);
export var removeGoodsCartDone = createAction(types.REMOVE_GOODS_CART_DONE);
export var removeGoodsCartFail = createAction(types.REMOVE_GOODS_CART_FAIL);
export var setGoodsCarts = createAction(types.SET_GOODS_CARTS);
export var wxPayCall = createAction(types.WXPAY_CALL);
export var wxPayDone = createAction(types.WXPAY_DONE);
export var wxPayFail = createAction(types.WXPAY_FAIL);
export var setShopTabIndex = createAction(types.SET_SHOP_TAB_INDEX);
export var settlementCall = createAction(types.SETTLEMENT_CALL);
export var settlementDone = createAction(types.SETTLEMENT_DONE);
export var settlementFail = createAction(types.SETTLEMENT_FAIL);
export var addOrderCall = createAction(types.ADD_ORDER_CALL);
export var addOrderDone = createAction(types.ADD_ORDER_DONE);
export var addOrderFail = createAction(types.ADD_ORDER_FAIL);
export var getAddressCall = createAction(types.GET_ADDRESS_CALL);
export var getAddressDone = createAction(types.GET_ADDRESS_DONE);
export var getAddressFail = createAction(types.GET_ADDRESS_FAIL);
export var saveAddressCall = createAction(types.SAVE_ADDRESS_CALL);
export var saveAddressDone = createAction(types.SAVE_ADDRESS_DONE);
export var saveAddressFail = createAction(types.SAVE_ADDRESS_FAIL);
export var getOrderListCall = createAction(types.GET_ORDER_LIST_CALL);
export var getOrderListDone = createAction(types.GET_ORDER_LIST_DONE);
export var getOrderListFail = createAction(types.GET_ORDER_LIST_FAIL);
export var getShipDetailCall = createAction(types.GET_SHIP_DETAIL_CALL);
export var getShipDetailDone = createAction(types.GET_SHIP_DETAIL_DONE);
export var getShipDetailFail = createAction(types.GET_SHIP_DETAIL_FAIL);
export var openShopItemPage = createAction(types.OPEN_SHOP_ITEM_PAGE);
export var openShopTab = createAction(types.OPEN_SHOP_TAB);
export var openShopPayPage = createAction(types.OPEN_SHOP_PAY_PAGE);
export var openOrderEvaluatePage = createAction(types.OPEN_ORDER_EVALUATE_PAGE);
export var openShoppingCartPage = createAction(types.OPEN_SHOPPING_CART_PAGE);
export var openShopOrderTabPage = createAction(types.OPEN_SHOP_ORDER_TAB_PAGE);
export var openShopIntegralPage = createAction(types.OPEN_SHOP_INTEGRAL_PAGE);
export var openGoodsEvaPage = createAction(types.OPEN_GOODS_EVA_PAGE);
export var openOrderInvoicePage = createAction(types.OPEN_ORDER_INVOICE_PAGE);
export var setPayError = createAction(types.SET_PAY_ERROR);
export var getShopSearchCall = createAction(types.GET_SHOP_SEARCH_CALL);
export var getShopSearchDone = createAction(types.GET_SHOP_SEARCH_DONE);
export var getShopSearchFail = createAction(types.GET_SHOP_SEARCH_FAIL);
export var orderConfirmCall = createAction(types.ORDER_CONFIRM_CALL);
export var orderConfirmDone = createAction(types.ORDER_CONFIRM_DONE);
export var orderConfirmFail = createAction(types.ORDER_CONFIRM_FAIL);
export var orderCancelCall = createAction(types.ORDER_CANCEL_CALL);
export var orderCancelDone = createAction(types.ORDER_CANCEL_DONE);
export var orderCancelFail = createAction(types.ORDER_CANCEL_FAIL);
export var orderRefundCall = createAction(types.ORDER_REFUND_CALL);
export var orderRefundDone = createAction(types.ORDER_REFUND_DONE);
export var orderRefundFail = createAction(types.ORDER_REFUND_FAIL);
export var orderStoreRemove = createAction(types.ORDER_STORE_REMOVE);
export var getOrderCountCall = createAction(types.GET_ORDER_COUNT_CALL);
export var getOrderCountDone = createAction(types.GET_ORDER_COUNT_DONE);
export var getOrderCountFail = createAction(types.GET_ORDER_COUNT_FAIL);
export var getSmsCodeCall = createAction(types.GET_SMS_CODE_CALL);
export var getSmsCodeDone = createAction(types.GET_SMS_CODE_DONE);
export var getSmsCodeFail = createAction(types.GET_SMS_CODE_FAIL);
export var getOrderDiscussCall = createAction(types.GET_ORDER_DISCUSS_CALL);
export var getOrderDiscussDone = createAction(types.GET_ORDER_DISCUSS_DONE);
export var getOrderDiscussFail = createAction(types.GET_ORDER_DISCUSS_FAIL);
export var saveOrderDiscussCall = createAction(types.SAVE_ORDER_DISCUSS_CALL);
export var saveOrderDiscussDone = createAction(types.SAVE_ORDER_DISCUSS_DONE);
export var saveOrderDiscussFail = createAction(types.SAVE_ORDER_DISCUSS_FAIL);
export var getGoodsEvaCall = createAction(types.GET_GOODS_EVA_CALL);
export var getGoodsEvaDone = createAction(types.GET_GOODS_EVA_DONE);
export var getGoodsEvaFail = createAction(types.GET_GOODS_EVA_FAIL);
export var getOrderReturnListCall = createAction(types.GET_ORDER_RETURN_LIST_CALL);
export var getOrderReturnListDone = createAction(types.GET_ORDER_RETURN_LIST_DONE);
export var getOrderReturnListFail = createAction(types.GET_ORDER_RETURN_LIST_FAIL);
export var getOrderReturnLogCall = createAction(types.GET_ORDER_RETURN_LOG_CALL);
export var getOrderReturnLogDone = createAction(types.GET_ORDER_RETURN_LOG_DONE);
export var getOrderReturnLogFail = createAction(types.GET_ORDER_RETURN_LOG_FAIL);
export var addOrderReturnCall = createAction(types.ADD_ORDER_RETURN_CALL);
export var addOrderReturnDone = createAction(types.ADD_ORDER_RETURN_DONE);
export var addOrderReturnFail = createAction(types.ADD_ORDER_RETURN_FAIL);
export var cancelOrderReturnCall = createAction(types.CANCEL_ORDER_RETURN_CALL);
export var cancelOrderReturnDone = createAction(types.CANCEL_ORDER_RETURN_DONE);
export var cancelOrderReturnFail = createAction(types.CANCEL_ORDER_RETURN_FAIL);
export var addOrderReturnShipCall = createAction(types.ADD_ORDER_RETURN_SHIP_CALL);
export var addOrderReturnShipDone = createAction(types.ADD_ORDER_RETURN_SHIP_DONE);
export var addOrderReturnShipFail = createAction(types.ADD_ORDER_RETURN_SHIP_FAIL);
export var getOrderReturnShipCall = createAction(types.GET_ORDER_RETURN_SHIP_CALL);
export var getOrderReturnShipDone = createAction(types.GET_ORDER_RETURN_SHIP_DONE);
export var getOrderReturnShipFail = createAction(types.GET_ORDER_RETURN_SHIP_FAIL);
export var getOrderInvoiceCall = createAction(types.GET_ORDER_INVOICE_CALL);
export var getOrderInvoiceDone = createAction(types.GET_ORDER_INVOICE_DONE);
export var getOrderInvoiceFail = createAction(types.GET_ORDER_INVOICE_FAIL);
export var addOrderInvoiceCall = createAction(types.ADD_ORDER_INVOICE_CALL);
export var addOrderInvoiceDone = createAction(types.ADD_ORDER_INVOICE_DONE);
export var addOrderInvoiceFail = createAction(types.ADD_ORDER_INVOICE_FAIL);
export var removeOrderInvoiceCall = createAction(types.REMOVE_ORDER_INVOICE_CALL);
export var removeOrderInvoiceDone = createAction(types.REMOVE_ORDER_INVOICE_DONE);
export var removeOrderInvoiceFail = createAction(types.REMOVE_ORDER_INVOICE_FAIL);
export var getClientInvoiceInfoCall = createAction(types.GET_CLIENT_INVOICE_INFO_CALL);
export var getClientInvoiceInfoDone = createAction(types.GET_CLIENT_INVOICE_INFO_DONE);
export var getClientInvoiceInfoFail = createAction(types.GET_CLIENT_INVOICE_INFO_FAIL);
export var directSellShareCall = createAction(types.DIRECT_SELL_SHARE_CALL);
export var directSellShareDone = createAction(types.DIRECT_SELL_SHARE_DONE);
export var directSellShareFail = createAction(types.DIRECT_SELL_SHARE_FAIL);