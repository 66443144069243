import { createAction } from 'redux-actions';
import * as types from '../../store/types';
export var getActivityItemsCall = createAction(types.GET_ACTIVITY_ITEMS_CALL);
export var getActivityItemGroupsCall = createAction(types.GET_ACTIVITY_ITEM_GROUPS_CALL);
export var getActivityItemGroupsDone = createAction(types.GET_ACTIVITY_ITEM_GROUPS_DONE);
export var getActivityItemGroupsFail = createAction(types.GET_ACTIVITY_ITEM_GROUPS_FAIL);
export var getRoutinesCall = createAction(types.GET_ROUTINES_CALL);
export var getRoutinesDone = createAction(types.GET_ROUTINES_DONE);
export var getRoutinesFail = createAction(types.GET_ROUTINES_FAIL);
export var getMyActivityItemsCall = createAction(types.GET_MY_ACTIVITY_ITEMS_CALL);
export var getMyActivityItemsDone = createAction(types.GET_MY_ACTIVITY_ITEMS_DONE);
export var getMyActivityItemsFail = createAction(types.GET_MY_ACTIVITY_ITEMS_FAIL);
export var addMyActivityItemCall = createAction(types.ADD_MY_ACTIVITY_ITEM_CALL);
export var addMyActivityItemDone = createAction(types.ADD_MY_ACTIVITY_ITEM_DONE);
export var addMyActivityItemFail = createAction(types.ADD_MY_ACTIVITY_ITEM_FAIL);
export var deleteMyActivityItemCall = createAction(types.DELETE_MY_ACTIVITY_ITEM_CALL);
export var deleteMyActivityItemDone = createAction(types.DELETE_MY_ACTIVITY_ITEM_DONE);
export var deleteMyActivityItemFail = createAction(types.DELETE_MY_ACTIVITY_ITEM_FAIL);