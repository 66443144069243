export var OPEN_PAGE = 'OPEN_PAGE';
export var PAGE_GO_BACK = 'PAGE_GO_BACK';
export var PAGE_GO_DIARY = 'PAGE_GO_DIARY';
export var PAGE_GO_NOTE_PREV = 'PAGE_GO_NOTE_PREV';
export var OPEN_INTRO_PAGE = 'OPEN_INTRO_PAGE';
export var OPEN_IMAGE_PICKER_MODAL = 'OPEN_IMAGE_PICKER_MODAL';
export var CLOSE_IMAGE_PICKER_MODAL = 'CLOSE_IMAGE_PICKER_MODAL';
export var OPEN_ADD_FOODSTUFFS_CONFIRM_MODAL = 'OPEN_ADD_FOODSTUFFS_CONFIRM_MODAL';
export var CLOSE_ADD_FOODSTUFFS_CONFIRM_MODAL = 'CLOSE_ADD_FOODSTUFFS_CONFIRM_MODAL';
export var OPEN_ADD_DB_FOODSTUFF_ITEM_PAGE = 'OPEN_ADD_DB_FOODSTUFF_ITEM_PAGE';
export var OPEN_NOTE_EDITOR_PAGE = 'OPEN_NOTE_EDITOR_PAGE';
export var OPEN_PRO_NOTE_EDITOR_PAGE = 'OPEN_PRO_NOTE_EDITOR_PAGE';
export var OPEN_PRO_NOTE_EDIT_CONFIRM_PAGE = 'OPEN_PRO_NOTE_EDIT_CONFIRM_PAGE';
export var OPEN_FOOD_NOTE_EDITOR_PAGE = 'OPEN_FOOD_NOTE_EDITOR_PAGE';
export var OPEN_NOTE_EDIT_CONFIRM_PAGE = 'OPEN_NOTE_EDIT_CONFIRM_PAGE';
export var OPEN_ADD_DB_FOODSTUFFS_PAGE = 'OPEN_ADD_DB_FOODSTUFFS_PAGE';
export var OPEN_ADD_SIXGROUP_PAGE = 'OPEN_ADD_SIXGROUP_PAGE';
export var OPEN_ADD_CALORIE_PAGE = 'OPEN_ADD_CALORIE_PAGE';
export var OPEN_ADD_MOVE_ITEMS_PAGE = 'OPEN_ADD_MOVE_ITEMS_PAGE';
export var COMPLETE_FOODSTUFFS_PAGES = 'COMPLETE_FOODSTUFFS_PAGES';
export var COMPLETE_ACTIVITY_ITEMS_PAGES = 'COMPLETE_ACTIVITY_ITEMS_PAGES';
export var OPEN_TARGET_PAGE = 'OPEN_TARGET_PAGE';
export var OPEN_TARGET_HELLO_PAGE = 'OPEN_TARGET_HELLO_PAGE';
export var OPEN_TARGET_BASIC_PAGE = 'OPEN_TARGET_BASIC_PAGE';
export var OPEN_CAMERA_SEND_PAGE = 'OPEN_CAMERA_SEND_PAGE';
export var CLOSE_CAMERA_SEND_PAGE = 'CLOSE_CAMERA_SEND_PAGE';
export var OPEN_SET_LANGUAGE_PAGE = 'OPEN_SET_LANGUAGE_PAGE';
export var OPEN_COACH_INFO_PAGE = 'open_coach_info_page';
export var OPEN_DATA_CHART_PAGE = 'OPEN_DATA_CHART_PAGE';
export var OPEN_COACH_TAB = 'OPEN_COACH_TAB';
export var OPEN_PROFILE_TAB = 'OPEN_PROFILE_TAB';
export var OPEN_CLIENT_TAB = 'OPEN_CLIENT_TAB';
export var OPEN_HOME_TAB = 'OPEN_HOME_TAB';
export var OPEN_SET_PASSWORD_PAGE = 'OPEN_SET_PASSWORD_PAGE';
export var OPEN_EXPLORE_TAB = 'OPEN_EXPLORE_TAB';
export var OPEN_ESSAYS_LIST_PAGE = 'OPEN_ESSAYS_LIST_PAGE';
export var OPEN_ESSAYS_TAG_LIST_PAGE = 'OPEN_ESSAYS_TAG_LIST_PAGE';
export var OPEN_SIXGROUP_DETAIL_PAGE = 'OPEN_SIXGROUP_DETAIL_PAGE';
export var OPEN_WEB_VIEW_PAGE = 'OPEN_WEB_VIEW_PAGE';
export var OPEN_POST_PAGE = 'OPEN_POST_PAGE';
export var OPEN_SOCIAL_PROFILE_PAGE = 'OPEN_SOCIAL_PROFILE_PAGE';
export var OPEN_SOCIAL_TAG_PAGE = 'OPEN_SOCIAL_TAG_PAGE';
export var OPEN_SOCIAL_SEARCH_PAGE = 'OPEN_SOCIAL_SEARCH_PAGE';
export var OPEN_RANK_PAGE = 'OPEN_RANK_PAGE';
export var OPEN_LOCATION_PAGE = 'OPEN_LOCATION_PAGE';
export var OPEN_BLOCK_LIST_PAGE = 'OPEN_BLOCK_LIST_PAGE';
export var OPEN_SOCIA_PAGE_CALL = 'OPEN_SOCIA_PAGE_CALL';
export var OPEN_SOCIA_PAGE_DONE = 'OPEN_SOCIA_PAGE_DONE';
export var OPEN_SOCIA_PAGE_FAIL = 'OPEN_SOCIA_PAGE_FAIL';
export var OPEN_SOCIAL_GROUP_PAGE_CALL = 'OPEN_SOCIAL_GROUP_PAGE_CALL';
export var OPEN_SOCIAL_GROUP_PAGE_DONE = 'OPEN_SOCIAL_GROUP_PAGE_DONE';
export var OPEN_SOCIAL_GROUP_PAGE_FAIL = 'OPEN_SOCIAL_GROUP_PAGE_FAIL';
export var OPEN_GROUP_TAG_PAGE_CALL = 'OPEN_GROUP_TAG_PAGE_CALL';
export var OPEN_GROUP_TAG_PAGE_DONE = 'OPEN_GROUP_TAG_PAGE_DONE';
export var OPEN_GROUP_TAG_PAGE_FAIL = 'OPEN_GROUP_TAG_PAGE_FAIL';
export var OPEN_PRICING_PAGE = 'OPEN_PRICING_PAGE';
export var OPEN_ADD_DATA_LIST_PAGE = 'OPEN_ADD_DATA_LIST_PAGE';
export var OPEN_PASSWORD_LOGIN_PAGE = 'OPEN_PASSWORD_LOGIN_PAGE';
export var OPEN_REGISTRATION_CODE_PAGE = 'OPEN_REGISTRATION_CODE_PAGE';