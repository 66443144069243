import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _ from 'lodash';
import * as types from '../../../store/types';
import { handleActions } from 'redux-actions';
export default handleActions(_defineProperty({}, types.GET_NOTE_TAGS_DONE, function (state, _ref) {
  var data = _ref.payload.data;
  return _objectSpread({}, state, {
    tags: data
  });
}), {
  tags: []
});