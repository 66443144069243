import _slicedToArray from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _toConsumableArray from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';
import { NutritionConstant, NoteConstant } from '../../utils';
import { note as NoteService } from '../../services';
import lib from '../../platform';
var pfConfig = lib.pfConfig,
    i18n = lib.pfI18n;
var isProType = pfConfig.isProType;
var MEALS = NoteConstant.getNoteOptions();

var getDate = function getDate(state) {
  return state.date;
};

var getAssetToOpen = function getAssetToOpen(state) {
  return state.assetToOpen;
};

var getMealOrder = function getMealOrder(state) {
  return state.mealOrder;
};

var getNoteIdToOpen = function getNoteIdToOpen(state) {
  return state.noteIdToOpen;
};

var getTargets = function getTargets(state) {
  return state.targets;
};

var getNotes = function getNotes(state) {
  return state.notes;
};

var getProfiles = function getProfiles(state) {
  return state.profiles;
};

var getClientId = function getClientId(state) {
  return state.clientId;
};

var getClients = function getClients(state) {
  return state.clients;
};

var getEventDates = function getEventDates(state) {
  return state.eventDates;
};

var getFoodRecords = function getFoodRecords(state) {
  return state.foodRecords;
};

var getActiviityDiarys = function getActiviityDiarys(state) {
  return state.activity;
};

var getUserId = function getUserId(state) {
  return state.userId;
};

var getUsers = function getUsers(state) {
  return state.users;
};

export var filterAerobic = function filterAerobic(items) {
  return _.filter(items, function (_ref) {
    var _ref$item = _ref.item,
        item = _ref$item === void 0 ? {} : _ref$item,
        _ref$items = _ref.items,
        items = _ref$items === void 0 ? [] : _ref$items;

    var checkAerobicItems = _.find(items, function (_ref2) {
      var item = _ref2.item;

      if (item) {
        var _category = item.category,
            _category_name = item.category_name;

        var _categoryIsObject = _.isObject(_category_name);

        return _category === 'measurement' || _category === 'workout' || _categoryIsObject;
      } else {
        return true;
      }
    });

    var category = item.category,
        category_name = item.category_name;

    var categoryIsObject = _.isObject(category_name);

    return category !== 'measurement' && category !== 'workout' && !checkAerobicItems && !categoryIsObject;
  });
};
export var getNoteMeal = function getNoteMeal(note) {
  var _note = note,
      id = _note.id,
      order = _note.meal_order,
      type = _note.type;
  var _note2 = note,
      _note2$calorie = _note2.calorie,
      calorie = _note2$calorie === void 0 ? 0 : _note2$calorie,
      _note2$items = _note2.items,
      items = _note2$items === void 0 ? [] : _note2$items,
      _note2$assets = _note2.assets,
      assets = _note2$assets === void 0 ? [] : _note2$assets,
      _note2$move_sessions = _note2.move_sessions,
      move_sessions = _note2$move_sessions === void 0 ? [] : _note2$move_sessions;
  var noteAssets = assets;

  var newAssets = _.orderBy(noteAssets, ['id'], ['asc']);

  var showNutritionMode = _.includes(['MoveNote', 'FoodNote'], type);

  var showActivityMode = _.isEqual('MoveNote', type);

  var showUrlImageOnlyMode = _.includes(['MoveNote', 'LifeNote', 'WaterNote', 'DiagNote'], type);

  var showWater = _.isEqual('WaterNote', type);

  var mealSummary = _.fromPairs(_.map(NutritionConstant.C63, function (attr) {
    return [attr, _.sumBy(newAssets, function (a) {
      return _.toNumber(a[attr] || 0);
    }) || 0];
  }));

  if (showWater && items.length !== 0) {
    var _items$0$water_intake = items[0].water_intake,
        water_intake = _items$0$water_intake === void 0 ? 0 : _items$0$water_intake;
    mealSummary.waterIntake = water_intake;
  }

  if (showActivityMode) {
    var aerobicList = filterAerobic(items);

    var aerobicItems = _.flattenDeep(_.values(_.groupBy(aerobicList, 'category_name')));

    var hasAerobic = !_.isEmpty(aerobicItems);

    var aerobicBurned = _.reduce(aerobicItems, function (sum, n) {
      return sum + (n.calorie || 0);
    }, 0);

    var workoutList = _.filter(items, {
      item: {
        category: 'workout'
      }
    });

    var workoutItems = _.flattenDeep(_.values(_.groupBy(workoutList, 'sub_category_name')));

    var hasWorkout = !_.isEmpty(workoutItems);
    var hasSession = !_.isEmpty(move_sessions);

    var measureList = _.filter(items, {
      item: {
        category: 'measurement'
      }
    });

    var measureItems = _.flattenDeep(_.values(_.groupBy(measureList, 'category_name')));

    var sessionBurned = _.reduce(move_sessions, function (sum, n) {
      return sum + (n.measure_calorie || 0);
    }, 0);

    var newItems = _.concat(items, move_sessions);

    var newCalorie = sessionBurned + aerobicBurned;
    note = _.assign(note, {
      aerobicItems: aerobicItems,
      workoutItems: workoutItems,
      measureItems: measureItems,
      sessionBurned: sessionBurned,
      aerobicBurned: aerobicBurned,
      hasAerobic: hasAerobic,
      hasWorkout: hasWorkout,
      hasSession: hasSession,
      calorie: calorie,
      items: newItems
    });
    mealSummary.calorie = newCalorie;
  }

  return _objectSpread({
    noteId: id,
    order: order,
    type: type,
    note: note,
    assets: newAssets,
    items: items,
    empty: false,
    // always show empty note
    commentable: true,
    showWater: showWater,
    showNutritionMode: showNutritionMode,
    showActivityMode: showActivityMode,
    showUrlImageOnlyMode: showUrlImageOnlyMode,
    editable: true
  }, mealSummary, NoteService.cal3P(mealSummary));
};

var getFoodRecordMeals = function getFoodRecordMeals(foodRecord) {
  return _.map(MEALS, function (_ref3) {
    var order = _ref3.order,
        isDefault = _ref3.isDefault;

    // const note = _.find(notes, { date: dateString, meal_order: order })
    // const noteAssets = note ? note.assets : []
    var foodRecordAssets = _.filter(foodRecord && foodRecord.assets, {
      meal_order: order
    });

    var assets = _.orderBy(foodRecordAssets, ['id'], ['asc']);

    var empty = assets.length === 0;

    var mealSummary = _.fromPairs(_.map(NutritionConstant.C63, function (attr) {
      return [attr, _.sumBy(assets, function (a) {
        return _.toNumber(a[attr] || 0);
      }) || 0];
    }));

    return _objectSpread({
      noteId: undefined,
      commentable: false,
      showNutritionMode: true,
      showActivityMode: false,
      showUrlImageOnlyMode: false,
      editable: false,
      order: order,
      note: {
        taken_at: empty ? '' : moment(_.first(assets)._time).format('HH:mm')
      },
      assets: assets,
      empty: empty,
      isDefault: isDefault
    }, mealSummary, NoteService.cal3P(mealSummary));
  });
};

var getActivityMeals = function getActivityMeals(_ref4) {
  var calorie = _ref4.calorie,
      records = _ref4.records;
  var items = records;
  var taken_at = items ? moment(_.first(items).created_at).format('HH:mm') : '';
  return items ? [{
    noteId: 'activityDIary',
    commentable: false,
    showNutritionMode: true,
    showActivityMode: true,
    showUrlImageOnlyMode: true,
    editable: false,
    note: {
      taken_at: taken_at,
      aerobicItems: items,
      aerobicBurned: calorie,
      hasAerobic: true
    },
    assets: [],
    items: items,
    empty: false,
    calorie: calorie
  }] : [];
};

export var getMeals = createSelector([getClientId, getDate, getNotes, getFoodRecords, getActiviityDiarys], function (clientId, date, notes, foodRecords, activityDiarys) {
  var dateString = date.format('YYYY-MM-DD');

  var foodRecord = _.find(foodRecords, {
    client_id: clientId,
    date: dateString
  });

  var foodRecordMeals = getFoodRecordMeals(foodRecord);
  var activityDiary = getActivityMeals(activityDiarys);

  var noteMeals = _.chain(notes).filter({
    client_id: clientId,
    date: dateString
  }).orderBy('taken_at', 'desc').map(getNoteMeal).value();

  return _.filter([].concat(_toConsumableArray(foodRecordMeals), _toConsumableArray(noteMeals), _toConsumableArray(activityDiary)), function (_ref5) {
    var empty = _ref5.empty;
    return !empty;
  });
});
export var getMeal = createSelector([getMeals, getNotes, getMealOrder, getNoteIdToOpen], function (meals, notes, order, noteId) {
  // from diary
  var mealByOrderOrNoteId = _.find(meals, _.omitBy({
    order: order,
    noteId: noteId
  }, _.isUndefined)); // from notification


  var noteById = _.find(notes, {
    id: noteId
  });

  return mealByOrderOrNoteId || (noteById ? getNoteMeal(noteById) : null);
});
export var getDiary = createSelector([getClientId, getDate, getNotes, getFoodRecords, getActiviityDiarys], function (clientId, date, notes, foodRecords, activityDiarys) {
  var dateString = date.format('YYYY-MM-DD');

  var foodNotesOfDate = _.filter(notes, {
    client_id: clientId,
    date: dateString,
    type: 'FoodNote'
  });

  var foodRecordsOfDate = _.filter(foodRecords, {
    client_id: clientId,
    date: dateString
  });

  var waterNotesOfDate = _.filter(notes, {
    client_id: clientId,
    date: dateString,
    type: 'WaterNote'
  });

  var waterItems = _.map(waterNotesOfDate, function (_ref6) {
    var items = _ref6.items;
    return items[0] || {};
  });

  var filterNotes = [].concat(_toConsumableArray(foodNotesOfDate), _toConsumableArray(foodRecordsOfDate), _toConsumableArray(waterItems));

  var diary = _.fromPairs(_.map(NutritionConstant.C63, function (attr) {
    return [attr, _.sumBy(filterNotes, function (item) {
      return Number(item[attr]) || 0;
    })];
  }));

  var burned = 0;
  burned = _.reduce(_.filter(notes, {
    type: 'MoveNote',
    date: dateString
  }), function (sum, n) {
    return sum + (n.calorie || 0);
  }, 0);
  if (!_.isEmpty(activityDiarys)) burned += activityDiarys.calorie;
  return _objectSpread({}, diary, NoteService.cal3P(diary), {
    burned: burned
  });
});
export var getClient = createSelector([getClientId, getClients], function (clientId, clients) {
  var client = _.find(clients, {
    id: clientId
  }) || {
    body_attrs: {}
  };
  var isCareReceiver = client.isCareReceiver,
      birthday = client.birthday,
      care_receivers = client.care_receivers,
      group_class_orders = client.group_class_orders,
      care_givers = client.care_givers;
  var age = moment().diff(birthday, 'years');
  var isChild = age <= 18 && isCareReceiver;
  var today = moment(new Date());

  var isAllFinished = _.every(group_class_orders, function (_ref7) {
    var finished_at = _ref7.finished_at;
    return moment(finished_at) < today;
  });

  var isFreeUser = !_.isArray(group_class_orders) || group_class_orders.length === 0 || isAllFinished;
  if (care_givers && care_givers.length !== 0) client.chatroom_id = care_givers[0].chatroom_id;
  return _objectSpread({}, client, {
    isFreeUser: isFreeUser,
    isChild: isChild,
    isCaregiver: _.isArray(care_receivers) && care_receivers.length !== 0
  });
});
export var getClientTargets = createSelector([getClientId, getTargets], function (clientId, targets) {
  return _.filter(targets, {
    client_id: clientId
  });
});
export var getUser = createSelector([getUserId, getUsers], function (userId, users) {
  return _.find(users, {
    id: userId
  }) || {};
});
export var getClientTarget = createSelector([getDate, getClientTargets], function (date, targets) {
  var dateString = date.format('YYYY-MM-DD');

  var _$filter = _.filter(targets, function (_ref8) {
    var beginDate = _ref8.begin_date,
        endDate = _ref8.end_date;
    return beginDate <= dateString && dateString <= endDate;
  }),
      _$filter2 = _slicedToArray(_$filter, 1),
      target = _$filter2[0];

  var defaultTarget = {
    calorie: 1500,
    fruits: 2,
    vegetables: 3,
    grains: 10,
    oil: 3,
    milk_low_fat: 2,
    meat_beans_low_fat: 4,
    meat_beans_medium_fat: 3,
    start_weight: 0,
    target_weight: 0,
    kg: 1,
    isDefault: true
  };
  return target || defaultTarget;
});
export var getAssetAndParent = createSelector([getAssetToOpen, getNotes, getFoodRecords], function (asset, notes, foodRecords) {
  var id = asset.id,
      _type = asset._type,
      _parentType = asset._parentType,
      _parentId = asset._parentId,
      _parentDate = asset._parentDate;
  var parentCollection = _parentType === 'note' ? notes : foodRecords;
  var query = _parentId ? {
    id: _parentId
  } : {
    date: _parentDate
  };

  var parent = _.find(parentCollection, query);

  var selectedAsset = parent && _.find(parent.assets, {
    id: id,
    _type: _type
  });

  if (selectedAsset) {
    selectedAsset.editable = _parentType === 'note';
  }

  return {
    asset: selectedAsset,
    parent: parent
  };
});
export var getClientEventDates = createSelector([getClientId, getEventDates], function (clientId, eventDates) {
  return _.find(eventDates, {
    client_id: clientId
  }) || {};
});
export var getClientProfile = createSelector([getClientId, getProfiles], function (clientId, profiles) {
  return _.find(profiles, {
    client_id: clientId
  }) || {
    bodyData: {
      month: undefined,
      year: undefined
    },
    nutritionData: {
      month: undefined,
      year: undefined
    },
    moveData: {
      month: undefined,
      year: undefined
    },
    moreData: undefined
  };
});
export var getFoodStuffItems = function getFoodStuffItems(items) {
  return _.reject(_.filter(items, {
    item_type: 'FoodStuff'
  }), '_destroy');
};
export var getSixGroupItems = function getSixGroupItems(items) {
  return _.reject(_.filter(items, {
    item_type: 'QuickAdd',
    quick_add_type: 'six_group'
  }), '_destroy');
};
export var getCalorieItems = function getCalorieItems(items) {
  return _.reject(_.filter(items, {
    item_type: 'QuickAdd',
    quick_add_type: 'calorie'
  }), '_destroy');
};
export var getNonDestroyed = function getNonDestroyed(arr) {
  return _.reject(arr, '_destroy');
};
export var getMember = function getMember(state) {
  var _state$member = state.member,
      type = _state$member.type,
      id = _state$member.id;
  if (!type || !id) return state.member;
  return _.find(state[type]["".concat(type, "s")], {
    id: id
  }) || state.member;
};
export var getSelectMember = function getSelectMember(state) {
  var user = state.user,
      client = state.client;
  var member = isProType ? getUser(user) : getClient(client);
  return member;
};
export var getWorkoutItems = function getWorkoutItems(_ref9) {
  var setList = _ref9.setList,
      item_id = _ref9.item_id,
      item_name = _ref9.item_name;

  var items = _.map(setList, function (setItem) {
    return _.merge({
      item_id: item_id,
      item_name: item_name,
      added_at: moment().format('YYYY-MM-DD HH:mm:ss')
    }, setItem);
  });

  return items;
};
export var getSelectedPost = function getSelectedPost(_ref10) {
  var draft = _ref10.draft,
      id = _ref10.id,
      selectType = _ref10.selectType,
      userId = _ref10.userId,
      userType = _ref10.userType,
      tagId = _ref10.tagId;
  var tabKey = draft.tabKey;
  var selectPosts = [];
  var collection = {};
  var matcher = {};

  switch (selectType) {
    case 'profilePosts':
      collection = draft.socialProfile.profilesPosts;
      matcher = {
        id: userId,
        userType: userType
      };
      selectPosts = (_.find(collection, matcher) || {
        posts: []
      }).posts;
      break;

    case 'tagPosts':
      collection = draft.tagPosts;
      matcher = {
        tagId: tagId
      };
      selectPosts = (_.find(collection, matcher) || {
        posts: []
      }).posts;
      break;

    case 'groupPosts':
      collection = draft.groupPosts;
      matcher = {
        selectGroupId: draft.selectGroupId
      };
      selectPosts = (_.find(collection, matcher) || {
        posts: []
      }).posts;
      break;

    case 'tabPosts':
      collection = draft.tabPosts;
      selectPosts = (draft.tabPosts[tabKey] || {
        posts: []
      }).posts;
      break;

    default:
      selectPosts = draft[selectType];
      break;
  }

  return _.find(selectPosts, {
    id: id
  }) || undefined;
};
export var giftInfoFormat = function giftInfoFormat(_ref11) {
  var item = _ref11.item,
      imageWebServer = _ref11.imageWebServer,
      currencyCode = _ref11.currencyCode;
  var _item$goods_main_phot = item.goods_main_photo,
      goods_main_photo = _item$goods_main_phot === void 0 ? '' : _item$goods_main_phot,
      _item$goods_price = item.goods_price,
      goods_price = _item$goods_price === void 0 ? 0 : _item$goods_price;
  var uri = "".concat(imageWebServer, "/").concat(goods_main_photo);
  var mainPhoto = {
    uri: uri
  };
  var priceText = "".concat(currencyCode, " ").concat(goods_price);
  return _objectSpread({}, item, {
    mainPhoto: mainPhoto,
    priceText: priceText,
    isGiftsGoods: true
  });
};
/* 
  activity_status:  活动状态，0为无活动，1为待审核，2为审核通过，3为活动已经过期活结束，审核未通过时状态为0
  combin_status:  组合销售商品，0为无组合销售，1为有组合销售，当该商品参加组合销售时（无论组合商品是否通过审核），该状态为1，当组合时间到期或者组合方案完全删除后该商品状态变为0
  order_enough_give_status: 0为非满就送商品，1为满就送商品
  enough_reduce: 1 滿減
*/

export var goodsFormat = function goodsFormat(_ref12) {
  var item = _ref12.item,
      imageWebServer = _ref12.imageWebServer,
      currencyCode = _ref12.currencyCode;
  var _item$goods_main_phot2 = item.goods_main_photo,
      goods_main_photo = _item$goods_main_phot2 === void 0 ? {} : _item$goods_main_phot2,
      _item$goods_photos = item.goods_photos,
      goods_photos = _item$goods_photos === void 0 ? [] : _item$goods_photos,
      _item$store_price = item.store_price,
      store_price = _item$store_price === void 0 ? 0 : _item$store_price,
      _item$goods_details = item.goods_details,
      goods_details = _item$goods_details === void 0 ? '' : _item$goods_details,
      _item$express_trans_f = item.express_trans_fee,
      express_trans_fee = _item$express_trans_f === void 0 ? 0 : _item$express_trans_f,
      order_enough_give_status = item.order_enough_give_status,
      buy_gift_msg = item.buy_gift_msg,
      activity_status = item.activity_status,
      combin_status = item.combin_status,
      enough_reduce = item.enough_reduce;
  var isBuyGift = order_enough_give_status === 1 && buy_gift_msg;
  var buyGiftMsg = undefined;
  var showBuyGift = false;

  if (isBuyGift) {
    var gift_info_json = buy_gift_msg.gift_info_json,
        condition_amount = buy_gift_msg.condition_amount,
        gift_status = buy_gift_msg.gift_status;
    var conditionAmountText = "".concat(currencyCode, " ").concat(condition_amount);

    var gifIinfoList = _.map(gift_info_json, function (item) {
      return giftInfoFormat({
        item: item,
        imageWebServer: imageWebServer,
        currencyCode: currencyCode
      });
    });

    showBuyGift = gift_status === 10;
    buyGiftMsg = _objectSpread({}, buy_gift_msg, {
      gifIinfoList: gifIinfoList,
      conditionAmountText: conditionAmountText
    });
  }

  var path = goods_main_photo.path,
      name = goods_main_photo.name;
  var uri = "".concat(imageWebServer, "/").concat(path, "/").concat(name);

  var mainPhoto = _objectSpread({}, goods_main_photo, {
    uri: uri
  });

  var priceText = "".concat(currencyCode, " ").concat(store_price);
  var detailsText = goods_details;
  var transfeeText = "".concat(currencyCode, " ").concat(express_trans_fee);

  var photos = _.map(goods_photos, function (item) {
    var path = item.path,
        name = item.name;
    return _objectSpread({}, item, {
      uri: "".concat(imageWebServer, "/").concat(path, "/").concat(name)
    });
  });

  photos.unshift(mainPhoto);
  return _objectSpread({}, item, {
    mainPhoto: mainPhoto,
    photos: photos,
    priceText: priceText,
    detailsText: detailsText,
    transfeeText: transfeeText,
    isBuyGift: isBuyGift,
    showBuyGift: showBuyGift,
    buyGiftMsg: buyGiftMsg
  });
};
export var igGoodsFormat = function igGoodsFormat(_ref13) {
  var item = _ref13.item,
      imageWebServer = _ref13.imageWebServer,
      currencyCode = _ref13.currencyCode;
  var t = i18n.getFixedT();
  var igText = t('shop:integral.title');
  var _item$ig_goods_name = item.ig_goods_name,
      ig_goods_name = _item$ig_goods_name === void 0 ? '' : _item$ig_goods_name,
      _item$ig_goods_img = item.ig_goods_img,
      ig_goods_img = _item$ig_goods_img === void 0 ? {} : _item$ig_goods_img,
      _item$ig_goods_price = item.ig_goods_price,
      ig_goods_price = _item$ig_goods_price === void 0 ? 0 : _item$ig_goods_price,
      _item$ig_goods_integr = item.ig_goods_integral,
      ig_goods_integral = _item$ig_goods_integr === void 0 ? 0 : _item$ig_goods_integr,
      _item$ig_content = item.ig_content,
      ig_content = _item$ig_content === void 0 ? '' : _item$ig_content,
      _item$ig_transfee_typ = item.ig_transfee_type,
      ig_transfee_type = _item$ig_transfee_typ === void 0 ? 0 : _item$ig_transfee_typ,
      _item$ig_transfee = item.ig_transfee,
      ig_transfee = _item$ig_transfee === void 0 ? 0 : _item$ig_transfee,
      _item$ig_goods_count = item.ig_goods_count,
      ig_goods_count = _item$ig_goods_count === void 0 ? 0 : _item$ig_goods_count;
  var path = ig_goods_img.path,
      name = ig_goods_img.name;
  var uri = "".concat(imageWebServer, "/").concat(path, "/").concat(name);

  var mainPhoto = _objectSpread({}, ig_goods_img, {
    uri: uri
  });

  var photos = [mainPhoto];
  var detailsText = ig_content.replace(/<p>/g, '').replace(/<\/p>/g, '');
  var priceText = "".concat(igText, " ").concat(ig_goods_integral);
  var storePriceText = "".concat(currencyCode, " ").concat(ig_goods_price);
  var goods_transfee = ig_transfee_type === 0 ? 1 : 0;
  var transfeeText = "".concat(currencyCode, " ").concat(ig_transfee);
  return _objectSpread({}, item, {
    mainPhoto: mainPhoto,
    photos: photos,
    detailsText: detailsText,
    priceText: priceText,
    storePriceText: storePriceText,
    goods_name: ig_goods_name,
    goods_transfee: goods_transfee,
    express_trans_fee: ig_transfee,
    transfeeText: transfeeText,
    isIgType: true,
    goods_inventory: ig_goods_count
  });
};