import React from "react";
import {Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}sample/:user_id`} component={asyncComponent(() => import('./SamplePage'))}/>
      <Route path={`${match.url}group_class`} component={asyncComponent(() => import('./GroupClassPage'))}/>
      <Route path={`${match.url}profile`} component={asyncComponent(() => import('./ProfilePage'))}/>
      <Route path={`${match.url}client/:client_id`} component={asyncComponent(() => import('./ClientPage'))}/>
      <Route path={`${match.url}glucose`} component={asyncComponent(() => import('./GlucosePage'))}/>
      <Route path={`${match.url}chat`} component={asyncComponent(() => import('./ChatPage'))}/>
      <Route path={`${match.url}client/:client_id/diary`} component={asyncComponent(() => import('./DiaryPage'))}/>
    </Switch>
  </div>
);

export default App;
