import { createAction } from 'redux-actions';
import * as types from '../../store/types';
export var handleOpenAppCall = createAction(types.HANDLE_OPEN_APP_CALL);
export var handleOpenAppDone = createAction(types.HANDLE_OPEN_APP_DONE);
export var handleOpenAppFail = createAction(types.HANDLE_OPEN_APP_FAIL);
export var handlePushNotificationCall = createAction(types.HANDLE_PUSH_NOTIFICATION_CALL);
export var handlePushNotificationFail = createAction(types.HANDLE_PUSH_NOTIFICATION_FAIL);
export var handleSelectPushNotificationCall = createAction(types.HANDLE_SELECT_PUSH_NOTIFICATION_CALL);
export var handleSelectPushNotificationDone = createAction(types.HANDLE_SELECT_PUSH_NOTIFICATION_DONE);
export var handleSelectPushNotificationFail = createAction(types.HANDLE_SELECT_PUSH_NOTIFICATION_FAIL);
export var handleReceivePushNotificationCall = createAction(types.HANDLE_RECEIVE_PUSH_NOTIFICATION_CALL);
export var handleReceivePushNotificationDone = createAction(types.HANDLE_RECEIVE_PUSH_NOTIFICATION_DONE);
export var handleReceivePushNotificationFail = createAction(types.HANDLE_RECEIVE_PUSH_NOTIFICATION_FAIL);
export var updateDeviceInfoCall = createAction(types.UPDATE_DEVICE_INFO_CALL);
export var updateDeviceInfoDone = createAction(types.UPDATE_DEVICE_INFO_DONE);
export var updateDeviceInfoFail = createAction(types.UPDATE_DEVICE_INFO_FAIL);
export var uploadDeviceInfoCall = createAction(types.UPLOAD_DEVICE_INFO_CALL);
export var uploadDeviceInfoDone = createAction(types.UPLOAD_DEVICE_INFO_DONE);
export var uploadDeviceInfoFail = createAction(types.UPLOAD_DEVICE_INFO_FAIL);
export var setAppBadgeCall = createAction(types.SET_APP_BADGE_CALL);
export var setAppBadgeDone = createAction(types.SET_APP_BADGE_DONE);
export var setAppBadgeFail = createAction(types.SET_APP_BADGE_FAIL);
export var updateBrokenCameraDeviceCall = createAction(types.UPDATE_BROKEN_CAMERA_DEVICE_CALL);
export var checkForUpdateCall = createAction(types.CHECK_FOR_UPDATE_CALL);
export var checkForUpdateDone = createAction(types.CHECK_FOR_UPDATE_DONE);
export var checkForUpdateFail = createAction(types.CHECK_FOR_UPDATE_FAIL);