import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";

var _handleActions;

import _ from 'lodash';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import * as types from '../../store/types';
var initialState = {
  userId: undefined,
  users: [] //教練沒身體數據無法算肌力熱量,先預設60

};
var body_attrs = {
  weight: 60
};
export default handleActions((_handleActions = {}, _defineProperty(_handleActions, types.SET_USER_ID, function (state, _ref) {
  var payload = _ref.payload;
  return _objectSpread({}, state, {
    userId: payload
  });
}), _defineProperty(_handleActions, types.GET_USER_DONE, function (state, _ref2) {
  var payload = _ref2.payload;
  return produce(state, function (draft) {
    var users = _.uniqBy([payload].concat(_toConsumableArray(state.users)), 'id'); //users default weight: 60


    draft.users = _.map(users, function (user) {
      return _objectSpread({}, user, {
        body_attrs: body_attrs
      });
    });
  });
}), _defineProperty(_handleActions, types.LOGOUT_DONE, function (state) {
  return initialState;
}), _defineProperty(_handleActions, types.UPDATE_USER_DONE, function (state, _ref3) {
  var user = _ref3.payload.user;
  return produce(state, function (draft) {
    var index = _.findIndex(draft.users, {
      id: user.id
    });

    draft.users[index] = _objectSpread({}, user, {
      body_attrs: body_attrs
    });
  });
}), _handleActions), initialState);