import { createAction } from 'redux-actions';
import { GET_FOOD_STUFF_GROUPS_CALL, GET_FOOD_STUFF_GROUPS_DONE, GET_FOOD_STUFF_GROUPS_FAIL, GET_FOOD_STUFFS_CALL, GET_FOOD_STUFFS_DONE, GET_FOOD_STUFFS_FAIL, SEARCH_FOOD_STUFFS_CALL, SEARCH_FOOD_STUFFS_DONE, SEARCH_FOOD_STUFFS_FAIL, INIT_FOOD_STUFFS } from '../types';
export var getFoodStuffGroupsCall = createAction(GET_FOOD_STUFF_GROUPS_CALL);
export var getFoodStuffGroupsDone = createAction(GET_FOOD_STUFF_GROUPS_DONE);
export var getFoodStuffGroupsFail = createAction(GET_FOOD_STUFF_GROUPS_FAIL);
export var getFoodStuffsCall = createAction(GET_FOOD_STUFFS_CALL);
export var getFoodStuffsDone = createAction(GET_FOOD_STUFFS_DONE);
export var getFoodStuffsFail = createAction(GET_FOOD_STUFFS_FAIL);
export var searchFoodStuffsCall = createAction(SEARCH_FOOD_STUFFS_CALL);
export var searchFoodStuffsDone = createAction(SEARCH_FOOD_STUFFS_DONE);
export var searchFoodStuffsFail = createAction(SEARCH_FOOD_STUFFS_FAIL);
export var initFoodStuffs = createAction(INIT_FOOD_STUFFS);