import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";

var _mediaHandlers;

import _ from 'lodash';
import { combineActions } from 'redux-actions';
import * as types from '../../../store/types';
var mediaHandlers = (_mediaHandlers = {}, _defineProperty(_mediaHandlers, types.UPLOAD_ASSET_DONE, function (state, _ref) {
  var _uid = _ref.payload._uid;
  return _objectSpread({}, state, {
    assets: _.map(state.assets, function (asset) {
      var matched = asset._uid === _uid;
      return matched ? _objectSpread({}, asset, {
        url: asset.uploadInfo.resultUrl,
        _uploaded: true
      }) : asset;
    })
  });
}), _defineProperty(_mediaHandlers, types.SELECT_MEDIA_DONE, function (state, _ref2) {
  var filePaths = _ref2.payload.filePaths;
  return _objectSpread({}, state, {
    assets: [].concat(_toConsumableArray(state.assets), _toConsumableArray(_.map(filePaths, function (filePath) {
      return {
        filePath: filePath,
        _id: "".concat(Math.random(), "_").concat(filePath)
      };
    })))
  });
}), _defineProperty(_mediaHandlers, combineActions(types.GET_MEDIA_UPLOAD_INFO_DONE, types.GET_MEDIA_UPLOAD_INFO_FAIL), function (state, _ref3) {
  var _ref3$payload = _ref3.payload,
      uri = _ref3$payload.uri,
      uploadInfo = _ref3$payload.uploadInfo;
  return _objectSpread({}, state, {
    assets: _.map(state.assets, function (asset) {
      var matched = asset.uri === uri;
      var media_type = asset.mediaType;

      if (matched) {
        asset.uploadInfo = uploadInfo;
      }

      return _objectSpread({}, asset, {
        media_type: media_type
      });
    })
  });
}), _defineProperty(_mediaHandlers, combineActions(types.PROCESS_IMAGE_DONE, types.PROCESS_IMAGE_FAIL), function (state, _ref4) {
  var _ref4$payload = _ref4.payload,
      uri = _ref4$payload.uri,
      processedUri = _ref4$payload.processedUri;
  return _objectSpread({}, state, {
    assets: _.map(state.assets, function (asset) {
      var matched = asset.uri === uri;
      return matched ? _objectSpread({}, asset, {
        processedUri: processedUri,
        _processed: true
      }) : asset;
    })
  });
}), _defineProperty(_mediaHandlers, types.START_SELECT_MEDIA, function (state, _ref5) {
  var payload = _ref5.payload;

  var selectedAssets = _.map(state.assets, function (asset) {
    return {
      _uid: asset._uid,
      disabled: true
    };
  });

  var maxCount = payload ? payload.maxCount : undefined;
  var selectedAsset = selectedAssets[state.currentAssetIndex];
  var singleSelect = maxCount && maxCount === selectedAssets.length + 1;

  if (singleSelect && selectedAsset) {
    selectedAsset.disabled = false;
  }

  var showOrder = !singleSelect;
  var orderOffset = selectedAssets.length;
  return _objectSpread({}, state, {
    selectedAssets: selectedAssets,
    maxCount: maxCount,
    singleSelect: singleSelect,
    showOrder: showOrder,
    orderOffset: orderOffset
  });
}), _defineProperty(_mediaHandlers, types.SELECT_MEDIA, function (state, _ref6) {
  var item = _ref6.payload;
  var maxCount = state.maxCount,
      selectedAssets = state.selectedAssets,
      singleSelect = state.singleSelect,
      assets = state.assets,
      currentAssetIndex = state.currentAssetIndex;

  var isCancel = _.find(selectedAssets, {
    _uid: item._uid
  });

  var maxNotReached = !maxCount || selectedAssets.length < maxCount;

  var newSelectedAssets = _toConsumableArray(selectedAssets);

  if (singleSelect) {
    // max reached, should replace selected (the one not marked as disabled)
    if (isCancel) {
      newSelectedAssets = _.map(assets, function (asset) {
        return {
          _uid: asset._uid,
          disabled: true
        };
      });
      var selectedAsset = newSelectedAssets[state.currentAssetIndex];

      if (selectedAsset) {
        selectedAsset.disabled = false;
      }
    } else {
      newSelectedAssets[currentAssetIndex] = _objectSpread({}, newSelectedAssets[currentAssetIndex], item);
    }
  } else if (isCancel) {
    newSelectedAssets = _.filter(selectedAssets, function (asset) {
      return asset._uid !== item._uid;
    });
  } else if (maxNotReached) {
    newSelectedAssets = _.concat(selectedAssets, item);
  }

  return _objectSpread({}, state, {
    selectedAssets: newSelectedAssets
  });
}), _mediaHandlers);
export default mediaHandlers;