import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import { Observable } from 'rxjs';
import { question } from '../../services';
import './RxOperators';
import * as actions from '../actions';
import * as types from '../types';
export var getQuestionListCall = function getQuestionListCall(action$, store) {
  return action$.ofType(types.GET_QUESTION_LIST_CALL).switchMap(function (_ref) {
    var payload = _ref.payload;
    return Observable.fromPromise(question.getQuestionList(payload)).switchMap(function (res) {
      return [actions.getQuestionListDone(res)];
    }).catch(function (err) {
      return Observable.of(actions.getQuestionListFail(err));
    });
  });
};
export var getQuestionCall = function getQuestionCall(action$, store) {
  return action$.ofType(types.GET_QUESTION_CALL).switchMap(function (_ref2) {
    var payload = _ref2.payload,
        questLvlIndex = _ref2.payload.questLvlIndex;
    return Observable.fromPromise(question.getQuestion(payload)).switchMap(function (res) {
      return [actions.getQuestionDone(_objectSpread({}, res, {
        questLvlIndex: questLvlIndex
      }))];
    }).catch(function (err) {
      return Observable.of(actions.getQuestionFail(err));
    });
  });
};
export var sendQuestionCall = function sendQuestionCall(action$, store) {
  return action$.ofType(types.SEND_QUESTION_CALL).switchMap(function (_ref3) {
    var _ref3$payload = _ref3.payload,
        getResult = _ref3$payload.getResult,
        param = _ref3$payload.param,
        onetypeId = _ref3$payload.onetypeId;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(question.sendQuestion(param)).switchMap(function (res) {
      return [getResult ? actions.getQuestionResultCall({
        quest_type_id: onetypeId
      }) : actions.empty(), actions.sendQuestionDone(res)];
    }).catch(function (err) {
      return Observable.of(actions.sendQuestionFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var newSendQuestionCall = function newSendQuestionCall(action$, store) {
  return action$.ofType(types.NEW_SEND_QUESTION_CALL).switchMap(function (_ref4) {
    var _ref4$payload = _ref4.payload,
        getResult = _ref4$payload.getResult,
        param = _ref4$payload.param,
        onetypeId = _ref4$payload.onetypeId;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(question.newSendQuestion(param)).switchMap(function (res) {
      return [getResult ? actions.getQuestionResultCall({
        quest_type_id: onetypeId
      }) : actions.empty(), actions.newSendQuestionDone(res)];
    }).catch(function (err) {
      return Observable.of(actions.newSendQuestionFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var getQuestionResultCall = function getQuestionResultCall(action$, store) {
  return action$.ofType(types.GET_QUESTION_RESULT_CALL).switchMap(function (_ref5) {
    var payload = _ref5.payload;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(question.getQuestionResult(payload)).map(function (res) {
      return actions.getQuestionResultDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getQuestionResultFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var getCofitQuestionCall = function getCofitQuestionCall(action$, store) {
  return action$.ofType(types.GET_COFIT_QUESTION_CALL).switchMap(function (_ref6) {
    var payload = _ref6.payload;
    return Observable.fromPromise(question.getCofitQuestion(payload)).switchMap(function (res) {
      return [actions.getCofitQuestionDone(res), actions.openCofitQuestionPage()];
    }).catch(function (err) {
      return Observable.of(actions.getCofitQuestionFail(err));
    });
  });
};