import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";

var _objectSpread2;

import _ from 'lodash';
import produce from 'immer';
import { combineActions, handleActions } from 'redux-actions';
import * as types from '../../../store/types';
import { sumC63 } from '../../../services/note';
import mediaHandlers from './mediaHandlers';
import itemHandlers from './itemHandlers';
import sessionHandlers from './sessionHandlers';
import { filterAerobic } from '../../selectors';
import uuid from 'uuid';
export default handleActions(_objectSpread({}, mediaHandlers, itemHandlers, sessionHandlers, (_objectSpread2 = {}, _defineProperty(_objectSpread2, types.INIT_NEW_NOTE, function (state, _ref) {
  var payload = _ref.payload;
  var type = payload.type,
      meal_order = payload.meal_order,
      date = payload.date,
      isProAdd = payload.isProAdd,
      showPostSwitch = payload.showPostSwitch,
      showGroupPostSwitch = payload.showGroupPostSwitch,
      isPost = payload.isPost,
      groupIds = payload.groupIds;
  return {
    type: type,
    meal_order: meal_order,
    date: date,
    note: {
      isPost: isPost,
      groupIds: groupIds,
      date: date,
      selectTags: []
    },
    id: undefined,
    assets: [],
    selectedAssets: [],
    items: [],
    selectedActivitySessions: [],
    isProAdd: isProAdd,
    showPostSwitch: showPostSwitch,
    showGroupPostSwitch: showGroupPostSwitch,
    uid: uuid.v4()
  };
}), _defineProperty(_objectSpread2, types.RESET_NEW_NOTE, function (state) {
  var type = state.type,
      meal_order = state.meal_order,
      date = state.date;
  return {
    type: type,
    meal_order: meal_order,
    date: date,
    note: {
      isPost: false,
      selectTags: [],
      date: date,
      groupIds: []
    },
    id: undefined,
    assets: [],
    selectedAssets: [],
    items: [],
    selectedActivitySessions: [],
    isProAdd: false,
    showPostSwitch: true,
    showGroupPostSwitch: true,
    uid: undefined
  };
}), _defineProperty(_objectSpread2, types.INIT_EDIT_NOTE, function (state, _ref2) {
  var _ref2$payload = _ref2.payload,
      isPost = _ref2$payload.isPost,
      note = _ref2$payload.note,
      id = _ref2$payload.id,
      showPostSwitch = _ref2$payload.showPostSwitch,
      showGroupPostSwitch = _ref2$payload.showGroupPostSwitch,
      groupIds = _ref2$payload.groupIds,
      isProAdd = _ref2$payload.isProAdd,
      isEditPost = _ref2$payload.isEditPost;
  var type = note.type,
      meal_order = note.meal_order,
      date = note.date,
      text = note.text,
      taken_at = note.taken_at,
      assets = note.assets,
      items = note.items,
      comments = note.comments,
      move_sessions = note.move_sessions,
      tags = note.tags,
      hasSocialPosts = note.has_social_posts,
      hasGroupClassPosts = note.has_group_class_posts,
      groupClassIds = note.group_class_ids,
      place = note.place;

  var activityItems = _.map(items, function (item) {
    var isWorkout = item.item_type && item.item_type === 'Activity::Item' && item.item.category === 'workout';
    return _objectSpread({}, item, {
      isWorkout: isWorkout,
      category: 'activity'
    });
  });

  var selectedActivityItems = filterAerobic(activityItems);

  var selectedActivitySessions = _.map(move_sessions, function (session) {
    var rounds = session.rounds,
        items = session.items;
    var isRounds = rounds && rounds.length !== 0;
    var item_id;

    if (!isRounds) {
      item_id = items[0].item_id;
    }

    return _objectSpread({}, session, {
      item_id: item_id,
      isWorkout: true,
      category: 'session'
    });
  });

  var oldAssets = _.map(assets, function (asset) {
    return _objectSpread({}, asset, {
      _destroy: true
    });
  });

  return {
    type: type,
    meal_order: meal_order,
    date: date,
    note: {
      text: text,
      date: date,
      taken_at: taken_at,
      isPost: isPost,
      groupIds: groupIds,
      selectTags: tags,
      place: place
    },
    hasSocialPosts: hasSocialPosts,
    hasGroupClassPosts: hasGroupClassPosts,
    groupClassIds: groupClassIds,
    id: id,
    assets: assets,
    oldAssets: oldAssets,
    selectedAssets: [],
    selectedActivityItems: selectedActivityItems,
    // so far all the items are activity_item
    selectedActivitySessions: selectedActivitySessions,
    items: items,
    comments: comments,
    showPostSwitch: showPostSwitch,
    showGroupPostSwitch: showGroupPostSwitch,
    isProAdd: isProAdd,
    uid: uuid.v4()
  };
}), _defineProperty(_objectSpread2, types.SET_NEW_NOTE_CALL, function (state, _ref3) {
  var item = _ref3.payload.item;
  return item;
}), _defineProperty(_objectSpread2, types.CREATE_NOTE_DONE, function (state, _ref4) {
  var note = _ref4.payload.note;
  return _objectSpread({}, state, {
    _done: true
  });
}), _defineProperty(_objectSpread2, types.UPDATE_NEW_NOTE, function (state, _ref5) {
  var payload = _ref5.payload;
  return _objectSpread({}, state, {
    note: _objectSpread({}, state.note, payload)
  });
}), _defineProperty(_objectSpread2, types.UPDATE_NEW_NOTE_ASSET, function (state, _ref6) {
  var payload = _ref6.payload;
  return produce(state, function (draft) {
    var assets = draft.assets,
        currentAssetIndex = draft.currentAssetIndex;
    var assetToUpdate = assets[currentAssetIndex];

    _.merge(assetToUpdate, payload);
  });
}), _defineProperty(_objectSpread2, types.REMOVE_NEW_NOTE_ASSET, function (state, _ref7) {
  var index = _ref7.payload.index;

  var assets = _toConsumableArray(state.assets);

  _.pullAt(assets, index);

  return _objectSpread({}, state, {
    assets: assets
  });
}), _defineProperty(_objectSpread2, types.REMOVE_NEW_NOTE_ASSET_IMAGE, function (state, _ref8) {
  var index = _ref8.payload.index;

  var assets = _toConsumableArray(state.assets);

  assets = [].concat(_toConsumableArray(assets.slice(0, index)), [_objectSpread({}, assets[index], {
    url: null,
    uri: null,
    _width: null,
    _height: null,
    _uid: null,
    _fromCamera: null,
    _type: null,
    uploadInfo: null,
    processUri: null,
    _processed: null
  })], _toConsumableArray(assets.slice(index + 1)));
  return _objectSpread({}, state, {
    assets: assets
  });
}), _defineProperty(_objectSpread2, types.ADD_NEW_NOTE_EMPTY_ASSET, function (state, _ref9) {
  var payload = _ref9.payload;

  var assets = _toConsumableArray(state.assets);

  assets.push({
    _type: 'noteAsset'
  });
  return _objectSpread({}, state, {
    assets: assets
  });
}), _defineProperty(_objectSpread2, types.SET_CURRENT_ASSET_INDEX, function (state, _ref10) {
  var payload = _ref10.payload;
  return _objectSpread({}, state, {
    currentAssetIndex: payload
  });
}), _defineProperty(_objectSpread2, types.PREPARE_FOR_UPDATE_NOTE, function (state, _ref11) {
  var member = _ref11.payload.member;
  return produce(state, function (draft) {
    var assets = draft.assets,
        newComment = draft.note.newComment,
        comments = draft.comments,
        noteId = draft.id;
    var capitalizedType = member.capitalizedType,
        id = member.id,
        avatar_thumbnail_url = member.avatar_thumbnail_url,
        sender_name = member.nick_name;

    if (newComment) {
      comments.push({
        sender_name: sender_name,
        sender_avatar_thumbnail_url: avatar_thumbnail_url,
        sender_id: id,
        sender_type: capitalizedType,
        content: newComment,
        record_id: noteId,
        record_type: 'Note'
      });
    }

    _.map(assets, function (asset, index) {
      if (asset.newComment) {
        asset.comments.push({
          sender_name: sender_name,
          sender_avatar_thumbnail_url: avatar_thumbnail_url,
          sender_id: id,
          sender_type: capitalizedType,
          content: asset.newComment,
          record_id: asset.id,
          record_type: 'Note::Asset'
        });
      }
    });
  });
}), _defineProperty(_objectSpread2, combineActions(types.INIT_EDIT_NOTE, types.CONFIRM_NEW_NOTE_FOODSTUFF, types.UPDATE_FOOD_STUFF, types.REMOVE_FOOD_STUFF, types.ADD_NEW_NOTE_SIXGROUP, types.REMOVE_SIX_GROUP, types.ADD_NEW_NOTE_CALORIE, types.REMOVE_NEW_NOTE_ASSET, types.FINISH_SELECT_MEDIA, types.FINISH_CHAT_SELECT_MEDIA), function (state, _ref12) {
  var payload = _ref12.payload;

  var assets = _.map(state.assets, function (asset) {
    return _objectSpread({}, asset, sumC63(_.reject(asset.items, '_destroy')));
  });

  return _objectSpread({}, state, {
    assets: assets
  }, sumC63(assets));
}), _objectSpread2)), {
  currentAssetIndex: 0,
  type: '',
  note: {},
  assets: [],
  selectedAssets: [],
  items: [],
  selectedFoodStuffs: [],
  selectedSixGroup: [],
  selectedActivityItems: [],
  selectedActivitySessions: [],
  comments: [],
  isProAdd: false,
  showPostSwitch: true,
  showGroupPostSwitch: true
});