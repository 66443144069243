import lib from '../platform';
var storage = lib.pfStorage;
export var setItemAsync = function setItemAsync(key, value) {
  return storage.setItemAsync(key, "".concat(value));
};
export var getItemAsync = function getItemAsync(key) {
  return storage.getItemAsync(key);
};
export var removeItemAsync = function removeItemAsync(key) {
  return storage.removeItemAsync(key);
};
export var getIsuseSecureStore = function getIsuseSecureStore() {
  return storage.getIsuseSecureStore();
};