import enLang from "./entries/en-US";
import twLang from "./entries/zh-TW"
import zhLang from "./entries/zh-CN"
import {addLocaleData} from "react-intl";

const AppLocale = {
  tw: twLang,
  en: enLang,
  cn: zhLang,
};

addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.cn.data);
addLocaleData(AppLocale.tw.data);

export default AppLocale;
