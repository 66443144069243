import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";

var _handleActions;

import _ from 'lodash';
import { handleActions } from 'redux-actions';
import * as types from '../../store/types';
import produce from 'immer';
export default handleActions((_handleActions = {}, _defineProperty(_handleActions, types.GET_ACTIVITY_ITEM_GROUPS_DONE, function (state, _ref) {
  var payload = _ref.payload;

  var items = _.map(payload, function (item) {
    return _objectSpread({}, item, {
      item_numeric: item.numeric
    });
  });

  var aerobicList = _.reject(items, {
    category: 'workout'
  });

  aerobicList = _.reject(aerobicList, {
    category: 'measurement'
  });

  var aerobic = _.keys(_.groupBy(aerobicList, 'category_name'));

  var aerobicItems = _.flattenDeep(_.values(_.groupBy(aerobicList, 'category_name')));

  var workoutList = _.filter(items, {
    category: 'workout'
  });

  var workout = _.keys(_.groupBy(workoutList, 'sub_category_name'));

  var workoutItems = _.flattenDeep(_.values(_.groupBy(workoutList, 'sub_category_name')));

  workoutItems = _.map(workoutItems, function (item) {
    return _objectSpread({}, item, {
      isWorkout: true
    });
  });

  var measureList = _.filter(items, {
    category: 'measurement'
  });

  var measureItems = _.flattenDeep(_.values(_.groupBy(measureList, 'category_name')));

  var activityItems = [].concat(_toConsumableArray(aerobicItems), _toConsumableArray(workoutItems));
  return _objectSpread({}, state, {
    groups: _objectSpread({}, state.groups, {
      aerobic: aerobic,
      workout: workout
    }),
    activityItems: activityItems,
    aerobicItems: aerobicItems,
    workoutItems: workoutItems,
    measureItems: measureItems
  });
}), _defineProperty(_handleActions, types.GET_ROUTINES_DONE, function (state, _ref2) {
  var payload = _ref2.payload;

  var routine = _.map(payload, 'name');

  var routines = _.map(payload, function (routine) {
    var sessions = _.map(routine.sessions, function (session) {
      return _objectSpread({}, session, {
        is_template: false
      });
    });

    return _objectSpread({}, routine, {
      sessions: sessions
    });
  });

  return _objectSpread({}, state, {
    groups: _objectSpread({}, state.groups, {
      routine: routine
    }),
    routines: routines
  });
}), _defineProperty(_handleActions, types.ADD_MY_ACTIVITY_ITEM_DONE, function (state, _ref3) {
  var item = _ref3.payload.item;
  return produce(state, function (draft) {
    var custom = _.uniqBy(_.concat(draft.custom, item), 'id');

    draft.custom = _.map(custom, function (item) {
      return _objectSpread({}, item, {
        isWorkout: true
      });
    });
  });
}), _defineProperty(_handleActions, types.DELETE_MY_ACTIVITY_ITEM_DONE, function (state, _ref4) {
  var id = _ref4.payload.id;
  return produce(state, function (draft) {
    _.remove(draft.custom, {
      id: id
    });
  });
}), _defineProperty(_handleActions, types.GET_MY_ACTIVITY_ITEMS_DONE, function (state, _ref5) {
  var custom = _ref5.payload;
  return produce(state, function (draft) {
    draft.custom = _.map(custom, function (item) {
      return _objectSpread({}, item, {
        isWorkout: true
      });
    });
  });
}), _defineProperty(_handleActions, types.GET_ACTIVITY_ITEMS_CALL, function (state, _ref6) {
  var _ref6$payload = _ref6.payload,
      type = _ref6$payload.type,
      category = _ref6$payload.category;
  var aerobicItems = state.aerobicItems,
      workoutItems = state.workoutItems,
      custom = state.custom,
      routines = state.routines;
  var currentItems = [];

  if (type === 'keyword') {
    var filteList = [].concat(_toConsumableArray(aerobicItems), _toConsumableArray(workoutItems), _toConsumableArray(routines), _toConsumableArray(custom));
    currentItems = _.filter(filteList, function (item) {
      if (item.display_name) {
        var display_name = item.display_name.toLowerCase();
        var categoryLowerCase = category.toLowerCase();
        return _.includes(display_name, categoryLowerCase);
      } else {
        return [];
      }
    });
  } else if (type === 'routine') {
    var tmp = _.find(routines, {
      name: category
    });

    currentItems = tmp.sessions || [];
  } else if (type === 'aerobic') {
    currentItems = _.filter(aerobicItems, {
      category_name: category
    });
  } else {
    currentItems = _.filter(workoutItems, {
      sub_category_name: category
    });
  }

  return _objectSpread({}, state, {
    currentItems: currentItems
  });
}), _handleActions), {
  groups: {},
  activityItems: [],
  measureItems: [],
  aerobicItems: [],
  workoutItems: [],
  routines: [],
  custom: []
});