import _ from 'lodash';
import { decimalKeyboard } from '../services/device';
var attrsMeta = [{
  key: 'height',
  name: '身高',
  unit: 'cm',
  inputType: decimalKeyboard,
  maxLength: 5
}, {
  key: 'weight',
  name: '體重',
  unit: 'kg',
  inputType: decimalKeyboard,
  inputMin: 1,
  inputMax: 250,
  default: 60,
  decimal: 1,
  maxLength: 5
}, {
  key: 'body_fat_mass_percentage',
  name: '體脂率',
  unit: '%',
  inputType: decimalKeyboard,
  inputMin: 1,
  inputMax: 100,
  default: 25,
  decimal: 1,
  maxLength: 4
}, {
  key: 'waist_circumference',
  name: '腰圍',
  unit: 'cm',
  inputType: decimalKeyboard,
  inputMin: 20,
  inputMax: 200,
  default: 80,
  decimal: 1,
  maxLength: 4
}, {
  key: 'hip_circumference',
  name: '臀圍',
  unit: 'cm',
  inputType: decimalKeyboard,
  inputMin: 20,
  inputMax: 200,
  default: 100,
  decimal: 1,
  maxLength: 4
}, {
  key: 'muscle_mass',
  name: '肌肉重',
  unit: 'kg',
  inputType: decimalKeyboard,
  inputMin: 1,
  inputMax: 250,
  default: 60,
  decimal: 1,
  maxLength: 5
}, {
  key: 'bmi',
  name: 'BMI',
  unit: '',
  decimal: 1,
  notEditable: true
}, {
  key: 'waist_hip_ratio',
  name: '腰臀比',
  unit: '',
  decimal: 2,
  notEditable: true
}, {
  key: 'total_body_water_percentage',
  name: '身體水分率',
  unit: '%',
  inputType: decimalKeyboard,
  inputMin: 1,
  inputMax: 100,
  default: 25,
  decimal: 1,
  maxLength: 4
}, {
  key: 'systolic_blood_pressure',
  name: '收縮壓 SBP',
  unit: 'mmHg',
  inputType: decimalKeyboard,
  maxLength: 4
}, {
  key: 'diastolic_blood_pressure',
  name: '舒張壓 DBP',
  unit: 'mmHg',
  inputType: decimalKeyboard,
  maxLength: 4
}, {
  key: 'pulse',
  name: '脈搏',
  unit: '',
  inputType: decimalKeyboard,
  inputMin: 40,
  inputMax: 300,
  default: 80,
  maxLength: 4
}, {
  key: 'blood_sugar',
  name: '血糖',
  unit: '',
  inputType: decimalKeyboard,
  maxLength: 4
}, {
  key: 'total_cholesterol',
  name: '總膽固醇 TC',
  unit: 'mg/dL',
  inputType: decimalKeyboard,
  maxLength: 4
}, {
  key: 'high_density_lipoprotein',
  name: '高密度脂蛋白膽固醇 HDL-C',
  unit: 'mg/dL',
  inputType: decimalKeyboard,
  maxLength: 4
}, {
  key: 'low_density_lipoprotein_cholesterol',
  name: '低密度脂蛋白膽固醇 LDL-C',
  unit: 'mg/dL',
  inputType: decimalKeyboard,
  maxLength: 4
}, {
  key: 'triglyceride',
  name: '三酸甘油酯 TG',
  unit: 'mg/dL',
  inputType: decimalKeyboard,
  maxLength: 4
}, {
  key: 'muscle_mass_percentage',
  name: '肌肉率',
  unit: '%',
  inputType: decimalKeyboard,
  inputMin: 1,
  inputMax: 100,
  default: 25,
  decimal: 1,
  maxLength: 4
}, {
  key: 'lean_body_mass',
  name: '去脂體重',
  unit: 'kg',
  inputType: decimalKeyboard,
  inputMin: 1,
  inputMax: 250,
  default: 60,
  decimal: 1,
  maxLength: 5
}];
export default {
  attrsMeta: attrsMeta,
  defaultAttrs: ['weight', 'body_fat_mass_percentage', 'waist_circumference'],
  defaultAttrsChild: ['height', 'weight', 'bmi'],
  addDataAttrs: _.map(attrsMeta, function (_ref) {
    var key = _ref.key;
    return key;
  }),
  groupedAttrs: {
    blood_pressure: ['systolic_blood_pressure', 'diastolic_blood_pressure'] // 'cholesterol': ['high_density_lipoprotein', 'low_density_lipoprotein_cholesterol'],

  }
};