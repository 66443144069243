import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import { Observable } from 'rxjs';
import { client, user, member, storage, monitor } from '../../services';
import './RxOperators';
import * as actions from '../actions';
import * as types from '../../store/types';
import _ from 'lodash';
import { Util } from '../../utils';
import lib from '../../platform';
import { getMember } from '../../store/selectors';
var pfConfig = lib.pfConfig;
var isProType = pfConfig.isProType,
    isFFApp = pfConfig.isFFApp,
    isSocialType = pfConfig.isSocialType,
    isProduction = pfConfig.isProduction;
var service = isProType ? user : client;
var getCurrentRouteName = Util.getCurrentRouteName,
    getBackLocale = Util.getBackLocale;
export var getChatRoomsCall = function getChatRoomsCall(action$, store) {
  return action$.ofType(types.GET_CHAT_ROOMS_CALL).switchMap(function (_ref) {
    var _ref$payload = _ref.payload,
        isOpenChat = _ref$payload.isOpenChat,
        id = _ref$payload.id,
        showShopChat = _ref$payload.showShopChat;
    return Observable.concat(Observable.of(isOpenChat ? actions.showLoading() : actions.empty()), Observable.fromPromise(service.getChatRooms()).switchMap(function (rooms) {
      var newActions = [actions.getChatRoomsDone(rooms)];

      if (isOpenChat) {
        newActions.push(actions.openChatCall({
          id: id
        }));
      }

      if (showShopChat) {
        newActions.push(actions.initSocketECCall());
      }

      return newActions;
    }).catch(function (err) {
      return Observable.of(actions.getChatRoomsFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var getMeCall = function getMeCall(action$, store) {
  return action$.ofType(types.GET_ME_CALL).switchMap(function (_ref2) {
    var isLogin = _ref2.payload.isLogin;
    var newActions = [];

    switch (true) {
      case isProType:
        newActions.push(actions.getUserMeCall({
          isLogin: isLogin,
          showLoaging: true
        }));
        break;

      case isFFApp:
        newActions.push(actions.getFFClientMeCall({
          isLogin: isLogin,
          showLoaging: false
        }));
        break;

      case isSocialType:
        newActions.push(actions.getSocialClientMeCall({
          isLogin: isLogin,
          showLoaging: false
        }));
        break;

      default:
        newActions.push(actions.getClientMeCall({
          isLogin: isLogin,
          showLoaging: true
        }));
        break;
    }

    newActions.push(actions.getSocialPostsCall({
      type: 'first'
    }));
    return newActions;
  });
};
export var getClientMeDone = function getClientMeDone(action$, store) {
  return action$.ofType(types.GET_CLIENT_ME_DONE).switchMap(function (_ref3) {
    var payload = _ref3.payload;
    return Observable.of(actions.getMeDone(_objectSpread({}, payload, {
      type: 'client'
    })));
  });
};
export var getUserMeDone = function getUserMeDone(action$, store) {
  return action$.ofType(types.GET_USER_ME_DONE).switchMap(function (_ref4) {
    var payload = _ref4.payload;
    return Observable.of(actions.getMeDone(_objectSpread({}, payload, {
      type: 'user'
    })));
  });
};
export var getMeDone = function getMeDone(action$, store) {
  return action$.ofType(types.GET_ME_DONE).switchMap(function (_ref5) {
    var payload = _ref5.payload;
    var id = payload.id,
        nick_name = payload.nick_name,
        locale = payload.locale,
        email = payload.email;

    if (isProduction) {
      monitor.setUser({
        id: id,
        username: nick_name,
        email: email
      });
    }

    return Observable.fromPromise(storage.getItemAsync('languageCode')).map(function (languageCode) {
      var saveLocale = getBackLocale(languageCode);

      if (locale !== saveLocale) {
        var param = {
          hiddenLogin: true,
          key: 'top',
          firstUpdate: true,
          value: {
            id: id,
            locale: saveLocale
          }
        };
        return actions.updateMember(param);
      } else {
        return actions.empty();
      }
    }).catch(function (err) {
      return Observable.of(actions.getMeFail(err));
    });
  });
};
export var getUnreadCount = function getUnreadCount(action$, store) {
  return action$.ofType(types.GET_UNREAD_COUNT_CALL).switchMap(function () {
    return Observable.fromPromise(service.getUnreadCount()).switchMap(function (unreadCount) {
      var routeName = getCurrentRouteName(store.value.nav);
      var inNotificationTab = routeName === 'NotificationTab';
      var newAction = inNotificationTab ? actions.getNotificationsCall({
        newer: true
      }) : actions.empty();
      return [actions.getUnreadCountDone({
        unreadCount: unreadCount
      }), actions.setAppBadgeCall(), newAction];
    }).catch(function (err) {
      return Observable.of(actions.getUnreadCountFail(err));
    });
  });
};
export var uploadDeviceInfoCall = function uploadDeviceInfoCall(action$, store) {
  return action$.ofType(types.UPLOAD_DEVICE_INFO_CALL).switchMap(function (_ref6) {
    var payload = _ref6.payload;
    return Observable.fromPromise(service.uploadDeviceInfo(payload)).map(function (res) {
      return actions.uploadDeviceInfoDone(res);
    }).catch(function (err) {
      return Observable.of(actions.uploadDeviceInfoFail(err));
    });
  });
};
export var getAvatarUploadInfo = function getAvatarUploadInfo(action$, store) {
  return action$.ofType(types.GET_AVATAR_UPLOAD_INFO_CALL).concatMap(function (_ref7) {
    var uri = _ref7.payload.uri;
    return Observable.fromPromise(member.getAvatarUploadInfo()).map(function (_ref8) {
      var url = _ref8.url,
          formData = _ref8.form_data,
          resultUrl = _ref8.result_url,
          storage = _ref8.storage,
          key = _ref8.key;
      var media = {
        uri: uri,
        uploadInfo: {
          url: url,
          storage: storage,
          key: key,
          formData: formData,
          resultUrl: resultUrl
        }
      };
      return actions.uploadAvatarCall({
        media: media
      });
    }).catch(function (err) {
      return Observable.of(actions.getAvatarUploadInfoFail({
        uri: uri,
        errMessage: err.message
      }));
    });
  });
};
export var uploadAvatar = function uploadAvatar(action$, store) {
  return action$.ofType(types.UPLOAD_AVATAR_CALL).concatMap(function (_ref9) {
    var media = _ref9.payload.media;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(member.uploadMediaDirect(media)).switchMap(function (res) {
      var avatar_url = media.uploadInfo.resultUrl;
      var member = getMember(store.value);
      var id = member.id;
      var param = {
        key: 'top',
        firstUpdate: true,
        value: {
          id: id,
          avatar_url: avatar_url,
          avatar_thumbnail_url: avatar_url
        }
      };
      return [actions.uploadAvatarDone(), actions.updateMember(param)];
    }).catch(function (err) {
      return Observable.of(actions.uploadAvatarFail({
        errMessage: err.message
      }));
    }), Observable.of(actions.hideLoading()));
  });
};
export var changePasswordCall = function changePasswordCall(action$, store) {
  return action$.ofType(types.CHANGE_PASSWORD_CALL).switchMap(function (_ref10) {
    var payload = _ref10.payload,
        isMobile = _ref10.payload.isMobile;
    var changePassword = isMobile ? client.changeMobilePassword : service.changePassword;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(changePassword(payload)).map(function (res) {
      return actions.changePasswordDone(res);
    }).catch(function (err) {
      return Observable.of(actions.changePasswordFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var updateMember = function updateMember(action$, store) {
  return action$.ofType(types.UPDATE_MEMBER).map(function (_ref11) {
    var payload = _ref11.payload;
    return isProType ? actions.updateUserCall(payload) : actions.updateClientCall(payload);
  });
};