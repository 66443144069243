import _ from 'lodash';
export var objectToArray = function objectToArray(object) {
  return _.map(_.toPairs(object), function (d) {
    return _.fromPairs([d]);
  });
};
export var stringInsert = function stringInsert(str, index, value) {
  return str.substr(0, index + 1) + value + str.substr(index + 1);
}; // 四捨五入至小數點 {pos} 位

export var round = function round(num) {
  var pos = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  return Number((Math.round(num * Math.pow(10, pos)) / Math.pow(10, pos)).toFixed(pos));
};
export var isURL = function isURL(str) {
  var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  return regexp.test(str);
};
export var getCurrentRouteName = function getCurrentRouteName() {
  var navigationState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _navigationState$rout = navigationState.routes,
      routes = _navigationState$rout === void 0 ? [{}] : _navigationState$rout,
      _navigationState$inde = navigationState.index,
      index = _navigationState$inde === void 0 ? 0 : _navigationState$inde;
  var route = routes[index] || {};

  try {
    return route.routes ? getCurrentRouteName(route) : route.routeName;
  } catch (error) {
    return '';
  }
};
export var isValidAmount = function isValidAmount(amount) {
  var onlyDot = amount === '.';
  var multipleDots = amount.match(/\.[^.]*\./);
  var startWithZeroAndNumber = amount.match(/^0[0-9]/);
  var invalid = onlyDot || multipleDots || startWithZeroAndNumber;
  return invalid;
};
export var getLocale = function getLocale(lng) {
  var locale = 'en';

  switch (lng) {
    case 'en':
      locale = 'en';
      break;

    case 'zh_TW':
      locale = 'zh-tw';
      break;

    case 'zh_CN':
      locale = 'zh-cn';
      break;

    case 'jp':
      locale = 'ja';
      break;

    default:
      locale = 'en';
      break;
  }

  return locale;
};
export var getBackLocale = function getBackLocale(lng) {
  var locale = 'en-US';

  switch (lng) {
    case 'en':
      locale = 'en-US';
      break;

    case 'zh_TW':
      locale = 'zh-TW';
      break;

    case 'zh_CN':
      locale = 'zh-CN';
      break;

    case 'jp':
      locale = 'ja';
      break;

    default:
      locale = 'en-US';
      break;
  }

  return locale;
};
export var requiredEmail = function requiredEmail(value) {
  return !value || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) && 'error';
};

var checkAccount = function checkAccount(id) {
  id = id.toUpperCase(); //身分證

  if (/^[A-Za-z][\d]{9}$/.test(id)) {
    var city = [1, 10, 19, 28, 37, 46, 55, 64, 39, 73, 82, 2, 11, 20, 48, 29, 38, 47, 56, 65, 74, 83, 21, 3, 12, 30];
    id = id.split('');
    var total = city[id[0].charCodeAt(0) - 65];

    for (var i = 1; i <= 8; i++) {
      total += eval(id[i]) * (9 - i);
    }

    total += eval(id[9]);
    return total % 10 == 0;
  } else if (/^[A-Za-z][A-Da-d][\d]{8}$/.test(id)) {
    //歪國人居留證先只判斷頭兩字與長度
    return true;
  } else if (id.length > 0) {
    return true;
  } else {
    return false;
  }
};

export var requiredAccount = function requiredAccount(value) {
  return (!value || !checkAccount(value)) && 'error';
};
export var minLength = function minLength(min, value) {
  return (!value || value.length < min) && 'error';
};
export var phoneChecks = {
  '+86': /^(\+?0?86\-?)?1[3456789]\d{9}$/,
  '+852': /^(\+?852\-?)?[569]\d{3}\-?\d{4}$/,
  '+886': /^(\+?886\-?|0)?9\d{8}$/
};
export var toFixedCheck = function toFixedCheck(value) {
  var check = /^[0-9]+(\.[0-9]{1,2})?$/;

  if (!check.test(value)) {
    return value.toFixed(2);
  } else {
    return value;
  }
};