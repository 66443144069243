export var HANDLE_OPEN_APP_CALL = 'HANDLE_OPEN_APP_CALL';
export var HANDLE_OPEN_APP_DONE = 'HANDLE_OPEN_APP_DONE';
export var HANDLE_OPEN_APP_FAIL = 'HANDLE_OPEN_APP_FAIL';
export var HANDLE_PUSH_NOTIFICATION_CALL = 'HANDLE_PUSH_NOTIFICATION_CALL';
export var HANDLE_PUSH_NOTIFICATION_FAIL = 'HANDLE_PUSH_NOTIFICATION_FAIL';
export var HANDLE_SELECT_PUSH_NOTIFICATION_CALL = 'HANDLE_SELECT_PUSH_NOTIFICATION_CALL';
export var HANDLE_SELECT_PUSH_NOTIFICATION_DONE = 'HANDLE_SELECT_PUSH_NOTIFICATION_DONE';
export var HANDLE_SELECT_PUSH_NOTIFICATION_FAIL = 'HANDLE_SELECT_PUSH_NOTIFICATION_FAIL';
export var HANDLE_RECEIVE_PUSH_NOTIFICATION_CALL = 'HANDLE_RECEIVE_PUSH_NOTIFICATION_CALL';
export var HANDLE_RECEIVE_PUSH_NOTIFICATION_DONE = 'HANDLE_RECEIVE_PUSH_NOTIFICATION_DONE';
export var HANDLE_RECEIVE_PUSH_NOTIFICATION_FAIL = 'HANDLE_RECEIVE_PUSH_NOTIFICATION_FAIL';
export var UPDATE_DEVICE_INFO_CALL = 'UPDATE_DEVICE_INFO_CALL';
export var UPDATE_DEVICE_INFO_DONE = 'UPDATE_DEVICE_INFO_DONE';
export var UPDATE_DEVICE_INFO_FAIL = 'UPDATE_DEVICE_INFO_FAIL';
export var UPLOAD_DEVICE_INFO_CALL = 'UPLOAD_DEVICE_INFO_CALL';
export var UPLOAD_DEVICE_INFO_DONE = 'UPLOAD_DEVICE_INFO_DONE';
export var UPLOAD_DEVICE_INFO_FAIL = 'UPLOAD_DEVICE_INFO_FAIL';
export var SET_APP_BADGE_CALL = 'SET_APP_BADGE_CALL';
export var SET_APP_BADGE_DONE = 'SET_APP_BADGE_DONE';
export var SET_APP_BADGE_FAIL = 'SET_APP_BADGE_FAIL';
export var UPDATE_BROKEN_CAMERA_DEVICE_CALL = 'UPDATE_BROKEN_CAMERA_DEVICE_CALL';
export var CHECK_FOR_UPDATE_CALL = 'CHECK_FOR_UPDATE_CALL';
export var CHECK_FOR_UPDATE_DONE = 'CHECK_FOR_UPDATE_DONE';
export var CHECK_FOR_UPDATE_FAIL = 'CHECK_FOR_UPDATE_FAIL';