export var sixGroups = ['fruits', 'vegetables', 'grains', 'meat_beans_low_fat', 'meat_beans_medium_fat', 'meat_beans_high_fat', 'meat_beans_super_high_fat', 'milk_skim', 'milk_low_fat', 'milk_whole_fat', 'oil'];
export var threeBig = ['protein', 'fat', 'carbohydrate'];
export var threeBigPercentage = ['protein_percentage', 'fat_percentage', 'carbohydrate_percentage'];
export var calorie = ['calorie'];
export var hasitem = ['hasitem'];
export var NutritionConstant = {
  THREE_BIG: threeBig,
  THREE_BIG_AND_SUGAR: [].concat(threeBig, ['sugar']),
  THREE_BIG_PERCENTAGE: threeBigPercentage,
  THREE_BIG_AND_CALORIE: [].concat(calorie, threeBig),
  THREE_BIG_AND_PERCENTAGE_AND_CALORIE: [].concat(calorie, threeBig, threeBigPercentage),
  SIX_GROUPS: sixGroups,
  SIX_GROUPS_AND_SUGAR: [].concat(sixGroups, ['sugar']),
  SIX_GROUPS_AND_THREE_BIG: [].concat(sixGroups, [threeBig]),
  SIX_GROUPS_AND_THREE_BIG_AND_CALORIE: [].concat(sixGroups, threeBig, calorie, hasitem),
  SIX_GROUPS_AND_CALORIE: [].concat(sixGroups, calorie),
  SIX_GROUPS_TO_THREE_BIG_WEIGHT: {
    milk_whole_fat: {
      protein: 8,
      carbohydrate: 12,
      fat: 8
    },
    milk_low_fat: {
      protein: 8,
      carbohydrate: 12,
      fat: 4
    },
    milk_skim: {
      protein: 8,
      carbohydrate: 12,
      fat: 0
    },
    meat_beans_low_fat: {
      protein: 7,
      carbohydrate: 0,
      fat: 3
    },
    meat_beans_medium_fat: {
      protein: 7,
      carbohydrate: 0,
      fat: 5
    },
    meat_beans_high_fat: {
      protein: 7,
      carbohydrate: 0,
      fat: 10
    },
    meat_beans_super_high_fat: {
      protein: 7,
      carbohydrate: 0,
      fat: 14
    },
    grains: {
      protein: 2,
      carbohydrate: 15,
      fat: 0
    },
    vegetables: {
      protein: 1,
      carbohydrate: 5,
      fat: 0
    },
    fruits: {
      protein: 0,
      carbohydrate: 15,
      fat: 0
    },
    oil: {
      protein: 0,
      carbohydrate: 0,
      fat: 5
    }
  }
};