import { createAction } from 'redux-actions';
import * as types from '../../store/types';
export var setTargetWeight = createAction(types.SET_TARGET_WEIGHT);
export var setTargetKgMax = createAction(types.SET_TARGET_KGMAX);
export var setTargetCalorie = createAction(types.SET_TARGET_CALORIE);
export var setTargetWater = createAction(types.SET_TARGET_WATER);
export var setTargetCall = createAction(types.SET_TARGET_CALL);
export var setTargetDone = createAction(types.SET_TARGET_DONE);
export var setTargetFail = createAction(types.SET_TARGET_FAIL);
export var getTargetsCall = createAction(types.GET_TARGETS_CALL);
export var getTargetsDone = createAction(types.GET_TARGETS_DONE);
export var getTargetsFail = createAction(types.GET_TARGETS_FAIL);
export var setTargetClient = createAction(types.SET_TARGET_CLIENT);