import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _ from 'lodash';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import * as types from '../types';
import lib from '../../platform';
var Appearance = lib.pfAppearance;
var initialColorScheme = Appearance.getColorScheme && Appearance.getColorScheme();
var initialIsDarkModeEnabled = initialColorScheme === 'dark';
var initialState = {
  colorScheme: initialColorScheme,
  isDarkModeEnabled: initialIsDarkModeEnabled
};
export default handleActions(_defineProperty({}, types.SET_COLOR_SCHEME, function (state, _ref) {
  var colorScheme = _ref.payload.colorScheme;
  return produce(state, function (draft) {
    var isDarkModeEnabled = colorScheme === 'dark';
    draft.colorScheme = colorScheme;
    draft.isDarkModeEnabled = isDarkModeEnabled;
  });
}), initialState);