import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
// import store from '../store'
import request from './request';
import lib from '../platform';
import qs from 'qs';
var pfConfig = lib.pfConfig;
var api = pfConfig.api,
    storeId = pfConfig.shop.storeId;
var apiUrl = api.apiUrl,
    socketUrl = api.socketUrl,
    shopUrl = api.shopUrl;
var store;

var getHeader = function getHeader(_ref) {
  var isShop = _ref.isShop;
  var token = '';

  try {
    var state = store.getState();

    if (isShop) {
      if (state.session.shopToken) {
        token = state.session.shopToken;
      }
    } else {
      if (state.session.accessToken) {
        token = state.session.accessToken;
      }
    } //改token
    //token = ''

  } catch (e) {
    token = '';
  }

  return {
    Authorization: "Bearer ".concat(token),
    token: "".concat(token)
  };
};

var _getUrl = function getUrl(url, baseUrl) {
  return baseUrl.concat(url);
};

var slackUrl = 'https://hooks.slack.com/services/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'; //searchLocation api

var searchGoogleKey = 'AIzaSyBH1vnw69UWBSU_7VslDUALhXbvK4C54eM';
var searchAmapKey = '53534ee4701ca2a8eebbe0820fcf8e79';
var googleUrl = 'https://maps.googleapis.com/maps/api';
var amapUrl = 'http://restapi.amap.com/v3';
export var setStore = function setStore(reduxStore) {
  store = reduxStore;
};
export default {
  setStore: setStore,
  get: function get(url, data) {
    return request('GET', _getUrl(url, apiUrl), data, getHeader({}));
  },
  post: function post(url, data) {
    return request('POST', _getUrl(url, apiUrl), data, getHeader({}));
  },
  uploadDirect: function uploadDirect(url, data, header) {
    return request('POST', url, data, header, {
      // Content-Type needs to be removed for Gcloud Storage upload!!
      transformRequest: [function (d, h) {
        if (h && h.common) delete h.common['Content-Type'];
        return d;
      }]
    });
  },
  upload: function upload(url, data) {
    return request('POST', _getUrl(url, apiUrl), data, getHeader({}));
  },
  delete: function _delete(url, data) {
    return request('DELETE', _getUrl(url, apiUrl), data, getHeader({}));
  },
  put: function put(url, data) {
    return request('PUT', _getUrl(url, apiUrl), data, getHeader({}));
  },
  header: function header() {
    return getHeader({});
  },
  getUrl: function getUrl(url, apiUrl) {
    return _getUrl(url, apiUrl);
  },
  socketUrl: socketUrl,
  getSearchGoogleLocationApi: function getSearchGoogleLocationApi(url, data) {
    return request('GET', _getUrl(url, googleUrl), _objectSpread({}, data, {
      key: searchGoogleKey
    }));
  },
  getSearchAmapLocationApi: function getSearchAmapLocationApi(url, data) {
    return request('GET', _getUrl(url, amapUrl), _objectSpread({}, data, {
      key: searchAmapKey
    }));
  },
  postToSlack: function postToSlack(data) {
    return request('POST', slackUrl, data);
  },
  getShopApi: function getShopApi(url, data) {
    return request('GET', _getUrl(url, shopUrl), _objectSpread({}, data, {
      store_id: storeId
    }), getHeader({
      isShop: true
    }));
  },
  postShopApi: function postShopApi(url, data, isJson) {
    var newData = _objectSpread({}, data, {
      store_id: storeId
    });

    var dataObj = isJson ? newData : qs.stringify(newData);
    return request('POST', _getUrl(url, shopUrl), dataObj, getHeader({
      isShop: true
    }));
  }
};