import { createAction } from 'redux-actions';
import * as types from '../../store/types';
export var getPricingsCall = createAction(types.GET_PRICINGS_CALL);
export var getPricingsDone = createAction(types.GET_PRICINGS_DONE);
export var getPricingsFail = createAction(types.GET_PRICINGS_FAIL);
export var setPricing = createAction(types.SET_PRICINGS);
export var registerCodeCall = createAction(types.REGISTER_CODE_CALL);
export var registerCodeDone = createAction(types.REGISTER_CODE_DONE);
export var registerCodeFail = createAction(types.REGISTER_CODE_FAIL);
export var checkRegisterCodeCall = createAction(types.CHECK_REGISTER_CODE_CALL);
export var checkRegisterCodeDone = createAction(types.CHECK_REGISTER_CODE_DONE);
export var checkRegisterCodeFail = createAction(types.CHECK_REGISTER_CODE_FAIL);
export var reSetRegisterCodeError = createAction(types.RESET_REGISTER_CODE_ERROR);