import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _handleActions;

import _ from 'lodash';
import produce from 'immer';
import { handleActions, combineActions } from 'redux-actions';
import * as types from '../types';
export default handleActions((_handleActions = {}, _defineProperty(_handleActions, types.GET_COMPANY_LIST_DONE, function (state, _ref) {
  var branches = _ref.payload.branches;
  return produce(state, function (draft) {
    draft.companyList = branches;
    draft.companyInfo = _.find(branches, {
      branch_code: state.branchCode
    }) || state.companyInfo;
  });
}), _defineProperty(_handleActions, types.SET_COMPANY_INFO, function (state, _ref2) {
  var branchCode = _ref2.payload.branchCode;
  return produce(state, function (draft) {
    draft.companyInfo = _.find(state.companyList, {
      branch_code: branchCode
    }) || state.companyInfo;
    draft.branchCode = branchCode;
  });
}), _handleActions), {
  branchCode: '',
  companyList: [],
  companyInfo: {
    branch_code: '',
    branch_short_name: '',
    branch_time: '',
    phone: '',
    address: ''
  }
});