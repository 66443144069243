import _toConsumableArray from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _ from 'lodash';
import { combineEpics } from 'redux-observable';
import * as activity from './activity';
import * as article from './article';
import * as chat from './chat';
import * as multipleChat from './multipleChat';
import * as client from './client';
import * as device from './device';
import * as diary from './diary';
import * as essays from './essays';
import * as explore from './explore';
import * as factory from './factory';
import * as food from './food';
import * as group from './group';
import * as imagesViewer from './imagesViewer';
import * as integral from './integral';
import * as invoice from './invoice';
import * as message from './message';
import * as member from './member';
import * as note from './note';
import * as notification from './notification';
import * as post from './post';
import * as profile from './profile';
import * as searchLocation from './searchLocation';
import * as session from './session';
import * as shop from './shop';
import * as storage from './storage';
import * as target from './target';
import * as user from './user';
import * as question from './question';
import * as share from './share';
import * as company from './company';
var epicGroups = [activity, article, chat, client, company, device, diary, essays, explore, factory, food, group, imagesViewer, integral, invoice, message, member, multipleChat, note, notification, post, profile, searchLocation, session, shop, storage, target, user, question, share]; // turning array of epic keys to functions

var allEpics = _.chain(epicGroups).map(function (group) {
  return _.map(Object.keys(group), function (key) {
    return group[key];
  });
}).flatMap().value();

var rootEpic = combineEpics.apply(void 0, _toConsumableArray(allEpics));
export default rootEpic;