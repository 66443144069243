import api from '../api';
export var getMe = function getMe() {
  return api.get('/v4/users/me');
};
export var updateMe = function updateMe(data) {
  return api.put('/v4/users/me', data);
};
export var getUnreadCount = function getUnreadCount() {
  return api.get('/v4/users/me/unread_count');
};
export var uploadDeviceInfo = function uploadDeviceInfo(payload) {
  return api.post('/v4/users/me/update_device', payload);
};
export var getChatRooms = function getChatRooms() {
  return api.get('/v4/users/me/chatrooms');
};
export var changePassword = function changePassword(payload) {
  return api.post('/v4/users/me/change_password', payload);
};