import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _ from 'lodash';
import { NutritionConstant, Util } from '../../utils';

var sixGroupsToMacros = function sixGroupsToMacros(sixGroups) {
  var protein = 0;
  var carbohydrate = 0;
  var fat = 0;

  var _arr = Object.keys(sixGroups);

  for (var _i = 0; _i < _arr.length; _i++) {
    var key = _arr[_i];
    var value = sixGroups[key] ? sixGroups[key] : 0;
    protein += value * NutritionConstant.SIX_GROUPS_TO_MACROS[key].protein;
    carbohydrate += value * NutritionConstant.SIX_GROUPS_TO_MACROS[key].carbohydrate;
    fat += value * NutritionConstant.SIX_GROUPS_TO_MACROS[key].fat;
  }

  return {
    protein: protein,
    carbohydrate: carbohydrate,
    fat: fat
  };
};

var calcCalorieByMacros = function calcCalorieByMacros(protein, carbohydrate, fat) {
  var sugar = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  return (protein || 0) * 4 + (carbohydrate || 0) * 4 + (fat || 0) * 9 + (sugar || 0) * 4;
};

var calcMacrosPercentage = function calcMacrosPercentage(protein, carbohydrate, fat) {
  var pos = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1;
  var calorie = calcCalorieByMacros(protein, carbohydrate, fat);
  var proteinPercentage = 0;
  var carbohydratePercentage = 0;
  var fatPercentage = 0;
  proteinPercentage = calorie === 0 ? 0 : Util.round(protein * 4 / calorie * 100, pos);
  carbohydratePercentage = calorie === 0 ? 0 : Util.round(carbohydrate * 4 / calorie * 100, pos);
  fatPercentage = calorie === 0 ? 0 : Util.round(100 - proteinPercentage - carbohydratePercentage, pos);
  return {
    protein_percentage: proteinPercentage,
    carbohydrate_percentage: carbohydratePercentage,
    fat_percentage: fatPercentage
  };
};

export var calcNutrients = function calcNutrients(type, amount, item) {
  var pos = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1;

  switch (true) {
    case type === 'calorie':
      return Util.round(item[type] * amount * item.multiply_ratio, pos);

    case _.includes(NutritionConstant.SIXGROUPS, type):
      return calcSixGroupWeight(type, amount, item);

    case type === 'fatty_acids_total_polyunsaturated':
    case type === 'fatty_acids_total_monounsaturated':
      return Util.round(item.fat * item[type] / 100, 1);
  }

  var val = _.has(item, type) ? Util.round(item[type] * amount * item.multiply_ratio, pos) : 0;
  return val < 0 ? 0 : val;
};

var calcSixGroupWeight = function calcSixGroupWeight(type, amount, item) {
  if (!item[type]) {
    return 0;
  }

  if (item.display_factor === 2 && item.six_group_factor === 1) {
    return Util.round(item[type] * amount * item.serving_weight / item.ex_weight);
  }

  return Util.round(item[type] * amount);
};

export var calcC63PByFoodStuffs = function calcC63PByFoodStuffs(amount) {
  var item = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var _item$macros = item.macros,
      macros = _item$macros === void 0 ? {} : _item$macros,
      _item$six_groups = item.six_groups,
      six_groups = _item$six_groups === void 0 ? {} : _item$six_groups;

  var itemToCal = _objectSpread({}, item, macros, six_groups);

  var macrosPercentage = calcMacrosPercentage(itemToCal.protein, itemToCal.carbohydrate, itemToCal.fat, 0);

  var result = _.reduce(NutritionConstant.C63, function (memo, type) {
    var typeVal = calcNutrients(type, amount, itemToCal, 2);
    return _.assign(memo, typeVal ? _defineProperty({}, type, typeVal) : {});
  }, {});

  return _objectSpread({}, result, macrosPercentage);
};
export var calcC63PBySixGroups = function calcC63PBySixGroups(sixGroups) {
  var _sixGroupsToMacros = sixGroupsToMacros(_.pick(sixGroups, NutritionConstant.SIXGROUPS)),
      protein = _sixGroupsToMacros.protein,
      carbohydrate = _sixGroupsToMacros.carbohydrate,
      fat = _sixGroupsToMacros.fat;

  var macrosPercentage = calcMacrosPercentage(protein, carbohydrate, fat, 0);
  var calorie = calcCalorieByMacros(protein, carbohydrate, fat);
  return _objectSpread({
    calorie: calorie
  }, sixGroups, {
    protein: protein,
    carbohydrate: carbohydrate,
    fat: fat
  }, macrosPercentage);
};