import * as Storage from './storage'
// import CommonStyle from '../styles/Common.style'
import config from './config'
import { setup, setupI18n } from 'cflib/platform'

export default {
  setup: () => {
    setup(
      {
        Analytics: {},
        Appearance: {},
        Asset: {},
        Device: {},
        Update: {},
        Storage,
        Media: {},
        Monitor: {},
        Session: {},
        CommonStyle: {},
        config,
        myCacheManager: {},
        Wechat: {},
      },
    )
  },
  setupI18n: (i18n) => {
    setupI18n(i18n)
  }
}
