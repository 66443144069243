import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";

var _handleActions;

import _ from 'lodash';
import produce from 'immer';
import { combineActions, handleActions } from 'redux-actions';
import * as types from '../types';
import { goodsFormat, igGoodsFormat, giftInfoFormat } from '../../store/selectors';
import lib from '../../platform';
var i18n = lib.pfI18n,
    config = lib.pfConfig;
var imageWebServer = config.shop.imageWebServer;

var uniqId = function uniqId(_ref) {
  var list = _ref.list;
  return _.chain(list).uniqBy('id').value();
};

var defaultList = {
  list: [],
  more: false,
  showLoading: true,
  showRefreshing: false,
  showLoadMoreing: false,
  scrollToTop: false
};
var defaultSettlement = {
  gcList: [],
  sumPrice: 0,
  storeGoodsPrice: 0,
  cartSession: '',
  addrs: undefined
};
var defaultPayError = {
  show: false,
  isCancel: false,
  errorCode: undefined
};
var initialData = {
  dataReady: false,
  tabGoods: {
    recommend: defaultList,
    new: defaultList,
    set: defaultList
  },
  tabIndex: 0,
  tabKey: 'recommend',
  advs: [],
  sysconfig: {},
  imageWebServer: imageWebServer,
  currencyCode: '¥',
  shoppingCart: [],
  shoppingCartLoading: true,
  shoppingCartRefreshing: false,
  settlement: defaultSettlement,
  user: {
    integral: 0,
    addrs: [],
    defaultAddrs: undefined
  },
  orderListIsIgType: false,
  orderList: {
    all: defaultList,
    pay: defaultList,
    ship: defaultList,
    receipt: defaultList,
    evaluation: defaultList,
    complete: defaultList
  },
  payError: defaultPayError,
  transInfo: [],
  orderData: {},
  orderCount: {},
  evaluateSession: undefined,
  serverList: [],
  integral: {
    goods: defaultList
  },
  goodsEva: defaultList,
  orderReturnList: defaultList,
  orderReturnLog: defaultList,
  returnGoodsHistoryList: defaultList,
  integralInfoList: {
    all: defaultList,
    get: defaultList,
    cost: defaultList
  },
  integralRulesList: [],
  integralTaskData: {
    list: [],
    showRefreshing: false,
    showLoading: false
  },
  expressCompanys: [],
  vatInvoice: undefined
};

var advsFormat = function advsFormat(advs, imageWebServer) {
  var newAdvs = _.map(advs, function (adv) {
    var acc = adv.acc;
    var path = acc.path,
        name = acc.name;
    var uri = "".concat(imageWebServer, "/").concat(path, "/").concat(name);
    return _objectSpread({}, adv, {
      uri: uri
    });
  });

  return newAdvs;
};

var orderGoodsFormat = function orderGoodsFormat(item, imageWebServer, currencyCode) {
  var goods_mainphoto_path = item.goods_mainphoto_path,
      goods_all_price = item.goods_all_price,
      goods_price = item.goods_price;
  var uri = "".concat(imageWebServer, "/").concat(goods_mainphoto_path);

  var price = _.round(goods_price, 2);

  var allPrice = _.round(goods_all_price, 2);

  var priceText = "".concat(currencyCode, " ").concat(price);
  var allPriceText = "".concat(currencyCode, " ").concat(allPrice);
  var mainPhoto = {
    uri: uri
  };
  return _objectSpread({}, item, {
    mainPhoto: mainPhoto,
    priceText: priceText,
    allPriceText: allPriceText
  });
};

export var igOrderGoodsFormat = function igOrderGoodsFormat(item, imageWebServer, currencyCode) {
  var t = i18n.getFixedT();
  var igText = t('shop:integral.title');
  var ig_goods_integral = item.ig_goods_integral,
      ig_goods_img = item.ig_goods_img,
      ig_goods_count = item.ig_goods_count,
      ig_goods_name = item.ig_goods_name;
  var uri = "".concat(imageWebServer, "/").concat(ig_goods_img);

  var price = _.round(ig_goods_integral / ig_goods_count, 2);

  var allPrice = _.round(ig_goods_integral, 2);

  var priceText = "".concat(igText, " ").concat(price);
  var allPriceText = "".concat(igText, " ").concat(allPrice);
  var mainPhoto = {
    uri: uri
  };
  return _objectSpread({}, item, {
    mainPhoto: mainPhoto,
    priceText: priceText,
    allPriceText: allPriceText,
    goods_count: ig_goods_count,
    goods_name: ig_goods_name
  });
};
export default handleActions((_handleActions = {}, _defineProperty(_handleActions, types.GET_SHOP_INDEX_CALL, function (state, _ref2) {
  var _ref2$payload = _ref2.payload,
      tabKey = _ref2$payload.tabKey,
      loadingType = _ref2$payload.loadingType,
      scrollToTop = _ref2$payload.scrollToTop;
  return produce(state, function (draft) {
    draft.tabGoods[tabKey].scrollToTop = scrollToTop === true;
    draft.tabGoods[tabKey].showLoading = true;
    draft.tabGoods[tabKey].showRefreshing = loadingType === 'refresh';
    draft.tabGoods[tabKey].showLoadMoreing = loadingType === 'loadMore';
  });
}), _defineProperty(_handleActions, types.GET_SHOP_INDEX_DONE, function (state, _ref3) {
  var _ref3$payload = _ref3.payload,
      code = _ref3$payload.code,
      data = _ref3$payload.data,
      tabKey = _ref3$payload.tabKey,
      loadingType = _ref3$payload.loadingType;
  return produce(state, function (draft) {
    if (code === 200) {
      var slides = data.slides,
          listGoods = data.list_goods,
          sysconfig = data.sysconfig,
          hasNext = data.hasNext,
          ms_list = data.ms_list;
      var _imageWebServer = sysconfig.imageWebServer,
          currencyCode = sysconfig.currency_code;
      draft.currencyCode = currencyCode;
      draft.imageWebServer = _imageWebServer;
      draft.sysconfig = sysconfig;
      draft.serverList = ms_list;
      draft.dataReady = true;
      draft.advs = advsFormat(slides, _imageWebServer);

      var goods = _.map(listGoods, function (item) {
        return goodsFormat({
          item: item,
          imageWebServer: _imageWebServer,
          currencyCode: currencyCode
        });
      });

      if (loadingType === 'loadMore') {
        var list = _.concat(draft.tabGoods[tabKey].list, goods);

        draft.tabGoods[tabKey].list = uniqId({
          list: list
        });
      } else {
        draft.tabGoods[tabKey].list = uniqId({
          list: goods
        });
      }

      draft.tabGoods[tabKey].more = hasNext === 1;
      draft.tabGoods[tabKey].showLoading = false;
      draft.tabGoods[tabKey].showRefreshing = false;
      draft.tabGoods[tabKey].showLoadMoreing = false;
      draft.tabGoods[tabKey].scrollToTop = false;
    }
  });
}), _defineProperty(_handleActions, types.GET_SHOP_INDEX_FAIL, function (state, _ref4) {
  var tabKey = _ref4.payload.tabKey;
  return produce(state, function (draft) {
    draft.dataReady = false;
    draft.tabGoods[tabKey].showLoading = false;
    draft.tabGoods[tabKey].showRefreshing = false;
    draft.tabGoods[tabKey].showLoadMoreing = false;
    draft.tabGoods[tabKey].scrollToTop = false;
  });
}), _defineProperty(_handleActions, types.GET_ORDER_LIST_CALL, function (state, _ref5) {
  var _ref5$payload = _ref5.payload,
      _ref5$payload$isIgTyp = _ref5$payload.isIgType,
      isIgType = _ref5$payload$isIgTyp === void 0 ? false : _ref5$payload$isIgTyp,
      tabKey = _ref5$payload.tabKey,
      loadingType = _ref5$payload.loadingType;
  return produce(state, function (draft) {
    if (draft.orderListIsIgType !== isIgType) {
      draft.orderListIsIgType = isIgType;
      draft.orderList[tabKey].list = [];
    }

    draft.orderList[tabKey].showLoading = true;
    draft.orderList[tabKey].showRefreshing = loadingType === 'refresh';
    draft.orderList[tabKey].showLoadMoreing = loadingType === 'loadMore';
  });
}), _defineProperty(_handleActions, types.GET_ORDER_LIST_DONE, function (state, _ref6) {
  var _ref6$payload = _ref6.payload,
      isIgType = _ref6$payload.isIgType,
      code = _ref6$payload.code,
      data = _ref6$payload.data,
      tabKey = _ref6$payload.tabKey,
      loadingType = _ref6$payload.loadingType;
  return produce(state, function (draft) {
    if (code !== '400') {
      var t = i18n.getFixedT();
      var _imageWebServer2 = state.imageWebServer,
          currencyCode = state.currencyCode;
      var OrderForm = data.OrderForm,
          integralGoodsOrderList = data.integralGoodsOrderList,
          hasNext = data.hasNext;
      var orderList = isIgType ? integralGoodsOrderList : OrderForm;

      var list = _.map(orderList, function (item) {
        var goods_info = item.goods_info,
            totalPrice = item.totalPrice,
            igo_total_integral = item.igo_total_integral,
            igo_status = item.igo_status,
            order_status = item.order_status,
            ship_price = item.ship_price,
            igo_trans_fee = item.igo_trans_fee,
            gift_infos_json = item.gift_infos_json;
        var goods = JSON.parse(goods_info);
        var igText = t('shop:integral.title');
        var unit = isIgType ? igText : currencyCode;
        var goodsAmount = isIgType ? igo_total_integral : totalPrice;
        var goodsAmountText = "".concat(unit, " ").concat(goodsAmount);
        var shipPrice = isIgType ? igo_trans_fee : ship_price;
        var goodFormat = isIgType ? igOrderGoodsFormat : orderGoodsFormat;

        var goodsInfo = _.map(goods, function (item) {
          return goodFormat(item, _imageWebServer2, currencyCode);
        });

        var giftsInfoList = [];

        if (gift_infos_json && !isIgType) {
          giftsInfoList = _.map(gift_infos_json, function (item) {
            return giftInfoFormat({
              item: item,
              imageWebServer: _imageWebServer2,
              currencyCode: currencyCode
            });
          });
        }

        var orderStatus = isIgType ? igo_status : order_status;
        return _objectSpread({}, item, {
          goodsAmountText: goodsAmountText,
          goodsInfo: goodsInfo,
          giftsInfoList: giftsInfoList,
          isIgType: isIgType,
          orderStatus: orderStatus,
          shipPrice: shipPrice
        });
      });

      if (loadingType === 'loadMore') {
        var newList = _.concat(draft.orderList[tabKey].list, list);

        draft.orderList[tabKey].list = uniqId({
          list: newList
        });
      } else {
        draft.orderList[tabKey].list = uniqId({
          list: list
        });
      }

      draft.orderList[tabKey].more = hasNext === 1;
      draft.orderList[tabKey].showLoading = false;
      draft.orderList[tabKey].showRefreshing = false;
      draft.orderList[tabKey].showLoadMoreing = false;
    }
  });
}), _defineProperty(_handleActions, types.GET_ORDER_LIST_FAIL, function (state, _ref7) {
  var tabKey = _ref7.payload.tabKey;
  return produce(state, function (draft) {
    draft.orderList[tabKey].showLoading = false;
    draft.orderList[tabKey].showRefreshing = false;
    draft.orderList[tabKey].showLoadMoreing = false;
  });
}), _defineProperty(_handleActions, combineActions(types.GET_GOODS_CART_CALL, types.GET_IG_GOODS_CART_CALL), function (state, _ref8) {
  var loadingType = _ref8.payload.loadingType;
  return produce(state, function (draft) {
    draft.shoppingCartLoading = loadingType === 'first';
    draft.shoppingCartRefreshing = loadingType === 'refresh';

    if (loadingType === 'first') {
      draft.shoppingCart = [];
    }
  });
}), _defineProperty(_handleActions, combineActions(types.GET_GOODS_CART_FAIL, types.GET_IG_GOODS_CART_FAIL), function (state, _ref9) {
  var loadingType = _ref9.payload.loadingType;
  return produce(state, function (draft) {
    draft.shoppingCartLoading = false;
    draft.shoppingCartRefreshing = false;
  });
}), _defineProperty(_handleActions, types.GET_GOODS_CART_DONE, function (state, _ref10) {
  var _ref10$payload = _ref10.payload,
      res = _ref10$payload.res,
      selectId = _ref10$payload.selectId;
  var code = res.code,
      data = res.data;
  return produce(state, function (draft) {
    if (code === 200) {
      var _imageWebServer3 = state.imageWebServer,
          currencyCode = state.currencyCode;
      var _data$cart = data.cart,
          cart = _data$cart === void 0 ? [] : _data$cart,
          _data$bg_goods = data.bg_goods,
          bg_goods = _data$bg_goods === void 0 ? [] : _data$bg_goods;

      var bgGoods = _.flattenDeep(_.map(bg_goods, function (_ref11) {
        var list = _ref11.list,
            buy_gift_msg = _ref11.buy_gift_msg;
        return _.map(list, function (item, index) {
          var bgId = buy_gift_msg.id;
          var showGroupBuyGift = index === 0;
          return _objectSpread({}, item, {
            isBuyGift: true,
            bgId: bgId,
            showGroupBuyGift: showGroupBuyGift,
            checkedBgGoodsId: undefined,
            checkedBgGoodsName: undefined
          });
        });
      }));

      var list = _.concat(cart, bgGoods);

      draft.shoppingCart = _.map(list, function (item) {
        var goods = item.goods;
        var id = goods.id;
        var checked = id === selectId;
        return _objectSpread({}, item, {
          goods: goodsFormat({
            item: goods,
            imageWebServer: _imageWebServer3,
            currencyCode: currencyCode
          }),
          checked: checked
        });
      });
      draft.shoppingCartLoading = false;
      draft.shoppingCartRefreshing = false;
    }
  });
}), _defineProperty(_handleActions, types.SET_GOODS_CARTS, function (state, _ref12) {
  var shoppingCart = _ref12.payload.shoppingCart;
  return produce(state, function (draft) {
    draft.shoppingCart = shoppingCart;
  });
}), _defineProperty(_handleActions, types.SET_SHOP_TAB_INDEX, function (state, _ref13) {
  var _ref13$payload = _ref13.payload,
      tabIndex = _ref13$payload.tabIndex,
      tabKey = _ref13$payload.tabKey;
  return produce(state, function (draft) {
    draft.tabIndex = tabIndex;
    draft.tabKey = tabKey;
  });
}), _defineProperty(_handleActions, types.SETTLEMENT_CALL, function (state, _ref14) {
  var payload = _ref14.payload;
  return produce(state, function (draft) {
    draft.settlement = defaultSettlement;
    draft.payError = defaultPayError;
  });
}), _defineProperty(_handleActions, types.SETTLEMENT_DONE, function (state, _ref15) {
  var _ref15$payload = _ref15.payload,
      code = _ref15$payload.code,
      data = _ref15$payload.data;
  return produce(state, function (draft) {
    if (code != 400) {
      var _imageWebServer4 = state.imageWebServer,
          currencyCode = state.currencyCode;
      var user = data.user,
          map_list = data.map_list,
          _data$addrs = data.addrs,
          addrs = _data$addrs === void 0 ? [] : _data$addrs,
          cart_session = data.cart_session,
          gcs = data.gcs,
          _data$gifts_info = data.gifts_info,
          gifts_info = _data$gifts_info === void 0 ? [] : _data$gifts_info,
          gifts_new = data.gifts_new;
      var listData = map_list[0];

      if (listData) {
        var gc_list = listData.gc_list,
            storeGoodsPrice = listData.store_goods_price;

        var giftsInfoList = _.map(gifts_info, function (item) {
          return _objectSpread({}, item, {
            goods: item,
            isGiftsGoods: true
          });
        });

        var sumTransfee = _.sumBy(gc_list, function (_ref16) {
          var goods = _ref16.goods;
          var goods_transfee = goods.goods_transfee,
              express_trans_fee = goods.express_trans_fee;

          if (goods_transfee === 1) {
            return 0;
          } else {
            return express_trans_fee;
          }
        });

        var list = _.concat(gc_list, giftsInfoList);

        var gcList = _.map(list, function (item) {
          var goods = item.goods;
          return _objectSpread({}, item, {
            goods: goodsFormat({
              item: goods,
              imageWebServer: _imageWebServer4,
              currencyCode: currencyCode
            })
          });
        });

        var sumPrice = storeGoodsPrice + sumTransfee;
        var defaultAddrs = addrs[0];
        var settlement = {
          gcs: gcs,
          gifts: gifts_new,
          gcList: gcList,
          storeGoodsPrice: storeGoodsPrice,
          sumTransfee: sumTransfee,
          sumPrice: sumPrice,
          cartSession: cart_session
        };
        draft.settlement = settlement;
        draft.user = _objectSpread({}, user, {
          addrs: addrs,
          defaultAddrs: defaultAddrs
        });
      }
    }
  });
}), _defineProperty(_handleActions, types.REMOVE_GOODS_CART_DONE, function (state, _ref17) {
  var newShoppingCart = _ref17.payload.newShoppingCart;
  return produce(state, function (draft) {
    draft.shoppingCart = newShoppingCart;
  });
}), _defineProperty(_handleActions, types.ADD_ORDER_CALL, function (state, _ref18) {
  var payload = _ref18.payload;
  return produce(state, function (draft) {
    draft.orderData = {};
  });
}), _defineProperty(_handleActions, types.ADD_ORDER_DONE, function (state, _ref19) {
  var order = _ref19.payload.order;
  return produce(state, function (draft) {
    draft.orderData = order;
  });
}), _defineProperty(_handleActions, types.GET_SHIP_DETAIL_CALL, function (state, _ref20) {
  var payload = _ref20.payload;
  return produce(state, function (draft) {
    draft.transInfo = [];
  });
}), _defineProperty(_handleActions, types.GET_SHIP_DETAIL_DONE, function (state, _ref21) {
  var _ref21$payload = _ref21.payload,
      data = _ref21$payload.data,
      isIgType = _ref21$payload.isIgType;
  return produce(state, function (draft) {
    var _data$transInfo_list = data.transInfo_list,
        transInfo_list = _data$transInfo_list === void 0 ? [] : _data$transInfo_list,
        _data$transInfo = data.transInfo,
        transInfo = _data$transInfo === void 0 ? {} : _data$transInfo;

    if (isIgType) {
      draft.transInfo = transInfo;
    } else if (transInfo_list.length > 0) {
      draft.transInfo = transInfo_list[0];
    }
  });
}), _defineProperty(_handleActions, types.SET_PAY_ERROR, function (state, _ref22) {
  var payload = _ref22.payload;
  return produce(state, function (draft) {
    draft.payError = payload;
  });
}), _defineProperty(_handleActions, types.ORDER_STORE_REMOVE, function (state, _ref23) {
  var payload = _ref23.payload;
  return produce(state, function (draft) {
    var param = payload.param,
        selectTab = payload.selectTab;
    var id = param.id;
    var key = selectTab.key;

    var _ref24 = draft.orderList[key] || {
      liet: []
    },
        list = _ref24.list;

    _.remove(list, {
      id: id
    });
  });
}), _defineProperty(_handleActions, types.GET_ORDER_COUNT_DONE, function (state, _ref25) {
  var _ref25$payload = _ref25.payload,
      data = _ref25$payload.data,
      code = _ref25$payload.code;
  return produce(state, function (draft) {
    if (code === 200) {
      draft.orderCount = data;
    }
  });
}), _defineProperty(_handleActions, types.GET_ORDER_DISCUSS_CALL, function (state, _ref26) {
  var payload = _ref26.payload;
  return produce(state, function (draft) {
    draft.evaluateSession = undefined;
  });
}), _defineProperty(_handleActions, types.GET_ORDER_DISCUSS_DONE, function (state, _ref27) {
  var _ref27$payload = _ref27.payload,
      data = _ref27$payload.data,
      code = _ref27$payload.code;
  return produce(state, function (draft) {
    if (code === 200) {
      var evaluate_session = data.evaluate_session;
      draft.evaluateSession = evaluate_session;
    }
  });
}), _defineProperty(_handleActions, types.GET_GOODS_EVA_CALL, function (state, _ref28) {
  var loadingType = _ref28.payload.loadingType;
  return produce(state, function (draft) {
    if (loadingType === 'first') {
      draft.goodsEva.list = [];
    }

    draft.goodsEva.showLoading = true;
    draft.goodsEva.showRefreshing = loadingType === 'refresh';
    draft.goodsEva.showLoadMoreing = loadingType === 'loadMore';
  });
}), _defineProperty(_handleActions, types.GET_GOODS_EVA_DONE, function (state, _ref29) {
  var _ref29$payload = _ref29.payload,
      data = _ref29$payload.data,
      loadingType = _ref29$payload.loadingType;
  return produce(state, function (draft) {
    var evas = data.eva_objs,
        hasNext = data.hasNext;

    if (loadingType === 'loadMore') {
      var list = _.concat(draft.goodsEva.list, evas);

      draft.goodsEva.list = uniqId({
        list: list
      });
    } else {
      draft.goodsEva.list = uniqId({
        list: evas
      });
    }

    draft.goodsEva.more = hasNext === 1;
    draft.goodsEva.showLoading = false;
    draft.goodsEva.showRefreshing = false;
    draft.goodsEva.showLoadMoreing = false;
  });
}), _defineProperty(_handleActions, types.GET_GOODS_EVA_FAIL, function (state, _ref30) {
  var payload = _ref30.payload;
  return produce(state, function (draft) {
    draft.goodsEva.showLoading = false;
    draft.goodsEva.showRefreshing = false;
    draft.goodsEva.showLoadMoreing = false;
  });
}), _defineProperty(_handleActions, types.GET_SHOP_INTEGRAL_INDEX_CALL, function (state, _ref31) {
  var _ref31$payload = _ref31.payload,
      loadingType = _ref31$payload.loadingType,
      scrollToTop = _ref31$payload.scrollToTop;
  return produce(state, function (draft) {
    draft.integral.goods.showLoading = true;
    draft.integral.goods.showRefreshing = loadingType === 'refresh';
    draft.integral.goods.showLoadMoreing = loadingType === 'loadMore';
  });
}), _defineProperty(_handleActions, types.GET_SHOP_INTEGRAL_INDEX_DONE, function (state, _ref32) {
  var _ref32$payload = _ref32.payload,
      code = _ref32$payload.code,
      data = _ref32$payload.data,
      loadingType = _ref32$payload.loadingType;
  return produce(state, function (draft) {
    if (code === 200) {
      var listGoods = data.integral_goods,
          hasNext = data.hasNext,
          user = data.user;
      var _imageWebServer5 = state.imageWebServer,
          currencyCode = state.currencyCode;
      draft.user = user;

      var goods = _.map(listGoods, function (item) {
        return igGoodsFormat({
          item: item,
          imageWebServer: _imageWebServer5,
          currencyCode: currencyCode
        });
      });

      if (loadingType === 'loadMore') {
        var list = _.concat(draft.integral.goods.list, goods);

        draft.integral.goods.list = uniqId({
          list: list
        });
      } else {
        draft.integral.goods.list = uniqId({
          list: goods
        });
      }

      draft.integral.goods.more = hasNext === 1;
      draft.integral.goods.showLoading = false;
      draft.integral.goods.showRefreshing = false;
      draft.integral.goods.showLoadMoreing = false;
    }
  });
}), _defineProperty(_handleActions, types.GET_SHOP_INTEGRAL_INDEX_FAIL, function (state, _ref33) {
  var payload = _ref33.payload;
  return produce(state, function (draft) {
    draft.integral.goods.showLoading = false;
    draft.integral.goods.showRefreshing = false;
    draft.integral.goods.showLoadMoreing = false;
  });
}), _defineProperty(_handleActions, types.GET_IG_GOODS_CART_DONE, function (state, _ref34) {
  var _ref34$payload = _ref34.payload,
      res = _ref34$payload.res,
      selectId = _ref34$payload.selectId;
  var code = res.code,
      data = res.data;
  return produce(state, function (draft) {
    if (code === 200) {
      var _imageWebServer6 = state.imageWebServer,
          currencyCode = state.currencyCode;
      var integral_cart = data.integral_cart;
      draft.shoppingCart = _.map(integral_cart, function (item) {
        var goods = item.goods,
            integral = item.integral;
        var id = goods.id;
        var checked = id === selectId;
        return _objectSpread({}, item, {
          price: integral,
          goods: igGoodsFormat({
            item: goods,
            imageWebServer: _imageWebServer6,
            currencyCode: currencyCode
          }),
          id: id,
          checked: checked
        });
      });
      draft.shoppingCartLoading = false;
      draft.shoppingCartRefreshing = false;
    }
  });
}), _defineProperty(_handleActions, types.IG_SETTLEMENT_CALL, function (state, _ref35) {
  var payload = _ref35.payload;
  return produce(state, function (draft) {
    draft.settlement = defaultSettlement;
    draft.payError = defaultPayError;
  });
}), _defineProperty(_handleActions, types.IG_SETTLEMENT_DONE, function (state, _ref36) {
  var _ref36$payload = _ref36.payload,
      code = _ref36$payload.code,
      data = _ref36$payload.data;
  return produce(state, function (draft) {
    if (code != 400) {
      var _imageWebServer7 = state.imageWebServer,
          currencyCode = state.currencyCode;
      var _data$addrs2 = data.addrs,
          addrs = _data$addrs2 === void 0 ? [] : _data$addrs2,
          igcs = data.igcs,
          integral_order_session = data.integral_order_session;

      var gcList = _.map(igcs, function (item) {
        var goods = item.goods;
        return _objectSpread({}, item, {
          goods: igGoodsFormat({
            item: goods,
            imageWebServer: _imageWebServer7,
            currencyCode: currencyCode
          })
        });
      });

      var sumTransfee = _.sumBy(gcList, function (_ref37) {
        var goods = _ref37.goods;
        var goods_transfee = goods.goods_transfee,
            express_trans_fee = goods.express_trans_fee;

        if (goods_transfee === 1) {
          return 0;
        } else {
          return express_trans_fee;
        }
      });

      var storeGoodsPrice = _.sumBy(gcList, function (_ref38) {
        var goods = _ref38.goods;
        var ig_goods_integral = goods.ig_goods_integral;
        return ig_goods_integral;
      });

      var defaultAddrs = addrs[0];
      var settlement = {
        gcList: gcList,
        storeGoodsPrice: storeGoodsPrice,
        sumTransfee: sumTransfee,
        cartSession: integral_order_session
      };
      draft.settlement = settlement;
      draft.user = {
        addrs: addrs,
        defaultAddrs: defaultAddrs
      };
    }
  });
}), _defineProperty(_handleActions, types.IG_ADD_ORDER_CALL, function (state, _ref39) {
  var payload = _ref39.payload;
  return produce(state, function (draft) {
    draft.orderData = {};
  });
}), _defineProperty(_handleActions, types.IG_ADD_ORDER_DONE, function (state, _ref40) {
  var order = _ref40.payload.order;
  return produce(state, function (draft) {
    draft.orderData = order;
  });
}), _defineProperty(_handleActions, types.GET_ORDER_RETURN_LIST_CALL, function (state, _ref41) {
  var loadingType = _ref41.payload.loadingType;
  return produce(state, function (draft) {
    draft.orderReturnList.showLoading = true;
    draft.orderReturnList.showRefreshing = loadingType === 'refresh';
    draft.orderReturnList.showLoadMoreing = loadingType === 'loadMore';
  });
}), _defineProperty(_handleActions, types.GET_ORDER_RETURN_LIST_DONE, function (state, _ref42) {
  var _ref42$payload = _ref42.payload,
      code = _ref42$payload.code,
      data = _ref42$payload.data,
      loadingType = _ref42$payload.loadingType;
  return produce(state, function (draft) {
    if (code === 200) {
      var OrderForm = data.OrderForm,
          hasNext = data.hasNext;
      var _imageWebServer8 = state.imageWebServer,
          currencyCode = state.currencyCode;

      var list = _.map(OrderForm, function (item) {
        var goods_info = item.goods_info;
        var goods = JSON.parse(goods_info);

        var goodsInfo = _.map(goods, function (item) {
          return orderGoodsFormat(item, _imageWebServer8, currencyCode);
        });

        var newGoodsInfo = _.filter(goodsInfo, function (_ref43) {
          var goods_return_status = _ref43.goods_return_status;
          return !goods_return_status || goods_return_status <= 5;
        });

        return _objectSpread({}, item, {
          goodsInfo: newGoodsInfo
        });
      });

      if (loadingType === 'loadMore') {
        var newList = _.concat(draft.orderReturnList.list, list);

        draft.orderReturnList.list = uniqId({
          list: newList
        });
      } else {
        draft.orderReturnList.list = uniqId({
          list: list
        });
      }

      draft.orderReturnList.more = hasNext === 1;
      draft.orderReturnList.showLoading = false;
      draft.orderReturnList.showRefreshing = false;
      draft.orderReturnList.showLoadMoreing = false;
    }
  });
}), _defineProperty(_handleActions, types.GET_ORDER_RETURN_LIST_FAIL, function (state, _ref44) {
  var payload = _ref44.payload;
  return produce(state, function (draft) {
    draft.orderReturnList.showLoading = false;
    draft.orderReturnList.showRefreshing = false;
    draft.orderReturnList.showLoadMoreing = false;
  });
}), _defineProperty(_handleActions, types.GET_ORDER_RETURN_LOG_CALL, function (state, _ref45) {
  var loadingType = _ref45.payload.loadingType;
  return produce(state, function (draft) {
    draft.orderReturnLog.showLoading = true;
    draft.orderReturnLog.showRefreshing = loadingType === 'refresh';
    draft.orderReturnLog.showLoadMoreing = loadingType === 'loadMore';
  });
}), _defineProperty(_handleActions, types.GET_ORDER_RETURN_LOG_DONE, function (state, _ref46) {
  var _ref46$payload = _ref46.payload,
      data = _ref46$payload.data,
      loadingType = _ref46$payload.loadingType;
  return produce(state, function (draft) {
    var ReturnGoodsLog = data.ReturnGoodsLog,
        hasNext = data.hasNext;
    var imageWebServer = state.imageWebServer,
        currencyCode = state.currencyCode;

    var list = _.map(ReturnGoodsLog, function (item) {
      var goodsInfo = orderGoodsFormat(item, imageWebServer, currencyCode);
      return _objectSpread({}, item, goodsInfo);
    });

    if (loadingType === 'loadMore') {
      var newList = _.concat(draft.orderReturnLog.list, list);

      draft.orderReturnLog.list = uniqId({
        list: newList
      });
    } else {
      draft.orderReturnLog.list = uniqId({
        list: list
      });
    }

    draft.orderReturnLog.more = hasNext === 1;
    draft.orderReturnLog.showLoading = false;
    draft.orderReturnLog.showRefreshing = false;
    draft.orderReturnLog.showLoadMoreing = false;
  });
}), _defineProperty(_handleActions, types.GET_ORDER_RETURN_LOG_FAIL, function (state, _ref47) {
  var payload = _ref47.payload;
  return produce(state, function (draft) {
    draft.orderReturnLog.showLoading = false;
    draft.orderReturnLog.showRefreshing = false;
    draft.orderReturnLog.showLoadMoreing = false;
  });
}), _defineProperty(_handleActions, types.GET_INTEGRAL_INFO_LIST_CALL, function (state, _ref48) {
  var _ref48$payload = _ref48.payload,
      isIgType = _ref48$payload.isIgType,
      tabKey = _ref48$payload.tabKey,
      loadingType = _ref48$payload.loadingType;
  return produce(state, function (draft) {
    draft.integralInfoList[tabKey].showLoading = true;
    draft.integralInfoList[tabKey].showRefreshing = loadingType === 'refresh';
    draft.integralInfoList[tabKey].showLoadMoreing = loadingType === 'loadMore';
  });
}), _defineProperty(_handleActions, types.GET_INTEGRAL_INFO_LIST_DONE, function (state, _ref49) {
  var _ref49$payload = _ref49.payload,
      code = _ref49$payload.code,
      data = _ref49$payload.data,
      tabKey = _ref49$payload.tabKey,
      loadingType = _ref49$payload.loadingType;
  return produce(state, function (draft) {
    if (code === 200) {
      var list = data.data,
          hasNext = data.hasNext;

      if (loadingType === 'loadMore') {
        var newList = _.concat(draft.orderList[tabKey].list, list);

        draft.integralInfoList[tabKey].list = uniqId({
          list: newList
        });
      } else {
        draft.integralInfoList[tabKey].list = uniqId({
          list: list
        });
      }

      draft.integralInfoList[tabKey].more = hasNext === 1;
      draft.integralInfoList[tabKey].showLoading = false;
      draft.integralInfoList[tabKey].showRefreshing = false;
      draft.integralInfoList[tabKey].showLoadMoreing = false;
    }
  });
}), _defineProperty(_handleActions, types.GET_INTEGRAL_INFO_LIST_FAIL, function (state, _ref50) {
  var tabKey = _ref50.payload.tabKey;
  return produce(state, function (draft) {
    draft.integralInfoList[tabKey].showLoading = false;
    draft.integralInfoList[tabKey].showRefreshing = false;
    draft.integralInfoList[tabKey].showLoadMoreing = false;
  });
}), _defineProperty(_handleActions, types.GET_ORDER_RETURN_SHIP_DONE, function (state, _ref51) {
  var data = _ref51.payload.data;
  return produce(state, function (draft) {
    var express_companys = data.express_companys;
    draft.expressCompanys = express_companys;
  });
}), _defineProperty(_handleActions, types.GET_ADDRESS_DONE, function (state, _ref52) {
  var data = _ref52.payload.data;
  return produce(state, function (draft) {
    var user = state.user;
    var addrs = data.addrs;
    var defaultAddrs = addrs[0];
    draft.user = _objectSpread({}, user, {
      addrs: addrs,
      defaultAddrs: defaultAddrs
    });
  });
}), _defineProperty(_handleActions, types.GET_ORDER_INVOICE_DONE, function (state, _ref53) {
  var data = _ref53.payload.data;
  return produce(state, function (draft) {
    var vatInvoice = data.vatInvoice;
    draft.vatInvoice = vatInvoice;
  });
}), _defineProperty(_handleActions, types.GET_INTEGRAL_TASK_CALL, function (state, _ref54) {
  var loadingType = _ref54.payload.loadingType;
  return produce(state, function (draft) {
    draft.integralTaskData.showLoading = true;
    draft.integralTaskData.showRefreshing = loadingType === 'refresh';
  });
}), _defineProperty(_handleActions, types.GET_INTEGRAL_TASK_DONE, function (state, _ref55) {
  var data = _ref55.payload.data;
  return produce(state, function (draft) {
    draft.integralTaskData = {
      list: data,
      showRefreshing: false,
      showLoading: false
    };
  });
}), _defineProperty(_handleActions, types.GET_INTEGRAL_TASK_FAIL, function (state, _ref56) {
  var payload = _ref56.payload;
  return produce(state, function (draft) {
    draft.integralTaskData.showLoading = false;
    draft.integralTaskData.showRefreshing = false;
  });
}), _defineProperty(_handleActions, types.GET_INTEGRAL_RULE_DONE, function (state, _ref57) {
  var _ref57$payload = _ref57.payload,
      code = _ref57$payload.code,
      data = _ref57$payload.data;
  return produce(state, function (draft) {
    if (code === 200) {
      var integralRulesList = data.integralRulesList;
      draft.integralRulesList = integralRulesList;
    }
  });
}), _defineProperty(_handleActions, types.LOGOUT_DONE, function (state) {
  return initialData;
}), _handleActions), initialData);