import api from '../api';
export var getFactoryList = function getFactoryList() {
  return api.get('/v4/fitness_factory/branch_information');
};
export var getFactoryCourse = function getFactoryCourse(_ref) {
  var branch_code = _ref.branchCode,
      date = _ref.date;
  return api.get('/v4/fitness_factory/class_schedules', {
    branch_code: branch_code,
    date: date
  });
};
export var getCoachList = function getCoachList(_ref2) {
  var branch_code = _ref2.branchCode;
  return api.get('/v4/fitness_factory/coach_information', {
    branch_code: branch_code
  });
};
export var getFactoryClientInfo = function getFactoryClientInfo(_ref3) {
  var member_code = _ref3.member_code;
  return api.get('/v4/fitness_factory/client_membership_information', {
    member_code: member_code
  });
};
export var getReserveClasses = function getReserveClasses(_ref4) {
  var member_code = _ref4.member_code,
      start_date = _ref4.start_date,
      end_date = _ref4.end_date;
  return api.get('/v4/fitness_factory/get_classes_by_member_code', {
    member_code: member_code,
    start_date: start_date,
    end_date: end_date
  });
};
export var reserveClasses = function reserveClasses(_ref5) {
  var member_code = _ref5.member_code,
      coach_class_id = _ref5.coach_class_id;
  return api.get('/v4/fitness_factory/make_appointment', {
    member_code: member_code,
    coach_class_id: coach_class_id
  });
};
export var cancelReserveClasses = function cancelReserveClasses(_ref6) {
  var member_code = _ref6.member_code,
      coach_class_id = _ref6.coach_class_id;
  return api.get('/v4/fitness_factory/cancel_appointment ', {
    member_code: member_code,
    coach_class_id: coach_class_id
  });
};