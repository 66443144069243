import lib from '../platform';
var pfCommonStyle = lib.pfCommonStyle;
var activeTintColor = pfCommonStyle.activeTintColor;
export default {
  nutritionGroups: [{
    key: 'calorie',
    icon: 'lunch',
    color: activeTintColor,
    showChart: true
  }, {
    key: 'fruit',
    icon: 'type-fruit',
    color: '#F66992'
  }, {
    key: 'fruits',
    icon: 'type-fruit',
    color: '#F66992',
    showChart: true
  }, {
    key: 'vegetable',
    icon: 'type-vegetable',
    color: '#96C827'
  }, {
    key: 'vegetables',
    icon: 'type-vegetable',
    color: '#96C827',
    showChart: true
  }, {
    key: 'grain',
    icon: 'type-grain',
    color: '#CDA152'
  }, {
    key: 'grains',
    icon: 'type-grain',
    color: '#CDA152',
    showChart: true
  }, {
    key: 'meat',
    icon: 'type-meat',
    color: '#A286E0',
    showChart: true
  }, {
    key: 'milk',
    icon: 'type-milk',
    color: '#70BAEC',
    showChart: true
  }, {
    key: 'oil',
    icon: 'type-oil',
    color: '#FFAC23',
    showChart: true
  }, {
    key: 'water',
    icon: 'cup-water',
    color: '#00bfff',
    iconType: 'MaterialCommunityIcons'
  }, {
    key: 'water_intake',
    icon: 'cup-water',
    color: '#00bfff',
    iconType: 'MaterialCommunityIcons',
    showChart: true
  }],
  SixGroupCal: ['calorie', 'fruits', 'vegetables', 'grains', 'meat_beans_low_fat', 'meat_beans_medium_fat', 'meat_beans_high_fat', 'meat_beans_super_high_fat', 'milk_skim', 'milk_low_fat', 'milk_whole_fat', 'oil'],
  SIXGROUPS: ['fruits', 'vegetables', 'grains', 'meat_beans_low_fat', 'meat_beans_medium_fat', 'meat_beans_high_fat', 'meat_beans_super_high_fat', 'milk_skim', 'milk_low_fat', 'milk_whole_fat', 'oil'],
  MACROS: ['carbohydrate', 'protein', 'fat'],
  MACROS_P: ['carbohydrate_percentage', 'protein_percentage', 'fat_percentage'],
  MACROS_UNIT_CAL: {
    carbohydrate: 4,
    protein: 4,
    fat: 9
  },
  C63: ['calorie', 'fruits', 'vegetables', 'grains', 'meat_beans_low_fat', 'meat_beans_medium_fat', 'meat_beans_high_fat', 'meat_beans_super_high_fat', 'milk_skim', 'milk_low_fat', 'milk_whole_fat', 'oil', 'carbohydrate', 'protein', 'fat', 'water_intake'],
  CalculatorPermittedAttrs: ['calorie', 'protein', 'fat', 'carbohydrate', 'fiber', 'dietary_fiber', 'total_sugars', 'cholesterol', 'water', 'retinol_equivalentre', 'vitD', 'vite', 'vitK', 'b1', 'b2', 'niacin', 'pantothenic_acid', 'b6', 'biotin', 'folicacid', 'b12', 'choline', 'vitc', 'na', 'k', 'ca', 'mg', 'p', 'fe', 'zn', 'i', 'se', 'f', 'fatty_acids_total_polyunsaturated', 'fatty_acids_total_monounsaturated', 'fatty_acids_total_saturated', 'amino_acid', 'fruits', 'vegetables', 'grains', 'meat_beans_low_fat', 'meat_beans_medium_fat', 'meat_beans_high_fat', 'meat_beans_super_high_fat', 'milk_skim', 'milk_low_fat', 'milk_whole_fat', 'oil', 'asp', 'thr', 'ser', 'glu', 'pro', 'gly', 'ala', 'cys', 'val', 'met', 'ile', 'leu', 'tyr', 'phe', 'lys', 'his', 'arg', 'trp', 'linoleic_acid', 'linolenic_acid', 'octadecatetraenoicacid', 'arachidonic_acid', 'eicosapentaenoic_acid_epa', 'docosapentaenoic_acid', 'docosahexaenoic_acid_dha', 'myristoleic_acid', 'palmitoleic_acid', 'oleic_acid', 'gadoleic_acid', 'erucic_acid', 'butyric_acid', 'caprylic_acid', 'capric_acid', 'lauric_acid', 'tridecanoic_acid', 'myristic_acid', 'pentadecanoic_acid', 'palmitic_acid', 'margaric_acid', 'stearic_acid', 'nonadecanoic_acid', 'arachidic_acid', 'behenic_acid', 'lignoceric_acid'],
  SIX_GROUPS_TO_MACROS: {
    milk_whole_fat: {
      protein: 8,
      carbohydrate: 12,
      fat: 8
    },
    milk_low_fat: {
      protein: 8,
      carbohydrate: 12,
      fat: 4
    },
    milk_skim: {
      protein: 8,
      carbohydrate: 12,
      fat: 0
    },
    meat_beans_low_fat: {
      protein: 7,
      carbohydrate: 0,
      fat: 3
    },
    meat_beans_medium_fat: {
      protein: 7,
      carbohydrate: 0,
      fat: 5
    },
    meat_beans_high_fat: {
      protein: 7,
      carbohydrate: 0,
      fat: 10
    },
    meat_beans_super_high_fat: {
      protein: 7,
      carbohydrate: 0,
      fat: 14
    },
    grains: {
      protein: 2,
      carbohydrate: 15,
      fat: 0
    },
    vegetables: {
      protein: 1,
      carbohydrate: 5,
      fat: 0
    },
    fruits: {
      protein: 0,
      carbohydrate: 15,
      fat: 0
    },
    oil: {
      protein: 0,
      carbohydrate: 0,
      fat: 5
    }
  }
};