import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";

var _handleActions;

import _ from 'lodash';
import { handleActions, combineActions } from 'redux-actions';
import * as types from '../../store/types';
import produce from 'immer';
export default handleActions((_handleActions = {}, _defineProperty(_handleActions, types.SHOW_LOADING, function (state, _ref) {
  var _ref$payload = _ref.payload,
      payload = _ref$payload === void 0 ? {} : _ref$payload;
  var _payload$loadingType = payload.loadingType,
      loadingType = _payload$loadingType === void 0 ? 'default' : _payload$loadingType,
      loadingText = payload.loadingText,
      loadingColor = payload.loadingColor;
  return _objectSpread({}, state, {
    showLoading: true,
    loadingType: loadingType,
    loadingText: loadingText,
    loadingColor: loadingColor
  });
}), _defineProperty(_handleActions, types.HIDE_LOADING, function (state) {
  return _objectSpread({}, state, {
    showLoading: false,
    loadingType: 'default',
    loadingText: undefined,
    loadingColor: undefined
  });
}), _defineProperty(_handleActions, types.GET_UNREAD_COUNT_DONE, function (state, _ref2) {
  var unreadCount = _ref2.payload.unreadCount;
  var unreads = unreadCount.unreads;
  var badges = state.badges;

  var chatUnread = _.find(unreads, {
    sumTo: 'chatTab'
  });

  var chatrooms = chatUnread ? _.pickBy(chatUnread, _.isNumber) : badges.chatrooms;

  var chatTab = _.sum(_.values(chatrooms));

  var notificationUnread = _.find(unreads, {
    sumTo: 'notificationTab'
  });

  var notificationTab = notificationUnread ? _.sum(_.values(_.pickBy(notificationUnread, _.isNumber))) : badges.notificationTab;
  return _objectSpread({}, state, {
    badges: _objectSpread({}, badges, {
      chatTab: chatTab,
      chatrooms: chatrooms,
      notificationTab: notificationTab
    })
  });
}), _defineProperty(_handleActions, types.RECEIVE_NEW_MESSAGE, function (state, _ref3) {
  var id = _ref3.payload.id;
  var badges = state.badges;

  var chatrooms = _objectSpread({}, badges.chatrooms, _defineProperty({}, id, 0));

  var chatTab = _.sum(_.values(chatrooms));

  return _objectSpread({}, state, {
    badges: _objectSpread({}, badges, {
      chatTab: chatTab,
      chatrooms: chatrooms
    })
  });
}), _defineProperty(_handleActions, types.CLEAR_NOTIFICATION_TAB_BADGE, function (state) {
  return _objectSpread({}, state, {
    badges: _objectSpread({}, state.badges, {
      notificationTab: 0
    })
  });
}), _defineProperty(_handleActions, combineActions(types.CLEAR_NOTIFICATION_TAB_BADGE, types.RECEIVE_NEW_MESSAGE, types.GET_UNREAD_COUNT_DONE), function (state) {
  var badges = state.badges;
  var _state$badges = state.badges,
      notificationTab = _state$badges.notificationTab,
      chatTab = _state$badges.chatTab;
  return _objectSpread({}, state, {
    badges: _objectSpread({}, badges, {
      total: notificationTab + chatTab
    })
  });
}), _defineProperty(_handleActions, types.LIST_SCROLL_TO_TOP, function (state, _ref4) {
  var _ref4$payload = _ref4.payload,
      scrollToTopList = _ref4$payload.scrollToTopList,
      scrollToTop = _ref4$payload.scrollToTop;
  return produce(state, function (draft) {
    draft.scrollToTop = scrollToTop;

    if (scrollToTopList) {
      draft.scrollToTopList = scrollToTopList;
    }
  });
}), _handleActions), {
  showLoading: false,
  loadingType: 'default',
  loadingText: undefined,
  loadingColor: undefined,
  badges: {
    chatTab: 0,
    notificationTab: 0,
    total: 0,
    chatrooms: {}
  },
  scrollToTopList: undefined,
  scrollToTop: false
});