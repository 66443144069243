import React, {Component} from "react";
import {connect} from "react-redux";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";


const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class HorizontalNav extends Component {

  getNavStyleSubMenuClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";

    }
  };

  render() {
    const {pathname, navStyle} = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    return (

      <Menu
        defaultOpenKeys={[defaultOpenKeys]}
        selectedKeys={[selectedKeys]}
        mode="horizontal">

        <SubMenu className={this.getNavStyleSubMenuClass(navStyle)} key="sidebar.rd"
          title={<IntlMessages id="sidebar.rd"/>}>
            <MenuItemGroup key="rd" className="gx-menu-group" title={<IntlMessages id="sidebar.rd"/>}>
              <Menu.Item key="sidebar.rd.groupClass">
                <Link to="/group_class"><i className="icon icon-widgets"/>
                  <IntlMessages id="sidebar.rd.groupClass"/></Link>
              </Menu.Item>
            </MenuItemGroup>        
        </SubMenu>
        <SubMenu className={this.getNavStyleSubMenuClass(navStyle)} key="sidebar.admin"
          title={<IntlMessages id="sidebar.admin"/>}>
            <MenuItemGroup key="admin" className="gx-menu-group" title={<IntlMessages id="sidebar.admin"/>}>
              <Menu.Item key="sidebar.admin.groupClass">
                <Link to="/group_class"><i className="icon icon-widgets"/>
                  <IntlMessages id="sidebar.admin.groupClass"/></Link>
              </Menu.Item>
              <Menu.Item key="sidebar.admin.rd">
                <Link to="/sidebar.admin.rd"><i className="icon icon-widgets"/>
                  <IntlMessages id="sidebar.admin.rd"/></Link>
              </Menu.Item>                                
            </MenuItemGroup>
        </SubMenu>
      </Menu>

    );
  }
}

let styles = {
  textWhite: {
    color: 'white'
  }
}


HorizontalNav.propTypes = {};
const mapStateToProps = ({settings}) => {
  const {themeType, navStyle, pathname, locale} = settings;
  return {themeType, navStyle, pathname, locale}
};
export default connect(mapStateToProps)(HorizontalNav);

