import { createAction } from 'redux-actions';
import * as types from '../../store/types';
export var openPage = createAction(types.OPEN_PAGE);
export var pageGoBack = createAction(types.PAGE_GO_BACK);
export var pageGoDiary = createAction(types.PAGE_GO_DIARY);
export var pageGoNotePrev = createAction(types.PAGE_GO_NOTE_PREV);
export var openIntroPage = createAction(types.OPEN_INTRO_PAGE);
export var openImagePickerModal = createAction(types.OPEN_IMAGE_PICKER_MODAL);
export var closeImagePickerModal = createAction(types.CLOSE_IMAGE_PICKER_MODAL);
export var openAddFoodStuffsConfirmModal = createAction(types.OPEN_ADD_FOODSTUFFS_CONFIRM_MODAL);
export var closeAddFoodStuffsConfirmModal = createAction(types.CLOSE_ADD_FOODSTUFFS_CONFIRM_MODAL);
export var openNoteEditorPage = createAction(types.OPEN_NOTE_EDITOR_PAGE);
export var openProNoteEditorPage = createAction(types.OPEN_PRO_NOTE_EDITOR_PAGE);
export var openProNoteEditconfirmPage = createAction(types.OPEN_PRO_NOTE_EDIT_CONFIRM_PAGE);
export var openNoteEditconfirmPage = createAction(types.OPEN_NOTE_EDIT_CONFIRM_PAGE);
export var openAddDBFoodStuffsPage = createAction(types.OPEN_ADD_DB_FOODSTUFFS_PAGE);
export var openAddSixgroupPage = createAction(types.OPEN_ADD_SIXGROUP_PAGE);
export var openAddCaloriePage = createAction(types.OPEN_ADD_CALORIE_PAGE);
export var openAddMoveItemsPage = createAction(types.OPEN_ADD_MOVE_ITEMS_PAGE);
export var completeFoodStuffsPage = createAction(types.COMPLETE_FOODSTUFFS_PAGES);
export var openFoodNoteEditorPage = createAction(types.OPEN_FOOD_NOTE_EDITOR_PAGE);
export var completeActivityItemsPage = createAction(types.COMPLETE_ACTIVITY_ITEMS_PAGES);
export var openTargetPage = createAction(types.OPEN_TARGET_PAGE);
export var openTargetHelloPage = createAction(types.OPEN_TARGET_HELLO_PAGE);
export var openTargetBasicPage = createAction(types.OPEN_TARGET_BASIC_PAGE);
export var openCameraSendPage = createAction(types.OPEN_CAMERA_SEND_PAGE);
export var closeCameraSendPage = createAction(types.CLOSE_CAMERA_SEND_PAGE);
export var openSetLanguagePage = createAction(types.OPEN_SET_LANGUAGE_PAGE);
export var openCoachInfoPage = createAction(types.OPEN_COACH_INFO_PAGE);
export var openDataChartPage = createAction(types.OPEN_DATA_CHART_PAGE);
export var openCoachTab = createAction(types.OPEN_COACH_TAB);
export var openProfileTab = createAction(types.OPEN_PROFILE_TAB);
export var openHomeTab = createAction(types.OPEN_HOME_TAB);
export var openSetPasswordPage = createAction(types.OPEN_SET_PASSWORD_PAGE);
export var openExploreTab = createAction(types.OPEN_EXPLORE_TAB);
export var openEssaysListPage = createAction(types.OPEN_ESSAYS_LIST_PAGE);
export var openEssaysTagListPage = createAction(types.OPEN_ESSAYS_TAG_LIST_PAGE);
export var openSixGroupDetailPage = createAction(types.OPEN_SIXGROUP_DETAIL_PAGE);
export var openWebViewPage = createAction(types.OPEN_WEB_VIEW_PAGE);
export var openPostPage = createAction(types.OPEN_POST_PAGE);
export var openSocialProfilePage = createAction(types.OPEN_SOCIAL_PROFILE_PAGE);
export var openSocialTagPage = createAction(types.OPEN_SOCIAL_TAG_PAGE);
export var openSocialSearchPage = createAction(types.OPEN_SOCIAL_SEARCH_PAGE);
export var openRankPage = createAction(types.OPEN_RANK_PAGE);
export var openLocationPage = createAction(types.OPEN_LOCATION_PAGE);
export var openBlockListPage = createAction(types.OPEN_BLOCK_LIST_PAGE);
export var openSociaPageCall = createAction(types.OPEN_SOCIA_PAGE_CALL);
export var openSociaPageDone = createAction(types.OPEN_SOCIA_PAGE_DONE);
export var openSociaPageFail = createAction(types.OPEN_SOCIA_PAGE_FAIL);
export var openSocialGroupPageCall = createAction(types.OPEN_SOCIAL_GROUP_PAGE_CALL);
export var openSocialGroupPageDone = createAction(types.OPEN_SOCIAL_GROUP_PAGE_DONE);
export var openSocialGroupPageFail = createAction(types.OPEN_SOCIAL_GROUP_PAGE_FAIL);
export var openGroupTagsPageCall = createAction(types.OPEN_GROUP_TAG_PAGE_CALL);
export var openGroupTagsPageDone = createAction(types.OPEN_GROUP_TAG_PAGE_DONE);
export var openGroupTagsPageFail = createAction(types.OPEN_GROUP_TAG_PAGE_FAIL);
export var openPricingPage = createAction(types.OPEN_PRICING_PAGE);
export var openAddDataListPage = createAction(types.OPEN_ADD_DATA_LIST_PAGE);
export var openPasswordLoginPage = createAction(types.OPEN_PASSWORD_LOGIN_PAGE);
export var openRegistrationCodePage = createAction(types.OPEN_REGISTRATION_CODE_PAGE);