import { createAction } from 'redux-actions';
import * as types from '../../store/types';
export var getAllSettingStoreCall = createAction(types.GET_ALL_SETTING_STORE_CALL);
export var getAllSettingStoreDone = createAction(types.GET_ALL_SETTING_STORE_DONE);
export var getAllSettingStoreFail = createAction(types.GET_ALL_SETTING_STORE_FAIL);
export var getPostStoreCall = createAction(types.GET_POST_STORE_CALL);
export var getPostStoreDone = createAction(types.GET_POST_STORE_DONE);
export var getPostStoreFail = createAction(types.GET_POST_STORE_FAIL);
export var setPostStoreCall = createAction(types.SET_POST_STORE_CALL);
export var setPostStoreDone = createAction(types.SET_POST_STORE_DONE);
export var setPostStoreFail = createAction(types.SET_POST_STORE_FAIL);
export var getGroupidsStoreCall = createAction(types.GET_GROUPIDS_STORE_CALL);
export var getGroupidsStoreDone = createAction(types.GET_GROUPIDS_STORE_DONE);
export var getGroupidsStoreFail = createAction(types.GET_GROUPIDS_STORE_FAIL);
export var setGroupidsStoreCall = createAction(types.SET_GROUPIDS_STORE_CALL);
export var setGroupidsStoreDone = createAction(types.SET_GROUPIDS_STORE_DONE);
export var setGroupidsStoreFail = createAction(types.SET_GROUPIDS_STORE_FAIL);
export var getOpenImagePickerStoreCall = createAction(types.GET_OPEN_IMAGE_PICKER_STORE_CALL);
export var getOpenImagePickerStoreDone = createAction(types.GET_OPEN_IMAGE_PICKER_STORE_DONE);
export var getOpenImagePickerStoreFail = createAction(types.GET_OPEN_IMAGE_PICKER_STORE_FAIL);
export var setOpenImagePickerStoreCall = createAction(types.SET_OPEN_IMAGE_PICKER_STORE_CALL);
export var setOpenImagePickerStoreDone = createAction(types.SET_OPEN_IMAGE_PICKER_STORE_DONE);
export var setOpenImagePickerStoreFail = createAction(types.SET_OPEN_IMAGE_PICKER_STORE_FAIL);
export var getSearchHistoryListStoreCall = createAction(types.GET_SEARCH_HISTORY_LIST_STORE_CALL);
export var getSearchHistoryListStoreDone = createAction(types.GET_SEARCH_HISTORY_LIST_STORE_DONE);
export var getSearchHistoryListStoreFail = createAction(types.GET_SEARCH_HISTORY_LIST_STORE_FAIL);
export var setSearchHistoryListStoreCall = createAction(types.SET_SEARCH_HISTORY_LIST_STORE_CALL);
export var setSearchHistoryListStoreDone = createAction(types.SET_SEARCH_HISTORY_LIST_STORE_DONE);
export var setSearchHistoryListStoreFail = createAction(types.SET_SEARCH_HISTORY_LIST_STORE_FAIL);
export var getBarCodeIsRowStoreCall = createAction(types.GET_BAR_CODE_IS_ROW_STORE_CALL);
export var getBarCodeIsRowStoreDone = createAction(types.GET_BAR_CODE_IS_ROW_STORE_DONE);
export var getBarCodeIsRowStoreFail = createAction(types.GET_BAR_CODE_IS_ROW_STORE_FAIL);
export var setBarCodeIsRowStoreCall = createAction(types.SET_BAR_CODE_IS_ROW_STORE_CALL);
export var setBarCodeIsRowStoreDone = createAction(types.SET_BAR_CODE_IS_ROW_STORE_DONE);
export var setBarCodeIsRowStoreFail = createAction(types.SET_BAR_CODE_IS_ROW_STORE_FAIL);
export var getAllowsEditingStoreCall = createAction(types.GET_ALLOWS_EDITING_STORE_CALL);
export var getAllowsEditingStoreDone = createAction(types.GET_ALLOWS_EDITING_STORE_DONE);
export var getAllowsEditingStoreFail = createAction(types.GET_ALLOWS_EDITING_STORE_FAIL);
export var setAllowsEditingStoreCall = createAction(types.SET_ALLOWS_EDITING_STORE_CALL);
export var setAllowsEditingStoreDone = createAction(types.SET_ALLOWS_EDITING_STORE_DONE);
export var setAllowsEditingStoreFail = createAction(types.SET_ALLOWS_EDITING_STORE_FAIL);
export var getIsTwoPostStoreCall = createAction(types.GET_IS_TWO_POST_STORE_CALL);
export var getIsTwoPostStoreDone = createAction(types.GET_IS_TWO_POST_STORE_DONE);
export var getIsTwoPostStoreFail = createAction(types.GET_IS_TWO_POST_STORE_FAIL);
export var setIsTwoPostStoreCall = createAction(types.SET_IS_TWO_POST_STORE_CALL);
export var setIsTwoPostStoreDone = createAction(types.SET_IS_TWO_POST_STORE_DONE);
export var setIsTwoPostStoreFail = createAction(types.SET_IS_TWO_POST_STORE_FAIL);
export var getIdentityStoreCall = createAction(types.GET_IDENTITY_STORE_CALL);
export var getIdentityStoreDone = createAction(types.GET_IDENTITY_STORE_DONE);
export var getIdentityStoreFail = createAction(types.GET_IDENTITY_STORE_FAIL);
export var setIdentityStoreCall = createAction(types.SET_IDENTITY_STORE_CALL);
export var setIdentityStoreDone = createAction(types.SET_IDENTITY_STORE_DONE);
export var setIdentityStoreFail = createAction(types.SET_IDENTITY_STORE_FAIL);
export var getStoreByKeyCall = createAction(types.GET_STORE_BY_KEY_CALL);
export var getStoreByKeyDone = createAction(types.GET_STORE_BY_KEY_DONE);
export var getStoreByKeyFail = createAction(types.GET_STORE_BY_KEY_FAIL);
export var setStoreByKeyCall = createAction(types.SET_STORE_BY_KEY_CALL);
export var setStoreByKeyDone = createAction(types.SET_STORE_BY_KEY_DONE);
export var setStoreByKeyFail = createAction(types.SET_STORE_BY_KEY_FAIL);
export var getStoreNull = createAction(types.GET_STORE_NULL);