import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";

var _handleActions;

import _ from 'lodash';
import produce from 'immer';
import { handleActions, combineActions } from 'redux-actions';
import * as types from '../../store/types';
export default handleActions((_handleActions = {}, _defineProperty(_handleActions, types.GET_FACTORY_LIST_DONE, function (state, _ref) {
  var factoryGroupsList = _ref.payload.factoryGroupsList;
  return _objectSpread({}, state, {
    factoryGroupsList: factoryGroupsList
  });
}), _defineProperty(_handleActions, types.GET_FACTORY_COURSE_CALL, function (state, _ref2) {
  var payload = _ref2.payload;
  return produce(state, function (draft) {
    draft.courseLoading = true;
  });
}), _defineProperty(_handleActions, types.GET_FACTORY_COURSE_DONE, function (state, _ref3) {
  var schedules = _ref3.payload.schedules;
  return produce(state, function (draft) {
    draft.courseList = schedules;
    draft.courseLoading = false;
  });
}), _defineProperty(_handleActions, types.GET_FACTORY_COURSE_FAIL, function (state, _ref4) {
  var payload = _ref4.payload;
  return produce(state, function (draft) {
    draft.courseLoading = false;
  });
}), _defineProperty(_handleActions, types.GET_FACTORY_COACH_LIST_CALL, function (state, _ref5) {
  var loadingType = _ref5.payload.loadingType;
  return produce(state, function (draft) {
    if (loadingType !== 'refresh') {
      draft.coachList = [];
    }

    draft.coachShowRefreshing = loadingType === 'refresh';
    draft.coachLoading = true;
  });
}), _defineProperty(_handleActions, types.GET_FACTORY_COACH_LIST_FAIL, function (state, _ref6) {
  var payload = _ref6.payload;
  return produce(state, function (draft) {
    draft.coachLoading = false;
    draft.coachShowRefreshing = false;
  });
}), _defineProperty(_handleActions, types.GET_FACTORY_COACH_LIST_DONE, function (state, _ref7) {
  var coaches = _ref7.payload.coaches;
  return produce(state, function (draft) {
    var formatCoaches = _.map(coaches, function (coache) {
      var uri = 'https://i.imgur.com/MvNMmx1.jpg';
      var uniformPhoto = coache.uniform_photo || uri;
      var professionalPhoto = coache.professional_photo || uri;
      var images = [{
        uri: uniformPhoto
      }, {
        uri: professionalPhoto
      }];
      return _objectSpread({}, coache, {
        uniformPhoto: uniformPhoto,
        professionalPhoto: professionalPhoto,
        images: images
      });
    });

    draft.coachList = [_.filter(formatCoaches, function (_ref8) {
      var gender = _ref8.gender;
      return gender === 'M';
    }), _.filter(formatCoaches, function (_ref9) {
      var gender = _ref9.gender;
      return gender !== 'M';
    })];
    draft.coachLoading = false;
    draft.coachShowRefreshing = false;
  });
}), _defineProperty(_handleActions, types.GET_FACTORY_CLIENT_INFO_CALL, function (state, _ref10) {
  var payload = _ref10.payload;
  return produce(state, function (draft) {
    draft.memberLoading = true;
  });
}), _defineProperty(_handleActions, types.GET_FACTORY_CLIENT_INFO_DONE, function (state, _ref11) {
  var membership = _ref11.payload.membership;
  return produce(state, function (draft) {
    draft.membership = membership;
    draft.memberLoading = false;
  });
}), _defineProperty(_handleActions, types.GET_FACTORY_CLIENT_INFO_FAIL, function (state, _ref12) {
  var payload = _ref12.payload;
  return produce(state, function (draft) {
    draft.memberLoading = false;
  });
}), _defineProperty(_handleActions, types.GET_RESERVE_CLASSES_CALL, function (state, _ref13) {
  var payload = _ref13.payload;
  return produce(state, function (draft) {
    draft.classesLoading = true;
  });
}), _defineProperty(_handleActions, types.GET_RESERVE_CLASSES_DONE, function (state, _ref14) {
  var _ref14$payload = _ref14.payload,
      success = _ref14$payload.success,
      classes = _ref14$payload.classes;
  return produce(state, function (draft) {
    draft.classesLoading = false;

    if (success) {
      draft.classesList = classes;
    }
  });
}), _defineProperty(_handleActions, types.GET_RESERVE_CLASSES_FAIL, function (state, _ref15) {
  var payload = _ref15.payload;
  return produce(state, function (draft) {
    draft.classesLoading = false;
  });
}), _defineProperty(_handleActions, types.SHOW_BAR_CODE_MODAL, function (state, _ref16) {
  var payload = _ref16.payload;
  return produce(state, function (draft) {
    draft.barCodeIsVisible = true;
  });
}), _defineProperty(_handleActions, types.CLOSE_BAR_CODE_MODAL, function (state, _ref17) {
  var payload = _ref17.payload;
  return produce(state, function (draft) {
    draft.barCodeIsVisible = false;
  });
}), _handleActions), {
  factoryGroupsList: [],
  courseList: [],
  coachList: [],
  membership: undefined,
  barCodeIsVisible: false,
  courseLoading: false,
  classesList: [],
  classesLoading: false,
  memberLoading: false,
  coachLoading: true,
  coachShowRefreshing: false
});