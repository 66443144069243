import _ from 'lodash';
import { Observable } from 'rxjs';
import './RxOperators';
import * as actions from '../actions';
import * as types from '../../store/types';
import { storage } from '../../services';
import lib from '../../platform';
var pfConfig = lib.pfConfig;
var isFFApp = pfConfig.isFFApp;
export var getAllSettingStoreCall = function getAllSettingStoreCall(action$, store) {
  return action$.ofType(types.GET_ALL_SETTING_STORE_CALL).switchMap(function () {
    var dispatch = [actions.getPostStoreCall(), actions.getGroupidsStoreCall(), actions.getOpenImagePickerStoreCall(), actions.getSearchHistoryListStoreCall(), actions.getBarCodeIsRowStoreCall(), actions.getAllowsEditingStoreCall(), actions.getIsTwoPostStoreCall(), actions.getAllSettingStoreDone(), actions.getStoreByKeyCall({
      key: 'draftListStore',
      type: 'json'
    }), actions.getStoreByKeyCall({
      key: 'isOpenPedometer',
      type: 'boolean'
    })];

    if (isFFApp) {
      dispatch.push(actions.getIdentityStoreCall());
      dispatch.push(actions.getStoreByKeyCall({
        key: 'activeCode',
        type: 'default'
      }));
      dispatch.push(actions.getStoreByKeyCall({
        key: 'firstOpenBarCode',
        type: 'boolean'
      }));
      dispatch.push(actions.getStoreByKeyCall({
        key: 'factoryDefaultCode',
        type: 'default'
      }));
    }

    return dispatch;
  }).catch(function (err) {
    return Observable.of(actions.getAllSettingStoreFail(err));
  });
};
export var getPostStoreCall = function getPostStoreCall(action$, store) {
  return action$.ofType(types.GET_POST_STORE_CALL).switchMap(function () {
    return Observable.fromPromise(storage.getItemAsync('isPostSwitch')).map(function (isPostSwitch) {
      if (isPostSwitch !== null) {
        //storage data is string
        var isPost = isPostSwitch === 'true';
        return actions.getPostStoreDone({
          isPost: isPost
        });
      } else {
        return actions.getStoreNull(types.GET_POST_STORE_CALL);
      }
    }).catch(function (err) {
      return Observable.of(actions.getPostStoreFail(err));
    });
  });
};
export var setPostStoreCall = function setPostStoreCall(action$, store) {
  return action$.ofType(types.SET_POST_STORE_CALL).switchMap(function (_ref) {
    var isPost = _ref.payload.isPost;
    return Observable.fromPromise(storage.setItemAsync('isPostSwitch', isPost)).map(function () {
      return actions.setPostStoreDone({
        isPost: isPost
      });
    }).catch(function (err) {
      return Observable.of(actions.setPostStoreFail(err));
    });
  });
};
export var getGroupidsStoreCall = function getGroupidsStoreCall(action$, store) {
  return action$.ofType(types.GET_GROUPIDS_STORE_CALL).switchMap(function () {
    return Observable.fromPromise(storage.getItemAsync('groupIds')).map(function (req) {
      var groupIds = [];
      var jsonReq = JSON.parse(req);

      if (jsonReq !== null) {
        groupIds = jsonReq;
      } else {
        var chatrooms = store.value.chat.chatrooms;

        var groupChatrooms = _.filter(chatrooms, {
          isGroupClass: true
        });

        groupIds = _.map(groupChatrooms, function (_ref2) {
          var group_class_id = _ref2.group_class_id;
          return group_class_id;
        });
      }

      return actions.getGroupidsStoreDone({
        groupIds: groupIds
      });
    }).catch(function (err) {
      return Observable.of(actions.getGroupidsStoreFail(err));
    });
  });
};
export var setGroupidsStoreCall = function setGroupidsStoreCall(action$, store) {
  return action$.ofType(types.SET_GROUPIDS_STORE_CALL).switchMap(function (_ref3) {
    var groupIds = _ref3.payload.groupIds;
    var stringifyData = JSON.stringify(groupIds);
    return Observable.fromPromise(storage.setItemAsync('groupIds', stringifyData)).map(function () {
      return actions.setGroupidsStoreDone({
        groupIds: groupIds
      });
    }).catch(function (err) {
      return Observable.of(actions.setGroupidsStoreFail(err));
    });
  });
};
export var getOpenImagePickerStoreCall = function getOpenImagePickerStoreCall(action$, store) {
  return action$.ofType(types.GET_OPEN_IMAGE_PICKER_STORE_CALL).switchMap(function () {
    return Observable.fromPromise(storage.getItemAsync('openImagePickerStore')).map(function (openImagePickerStore) {
      if (openImagePickerStore !== null) {
        var isOpenImagePicker = openImagePickerStore === 'true';
        return actions.getOpenImagePickerStoreDone({
          isOpenImagePicker: isOpenImagePicker
        });
      } else {
        return actions.getStoreNull(types.GET_OPEN_IMAGE_PICKER_STORE_CALL);
      }
    }).catch(function (err) {
      return Observable.of(actions.getOpenImagePickerStoreFail(err));
    });
  });
};
export var setOpenImagePickerStoreCall = function setOpenImagePickerStoreCall(action$, store) {
  return action$.ofType(types.SET_OPEN_IMAGE_PICKER_STORE_CALL).switchMap(function (_ref4) {
    var isOpenImagePicker = _ref4.payload.isOpenImagePicker;
    return Observable.fromPromise(storage.setItemAsync('openImagePickerStore', isOpenImagePicker)).map(function () {
      return actions.setOpenImagePickerStoreDone({
        isOpenImagePicker: isOpenImagePicker
      });
    }).catch(function (err) {
      return Observable.of(actions.setOpenImagePickerStoreFail(err));
    });
  });
};
export var getSearchHistoryListStoreCall = function getSearchHistoryListStoreCall(action$, store) {
  return action$.ofType(types.GET_SEARCH_HISTORY_LIST_STORE_CALL).switchMap(function () {
    return Observable.fromPromise(storage.getItemAsync('searchHistoryListStore')).map(function (searchHistoryListStore) {
      var searchHistoryList = [];

      if (searchHistoryListStore !== null) {
        searchHistoryList = JSON.parse(searchHistoryListStore);
      }

      return actions.getSearchHistoryListStoreDone({
        searchHistoryList: searchHistoryList
      });
    }).catch(function (err) {
      return Observable.of(actions.getSearchHistoryListStoreFail(err));
    });
  });
};
export var setSearchHistoryListStoreCall = function setSearchHistoryListStoreCall(action$, store) {
  return action$.ofType(types.SET_SEARCH_HISTORY_LIST_STORE_CALL).switchMap(function (_ref5) {
    var searchHistoryList = _ref5.payload.searchHistoryList;
    var stringifyData = JSON.stringify(searchHistoryList);
    return Observable.fromPromise(storage.setItemAsync('searchHistoryListStore', stringifyData)).map(function () {
      return actions.setSearchHistoryListStoreDone({
        searchHistoryList: searchHistoryList
      });
    }).catch(function (err) {
      return Observable.of(actions.setSearchHistoryListStoreFail(err));
    });
  });
};
export var getBarCodeIsRowStoreCall = function getBarCodeIsRowStoreCall(action$, store) {
  return action$.ofType(types.GET_BAR_CODE_IS_ROW_STORE_CALL).switchMap(function () {
    return Observable.fromPromise(storage.getItemAsync('barCodeIsRowStore')).map(function (barCodeIsRowStore) {
      if (barCodeIsRowStore !== null) {
        var barCodeIsRow = barCodeIsRowStore === 'true';
        return actions.getBarCodeIsRowStoreDone({
          barCodeIsRow: barCodeIsRow
        });
      } else {
        return actions.getStoreNull(types.GET_BAR_CODE_IS_ROW_STORE_CALL);
      }
    }).catch(function (err) {
      return Observable.of(actions.getBarCodeIsRowStoreFail(err));
    });
  });
};
export var setBarCodeIsRowStoreCall = function setBarCodeIsRowStoreCall(action$, store) {
  return action$.ofType(types.SET_BAR_CODE_IS_ROW_STORE_CALL).switchMap(function (_ref6) {
    var barCodeIsRow = _ref6.payload.barCodeIsRow;
    return Observable.fromPromise(storage.setItemAsync('barCodeIsRowStore', barCodeIsRow)).map(function () {
      return actions.setBarCodeIsRowStoreDone({
        barCodeIsRow: barCodeIsRow
      });
    }).catch(function (err) {
      return Observable.of(actions.setBarCodeIsRowStoreFail(err));
    });
  });
};
export var getAllowsEditingStoreCall = function getAllowsEditingStoreCall(action$, store) {
  return action$.ofType(types.GET_ALLOWS_EDITING_STORE_CALL).switchMap(function () {
    return Observable.fromPromise(storage.getItemAsync('allowsEditing')).map(function (allowsEditingStore) {
      if (allowsEditingStore !== null) {
        //storage data is string
        var allowsEditing = allowsEditingStore === 'true';
        return actions.getAllowsEditingStoreDone({
          allowsEditing: allowsEditing
        });
      } else {
        return actions.getStoreNull(types.GET_ALLOWS_EDITING_STORE_CALL);
      }
    }).catch(function (err) {
      return Observable.of(actions.getAllowsEditingStoreFail(err));
    });
  });
};
export var setAllowsEditingStoreCall = function setAllowsEditingStoreCall(action$, store) {
  return action$.ofType(types.SET_ALLOWS_EDITING_STORE_CALL).switchMap(function (_ref7) {
    var allowsEditing = _ref7.payload.allowsEditing;
    return Observable.fromPromise(storage.setItemAsync('allowsEditing', allowsEditing)).map(function () {
      return actions.setAllowsEditingStoreDone({
        allowsEditing: allowsEditing
      });
    }).catch(function (err) {
      return Observable.of(actions.setAllowsEditingStoreFail(err));
    });
  });
};
export var getIsTwoPostStoreCall = function getIsTwoPostStoreCall(action$, store) {
  return action$.ofType(types.GET_IS_TWO_POST_STORE_CALL).switchMap(function () {
    return Observable.fromPromise(storage.getItemAsync('isTwoPostStore')).map(function (isTwoPostStore) {
      if (isTwoPostStore !== null) {
        //storage data is string
        var isTwoPost = isTwoPostStore === 'true';
        return actions.getIsTwoPostStoreDone({
          isTwoPost: isTwoPost
        });
      } else {
        return actions.getStoreNull(types.GET_IS_TWO_POST_STORE_CALL);
      }
    }).catch(function (err) {
      return Observable.of(actions.getIsTwoPostStoreFail(err));
    });
  });
};
export var setIsTwoPostStoreCall = function setIsTwoPostStoreCall(action$, store) {
  return action$.ofType(types.SET_IS_TWO_POST_STORE_CALL).switchMap(function (_ref8) {
    var isTwoPost = _ref8.payload.isTwoPost;
    return Observable.fromPromise(storage.setItemAsync('isTwoPostStore', isTwoPost)).map(function () {
      return actions.setIsTwoPostStoreDone({
        isTwoPost: isTwoPost
      });
    }).catch(function (err) {
      return Observable.of(actions.setIsTwoPostStoreFail(err));
    });
  });
};
export var getIdentityStoreCall = function getIdentityStoreCall(action$, store) {
  return action$.ofType(types.GET_IDENTITY_STORE_CALL).switchMap(function () {
    return Observable.fromPromise(storage.getItemAsync('identityStore')).map(function (identityStore) {
      if (identityStore !== null) {
        var identity = JSON.parse(identityStore);
        return actions.getIdentityStoreDone({
          identity: identity
        });
      } else {
        return actions.getStoreNull(types.GET_IDENTITY_STORE_CALL);
      }
    }).catch(function (err) {
      return Observable.of(actions.getIdentityStoreFail(err));
    });
  });
};
export var setIdentityStoreCall = function setIdentityStoreCall(action$, store) {
  return action$.ofType(types.SET_IDENTITY_STORE_CALL).switchMap(function (_ref9) {
    var identity = _ref9.payload.identity;
    var identityStore = JSON.stringify(identity);
    return Observable.fromPromise(storage.setItemAsync('identityStore', identityStore)).map(function () {
      return actions.setIdentityStoreDone({
        identity: identity
      });
    }).catch(function (err) {
      return Observable.of(actions.setIdentityStoreFail(err));
    });
  });
};
export var getStoreByKeyCall = function getStoreByKeyCall(action$, store) {
  return action$.ofType(types.GET_STORE_BY_KEY_CALL).mergeMap(function (_ref10) {
    var _ref10$payload = _ref10.payload,
        key = _ref10$payload.key,
        type = _ref10$payload.type;
    return Observable.fromPromise(storage.getItemAsync(key)).switchMap(function (valueStore) {
      if (valueStore !== null) {
        var value = valueStore;

        switch (type) {
          case 'json':
            value = JSON.parse(valueStore);
            break;

          case 'boolean':
            value = valueStore === 'true';
            break;

          default:
            value = valueStore;
            break;
        }

        var dispatch = [actions.getStoreByKeyDone({
          key: key,
          value: value
        })];

        if (key === 'firstOpenBarCode' && value) {
          dispatch.push(actions.showBarCodeModal());
        }

        return dispatch;
      } else {
        return [actions.getStoreNull(types.GET_STORE_BY_KEY_CALL)];
      }
    }).catch(function (err) {
      return Observable.of(actions.getStoreByKeyFail(err));
    });
  });
};
export var setStoreByKeyCall = function setStoreByKeyCall(action$, store) {
  return action$.ofType(types.SET_STORE_BY_KEY_CALL).switchMap(function (_ref11) {
    var _ref11$payload = _ref11.payload,
        key = _ref11$payload.key,
        type = _ref11$payload.type,
        value = _ref11$payload.value;
    var valueStore = value;

    switch (type) {
      case 'json':
        valueStore = JSON.stringify(value);
        break;

      default:
        valueStore = value;
        break;
    }

    return Observable.fromPromise(storage.setItemAsync(key, valueStore)).map(function () {
      return actions.setStoreByKeyDone({
        key: key,
        value: value
      });
    }).catch(function (err) {
      return Observable.of(actions.setStoreByKeyFail(err));
    });
  });
};