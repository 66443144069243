import _toConsumableArray from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _ from 'lodash';
import moment from 'moment';
import { Observable } from 'rxjs';
import { note } from '../../services';
import './RxOperators';
import * as actions from '../actions';
import * as types from '../../store/types';
import lib from '../../platform';
var pfConfig = lib.pfConfig,
    Asset = lib.pfAsset;
var isProType = pfConfig.isProType,
    _pfConfig$common = pfConfig.common,
    defaultShowPostSwitch = _pfConfig$common.defaultShowPostSwitch,
    handleGetMyPosts = _pfConfig$common.handleGetMyPosts;
export var initNewNoteCall = function initNewNoteCall(action$, store) {
  return action$.ofType(types.INIT_NEW_NOTE_CALL).switchMap(function (_ref) {
    var payload = _ref.payload;
    var storage = store.value.storage;
    var isOpenImagePicker = storage.isOpenImagePicker,
        storageIsPost = storage.isPost,
        groupIds = storage.groupIds;
    var type = payload.type,
        _payload$pickerModalT = payload.pickerModalTitle,
        pickerModalTitle = _payload$pickerModalT === void 0 ? '' : _payload$pickerModalT,
        isProAdd = payload.isProAdd;
    var isWater = type === 'water';
    var isChatMedia = type === 'chatMedia';
    var isOpenImage = !isWater && (isChatMedia || isOpenImagePicker);
    var openPage = isOpenImage ? actions.openImagePickerModal({
      pickerModalTitle: pickerModalTitle,
      showHistory: type === 'food',
      showHeader: true,
      showDraft: true
    }) : actions.openNoteEditorPage({
      type: type,
      isNew: true,
      isProAdd: isProAdd
    });
    var isPost = storageIsPost;
    var showPostSwitch = defaultShowPostSwitch;
    var showGroupPostSwitch = defaultShowPostSwitch;
    var newGroupIds = groupIds; //水不能分享到社群

    if (isWater) {
      isPost = false;
      showPostSwitch = false;
      showGroupPostSwitch = false;
    }

    if (isProType) {
      isPost = isProAdd;
      showGroupPostSwitch = isProAdd;
      newGroupIds = isProAdd ? groupIds : [];
    }

    var params = _objectSpread({}, payload, {
      showPostSwitch: showPostSwitch,
      showGroupPostSwitch: showGroupPostSwitch,
      isPost: isPost,
      groupIds: newGroupIds
    });

    return [actions.initNewNote(params), actions.setCurrentAssetIndex(0), actions.startSelectMedia(), openPage];
  });
};
export var resetNewNoteCall = function resetNewNoteCall(action$, store) {
  return action$.ofType(types.RESET_NEW_NOTE_CALL).concatMap(function () {
    return [actions.pageGoBack(), actions.resetNewNote(), actions.setCurrentAssetIndex(0), actions.startSelectMedia()];
  });
};
export var initEditNoteCall = function initEditNoteCall(action$, store) {
  return action$.ofType(types.INIT_EDIT_NOTE_CALL).concatMap(function (_ref2) {
    var payload = _ref2.payload;
    var id = payload.id,
        currentAssetIndex = payload.currentAssetIndex,
        type = payload.type,
        isProAdd = payload.isProAdd,
        editNote = payload.editNote,
        isEditPost = payload.isEditPost;
    var _store$value = store.value,
        storage = _store$value.storage,
        notes = _store$value.diary.notes;
    var storageIsPost = storage.isPost,
        groupIds = storage.groupIds;
    var isWater = type === 'water';
    var note = editNote || _.find(notes, {
      id: id
    }) || {
      assets: [],
      text: '',
      taken_at: '',
      selectTags: []
    };
    var hasSocialPosts = note.has_social_posts;
    var isPost = storageIsPost || isEditPost;
    var showPostSwitch = !hasSocialPosts && defaultShowPostSwitch;
    var showGroupPostSwitch = defaultShowPostSwitch;
    var newGroupIds = groupIds;

    if (isWater) {
      isPost = false;
      showPostSwitch = false;
      showGroupPostSwitch = false;
    }

    if (isProType) {
      isPost = isProAdd;
      showGroupPostSwitch = isProAdd;
      newGroupIds = isProAdd ? groupIds : [];
    }

    var params = {
      note: note,
      id: id,
      showPostSwitch: showPostSwitch,
      showGroupPostSwitch: showGroupPostSwitch,
      isPost: isPost,
      groupIds: newGroupIds,
      isProAdd: isProAdd
    };
    return [actions.initEditNote(params), actions.openNoteEditorPage({
      currentAssetIndex: currentAssetIndex,
      type: type,
      isProAdd: isProAdd
    })];
  });
};
export var openNoteEditorPage = function openNoteEditorPage(action$, store) {
  return action$.ofType(types.OPEN_NOTE_EDITOR_PAGE).switchMap(function (_ref3) {
    var _ref3$payload = _ref3.payload,
        currentAssetIndex = _ref3$payload.currentAssetIndex,
        type = _ref3$payload.type,
        isNew = _ref3$payload.isNew,
        isProAdd = _ref3$payload.isProAdd;
    var isOpenProNoteEditorPage = isProType && !isProAdd;
    var dispatch = [];

    switch (true) {
      case type === 'FoodNote' || type === 'food':
        if (isNew) {
          dispatch.push(actions.addNewNoteEmptyAsset());
        }

        if (currentAssetIndex !== undefined) {
          dispatch.push(actions.setCurrentAssetIndex(currentAssetIndex));
        }

        dispatch.push(isOpenProNoteEditorPage ? actions.openProNoteEditorPage() : actions.openFoodNoteEditorPage());
        break;

      default:
        dispatch.push(actions.openNoteEditconfirmPage());
        break;
    }

    return dispatch;
  });
};
export var deleteNote = function deleteNote(action$, store) {
  return action$.ofType(types.DELETE_NOTE_CALL).switchMap(function (_ref4) {
    var _ref4$payload = _ref4.payload,
        id = _ref4$payload.id,
        goBack = _ref4$payload.goBack;
    var goBackAction = goBack ? actions.pageGoBack() : actions.empty();
    return Observable.fromPromise(note.deleteNote(id)).concatMap(function (res) {
      return [actions.deleteNoteDone(), goBackAction];
    }).catch(function (err) {
      return Observable.of(actions.deleteNoteFail(err));
    });
  });
};
export var createNoteCall = function createNoteCall(action$, store) {
  return action$.ofType(types.CREATE_NOTE_CALL).exhaustMap(function () {
    var _store$value2 = store.value,
        netInfo = _store$value2.netInfo,
        newNote = _store$value2.note.newNote,
        clientId = _store$value2.client.clientId,
        member = _store$value2.member;
    var isConnected = netInfo.isConnected;

    if (isConnected) {
      var isProAdd = newNote.isProAdd,
          type = newNote.type,
          assets = newNote.assets,
          date = newNote.date,
          _newNote$note = newNote.note,
          isPost = _newNote$note.isPost,
          newDate = _newNote$note.date,
          groupIds = _newNote$note.groupIds;

      var isFromCamera = _.some(assets, '_fromCamera');

      var isChangeDate = date !== newDate;
      return Observable.concat(Observable.of(actions.showLoading()), Observable.of(isFromCamera ? actions.uploadAllAssetsCall() : actions.empty()), isFromCamera ? action$.ofType(types.UPLOAD_ALL_ASSETS_DONE).take(1) : Observable.of(actions.empty()), Observable.of(actions.prepareForUpdateNote({
        member: member
      })), Observable.defer(function () {
        return note.createOrUpdateNote(_objectSpread({}, store.value.note.newNote, {
          clientId: clientId
        }));
      }).retry(2).concatMap(function (note) {
        var post = note.post;
        var newActions = [actions.createNoteDone({
          note: note
        }), isChangeDate ? actions.changeDate({
          date: moment(newDate)
        }) : actions.empty()];

        if (post) {
          if (post.public) {
            newActions.push(actions.createPost({
              post: post
            }));
          } else {
            newActions.push(actions.showI18nMessageCall({
              message: 'message.postError',
              type: 'danger',
              icon: 'warning'
            }));
          }
        }

        if (isProType) {
          if (isProAdd) {
            newActions.push(actions.getMyPostsCall({
              type: 'first'
            }));
          } else {
            newActions.push(actions.getDiaryCall({
              clientId: clientId,
              date: newDate
            }));
          }

          newActions.push(actions.pageGoNotePrev({
            type: type
          }));
        } else if (handleGetMyPosts) {
          newActions.push(actions.getMyPostsCall({
            type: 'first'
          }));
          newActions.push(actions.pageGoNotePrev({
            type: type
          }));
        } else {
          var isGroup = groupIds && groupIds.length !== 0;
          var openPage = isPost || isGroup ? actions.openSociaPageCall({
            isPost: isPost,
            isGroup: isGroup,
            groupIds: groupIds
          }) : actions.pageGoNotePrev({
            type: type
          });
          newActions = [].concat(_toConsumableArray(newActions), [actions.getDiaryCall({
            clientId: clientId,
            date: newDate
          }), openPage]);
        }

        return newActions;
      }).catch(function (err) {
        return Observable.of(actions.createNoteFail(err));
      }), Observable.of(actions.hideLoading()));
    } else {
      return Observable.of(actions.setModalNetError());
    }
  });
};
export var updateNoteCall = function updateNoteCall(action$, store) {
  return action$.ofType(types.UPDATE_NOTE_CALL).switchMap(function (_ref5) {
    var parent = _ref5.payload.parent;
    var _store$value3 = store.value,
        notes = _store$value3.diary.notes,
        clientId = _store$value3.client.clientId;

    var noteToUpdate = _.find(notes, {
      id: parent.id
    });

    return Observable.defer(function () {
      return note.createOrUpdateNote(_objectSpread({}, noteToUpdate, {
        clientId: clientId
      }));
    }).map(function (res) {
      return actions.updateNoteDone(res);
    }).catch(function (err) {
      return Observable.of(actions.updateNoteFail(err));
    });
  });
};
export var uploadAllAssetsCall = function uploadAllAssetsCall(action$, store) {
  return action$.ofType(types.UPLOAD_ALL_ASSETS_CALL).switchMap(function () {
    var newNote = store.value.note.newNote;

    var assets = _.filter(newNote.assets, '_fromCamera'); // forkJoin to fire together and wait for ALL done


    return Observable.forkJoin.apply(Observable, _toConsumableArray(_.map(assets, function (asset) {
      return Observable.defer(function () {
        return note.uploadMediaDirect(asset);
      }).retry(2).catch(function (err) {
        return Observable.defer(function () {
          return note.uploadMedia(asset);
        }).retry(2);
      });
    }))).concatMap(function (_uids) {
      return [].concat(_toConsumableArray(_uids.map(function (_uid) {
        return actions.uploadAssetDone({
          _uid: _uid
        });
      })), [actions.uploadAllAssetsDone()]);
    }).catch(function (err) {
      return Observable.of(actions.uploadAllAssetsFail(err));
    });
  });
};
export var finishSelectMedia = function finishSelectMedia(action$, store) {
  return action$.ofType(types.FINISH_SELECT_MEDIA).switchMap(function () {
    var assets = store.value.note.newNote.assets;
    return _.flatMap(_.filter(assets, '_fromCamera'), function (asset) {
      var dispatch = [];
      var _asset$type = asset.type,
          type = _asset$type === void 0 ? 'image' : _asset$type,
          uploadInfo = asset.uploadInfo,
          uri = asset.uri,
          width = asset._width,
          height = asset._height,
          _processed = asset._processed;
      var isVideo = type === 'video';

      var _Asset$fromModule = Asset.fromModule(uri),
          extname = _Asset$fromModule.type;

      if (!uploadInfo) {
        dispatch.push(actions.getMediaUploadInfoCall({
          uri: uri,
          extname: extname
        }));
      }

      if (!_processed && !isVideo) {
        dispatch.push(actions.processImageCall({
          uri: uri,
          width: width,
          height: height
        }));
      }

      return dispatch;
    });
  });
};
export var getMediaUploadInfo = function getMediaUploadInfo(action$, store) {
  return action$.ofType(types.GET_MEDIA_UPLOAD_INFO_CALL).concatMap(function (_ref6) {
    var _ref6$payload = _ref6.payload,
        uri = _ref6$payload.uri,
        extname = _ref6$payload.extname;
    return Observable.defer(function () {
      return note.getMediaUploadInfo({
        extname: extname
      });
    }).retry(2).map(function (_ref7) {
      var url = _ref7.url,
          storage = _ref7.storage,
          key = _ref7.key,
          formData = _ref7.form_data,
          resultUrl = _ref7.result_url;
      var uploadInfo = {
        url: url,
        storage: storage,
        key: key,
        formData: formData,
        resultUrl: resultUrl
      };
      return actions.getMediaUploadInfoDone({
        uri: uri,
        uploadInfo: uploadInfo
      });
    }).catch(function (err) {
      return Observable.of(actions.getMediaUploadInfoFail({
        uri: uri,
        errMessage: err.message
      }));
    });
  });
};
export var processImage = function processImage(action$, store) {
  return action$.ofType(types.PROCESS_IMAGE_CALL).concatMap(function (_ref8) {
    var _ref8$payload = _ref8.payload,
        uri = _ref8$payload.uri,
        width = _ref8$payload.width,
        height = _ref8$payload.height;
    return Observable.defer(function () {
      return note.processImageAsync({
        uri: uri,
        width: width,
        height: height
      });
    }).retry(2).map(function (_ref9) {
      var processedUri = _ref9.processedUri;
      return actions.processImageDone({
        uri: uri,
        processedUri: processedUri
      });
    }).catch(function (err) {
      return Observable.of(actions.processImageFail({
        uri: uri,
        errMessage: err.message
      }));
    });
  });
};
export var getHistoryAssets = function getHistoryAssets(action$, store) {
  return action$.ofType(types.GET_HISTORY_ASSETS_CALL).switchMap(function (_ref10) {
    var _ref10$payload = _ref10.payload;
    _ref10$payload = _ref10$payload === void 0 ? {} : _ref10$payload;
    var older = _ref10$payload.older;
    var oldestTimestamp = store.value.note.historyAssets.oldestTimestamp;
    var before = older ? oldestTimestamp : undefined;
    return Observable.fromPromise(note.getHistoryAssets({
      before: before
    })).map(function (assets) {
      return actions.getHistoryAssetsDone({
        assets: assets
      });
    }).catch(function (err) {
      return Observable.of(actions.getHistoryAssetsFail(err));
    });
  });
};
export var editSixGroup = function editSixGroup(action$, store) {
  return action$.ofType(types.EDIT_SIX_GROUP).switchMap(function (_ref11) {
    var _ref11$payload = _ref11.payload;
    _ref11$payload = _ref11$payload === void 0 ? {} : _ref11$payload;
    var item = _ref11$payload.item;
    return [actions.openAddSixgroupPage(), actions.initSelectedSixgroup({
      item: item
    })];
  });
};
export var editFoodStuffs = function editFoodStuffs(action$, store) {
  return action$.ofType(types.EDIT_FOOD_STUFFS).switchMap(function () {
    return [actions.openAddDBFoodStuffsPage(), actions.initSelectedFoodStuffs()];
  });
};
export var editCalorie = function editCalorie(action$, store) {
  return action$.ofType(types.EDIT_CALORIE).switchMap(function (_ref12) {
    var _ref12$payload = _ref12.payload;
    _ref12$payload = _ref12$payload === void 0 ? {} : _ref12$payload;
    var item = _ref12$payload.item,
        kind = _ref12$payload.kind,
        item_name = _ref12$payload.item_name;
    return [actions.initSelectedCalorie({
      item: item,
      kind: kind,
      item_name: item_name
    }), actions.openAddCaloriePage()];
  });
};
export var editMoveItems = function editMoveItems(action$, store) {
  return action$.ofType(types.EDIT_MOVE_ITEMS).switchMap(function () {
    return [actions.openAddMoveItemsPage()];
  });
};
export var deleteNoteAsset = function deleteNoteAsset(action$, store) {
  return action$.ofType(types.DELETE_NOTE_ASSET_CALL).concatMap(function (_ref13) {
    var parent = _ref13.payload,
        asset = _ref13.asset;
    return [actions.markDestroyAsset(parent, asset), actions.updateNoteCall(parent), actions.pageGoBack()];
  });
};
export var getNoteTags = function getNoteTags(action$, store) {
  return action$.ofType(types.GET_NOTE_TAGS_CALL).switchMap(function (_ref14) {
    var payload = _ref14.payload;
    return Observable.fromPromise(note.getNoteTags()).map(function (res) {
      return actions.getNoteTagsDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getNoteTagsFail(err));
    });
  });
};
export var updateComment = function updateComment(action$, store) {
  return action$.ofType(types.UPDATE_COMMENT_CALL).switchMap(function (_ref15) {
    var payload = _ref15.payload;
    return Observable.fromPromise(note.updateComment(payload)).map(function (res) {
      return actions.updateCommentDone(payload);
    }).catch(function (err) {
      return Observable.of(actions.updateCommentFail(err));
    });
  });
};
export var deleteComment = function deleteComment(action$, store) {
  return action$.ofType(types.DELETE_COMMENT_CALL).switchMap(function (_ref16) {
    var payload = _ref16.payload;
    return Observable.fromPromise(note.deleteComment(payload)).map(function (res) {
      return actions.deleteCommentDone(payload);
    }).catch(function (err) {
      return Observable.of(actions.deleteCommentFail(err));
    });
  });
};
export var setNewNoteCall = function setNewNoteCall(action$, store) {
  return action$.ofType(types.SET_NEW_NOTE_CALL).switchMap(function (_ref17) {
    var payload = _ref17.payload;
    return [actions.setNewNoteDone(payload), actions.startSelectMedia(), actions.finishSelectMedia()];
  }).catch(function (err) {
    return Observable.of(actions.setNewNoteFail(err));
  });
};