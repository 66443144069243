import { createAction } from 'redux-actions';
import * as types from '../../store/types';
export var initNewNote = createAction(types.INIT_NEW_NOTE);
export var initNewNoteCall = createAction(types.INIT_NEW_NOTE_CALL);
export var resetNewNote = createAction(types.RESET_NEW_NOTE);
export var resetNewNoteCall = createAction(types.RESET_NEW_NOTE_CALL);
export var initEditNote = createAction(types.INIT_EDIT_NOTE);
export var initEditNoteCall = createAction(types.INIT_EDIT_NOTE_CALL);
export var updateNewNote = createAction(types.UPDATE_NEW_NOTE);
export var updateNewNoteAsset = createAction(types.UPDATE_NEW_NOTE_ASSET);
export var removeNewNoteAsset = createAction(types.REMOVE_NEW_NOTE_ASSET);
export var removeNewNoteAssetImage = createAction(types.REMOVE_NEW_NOTE_ASSET_IMAGE);
export var confirmNewNoteFoodStuff = createAction(types.CONFIRM_NEW_NOTE_FOODSTUFF);
export var addNewNoteSixGroup = createAction(types.ADD_NEW_NOTE_SIXGROUP);
export var addNewNoteCalorie = createAction(types.ADD_NEW_NOTE_CALORIE);
export var addNewNoteEmptyAsset = createAction(types.ADD_NEW_NOTE_EMPTY_ASSET);
export var createNoteCall = createAction(types.CREATE_NOTE_CALL);
export var createNoteDone = createAction(types.CREATE_NOTE_DONE);
export var createNoteFail = createAction(types.CREATE_NOTE_FAIL);
export var deleteNoteCall = createAction(types.DELETE_NOTE_CALL);
export var deleteNoteDone = createAction(types.DELETE_NOTE_DONE);
export var deleteNoteFail = createAction(types.DELETE_NOTE_FAIL);
export var deleteNoteAssetCall = createAction(types.DELETE_NOTE_ASSET_CALL);
export var deleteNoteAssetDone = createAction(types.DELETE_NOTE_ASSET_DONE);
export var deleteNoteAssetFail = createAction(types.DELETE_NOTE_ASSET_FAIL);
export var getMediaUploadInfoCall = createAction(types.GET_MEDIA_UPLOAD_INFO_CALL);
export var getMediaUploadInfoDone = createAction(types.GET_MEDIA_UPLOAD_INFO_DONE);
export var getMediaUploadInfoFail = createAction(types.GET_MEDIA_UPLOAD_INFO_FAIL);
export var processImageCall = createAction(types.PROCESS_IMAGE_CALL);
export var processImageDone = createAction(types.PROCESS_IMAGE_DONE);
export var processImageFail = createAction(types.PROCESS_IMAGE_FAIL);
export var getNotesDone = createAction(types.GET_NOTES_DONE);
export var startSelectMedia = createAction(types.START_SELECT_MEDIA);
export var restartSelectMedia = createAction(types.RESTART_SELECT_MEDIA);
export var selectMedia = createAction(types.SELECT_MEDIA);
export var finishSelectMedia = createAction(types.FINISH_SELECT_MEDIA);
export var selectMediaCall = createAction(types.SELECT_MEDIA_CALL);
export var selectMediaDone = createAction(types.SELECT_MEDIA_DONE);
export var selectMediaFail = createAction(types.SELECT_MEDIA_FAIL);
export var uploadAllAssetsCall = createAction(types.UPLOAD_ALL_ASSETS_CALL);
export var uploadAllAssetsDone = createAction(types.UPLOAD_ALL_ASSETS_DONE);
export var uploadAllAssetsFail = createAction(types.UPLOAD_ALL_ASSETS_FAIL);
export var uploadAssetDone = createAction(types.UPLOAD_ASSET_DONE);
export var getHistoryAssetsCall = createAction(types.GET_HISTORY_ASSETS_CALL);
export var getHistoryAssetsDone = createAction(types.GET_HISTORY_ASSETS_DONE);
export var getHistoryAssetsFail = createAction(types.GET_HISTORY_ASSETS_FAIL);
export var editFoodStuffs = createAction(types.EDIT_FOOD_STUFFS);
export var editSixGroup = createAction(types.EDIT_SIX_GROUP);
export var editCalorie = createAction(types.EDIT_CALORIE);
export var deleteSixGroup = createAction(types.REMOVE_SIX_GROUP);
export var setCurrentAssetIndex = createAction(types.SET_CURRENT_ASSET_INDEX);
export var initSelectedFoodStuffs = createAction(types.INIT_SELECTED_FOOD_STUFFS);
export var initSelectedSixgroup = createAction(types.INIT_SELECTED_SIX_GROUP);
export var initSelectedCalorie = createAction(types.INIT_SELECTED_CALORIE);
export var addFoodStuff = createAction(types.ADD_FOOD_STUFF);
export var updateFoodStuff = createAction(types.UPDATE_FOOD_STUFF);
export var removeFoodStuff = createAction(types.REMOVE_FOOD_STUFF);
export var markDestroyAsset = createAction(types.MARK_DESTROY_ASSET);
export var updateNoteCall = createAction(types.UPDATE_NOTE_CALL);
export var updateNoteDone = createAction(types.UPDATE_NOTE_DONE);
export var updateNoteFail = createAction(types.UPDATE_NOTE_FAIL);
export var editMoveItems = createAction(types.EDIT_MOVE_ITEMS);
export var addActivityItems = createAction(types.ADD_ACTIVITY_ITEMS);
export var updateActivityItems = createAction(types.UPDATE_ACTIVITY_ITEMS);
export var removeActivityItems = createAction(types.REMOVE_ACTIVITY_ITEMS);
export var confirmNewNoteActivityItems = createAction(types.CONFIRM_NEW_NOTE_ACTIVITY_ITEMS);
export var addActivitySessions = createAction(types.ADD_ACTIVITY_SESSIONS);
export var updateActivitySessions = createAction(types.UPDATE_ACTIVITY_SESSIONS);
export var removeActivitySessions = createAction(types.REMOVE_ACTIVITY_SESSIONS);
export var confirmNewNoteActivitySessions = createAction(types.CONFIRM_NEW_NOTE_ACTIVITY_SESSIONS);
export var prepareForUpdateNote = createAction(types.PREPARE_FOR_UPDATE_NOTE);
export var getNoteTagsCall = createAction(types.GET_NOTE_TAGS_CALL);
export var getNoteTagsDone = createAction(types.GET_NOTE_TAGS_DONE);
export var getNoteTagsFail = createAction(types.GET_NOTE_TAGS_FAIL);
export var updateCommentCall = createAction(types.UPDATE_COMMENT_CALL);
export var updateCommentDone = createAction(types.UPDATE_COMMENT_DONE);
export var updateCommentFail = createAction(types.UPDATE_COMMENT_FAIL);
export var deleteCommentCall = createAction(types.DELETE_COMMENT_CALL);
export var deleteCommentDone = createAction(types.DELETE_COMMENT_DONE);
export var deleteCommentFail = createAction(types.DELETE_COMMENT_FAIL);
export var setNewNoteCall = createAction(types.SET_NEW_NOTE_CALL);
export var setNewNoteDone = createAction(types.SET_NEW_NOTE_DONE);
export var setNewNoteFail = createAction(types.SET_NEW_NOTE_FAIL);