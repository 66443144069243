import _ from 'lodash';
import { Observable } from 'rxjs';
import './RxOperators';
import * as actions from '../actions';
import * as types from '../../store/types';
import { essays } from '../../services';
export var getEssays = function getEssays(action$, store) {
  return action$.ofType(types.GET_ESSAYS_CALL).switchMap(function (_ref) {
    var _ref$payload = _ref.payload,
        payload = _ref$payload === void 0 ? {} : _ref$payload;
    return Observable.fromPromise(essays.getEssays(payload)).map(function (res) {
      return actions.getEssaysDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getEssaysFail(err));
    });
  });
};
export var getEssaysCategory = function getEssaysCategory(action$, store) {
  return action$.ofType(types.GET_ESSAYS_CATEGORY_CALL).switchMap(function (_ref2) {
    var payload = _ref2.payload,
        openPage = _ref2.payload.openPage;
    return Observable.concat(Observable.of(openPage ? actions.openEssaysListPage(payload) : actions.empty()), Observable.fromPromise(essays.getEssays(payload)).map(function (res) {
      return actions.getEssaysCategoryDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getEssaysCategoryFail(err));
    }));
  });
};
export var getEssaysTag = function getEssaysTag(action$, store) {
  return action$.ofType(types.GET_ESSAYS_TAG_CALL).switchMap(function (_ref3) {
    var payload = _ref3.payload;
    return Observable.concat(Observable.of(actions.openEssaysTagListPage(payload)), Observable.fromPromise(essays.getEssaysTag(payload)).map(function (res) {
      return actions.getEssaysTagDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getEssaysTagFail(err));
    }));
  });
};