import api from '../api';
export var getActivityItems = function getActivityItems() {
  return api.get('/v4/activity_items');
};
export var getRoutines = function getRoutines() {
  return api.get('/v4/routines');
};
export var getMyActivityItems = function getMyActivityItems() {
  return api.get('/v4/activity_items/my_items');
};
export var addMyActivityItem = function addMyActivityItem(param) {
  return api.post('/v4/activity_items/create_my_item', param);
};
export var deleteMyActivityItem = function deleteMyActivityItem(param) {
  return api.delete('/v4/activity_items/destroy_my_item', param);
};