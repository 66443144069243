import { Observable } from 'rxjs';
import { invoice } from '../../services';
import './RxOperators';
import * as actions from '../actions';
import * as types from '../../store/types';
export var getInvoices = function getInvoices(action$, store) {
  return action$.ofType(types.GET_INVOICES_CALL).switchMap(function (_ref) {
    var payload = _ref.payload;
    return Observable.fromPromise(invoice.getInvoices()).map(function (res) {
      return actions.getInvoicesDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getInvoicesFail(err));
    });
  });
};