export default {
  common: {
    all: 'All',
    button: {
      add: 'Add',
      edit: 'Edit',
      orderby: 'Order by',
      complete: 'Complete',
      completed: 'Completed',
      next: 'Next step',
      submit: 'Submit',
      ok: 'Ok',
      no: 'No',
      close: 'Close',
      cancel: 'Cancel',
      update: 'Update',
      delete: 'Delete',
      send: 'Send',
      join: 'Join',
      back: 'Back',
      skip: 'Skip',
      save: 'Save',
      list: 'List',
      copy: 'Copy',
      reload: 'Reload',
      rotate: 'Rotate',
      more: 'Read More',
      default: 'Default',
      manage: 'Manage'
    },
    enterMessage: 'Enter message here',
    foodseperator: ', ',
    of: 'of',
    inputPlaceholder: 'Please enter {{content}}',
    loading: 'Loading',
    empty: 'Empty data',
    message: {
      title: 'Notification',
      serverError: 'No network please wait',
      noNet: 'Internet not connected',
      isNew: 'You are using the lasted version',
      update: 'New version available\nversion{{appVersion}}\n',
      backStep: 'The current edits will not be save if you go back',
      postError: 'Your post contains sensitive contents. Please edit it again.',
      deleteCheck: 'Are you sure you want to delete this?',
      cancelCheck: 'Are you sure you want to cancel this?'
    },
    time: {
      title: 'Time',
      selectDate: 'Select Date',
      select: 'Select time',
      minute: 'min',
      second: 'sec',
      today: 'Today'
    },
    tabs: {
      home: {
        title: 'Home',
        label: 'Home'
      },
      diary: {
        title: 'Diary',
        label: 'Diary'
      },
      information: {
        title: 'Information',
        label: 'Information'
      },
      coach: {
        title: 'Expert',
        label: 'Expert',
        anTitle: 'Friend'
      },
      social: {
        title: 'Social',
        label: 'Social'
      },
      explore: {
        title: 'Explore',
        label: 'Explore'
      },
      notification: {
        title: 'Message',
        label: 'Message'
      },
      profile: {
        title: 'Profile',
        label: 'Profile'
      },
      client: {
        title: 'Client',
        label: 'Client',
        ffTitle: 'Member'
      },
      dialog: {
        title: 'Client dialog',
        label: 'Client dialog',
        ffTitle: 'Member dialog'
      },
      setting: {
        title: 'Setting',
        label: 'Setting'
      },
      shop: {
        title: 'Shop',
        label: 'Shop'
      }
    },
    unit: {
      pound: 'pound',
      kg: 'kg',
      cm: 'cm',
      day: 'day',
      kcal: 'Kcal',
      servings: 'portions',
      group: 'set',
      times: 'rep',
      number: '{{unit}}times',
      ml: 'ml',
      gram: 'g',
      calorie: 'calorie',
      each: 'each{{unit}}'
    }
  },
  food: {
    normalServing: 'Standard Portions',
    weight: 'Weight',
    number: 'Portions',
    info: 'Info',
    mealByOrder: {
      1: 'Breakfast',
      2: 'Snack',
      3: 'Lunch',
      4: 'Snack',
      5: 'Dinner',
      6: 'Snack',
      7: 'Snack'
    }
  },
  dataChart: {
    title: 'Data Chart',
    healthTitle: 'Health',
    health: 'Health Chart',
    nutrition: 'Nutrition',
    move: 'Movement',
    week: 'Week',
    month: 'Month',
    year: 'Year',
    M: 'M',
    Y: 'Y',
    addData: 'Add data',
    noData: 'No data available',
    notShow: 'Disabled',
    entered: 'Entered',
    notEntered: 'Not entered',
    errorTitle: 'Invalid Number',
    errorText: '{{title}} Please enter a valid number between {{inputMin}} and {{inputMax}}.',
    list: 'Data List'
  },
  login: {
    login: 'Login',
    register: 'Register',
    fbLogin: 'Facebook Login',
    fbRegister: 'Facebook Register',
    wechatLogin: 'WeChat Login',
    wechatRegister: 'WeChat Register',
    passwordLogin: 'Password Login',
    account: 'Account',
    password: 'Password',
    name: 'Name',
    selectLanguage: 'Select Your Language',
    or: 'or',
    accountPlaceholder: 'Please enter email',
    passwordPlaceholder: 'At least 8 digits',
    namePlaceholder: 'Your Name',
    toRegister: 'To Register',
    toLogin: 'To Login',
    loginErrorTitle: 'Error',
    loginErrorMessage: 'Please confirm if your account or password is correct.',
    loginErrorMessageEmail: 'Please activate Email verification letter.',
    loginErrorOverDays: 'This account doesn\'t exist',
    loginErrorEmailFormat: 'Please enter valid Email',
    loginErrorPasswordMissing: 'Please enter password',
    noPermission: 'You are not allowed to log in.',
    investigation: 'Your account is under investigation.',
    registerTitle: 'Verification email sent.',
    registerMessage: 'Please click the link in the email to complete the registration.',
    registerErrorTitle: 'This account is already used.',
    registerErrorMessage: 'This email is already used.',
    phone: {
      title: 'Phone Login',
      codeLogin: 'Verification code login',
      passwordLogin: 'Password login',
      number: 'Phone Number',
      code: 'Code',
      numberPlaceholder: 'Phone Number',
      codePlaceholder: 'Verification Code',
      pwdPlaceholder: 'Password',
      countryCode: 'Country Code',
      getCode: 'Get Verification Code',
      codeError: 'Invalid Verification Code',
      pwdError: 'Invalid Number or Password',
      reGetCode: 'Resend Verification Code',
      getCodeError: 'Resend Verification Code Error'
    },
    bind: {
      title: 'Binding',
      codeLogin: 'Verification Code Login',
      passwordLogin: 'Password Login'
    }
  },
  forget: {
    title: 'Forget password?',
    titleMessage: 'Please enter the email, and check your inbox for a password reset email.',
    ffTitleMessage: 'Please enter your ID, and check your email inbox for a password reset email.',
    sendPassword: 'Password reset.',
    sendTitle: 'Link sent.',
    sendMessage: 'Please reset your password by following the instructions in the email.',
    notExist: 'Email does not exist'
  },
  target: {
    title: 'Set Plan',
    projectTarget: 'Target',
    end: 'Goal set successfully.',
    start: 'Start!',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    birthday: 'Birthday',
    nowWeight: 'Current Weight',
    nowHeight: 'Current Height',
    water: 'Water Intake Goal',
    waterPlaceholder: 'Please enter your water intake goal or reset automatically',
    reSetWater: 'Reset water',
    calorie: 'Calorie Goal',
    targetWeight: 'Weight Goal',
    warningWeight: 'Weight goal should be less than current weight',
    warningBmi: 'The weight goal is too light! May cause weight regain or health issues',
    activity: {
      title: 'Daily Activity',
      low: {
        title: 'Sedentary',
        text: 'Your daily routine is with little or no physical activity. ex:You spend a lot of time sitting at a desk. '
      },
      slow: {
        title: 'Light Activity',
        text: 'Your daily routine is low-intensity and will not make you sweat. ex:easy walking or stretching.'
      },
      mid: {
        title: 'Moderately Active',
        text: 'You have moderate level exercise one hour a day. ex: riding a bike or power walking.'
      },
      high: {
        title: 'Very Active',
        text: 'You have at least one hour of high intensity workout a day. ex: jogging, strength training, playing basketball or doing high intensity aerobics.'
      }
    },
    warningLoss: 'The weight loss pace is too fast. It might affect your health, and it\'s more likely for you to regain weight later.',
    warningCalorie: 'Lower than BMR',
    calorieTitle: 'Daily Calorie intake',
    monthKg: 'Monthly weight loss',
    addMonthKg: 'Weekly weight loss',
    weeklyKg: 'Month weight add',
    addWeeklyKg: 'Weekly weight add',
    targetDay: 'Estimated time to reach the plan',
    targetSet: 'Your target during this period is set',
    PlanCountdown: 'Plan countdown',
    ResetPlan: 'Reset plan',
    targetIntake: 'Nutrition Target',
    welcome: 'Welcome',
    welcomeText: 'During the program time, we will be with you along the way to help you to reach the goal!',
    lossWeight: 'Weight Loss',
    addMuscle: 'Muscle Gain',
    addWeight: 'Weight Gain',
    keep: 'Maintain Weight',
    select: 'Goals',
    warn: 'During the program your goal weight and goal calories will be set automatically by the app. Please talk to your dietitian if you want to adjust the numbers.',
    targetEnd: {
      default: {
        text1: 'You have entered all the basic information. Your personalize diet plan has been set!',
        text2: 'The calories and the portions of six food groups are based on the your personal data. The more dedicate you are into the plan, the closer you are about to reach your goal!'
      },
      child: {
        text1: 'You have entered all the basic information of your child. Your child\'s personalize diet plan has been set!',
        text2: 'The calories and the portions of six food groups are based on your child\'s data.The higher completion of daily diet, exercise, and lifestyle, the more helpful it is to improve the potential of growth and maintain a healthy weight!'
      },
      age65: {
        text1: 'The muscle mass of elderly will directly affect the risk of death. Therefore the plan will focus on reducing bodyfat% or increasing muscle mass to make you have good health and great figure at the same time.',
        text2: 'The calories and the portions of six food groups are based on the your personal data. The more dedicate you are into the plan, the closer you are about to reach your goal!'
      },
      bmi18: {
        text1: 'Your BMI and weight is lower than standard. Therefore the plan will focus on reducing bodyfat% or increasing muscle mass to make your shape and curve more obvious.',
        text2: 'The calories and the portions of six food groups are based on the your personal data. The more dedicate you are into the plan, the closer you are about to reach your goal!'
      },
      bmi24: {
        text1: 'You have a healthy BMI and weight. Therefore the plan will focus on reducing bodyfat% to make your shape and curve more obvious.',
        text2: 'The calories and the portions of six food groups are based on the your personal data. The more dedicate you are into the plan, the closer you are about to reach your goal!'
      }
    }
  },
  registrationCode: {
    title: 'Enter id',
    titleMessage: "You nutritionist/dietician will be able to view the information you provide:\n      - Basic info\n      - Body and nutrition intake details\n      - Conversation\n      - Goals\n      - Meal comments and calculations",
    inputPlaceholder: 'Please enter chat room id',
    modalTitle: 'Successful',
    modalMessage: 'Is the plan to be successful and are you ready to lose weight with Cofit?',
    modalText: 'Ready!',
    error: 'Chat room id not exists',
    errorMessage: 'Wrong chat room id'
  },
  setting: {
    account: 'Account',
    title: 'Setting',
    language: 'Language',
    switchLanguage: 'Switch language',
    eula: 'EULA',
    privacyPolicy: 'Privacy policy',
    version: 'Version',
    logout: 'Logout',
    phoneName: 'Phone Name',
    system: 'System',
    update: 'Checking for Update',
    appSetting: 'Settings',
    appStore: 'go to Store',
    web: 'Website',
    blockList: 'Blocklist',
    defaultOpenImage: 'default open photos',
    defaultIsPost: 'default public posts to the community',
    defaultEditing: 'default photo crop',
    defaultIsTwoPost: 'default two-page spread',
    defaultOpenBarCode: 'default open bardcode',
    defaultPedometer: 'default open pedometer',
    issue: 'report an issue',
    issuePlaceholder: 'please describe your issue',
    accountPlaceholder: 'Account',
    agree: 'by continuing you agree to'
  },
  customerService: {
    title: 'Ask',
    ask: 'I want to ask「{{what}}」'
  },
  bodyAttrs: {
    calorie: 'Calories',
    height: 'Height',
    weight: 'Weight',
    body_fat_mass_percentage: 'Body Fat %',
    waist_circumference: 'Waist Measurements',
    hip_circumference: 'Hip Measurements',
    muscle_mass: 'Muscle Mass',
    bmi: 'BMI',
    waist_hip_ratio: 'Waist/Hip Ratio',
    total_body_water_percentage: 'Body Water %',
    systolic_blood_pressure: 'SBP',
    diastolic_blood_pressure: 'DBP',
    pulse: 'Pulse',
    blood_sugar: 'Blood sugar',
    total_cholesterol: 'Total cholesterol',
    high_density_lipoprotein: 'HDL-C',
    low_density_lipoprotein_cholesterol: 'LDL-C',
    triglyceride: 'Triacylglycerol',
    blood_pressure: 'Blood Pressure',
    muscle_mass_percentage: 'Muscle %',
    lean_body_mass: 'Lean body mass',
    fruit: 'Fruit',
    fruits: 'Fruits',
    vegetables: 'Vegetables',
    grains: 'Grains',
    meat: 'Protein Foods',
    milk: 'Dairy',
    oil: 'Oils and Fats',
    meat_beans_low_fat: 'Low Fat Protein',
    meat_beans_medium_fat: 'Medium Fat Protein',
    meat_beans_high_fat: 'High Fat Protein',
    meat_beans_super_high_fat: 'Ultra Fat Protein',
    milk_skim: 'Skimmed Milk',
    milk_low_fat: 'Semi Skimmed Milk',
    milk_whole_fat: 'Whole Milk',
    measurement: 'Measurement',
    water: 'Water',
    water_intake: 'Water Intake'
  },
  moveAttrs: {
    training_volume: 'Volume',
    calorie: 'Calories'
  },
  self: {
    title: 'My profile',
    name: 'Name',
    preferredName: 'Preferred name',
    selfIntroduction: 'About me',
    noSelfIntroduction: 'Tell us about yourself!'
  },
  child: {
    basicTitle: 'Information of the child',
    estimateTitle: 'Child\'s height estimate',
    name: 'Name',
    namePlaceholder: 'Please enter the name of the child',
    changeNote: 'Change diary',
    targetDay: 'The class will go for',
    targetHeight: 'Child\'s height estimate',
    father: 'Father\'s height',
    mom: 'Mother\'s height'
  },
  chat: {
    loadMore: 'More Message',
    send: 'Send to {{name}}',
    disabled: 'The conversation is disconnected',
    members: 'Members',
    lastOnlineAt: 'Last online {{lastOnlineAt}} seconds ago',
    addChat: 'Add Chat',
    addGroup: 'Add Group',
    enterGroup: 'Enter Group',
    shopChat: 'Chat',
    leaveChats: 'Leave Chat',
    addMembers: 'Add Members',
    showChatId: 'Show Chat ID',
    mute: '關閉通知',
    unMute: '開啟通知',
    isMute: '已關閉通知',
    isUnMute: '已開啟通知',
    chatId: 'Chat ID',
    leaveCheck: 'Are you sure you want to leave the chat?'
  },
  plan: {
    title: 'Plan Selection',
    more: 'More Plans',
    isBuy: 'Already joined?',
    freeTitle: 'Free Trial',
    try: 'Start Trial'
  },
  move: {
    train: 'Training',
    device: 'Equipment',
    duration: 'Duration',
    repeat: 'Repetitions',
    fire: 'Burned Calories',
    routine: 'Routine',
    aerobic: 'Cardio',
    workout: 'Weight Training',
    custom: 'Custom',
    addCustom: 'Add Custom',
    addCustomPlaceholder: 'Please enter custom name',
    calorieInfo: 'Burn {{calorie}}calories',
    aerobicInfo: 'last{{dur}}minutes, burn {{calorie}}calories',
    workoutInfo: '{{set}}sets, repeat{{rep}}times, weight{{weight}}kilos',
    sessionInfo: '{{name}} {{reps}}reps, rest{{restTime}}sec, {{weight}}',
    sessionItem: 'last{{dur}}minuits, burn {{calorie}}calories, {{numericText}}',
    fatigue: {
      title: 'Fatigue Level',
      numeric4: 'Easy breezy',
      numeric5: 'Exhausted',
      numeric6: 'Absolute maximum',
      select: 'Please select your fatigue level'
    },
    remark: {
      title: 'Notes',
      placeholder: 'ex: grinding noise in my knee joint...'
    },
    plan: {
      title: 'The {{group}} Set',
      prev: 'Previous Set',
      next: 'Next Set'
    },
    load: 'Weights',
    restTime: 'Rest time',
    list: {
      title: 'List'
    }
  },
  diary: {
    move: 'Move',
    life: 'Life',
    diag: 'Diagnose',
    info: '{{genderText}}, {{age}}age, {{weight}}kg',
    over: 'Over',
    remain: 'Remain',
    haveEat: 'Have consumed',
    moveReduction: 'Burned',
    fullData: 'Full Data'
  },
  article: {
    list: 'Articles',
    title: 'Articles',
    newArticle: 'Lasted Articles',
    sixgroup: 'Food Groups',
    readMore: 'Continue reading'
  },
  note: {
    punch: {
      punchText: 'at {{name}}'
    },
    open: 'Open',
    close: 'Close',
    light: {
      red: 'Red',
      green: 'Green',
      yellow: 'Yellow'
    },
    add: {
      addCard: 'Add card',
      addPost: 'Post to community',
      postTo: 'Post to',
      addTags: 'Add tags',
      addTagName: 'Add tag name',
      addLocation: 'Location',
      defaultName: 'Untitled',
      album: 'Album',
      openAlbum: 'Open Album',
      openCamera: 'Open Camera',
      history: 'History',
      photo: 'Photo',
      camera: 'Camera',
      upload: 'Upload',
      video: 'Video',
      draft: 'Draft',
      saveDraft: 'Draft',
      saveDraftSuccess: 'Save draft success',
      processing: 'Processing',
      picker: 'Choose a pic',
      fastAdd: 'Quick add',
      custom: 'Custom',
      noDataMessage: 'Please post pictures or content',
      diag: {
        placeholder: "Describe the diagnose result",
        date: 'Date',
        time: 'Time'
      },
      life: {
        placeholder: "What's on your mind?",
        date: 'Date',
        time: 'Time'
      },
      move: {
        placeholder: "Describe your training",
        date: 'Date',
        time: 'Time',
        addName: 'What did you do'
      },
      food: {
        placeholder: "Describe what you eat",
        date: 'Date',
        time: 'Time',
        addName: 'Please add the name of food',
        name: 'Name'
      },
      water: {
        placeholder: "Description",
        date: 'Date',
        time: 'time',
        inputPlaceholder: 'Water Intake'
      }
    },
    edit: {
      lightPlaceholder: 'If it\'s red or yellow light, please leave the reasons and inform how to improve.',
      save: 'Save and send notification',
      allPlaceholder: 'Advice for the entire meal'
    },
    delete: {
      title: 'Delete the record',
      image: {
        title: 'Delete the picture',
        message: 'Are you sure you want to delete this?'
      },
      food: {
        title: 'Delete this picture and food record'
      }
    }
  },
  comment: {
    noComment: 'There\'s no comment yet!'
  },
  imagePicker: {
    noPermission: 'Can\'t access your album and camera',
    remind: 'Please go to settings and allow this app to access your album and camera'
  },
  camera: {
    noPermission: 'Allow this app access to your camera',
    remind: 'Please allow access to your camera and microphone with this app'
  },
  location: {
    noPermission: 'Unable to access your location',
    remind: 'Please go to settings and allow location access.'
  },
  social: {
    title: 'Community',
    likes: '{{likesCount}} likes',
    kept: 'Keep',
    keptPosts: 'Keep',
    info: {
      add: {
        life: 'Life'
      },
      articles: {
        move: 'Training',
        nutrition: 'Nutrition'
      }
    },
    profile: {
      edit: 'Edit',
      post: 'Post',
      unFollow: 'Unfollow',
      follower: 'Follower',
      following: 'Following',
      latest: 'Latest'
    },
    tab: {
      hot: 'Hot',
      interesting: 'Interesting',
      activity: 'Activity',
      recommend: 'Recommend'
    }
  },
  question: {
    title: 'Answer the questions',
    multiple: '(multiple choices)',
    success: 'You got the right answer!',
    list: 'List',
    test: 'Test',
    newSend: 'Send',
    next: 'Next',
    error: 'Please choose the answer',
    overchoice: 'You choose too many',
    maximun: 'maximun',
    choice_unit: 'option',
    start: 'start'
  },
  nutrition: {
    sixgroup: 'Food Groups',
    threeMacroSummary: 'Calories and Macros',
    low: 'Low',
    medium: 'Moderate',
    high: 'High',
    super: 'Super',
    whole: 'Whole',
    off: 'Skimmed',
    protein: 'Protein',
    cho: 'Carbohydrate',
    fat: 'Fat',
    sugar: 'Sugar',
    alcohol: 'Alcohol'
  },
  database: {
    title: 'Find food',
    food: 'Find food',
    brand: 'Brands',
    government: 'Government',
    move: 'Movements'
  },
  client: {
    myClient: 'My clients',
    star: 'Frequently use',
    base: 'information',
    data: 'data',
    chat: 'chat',
    namePlaceholder: 'please insert username',
    setName: 'Name(client adds)'
  },
  ffCommon: {
    title: 'My gym',
    login: {
      accountPlaceholder: 'Preset ID number',
      passwordPlaceholder: 'Birthday,ex:19900101'
    },
    coach: {
      title: 'Trainer',
      about: 'About',
      expertise: 'Specialty',
      license: 'License',
      male: 'Male Trainers',
      female: 'Female Trainers'
    },
    course: {
      title: 'Class',
      group: 'Group class'
    },
    info: {
      title: 'Info',
      openMap: 'Open Google Map',
      time: 'Time',
      address: 'Address',
      phone: 'Phone'
    },
    profileInfo: {
      title: 'Information',
      phone: 'Phone',
      venue: 'Your Gym',
      memberCode: 'Membership ID',
      startTime: 'Start Time',
      endTime: 'End time',
      courseVenue: 'Course Gym Site',
      courseName: 'Name of Course',
      remainingCourse: 'Classes Left',
      lockerVenue: 'Locker Gym Site',
      lockerNumber: 'Number of Locker'
    },
    card: {
      user: 'Membership Card',
      staff: 'Staff ID',
      codeRemind: 'Click the barcode or QR Code to enlarge',
      remind: 'Features',
      remindText1: '1.Barcode can be shown automatically by shaking you phone\n',
      remindText2: '2.Barcode will be stored temporarily, and be available while no network or server busy \n',
      remindText3: '3.While opening barcode page, the brightness will be changed automatically. \n',
      remindText4: '4.Click the barcode or QR Code to enlarge, click again to resize\n',
      remindText5: '5.Go to Settings to default barcode page.\n'
    }
  },
  notification: {
    allRead: 'Read'
  },
  search: {
    title: 'Search',
    user: 'Pro',
    client: 'Client',
    tag: 'Tag',
    clearHistory: 'Clear history',
    emptyHistory: 'No searching history'
  },
  group: {
    rank: 'Ranking List',
    greenLightRatios: 'Green Light Ratios',
    movementNumbers: 'Workout Completed',
    completedQuestionNumbers: 'Courses Completed',
    greenLightCheckInNumbers: 'Green Light Check In Count',
    exerciseCheckInNumbers: 'Green Light Check In Count',
    weightDifferences: 'Weight Difference',
    social: 'Group Social Wall',
    tag: 'Group Tags',
    survey: 'Survey'
  },
  program: {
    title: 'Programs'
  },
  invoice: {
    title: 'Invoice',
    info: 'Info'
  },
  share: {
    title: 'Share',
    saveImage: 'Save Image'
  },
  report: {
    title: 'Report',
    placeholder: 'Please enter a reason for report this post.',
    subTitle: 'We will review the post \n You will not see the posts from this user after blocking.',
    send: 'Send',
    block: 'Block',
    unBlock: 'Unblock',
    success: 'Report success, we will review the post'
  },
  editPassword: {
    title: 'Change password',
    setPassword: 'Set password',
    inputOld: 'Enter current password',
    inputNew: 'Enter new password',
    inputCheck: 'Confirm new password',
    button: 'Change password',
    setButton: 'Set password',
    inputCheckError: 'Different new password',
    oldOasswordError: 'Wrong current password',
    success: 'Success'
  },
  editEmail: {
    title: 'Change email',
    placeholder: 'Please enter new email address.',
    error: 'This email address is already used.',
    prompt: 'Please edit your email.'
  },
  editComment: {
    title: 'Change comment',
    placeholder: 'Please enter new comment.'
  },
  wechat: {
    noApp: 'You have not installed WeChat App'
  },
  classOrders: {
    title: 'My Course',
    reserve: 'Course Reserved',
    nutrition: 'Nutrition Course',
    dateRange: 'Start:{{start}}\nEnd:{{end}}',
    end: 'Course Ended',
    diffWeek: 'Course Week {{diffWeek}} '
  },
  barCode: {
    title: 'Barcode',
    scanner: 'Scan barcode'
  },
  classReserve: {
    title: 'Reserve Class',
    timePlaceholder: 'Please select time',
    add: 'Confirm',
    date: 'Date',
    time: 'Time',
    reserve: 'Reserve'
  },
  shop: {
    buyGift: {
      goods: '滿贈商品',
      item: '目前商品滿{{amount}}元即贈送商品',
      select: '點擊選擇贈送商品',
      price: '贈品,原價:{{priceText}}'
    },
    goods: {
      service: 'Service',
      details: 'Details',
      all: 'All'
    },
    menu: {
      title: 'More',
      addMe: 'Add Us',
      sellShare: 'Sell Share'
    },
    type: {
      recommend: 'Recommend',
      new: 'New',
      set: 'Set',
      deduce: 'Deduce',
      freegood: 'Free Goodies',
      buyGift: 'Buy Gift',
      lifegood: 'Group Purchase'
    },
    shoppingCart: {
      title: 'Shopping Cart',
      success: 'Added',
      selectAll: 'Select All',
      settlement: 'Total'
    },
    add: {
      title: 'Pay for the order',
      cart: 'Add to cart',
      pay: 'Pay for the order',
      buy: 'Buy',
      send: 'Send order',
      again: 'Pay again',
      number: 'Quantity',
      inventory: 'Inventory {{inventory}} Left',
      notInventory: '商品沒有庫存了'
    },
    pay: {
      title: '支付方式',
      wxPay: '微信支付',
      detail: '支付明細',
      sum: '總計',
      amount: '商品金額',
      freight: '運費',
      nofreight: '免運費',
      nopurchase: '免加價',
      purchase: '加價購',
      success: '支付成功',
      error: '支付失敗',
      viewOrder: '查看訂單',
      backShop: '返回商城',
      massage: '給商家留言',
      msgPlaceholder: '填寫內容'
    },
    purchaser: {
      title: '收貨地址',
      placeholder: '請填寫收貨地址',
      check: '請填寫完整地址',
      name: 'Name',
      phone: 'Phone',
      area: 'Area',
      address: 'Address',
      setDefault: 'Default mailing information',
      contact: '聯繫人'
    },
    order: {
      title: 'order',
      info: '訂單詳情',
      myOrder: '我的訂單',
      all: '查看全部訂單',
      cancel: '已取消',
      pay: '待付款',
      ship: '待發貨',
      receipt: '待收貨',
      evaluation: '待評價',
      time: '下單時間：',
      amountText: '共{{amount}}件商品',
      orderConfirm: '確認收貨',
      orderConfirmCheck: '確認已經收貨了嗎？',
      orderCancel: '取消訂單',
      apply: '申請開票中',
      applySuccess: '審核成功',
      applyFail: '審核失敗',
      reApply: '重新審核',
      applyAudit: '審核中',
      view: '查看',
      orderRefund: '退款',
      orderRefundCheck: '確認退款？',
      refunding: '退款中',
      refunded: '退款完成'
    },
    returnGoods: {
      title: '退貨申請',
      text: '退款/售後',
      cancel: '取消退貨',
      shop: '填寫退貨物流',
      check: '待商家確認',
      refund: '待退款',
      number: '退貨數量',
      content: '退貨申請說明',
      contentPlaceholder: '請填寫退貨申請說明',
      shipTitle: '物流公司',
      shipTitlePlaceholder: '請選擇物流公司',
      shipNumber: '物流單號',
      shipNumberPlaceholder: '請填寫物流單號',
      orderReturnList: '退貨申請',
      orderReturnLog: '退貨紀錄',
      complete: '退款完成',
      shopAddrsInfo: '商家收貨訊息'
    },
    transport: {
      company: '运送公司',
      code: '查询号码'
    },
    evaluate: {
      title: '评价',
      list: '評價列表',
      buyer: '整體評價：',
      description: '描述相符：',
      service: '服務態度：',
      ship: '發貨速度：',
      info: '評論：',
      check: '匿名評價',
      good: '好評',
      mid: '中評',
      bad: '差評'
    },
    integral: {
      myIntegral: '我的積分',
      order: '積分訂單',
      shop: '積分商城',
      title: '積分',
      balance: '餘額',
      error: '積分不足',
      buy: '立即兌換',
      rule: '積分規則',
      info: '積分明細',
      activity: '積分活動',
      get: '已獲得',
      cost: '已消耗',
      date: '日期',
      id: 'id',
      getName: '參與項目',
      value: '積分值',
      getValue: '獲得積分',
      remark: '備註',
      action: '動作',
      run: '前往進行',
      isSign: '您今天已經完成簽到了哦',
      ruleTitle: '獲得積分規則',
      tagMessage: '[有人@你]【拜託幫我點一下，你也🈶💰💰】'
    },
    invoice: {
      add: '申請發票',
      noNeed: '無需發票',
      person: '個人',
      general: '增值稅普票',
      special: '增值稅專票',
      order: '訂單編號',
      pay: '發票金額',
      addr: '發票寄送地址',
      company: '公司名稱',
      numberId: '納稅人識別號',
      companyAddr: '註冊地址',
      companyPhone: '註冊電話',
      bank: '開戶行',
      bankId: '銀行帳號',
      review: '我們會在一個工作日內完成審核'
    }
  },
  video: {
    play: 'play',
    recordStart: 'press start recording',
    recordEnd: 'release stop recording',
    prompt: 'swipe up to cancel',
    cancel: 'release cancel recording'
  },
  anApp: {
    companyPageTitle: '美聚',
    companyListTitle: '合作醫院',
    companyActivity: '精彩活動',
    questTitle: '肌膚評測'
  }
};