import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";

var _handleActions;

import _ from 'lodash';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import * as types from '../../store/types';

var getAssetType = function getAssetType(recordType) {
  return {
    'Consulting::FoodRecordPhoto': 'foodRecordPhoto',
    'Consulting::FoodRecordNote': 'foodRecordNote',
    'Note::Asset': 'noteAsset',
    Note: 'note'
  }[recordType];
};

var getAssetParentType = function getAssetParentType(recordType) {
  return {
    'Consulting::FoodRecordPhoto': 'foodRecord',
    'Consulting::FoodRecordNote': 'foodRecord',
    'Note::Asset': 'note'
  }[recordType];
};

var getAssets = function getAssets(notifications) {
  return _.map(notifications, function (notification) {
    var light = notification.light,
        recordType = notification.record_type,
        _parentDate = notification.record_diary_date,
        _parentId = notification.record_parent_id,
        id = notification.record_id,
        url = notification.image_url,
        text = notification.record_text;

    if (_parentDate) {
      return _objectSpread({}, notification, {
        asset: {
          id: id,
          url: url,
          recordType: recordType,
          text: text,
          light: light,
          _parentId: _parentId,
          _parentDate: _parentDate,
          _type: getAssetType(recordType),
          _parentType: getAssetParentType(recordType)
        }
      });
    }

    return _objectSpread({}, notification, {
      asset: {
        id: id,
        url: url,
        recordType: recordType
      }
    });
  });
};

var initialState = {
  notifications: [],
  newestTimestamp: undefined,
  oldestTimestamp: undefined,
  endReached: false,
  showRefreshing: false,
  showLoading: false
};
export default handleActions((_handleActions = {}, _defineProperty(_handleActions, types.LOGOUT_DONE, function (state) {
  return initialState;
}), _defineProperty(_handleActions, types.GET_NOTIFICATIONS_CALL, function (state, _ref) {
  var _ref$payload = _ref.payload,
      _ref$payload$scrollTo = _ref$payload.scrollToTop,
      scrollToTop = _ref$payload$scrollTo === void 0 ? false : _ref$payload$scrollTo,
      _ref$payload$older = _ref$payload.older,
      older = _ref$payload$older === void 0 ? false : _ref$payload$older;
  return produce(state, function (draft) {
    draft.showLoading = true;
    draft.scrollToTop = scrollToTop;
    draft.showLoadMoreing = older;
  });
}), _defineProperty(_handleActions, types.GET_NOTIFICATIONS_DONE, function (state, _ref2) {
  var _ref2$payload = _ref2.payload,
      newNotifications = _ref2$payload.notifications,
      _ref2$payload$endReac = _ref2$payload.endReached,
      endReached = _ref2$payload$endReac === void 0 ? false : _ref2$payload$endReac;

  var notifications = _.orderBy(_.uniqBy([].concat(_toConsumableArray(getAssets(newNotifications)), _toConsumableArray(state.notifications)), 'id'), ['id'], ['desc']);

  return _objectSpread({}, state, {
    notifications: notifications,
    newestTimestamp: _.first(notifications) ? _.first(notifications).created_at : undefined,
    oldestTimestamp: _.last(notifications) ? _.last(notifications).created_at : undefined,
    endReached: state.endReached || endReached,
    scrollToTop: false,
    showLoading: false,
    showLoadMoreing: false
  });
}), _defineProperty(_handleActions, types.GET_NOTIFICATIONS_FAIL, function (state) {
  return produce(state, function (draft) {
    draft.scrollToTop = false;
    draft.showLoading = false;
    draft.showLoadMoreing = false;
  });
}), _defineProperty(_handleActions, types.START_GETTING_NOTIFICATIONS, function (state, _ref3) {
  var _ref3$payload$showRef = _ref3.payload.showRefreshing,
      showRefreshing = _ref3$payload$showRef === void 0 ? false : _ref3$payload$showRef;
  return _objectSpread({}, state, {
    showRefreshing: showRefreshing
  });
}), _defineProperty(_handleActions, types.STOP_GETTING_NOTIFICATIONS, function (state) {
  return _objectSpread({}, state, {
    showRefreshing: false
  });
}), _defineProperty(_handleActions, types.SET_NOTIFICATIONS_READ, function (state, _ref4) {
  var _ref4$payload$ids = _ref4.payload.ids,
      ids = _ref4$payload$ids === void 0 ? [] : _ref4$payload$ids;
  return produce(state, function (draft) {
    _.each(draft.notifications, function (n) {
      if (ids.includes(n.id)) n.read = true;
    });
  });
}), _handleActions), initialState);