import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _toConsumableArray from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";

var _handleActions;

import _ from 'lodash';
import moment from 'moment';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import * as types from '../../store/types';
export default handleActions((_handleActions = {}, _defineProperty(_handleActions, types.GET_TARGETS_DONE, function (state, _ref) {
  var _ref$payload$targets = _ref.payload.targets,
      targets = _ref$payload$targets === void 0 ? [] : _ref$payload$targets;
  var newTargets = [].concat(_toConsumableArray(targets), _toConsumableArray(state.targets));
  return _objectSpread({}, state, {
    targets: _.uniqBy(_.map(newTargets, function (target) {
      return _.omitBy(target, _.isNil);
    }), 'id')
  });
}), _defineProperty(_handleActions, types.CHANGE_DATE, function (state, _ref2) {
  var date = _ref2.payload.date;
  return _objectSpread({}, state, {
    date: date
  });
}), _defineProperty(_handleActions, types.SET_TARGET_WEIGHT, function (state, _ref3) {
  var _ref3$payload = _ref3.payload,
      targetWeight = _ref3$payload.targetWeight,
      monthKg = _ref3$payload.monthKg,
      option = _ref3$payload.option;
  return produce(state, function (draft) {
    draft.newTarget.targetWeight = targetWeight;
    draft.newTarget.monthKg = monthKg;
    draft.newTarget.option = option;
  });
}), _defineProperty(_handleActions, types.SET_TARGET_KGMAX, function (state, _ref4) {
  var kgMax = _ref4.payload.kgMax;
  return produce(state, function (draft) {
    draft.newTarget.kgMax = kgMax;
  });
}), _defineProperty(_handleActions, types.SET_TARGET_CALORIE, function (state, _ref5) {
  var _ref5$payload = _ref5.payload,
      monthKg = _ref5$payload.monthKg,
      calorie = _ref5$payload.calorie,
      targetDay = _ref5$payload.targetDay,
      option = _ref5$payload.option;
  return produce(state, function (draft) {
    draft.newTarget = _objectSpread({}, draft.newTarget, {
      monthKg: monthKg,
      weekly: monthKg / 4,
      calorie: calorie,
      targetDay: targetDay,
      option: option
    });
  });
}), _defineProperty(_handleActions, types.SET_TARGET_WATER, function (state, _ref6) {
  var water = _ref6.payload.water;
  return produce(state, function (draft) {
    draft.newTarget.water = water;
  });
}), _defineProperty(_handleActions, types.SET_TARGET_CLIENT, function (state, _ref7) {
  var payload = _ref7.payload;
  return _objectSpread({}, state, {
    clientId: payload
  });
}), _handleActions), {
  targets: [],
  clientId: undefined,
  date: moment(),
  newTarget: {
    targetWeight: 59,
    kgMax: 3,
    monthKg: 4,
    weekly: 1,
    calorie: 1526,
    targetDay: 56,
    option: 1,
    water: 2000
  }
});