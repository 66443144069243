import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _toConsumableArray from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import { Observable } from 'rxjs';
import _ from 'lodash';
import { socket, chat, note, socketec, message } from '../../services';
import './RxOperators';
import * as actions from '../actions';
import * as types from '../../store/types';
import { getMember } from '../../store/selectors';
import lib from '../../platform';
var Asset = lib.pfAsset;

var getGid = function getGid(store) {
  return store.value.member.gid;
};

var getAvatar = function getAvatar(store) {
  var _getMember = getMember(store.value),
      avatar_thumbnail_url = _getMember.avatar_thumbnail_url;

  return avatar_thumbnail_url;
};

var getChatroom = function getChatroom(store, id) {
  var chatrooms = store.value.chat.chatrooms;
  return _.find(chatrooms, {
    id: id
  });
};

export var openChat = function openChat(action$, store) {
  return action$.ofType(types.OPEN_CHAT_CALL).mergeMap(function (_ref) {
    var _ref$payload = _ref.payload,
        id = _ref$payload.id,
        textToSend = _ref$payload.textToSend;
    var isShopChat = id === 'shopchat';
    var chatroomAvailable = getChatroom(store, id);
    var gid = getGid(store);
    var currentId = store.value.chat.id;

    if (isShopChat) {
      var title = chatroomAvailable.title,
          isService = chatroomAvailable.isService;
      return [actions.showLoading(), actions.openSocketECCall({
        service_type: 'before-sales'
      }), actions.openChatDone({
        id: id,
        isShopChat: isShopChat
      }), actions.openChatPage({
        title: title,
        isService: isService,
        isShopChat: isShopChat
      })];
    } else if (!currentId && chatroomAvailable) {
      var _title = chatroomAvailable.title,
          isGroup = chatroomAvailable.isGroup,
          isGroupClass = chatroomAvailable.isGroupClass,
          _isService = chatroomAvailable.isService,
          leavable = chatroomAvailable.leavable,
          exclusive = chatroomAvailable.exclusive;
      return [actions.showLoading(), actions.initSocketCall({
        id: id
      }), actions.openSocketCall({
        id: id,
        gid: gid
      }), actions.joinChatroomCall({
        id: id,
        gid: gid
      }), actions.fetchMessagesCall({
        id: id
      }), actions.openChatDone({
        id: id
      }), actions.openChatPage({
        title: _title,
        textToSend: textToSend,
        isGroup: isGroup,
        isGroupClass: isGroupClass,
        isService: _isService,
        leavable: leavable,
        exclusive: exclusive
      })];
    } else {
      return Observable.empty();
    }
  }).catch(function (err) {
    return Observable.concat(Observable.of(actions.hideLoading()), Observable.of(actions.openChatFail(err)));
  });
};
export var openServiceChatCall = function openServiceChatCall(action$, store) {
  return action$.ofType(types.OPEN_SERVICE_CHAT_CALL).map(function (_ref2) {
    var _ref2$payload = _ref2.payload,
        payload = _ref2$payload === void 0 ? {} : _ref2$payload;
    var textToSend = payload.textToSend;
    var chatrooms = store.value.chat.chatrooms;

    var service = _.find(chatrooms, {
      isService: true
    });

    if (service && service.id) {
      return actions.openChatCall({
        id: service.id,
        textToSend: textToSend
      });
    } else {
      return actions.empty();
    }
  });
};
export var closeChatCall = function closeChatCall(action$, store) {
  return action$.ofType(types.CLOSE_CHAT_CALL).switchMap(function (payload) {
    return [actions.closeSocketCall(payload), actions.closeChatDone()];
  }).catch(function (err) {
    return Observable.of(actions.closeChatFail(err));
  });
};
export var initSocket = function initSocket(action$, store) {
  return action$.ofType(types.INIT_SOCKET_CALL).switchMap(function (_ref3) {
    var id = _ref3.payload.id;

    var _socket$getStreams = socket.getStreams(),
        connect$ = _socket$getStreams.connect$,
        disconnect$ = _socket$getStreams.disconnect$,
        reconnect$ = _socket$getStreams.reconnect$,
        connectError$ = _socket$getStreams.connectError$,
        chatRoomUpdate$ = _socket$getStreams.chatRoomUpdate$,
        newMessage$ = _socket$getStreams.newMessage$,
        fetchedMessages$ = _socket$getStreams.fetchedMessages$,
        fetchedEnd$ = _socket$getStreams.fetchedEnd$;

    return Observable.merge(newMessage$.mergeMap(function (newMessage) {
      var messages = chat.formatIncomingMessages([newMessage]);
      return [actions.hideLoading(), actions.receiveNewMessage({
        id: id,
        messages: messages
      }), actions.updateReadAtCall({
        id: id
      }), actions.setAppBadgeCall()];
    }), fetchedMessages$.mergeMap(function (newMessages) {
      var messages = chat.formatIncomingMessages(newMessages);
      return [actions.hideLoading(), actions.receiveNewMessage({
        id: id,
        messages: messages
      }), actions.updateReadAtCall({
        id: id
      }), actions.setAppBadgeCall()];
    }), fetchedEnd$.mergeMap(function (_ref4) {
      var chatRoomId = _ref4.chatRoomId;
      // const messages = chat.formatIncomingMessages(newMessages)
      return [actions.updateChatRoomLoadMore({
        chatRoomId: chatRoomId
      })];
    }), connectError$.mergeMap(function (messages) {
      console.log('connect error');
      return Observable.of(actions.hideLoading());
    }), chatRoomUpdate$.mergeMap(function (chatroom) {
      //console.log(chatroom)
      return Observable.of(actions.updateChatRoom(chatroom));
    }), reconnect$.mergeMap(function (messages) {
      return Observable.empty();
    }), connect$.mergeMap(function (messages) {
      return Observable.empty();
    }), disconnect$.mergeMap(function (messages) {
      return Observable.of(actions.hideLoading());
    })).takeUntil(action$.ofType(types.CLOSE_SOCKET_DONE));
  }).catch(function (err) {
    return Observable.concat(Observable.of(actions.hideLoading()), Observable.of(actions.initSocketFail(err)));
  });
};
export var openSocket = function openSocket(action$, store) {
  return action$.ofType(types.OPEN_SOCKET_CALL).map(function (_ref5) {
    var payload = _ref5.payload;
    socket.connect(payload);
    return actions.openSocketDone();
  }).catch(function (err) {
    return Observable.of(actions.openSocketFail(err));
  });
};
export var reConnectSocket = function reConnectSocket(action$, store) {
  return action$.ofType(types.RECONNECT_SOCKET_CALL).mergeMap(function (_ref6) {
    var payload = _ref6.payload;
    var gid = getGid(store);
    var id = store.value.chat.id;
    var chatroomAvailable = getChatroom(store, id);

    if (id && chatroomAvailable) {
      return [actions.showLoading(), actions.initSocketCall({
        id: id
      }), actions.openSocketCall({
        id: id,
        gid: gid
      }), actions.joinChatroomCall({
        id: id,
        gid: gid
      }), actions.fetchMessagesCall({
        id: id
      }), actions.reConnectSocketDone()];
    } else {
      return Observable.empty();
    }
  }).catch(function (err) {
    return Observable.concat(Observable.of(actions.hideLoading()), Observable.of(actions.reConnectSocketFail(err)));
  });
};
export var joinChatroom = function joinChatroom(action$, store) {
  return action$.ofType(types.JOIN_CHATROOM_CALL).map(function (_ref7) {
    var payload = _ref7.payload;
    socket.joinChatroom(payload);
    return actions.joinChatroomDone();
  }).catch(function (err) {
    return Observable.of(actions.joinChatroomFail(err));
  });
};
export var closeSocket = function closeSocket(action$, store) {
  return action$.ofType(types.CLOSE_SOCKET_CALL).map(function (_ref8) {
    var isShopChat = _ref8.payload.isShopChat;

    if (isShopChat) {
      socketec.disconnect();
    } else {
      socket.disconnect();
    }

    return actions.closeSocketDone();
  }).catch(function (err) {
    return Observable.of(actions.closeSocketFail(err));
  });
};
export var sendMessage = function sendMessage(action$, store) {
  return action$.ofType(types.SEND_MESSAGE_CALL).switchMap(function (_ref9) {
    var _ref9$payload = _ref9.payload,
        id = _ref9$payload.id,
        message = _ref9$payload.message;
    var isShopChat = id === 'shopchat';
    var newMessage = chat.formatInsertedMessage({
      id: id,
      message: message,
      isShopChat: isShopChat
    });
    var tempID = newMessage._id;

    if (isShopChat) {
      var shopChatData = store.value.chat.shopChatData;
      var messageToSend = chat.formatECOutgoingMessage({
        message: newMessage,
        shopChatData: shopChatData
      });
      socketec.sendMessage(messageToSend);
      return Observable.of(actions.insertMessage({
        id: id,
        messages: [newMessage]
      }));
    } else {
      var _messageToSend = chat.formatOutgoingMessage({
        id: id,
        message: newMessage
      });

      return Observable.concat(Observable.of(actions.insertMessage({
        id: id,
        messages: [newMessage]
      })), Observable.fromPromise(socket.sendMessage(_messageToSend)).map(function (newID) {
        return actions.sendMessageDone({
          id: id,
          tempID: tempID,
          newID: newID
        });
      }));
    }
  }).catch(function (err) {
    return Observable.of(actions.sendMessageFail(err));
  });
};
export var fetchMessages = function fetchMessages(action$, store) {
  return action$.ofType(types.FETCH_MESSAGES_CALL).switchMap(function (_ref10) {
    var _ref10$payload = _ref10.payload,
        id = _ref10$payload.id,
        before = _ref10$payload.before,
        returnChatRoomId = _ref10$payload.returnChatRoomId;
    var gid = getGid(store);
    var query = {
      chatRoomId: id,
      gid: gid,
      before: before,
      returnChatRoomId: returnChatRoomId
    };
    return Observable.of(socket.fetchMessages(query)).map(function () {
      return actions.fetchMessagesDone();
    });
  }).catch(function (err) {
    return Observable.concat(Observable.of(actions.hideLoading()), Observable.of(actions.fetchMessagesFail(err)));
  });
};
export var updateReadAt = function updateReadAt(action$, store) {
  return action$.ofType(types.UPDATE_READ_AT_CALL).map(function (_ref11) {
    var id = _ref11.payload.id;
    var gid = getGid(store);
    var chatroom = getChatroom(store, id);
    var readAt = chatroom.readAt;
    socket.updateReadAt({
      chatRoomId: id,
      gid: gid,
      readAt: readAt
    });
    return actions.updateReadAtDone();
  }).catch(function (err) {
    return Observable.of(actions.updateReadAtFail(err));
  });
};
export var finishChatSelectMedia = function finishChatSelectMedia(action$, store) {
  return action$.ofType(types.FINISH_CHAT_SELECT_MEDIA).switchMap(function (_ref12) {
    var _ref12$payload = _ref12.payload,
        chatroom_id = _ref12$payload.chatroom_id,
        isService = _ref12$payload.isService;
    var assets = store.value.note.newNote.assets;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.forkJoin.apply(Observable, _toConsumableArray(_.map(_.filter(assets, '_fromCamera'), function (asset) {
      var uri = asset.uri,
          width = asset._width,
          height = asset._height,
          type = asset.type;
      var item = {
        uri: uri,
        width: width,
        height: height,
        type: type
      };

      if (type === 'video') {
        return Observable.of(item);
      } else {
        return Observable.defer(function () {
          return note.processImageAsync({
            uri: uri,
            width: width,
            height: height
          });
        });
      }
    }))).concatMap(function (datas) {
      var dispatch = [];

      _.map(datas, function (_ref13, index) {
        var uri = _ref13.uri,
            processedUri = _ref13.processedUri,
            type = _ref13.type;

        var asset = _objectSpread({}, assets[index], {
          processedUri: processedUri,
          uri: uri
        });

        var _Asset$fromModule = Asset.fromModule(uri),
            extname = _Asset$fromModule.type;

        if (type === 'image') {
          dispatch.push(actions.processImageDone({
            uri: uri,
            processedUri: processedUri
          }));
        }

        dispatch.push(actions.getChatMediaUploadInfoCall({
          chatroom_id: chatroom_id,
          asset: asset,
          extname: extname,
          mediaType: type,
          isService: isService
        }));
      });

      return dispatch;
    }).catch(function (err) {
      return Observable.concat(Observable.of(actions.hideLoading()), Observable.of(actions.processImageFail({
        errMessage: err.message
      })));
    }));
  });
};
export var getChatMediaUploadInfo = function getChatMediaUploadInfo(action$, store) {
  return action$.ofType(types.GET_CHAT_MEDIA_UPLOAD_INFO_CALL).concatMap(function (_ref14) {
    var _ref14$payload = _ref14.payload,
        chatroom_id = _ref14$payload.chatroom_id,
        asset = _ref14$payload.asset,
        extname = _ref14$payload.extname,
        mediaType = _ref14$payload.mediaType,
        isService = _ref14$payload.isService;
    return Observable.fromPromise(chat.getMediaUploadInfo({
      chatroom_id: chatroom_id,
      extname: extname,
      isService: isService
    })).retry(2).concatMap(function (data) {
      var url = data.url,
          storage = data.storage,
          key = data.key,
          formData = data.form_data,
          resultUrl = data.result_url;
      var uploadInfo = {
        url: url,
        storage: storage,
        key: key,
        formData: formData,
        resultUrl: resultUrl
      };

      var newAsset = _objectSpread({}, asset, {
        uploadInfo: uploadInfo
      });

      return [actions.getChatMediaUploadInfoDone(), actions.uploadChatAssetCall({
        chatroom_id: chatroom_id,
        asset: newAsset,
        url: resultUrl,
        mediaType: mediaType,
        isService: isService
      })];
    }).catch(function (err) {
      return Observable.concat(Observable.of(actions.hideLoading()), Observable.of(actions.getChatMediaUploadInfoFail({
        err: err
      })));
    });
  });
};
export var uploadChatAsset = function uploadChatAsset(action$, store) {
  return action$.ofType(types.UPLOAD_CHAT_ASSET_CALL).concatMap(function (_ref15) {
    var _ref15$payload = _ref15.payload,
        chatroom_id = _ref15$payload.chatroom_id,
        asset = _ref15$payload.asset,
        url = _ref15$payload.url,
        mediaType = _ref15$payload.mediaType,
        isService = _ref15$payload.isService;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(chat.uploadMediaDirect(asset)).concatMap(function (res) {
      return [actions.uploadChatAssetDone({
        _uid: asset._uid
      }), actions.createAttachmentCall({
        chatroom_id: chatroom_id,
        url: url,
        mediaType: mediaType,
        isService: isService
      })];
    }).catch(function (err) {
      return Observable.of(actions.uploadChatAssetFail({
        errMessage: err.message
      }));
    }));
  });
};
export var createAttachment = function createAttachment(action$, store) {
  return action$.ofType(types.CREATE_ATTACHMENT_CALL).concatMap(function (_ref16) {
    var _ref16$payload = _ref16.payload,
        chatroom_id = _ref16$payload.chatroom_id,
        url = _ref16$payload.url,
        mediaType = _ref16$payload.mediaType,
        isService = _ref16$payload.isService;
    return Observable.fromPromise(chat.createAttachment({
      chatroom_id: chatroom_id,
      url: url,
      isService: isService
    })).concatMap(function (res) {
      var message = {
        createdAt: new Date(),
        user: {
          avatar: getAvatar(store),
          _id: getGid(store)
        },
        text: '',
        mediaUrl: url,
        mediaType: mediaType
      };
      return [actions.hideLoading(), actions.createAttachmentDone(res), actions.sendMessageCall({
        id: chatroom_id,
        message: message
      }), actions.closeImagePickerModal()];
    }).catch(function (err) {
      return Observable.concat(Observable.of(actions.hideLoading()), Observable.of(actions.createAttachmentFail({
        errMessage: err.message
      })));
    });
  });
};
export var issueReturnCall = function issueReturnCall(action$, store) {
  return action$.ofType(types.ISSUE_RETURN_CALL).switchMap(function (_ref17) {
    var _ref17$payload = _ref17.payload,
        textToSend = _ref17$payload.textToSend,
        isLoginPage = _ref17$payload.isLoginPage;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(chat.issueReturn({
      text: textToSend
    })).switchMap(function (res) {
      return [isLoginPage ? actions.empty() : actions.openServiceChatCall({
        textToSend: textToSend
      }), actions.issueReturnDone()];
    }).catch(function (err) {
      return Observable.of(actions.issueReturnFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var addChatsCall = function addChatsCall(action$, store) {
  return action$.ofType(types.ADD_CHATS_CALL).switchMap(function (_ref18) {
    var payload = _ref18.payload;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(chat.addChats(payload)).switchMap(function (res) {
      return [actions.addChatsDone(res), actions.getChatRoomsCall({
        isOpenChat: true,
        id: res.id
      })];
    }).catch(function (err) {
      return Observable.of(actions.addChatsFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var openSocketECCall = function openSocketECCall(action$, store) {
  return action$.ofType(types.OPEN_SOCKETEC_CALL).switchMap(function (_ref19) {
    var payload = _ref19.payload;
    return Observable.fromPromise(chat.getShopChats(payload)).switchMap(function (res) {
      var _socketec$getStreams = socketec.getStreams(),
          newMessage$ = _socketec$getStreams.newMessage$,
          connect$ = _socketec$getStreams.connect$,
          error$ = _socketec$getStreams.error$;

      var data = res.data;
      var service_id = data.service_id,
          user_id = data.user_id,
          token = data.token;
      return Observable.concat(Observable.of(actions.openSocketECDone(res)), Observable.of(actions.hideLoading()), Observable.merge(connect$.mergeMap(function (newMessage) {
        loginMsg = {
          type: 'user',
          service_id: service_id,
          user_id: user_id,
          token: token
        };
        socketec.sendMessage(loginMsg);
        return Observable.empty();
      }), newMessage$.mergeMap(function (newMessage) {
        return [actions.receiveNewMessage({
          id: 'shopchat',
          messages: [newMessage]
        }), actions.hideLoading()];
      }), error$.mergeMap(function (err) {
        return Observable.of(actions.openSocketECFail(err));
      })).takeUntil(action$.ofType(types.CLOSE_SOCKET_DONE)));
    }).catch(function (err) {
      return Observable.concat(Observable.of(actions.hideLoading()), Observable.of(actions.openSocketECFail(err)));
    });
  });
};
export var leaveChatsCall = function leaveChatsCall(action$, store) {
  return action$.ofType(types.LEAVE_CHATS_CALL).switchMap(function (_ref20) {
    var payload = _ref20.payload;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(chat.leaveChats(payload)).switchMap(function (res) {
      return [actions.leaveChatsDone(payload), actions.pageGoBack()];
    }).catch(function (err) {
      return Observable.of(actions.leaveChatsFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var addChatsMembersCall = function addChatsMembersCall(action$, store) {
  return action$.ofType(types.ADD_CHATS_MEMBERS_CALL).switchMap(function (_ref21) {
    var payload = _ref21.payload;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(chat.addChatsMembers(payload)).switchMap(function (res) {
      return [actions.addChatsMembersDone(res), actions.getChatRoomsCall({
        isOpenChat: false
      }), actions.pageGoBack()];
    }).catch(function (err) {
      return Observable.of(actions.addChatsMembersFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var enterChatsCall = function enterChatsCall(action$, store) {
  return action$.ofType(types.ENTER_CHATS_CALL).switchMap(function (_ref22) {
    var payload = _ref22.payload;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(chat.enterChats(payload)).switchMap(function (res) {
      return [actions.enterChatsDone(res), actions.getChatRoomsCall({
        isOpenChat: false
      }), actions.pageGoBack()];
    }).catch(function (err) {
      return Observable.of(actions.enterChatsFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var initSocketECCall = function initSocketECCall(action$, store) {
  return action$.ofType(types.INIT_SOCKETEC_CALL).switchMap(function (_ref23) {
    var payload = _ref23.payload;
    return Observable.fromPromise(message.initSocketEC()).map(function (res) {
      return actions.initSocketECDone(res);
    });
  }).catch(function (err) {
    return Observable.of(actions.initSocketECFail(err));
  });
};
export var muteChatsCall = function muteChatsCall(action$, store) {
  return action$.ofType(types.MUTE_CHATS_CALL).switchMap(function (_ref24) {
    var payload = _ref24.payload;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(chat.muteChats(payload)).switchMap(function (res) {
      return [actions.muteChatsDone(res), actions.getChatRoomsCall({
        isOpenChat: false
      }), actions.showI18nMessageCall({
        message: 'chat:isMute',
        type: 'success',
        icon: 'success',
        position: 'center'
      })];
    }).catch(function (err) {
      return Observable.of(actions.muteChatsFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var unMuteChatsCall = function unMuteChatsCall(action$, store) {
  return action$.ofType(types.UN_MUTE_CHATS_CALL).switchMap(function (_ref25) {
    var payload = _ref25.payload;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(chat.unMuteChats(payload)).switchMap(function (res) {
      return [actions.unMuteChatsDone(res), actions.getChatRoomsCall({
        isOpenChat: false
      }), actions.showI18nMessageCall({
        message: 'chat:isUnMute',
        type: 'success',
        icon: 'success',
        position: 'center'
      })];
    }).catch(function (err) {
      return Observable.of(actions.unMuteChatsFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};