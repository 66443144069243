import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import api from '../api';
import moment from 'moment';
export var getDiary = function getDiary(clientId, date, dates) {
  return api.get('/v4/diaries/search', {
    client_id: clientId,
    date: date,
    dates: dates
  });
};
export var getDiaries = function getDiaries(clients) {
  return api.get('/v4/diaries/search_by_clients', {
    clients: clients
  });
};
export var getDiaryDates = function getDiaryDates(clientId, after, before) {
  // TODO: add client_id to params
  var payload = !before && !after ? {
    after: moment().subtract(4, 'month').format('YYYY-MM-DD')
  } : {
    after: after,
    before: before
  };
  return api.get('/v4/diaries/dates', _objectSpread({}, payload, {
    client_id: clientId
  }));
};
export var createComment = function createComment(_ref) {
  var _ref$record = _ref.record,
      _type = _ref$record._type,
      id = _ref$record.id,
      content = _ref.content;
  var path = '';
  var payload = {};

  switch (_type) {
    case 'noteAsset':
      path = "assets/".concat(id);
      payload = {
        content: content
      };
      break;

    case 'note':
      path = "notes/".concat(id);
      payload = {
        content: content
      };
      break;

    case 'foodRecordPhoto':
      path = 'food_record_assets';
      payload = {
        content: content,
        id: id,
        type: 'photo'
      };
      break;

    case 'foodRecordNote':
      path = 'food_record_assets';
      payload = {
        content: content,
        id: id,
        type: 'note'
      };
      break;

    default:
      break;
  }

  return api.post("/v4/".concat(path, "/create_comment"), payload);
};