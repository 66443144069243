import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import api from '../api';

var getQueryObject = function getQueryObject() {
  var payload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var order_key_cursor = payload.orderKey,
      tag_id = payload.tagId,
      group_class_id = payload.groupClassId,
      type = payload.type;
  var count = type === 'first' ? 6 : 12;

  var params = _objectSpread({}, payload, {
    count: count,
    order_key_cursor: order_key_cursor,
    tag_id: tag_id,
    group_class_id: group_class_id
  });

  return params;
};

export var getSocialClient = function getSocialClient(_ref) {
  var id = _ref.id;
  return api.get("/v4/clients/".concat(id));
};
export var getSocialUser = function getSocialUser(_ref2) {
  var id = _ref2.id;
  return api.get("/v4/users/".concat(id));
};
export var getMediaPosts = function getMediaPosts(payload) {
  return api.get('/v4/media_posts', payload);
};
export var getSocialPosts = function getSocialPosts(payload) {
  return api.get('/v4.1/social_posts', getQueryObject(payload));
};
export var getLatestPosts = function getLatestPosts(payload) {
  return api.get('/v4.1/latest_posts', getQueryObject(payload));
};
export var getChannelPosts = function getChannelPosts(payload) {
  return api.get('/v4/get_posts', getQueryObject(payload));
};
export var getMyPosts = function getMyPosts(payload) {
  return api.get('/v4.1/my_posts', getQueryObject(payload));
};
export var getTagPosts = function getTagPosts(payload) {
  return api.get('/v4.1/tagging_posts', getQueryObject(payload));
};
export var getGroupPosts = function getGroupPosts(payload) {
  return api.get('/v4.1/group_class_posts', getQueryObject(payload));
};
export var getFollowingPosts = function getFollowingPosts(payload) {
  return api.get('/v4.1/following_posts', getQueryObject(payload));
};
export var getClientPosts = function getClientPosts(payload) {
  return api.get("/v4.1/clients/".concat(payload.id, "/posts"), getQueryObject(payload));
};
export var getUserPosts = function getUserPosts(payload) {
  return api.get("/v4.1/users/".concat(payload.id, "/posts"), getQueryObject(payload));
};
export var getPost = function getPost(_ref3) {
  var id = _ref3.id;
  return api.get("/v4/posts/".concat(id));
};
export var deletePost = function deletePost(_ref4) {
  var id = _ref4.id;
  return api.delete("/v4/posts/".concat(id), {});
};
export var createComment = function createComment(_ref5) {
  var id = _ref5.id,
      content = _ref5.content,
      _tag_members = _ref5._tag_members;
  return api.post("/v4/posts/".concat(id, "/create_comment"), {
    content: content,
    _tag_members: _tag_members
  });
};
export var togglePostLike = function togglePostLike(_ref6) {
  var id = _ref6.id,
      liked = _ref6.liked;
  var action = liked ? 'like' : 'unlike';
  return api.post("/v4/posts/".concat(id, "/").concat(action));
};
export var toggleFollowClient = function toggleFollowClient(_ref7) {
  var follow = _ref7.follow,
      id = _ref7.id;
  var action = follow ? 'follow' : 'unfollow';
  return api.post("/v4/clients/".concat(id, "/").concat(action));
};
export var toggleFollowUser = function toggleFollowUser(_ref8) {
  var follow = _ref8.follow,
      id = _ref8.id;
  var action = follow ? 'follow' : 'unfollow';
  return api.post("/v4/users/".concat(id, "/").concat(action));
};
export var getSocialSearch = function getSocialSearch(param) {
  return api.get('/v4/social/search/', param);
}; //api.get('/v4/social/search/', { ...param, per_page: 1 })

export var report = function report(param) {
  return api.post('/v4/social/report', param);
};
export var block = function block(param) {
  return api.post('/v4/social/block', param);
};
export var unBlock = function unBlock(param) {
  return api.post('/v4/social/unblock', param);
};
export var togglePostKeep = function togglePostKeep(_ref9) {
  var id = _ref9.id,
      kept = _ref9.kept;
  var action = kept ? 'keep' : 'unkeep';
  return api.post("/v4/posts/".concat(id, "/").concat(action));
};
export var getMyKeptPosts = function getMyKeptPosts(payload) {
  return api.get('/v4.1/kept_posts', getQueryObject(payload));
};