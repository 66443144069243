import { UserTerms } from './docs'

export default {
  common: {
    type: 'User',
    webUrl: 'https://www.fitnessfactory.com.tw',
    // userIcon: require('../../../assets/common/ff_user.png'),
    userTerms: {
      content: UserTerms,
      type: 'html',
      hidden: false,
    },
    notice: {
      content: 'https://www.fitnessfactory.com.tw/protectionPolicy',
      type: 'uri',
      hidden: false,
    },
    moveIsFirst: true,
    useJpush: false,
    showAddChatHeader: false,
    showProgramTab: false,
    showFFInfo: false,
    showInvoice: false,
    showRoutines: true,
    enableShake: false,
    enableCheckUpdate: true,
    showSocialInfo: true,
    handGetArticle: false,
    handleGetMyPosts: true,
    hiddenHistory: true,
    defaultShowPostSwitch: false,
    useBarCode: false,
    showClassReserve: false,
    showAboutWeb: false,
    dsn: 'https://bc36731896c442e5b90adf9950a0fcd9@sentry.io/1328378',
    appNavigator: 'FFProAppNavigator',
    navNextState: 'ProNextState',
  },
  post: {
    tabIndex: 0,
  },
  store: {
    defaultIsPost: true,
    defaultIsOpenImagePicker: true,
    defaultBarCodeIsRowStore: false,
    defaultEditing: false,
    defaultIsTwoPost: true,
  },
  proSetting: {
    clientOnlyAddMove: true,
    showFFProfileInfo: true,
  },
  login: {
    enableWechatLogin: false,
    enableFbLogin: false,
    enableRegister: false,
    accountLogin: true,
    showEmailRegister: false,
    // logo: require('../../../assets/ffproapp/logo_wide.png'),
    fbAppId: '',
    wechatAppId: '',
  },
  styles: {
    defaultIsDark: false,
    mainBackgroundColor: 'white',
  },
  locales: {
    dataChartTitle: 'dataChart:healthTitle',
    coachTabTitle: 'tabs.dialog.title',
  },
  api: {
    apiUrl: 'https://ff.cofit.me/api',
    socketUrl: 'https://ff-chat.cofit.me',
    signInPath: '/v4/fitness_factory/login_user',
    resetPasswordPath: '/v4/fitness_factory/user_reset_password',
    signOutPath: '/v4/users/sign_out',
    provider: 'google',
  },
}
