export var SET_TARGET_WEIGHT = 'SET_TARGET_WEIGHT';
export var SET_TARGET_KGMAX = 'SET_TARGET_KGMAX';
export var SET_TARGET_CALORIE = 'SET_TARGET_CALORIE';
export var SET_TARGET_WATER = 'SET_TARGET_WATER';
export var SET_TARGET_CALL = 'SET_TARGET_CALL';
export var SET_TARGET_DONE = 'SET_TARGET_DONE';
export var SET_TARGET_FAIL = 'SET_TARGET_FAIL';
export var GET_TARGETS_CALL = 'GET_TARGETS_CALL';
export var GET_TARGETS_DONE = 'GET_TARGETS_DONE';
export var GET_TARGETS_FAIL = 'GET_TARGETS_FAIL';
export var SET_TARGET_CLIENT = 'SET_TARGET_CLIENT';