const LegalNoticeZh = `
<html>

<head>
  <meta charset="utf-8">
</head>
<style>
  body {
  font-size: 24;
  }
</style>

<body>
  <p>更新日期：2019年6月13日</p>
  <p>本公司為了幫助健康管理人員（包括但不限於營養師、教練等）記錄客戶個人資料，提供良好的指導和服務，需蒐集立書人個人資料，並依據「個人資料保護法」及本隱私權政策（以下簡稱「本政策」）之各項內容進行使用。為了保障您的權益及幫助您瞭解本公司如何蒐集、利用及保護您個人資訊，請務必詳細的閱讀本政策之各項內容。當您繼續瀏覽本公司網站、App或參與活動時，即表示您已閱讀、瞭解、同意本政策所載之事項，並同意本公司蒐集、處理、利用您的個人資料。我們可能會不定時更新本政策，您都能在本公司的網站及軟體中隨時查閱，當你持續使用我們的服務時，將視為同意最新更新版本的隱私權政策。</p>
  <p>1.蒐集目的</p>
  <p>我們蒐集您個人資料之目的，在於提供健康管理人員及本公司管理、記錄、分析您的個人健康資料，以便為您提供和推薦更好的服務、內容及商品。</p>
  <p>2.個人資料範圍</p>
  <p>(1)辨識個人者：包括但不限於姓名、職業、聯絡方式等。</p>
  <p>(2)辨識財務者：包括但不限於金融機構帳戶之號碼與姓名、信用卡或簽帳卡之號碼等。</p>
  <p>(3)辨識政府資料者：如國民身分證統一編號等。</p>
  <p>(4)個人描述：如年齡、性別、出生年月日、生活、飲食、健身習慣等。</p>
  <p>(5)特種個資：如醫療、健康檢查、疾病史、飲食記錄、健身資訊等。</p>
  <p>3.個人資訊之收集方式</p>
  <p>除您填寫的個人資訊外，我們還會通過以下其他方式來收集您的個人資訊：</p>
  <p>(1)流量數據</p>
  <p>本公司會自動收集使用者在網站上所提供的訊息，包括(1)IP位置(2)網域(3)瀏覽器類型(3)作業系統等流量數據資料。</p>
  <p>(2)Cookie</p>
  <p>Cookie是網站伺服器用來和會員瀏覽器進行溝通的一種技術，它可能在會員的電腦中隨機儲存字串，用户瀏覽器會在用户每次查詢網站時將此字串提供给網站。Cookie的作用是將用戶識別為網站的唯一用户及儲存您的密碼，讓您不用每次造訪網站時都需要重新输入。另外，我們可能使用Cookie和其它方式通過您的Web瀏覽器儲存和檢視數據。如果您不希望Cookie保留在你的電腦上，則請在造訪網站前將瀏覽器設置為拒絕Cookie。但是請注意，如果没有Cookie，網站上的某些服務和特定功能可能無法正常使用。</p>
  <p>(3)網站信標</p>
  <p>本公司網頁和HTML格式的電子報會使用網站信標(Web Beacon) 結合Cookie，以蒐集有關網站使用情況的彙整統計資料。網站信標(Web Beacon)是一種稱為單一像素(1x1)或清晰GIF的電子影像。網站信標(Web
    Beacon)可以辨識到訪者電腦上的某些資訊類型，例如到訪者的Cookie編號、頁面檢視的時間和日期，以及網站信標(Web
    Beacon)所在頁面的描述。您可以藉由拒絕某些網站信標(Web Beacon)關聯的Cookie而使這些網站信標(Web Beacon)無法使用。</p>
  <p>(4)手持裝置數據</p>
  <p>用戶在手持裝置或其他無線通訊設備上下載、安裝和使用應用程式時，本公司會自動收集訊息，包括但不限定於運營商，設備型號、作業系統、瀏覽器類型、地理位置等。定位服務可以通過移動設備設置隨時啟用或關閉。</p>
  <p>4.個人資料利用之期間、地區、對象</p>
  <p>(1)利用期間：至蒐集目的消失或依相關法令規定或契約約定之保存年限。</p>
  <p>(2)利用地區：包括但不限於台灣、大陸及合作夥伴公司所在地 </p>
  <p>(3)利用對象：本公司、與您連線的健康管理人員、本公司委託處理事務之委外機構及依法有權機關或金融監理機關。若基於公司業務上之原因，例如合併、收購或破產等會產生轉移本公司部分或全部資產等可能，我們有權將我們擁有的所有個人資訊轉讓给接任之公司或組織。除破產或其他法院指定之範圍，使用和披露所有轉讓的個人資料將受本政策的約束，如果您被通知更新的隱私權政策，本政策有可能停止，則使用和披露行為受新的隱私權政策之約束。</p>
  <p>5.個人資料利用方式</p>
  <p>以自動化機器或其他非自動化方式所為之利用，形式包括但不限於：書面、電子、國際傳輸等。</p>
  <p>(1)提供服務</p>
  <p>我們收集您的個人資訊，最重要的是為了提供更好的服務。根據您的個人健康資訊、飲食偏好、運動習慣等，提供更客製化的使用體驗。同時，也可以為您推送更適合的相關訊息。</p>
  <p>(2)訊息通知</p>
  <p>我們收集您的個人資訊及聯絡方式，可讓我們通知您最新的產品公告、系統更新和即將舉行的活動。若您參加抽獎、競賽或類促銷活動，我們也可能使用您提供的資訊進行這些活動。</p>
  <p>(3)使用分析</p>
  <p>本公司可能會不定期以匿名形式或者統計方式收集或發布消息，以進行產業分析、研究、受眾統計等。我們甚至可能會基於行銷目的與第三方分享這些信息，但這些信息都不會透露您的個人身份。</p>
  <p>6.立書人之權利</p>
  <p>您可依據「個人資料保護法」，對本公司享有查詢或請求閱覽；請求製給複製；請求補充或更正；請求停止蒐集、處理及利用；請求刪除之權利。</p>
  <p>7.連結到其他網站</p>
  <p>本公司網站和應用程式中包含指向第三方網站及應用程式之連結。我們不擁有亦不能控制這些第三方網站及應用程式。我們不對這些第三方網站及應用程式之行為承擔任何責任，本隱私權政策亦不適用於任何第三方網站及應用程式，在訪問或將個人資訊提供給第三方網站前，您應知悉該第三方網站之隱私權政策和慣例，且您應自行採取措施來保護您的隱私。</p>
  <p>8.聯絡資訊</p>
  <p>我們承諾保護您的個人資訊和隱私權，我們歡迎您隨時聯絡我們，給予您寶貴的意見，來信請寄至services@cofit.me。</p>
</body>

</html>`

const UserTermsZh = `<html>

<head>
  <meta charset="utf-8">
</head>
<style>
  body {
    font-size: 24;
  }
</style>

<body>
  <p>更新日期：2019年6月13日</p>
  <p>一、關於本App</p>
  <p>&nbsp;&nbsp;&nbsp;1.本軟體係研發提供民眾營養運動知識之軟體。可以通過本公司之網站http://www.cofit.me（以下簡稱本網站）或經本公司授權之第三方應用下載使用。旨在結合營養學和科技，幫助人們自行記錄飲食習慣，活得更健康。用戶通過本軟體能自主健康管理也能和專家即時連線，也能通過付費獲得本公司提供的其他服務。</p>
  <p>&nbsp;&nbsp;&nbsp;2.您成為本軟體用戶後，可以享受本公司提供的服務（以下簡稱本服務）：</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a) 本軟體提供自主健康管理服務。例如您可以隨時自行記錄飲食、身體數據等健康資訊。</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b) 購買本軟體為您提供的個人化服務。例如為您連線專門的營養師、私人教練，提供營養照護、健康建議之服務。</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c) 付費連線您自選之健康服務提供者，例如營養師、教練、理療師等。</p>
  <p>&nbsp;&nbsp;&nbsp;3.用戶在註冊本軟體時，閱讀與接受本協議並點選確認，即表明您已閱讀並接受所有用戶條款，故為了保護您的合法權益，請務必審慎閱讀、充分理解以下用戶條款。</p>
  <p>二、關於本條款</p>
  <p>&nbsp;&nbsp;&nbsp;1.本條款所稱之「用戶」，係依照中華民國法律法規，有簽訂契約之能力，並依照本軟體之指引，完成註冊之人。若您為無行為能力人，應由法定代理人為之，若您為限制行為能力人，應得法定代理人之事前同意。</p>
  <p>&nbsp;&nbsp;&nbsp;2.當您使用本公司之產品或服務時，應確實遵守本條款規定事項及相關法律規定。</p>
  <p>&nbsp;&nbsp;&nbsp;3.本公司有權審核用戶之資格，當您違反本條約之事項及相關法律規定時，本公司有權終止或解除您的用戶資格。且本公司保留隨時中斷、終止或解除部分或全部服務的權利，毋需對用戶或第三人負責。</p>
  <p>&nbsp;&nbsp;&nbsp;4.本條款之修訂，適用於所有用戶。當本公司修訂本條款時，將於本網站或本軟體上公告，若您不接受修訂後之條款，請發送電子郵件與本公司協商；若您繼續使用，則視為您已閱讀並同意修訂後之條款。</p>
  <p>三、隱私權保護</p>
  <p>&nbsp;&nbsp;&nbsp;1、本公司尊重並保護您的隱私與個人資訊（請參閱「隱私權政策」）。</p>
  <p>四、註冊與登入</p>
  <p>&nbsp;&nbsp;&nbsp;1.用戶註冊帳號並使用註冊之帳號密碼登入使用本軟體。本公司僅根據用戶填寫之信息提供服務，若您提供的信息與實際情況不符，本公司不承擔責任。</p>
  <p>&nbsp;&nbsp;&nbsp;2.用戶不得將密碼洩露或提供給他人知道或使用。以同一個用戶帳號和密碼使用本服務所進行的所有行為，都將被視為是該用戶本人之行為，應自行承擔責任。若致其他用戶、本公司或其他任何第三人受有損害，用戶承擔全部責任。</p>
  <p>五、用戶使用行為</p>
  <p>&nbsp;&nbsp;&nbsp;1.您承諾絕不為任何非法目的或以任何非法方式使用本服務或本軟體提供的服務範圍，並承諾遵守中華民國相關法規及一切使用網際網路之國際慣例。</p>
  <p>&nbsp;&nbsp;&nbsp;2.您若係中華民國以外之用戶，您使用本服務之一切行為除前項規定外，必須符合當地或國際相關法令規範。若致生損害，須自行負責。若致有本公司受有損害，應付賠償責任。</p>
  <p>&nbsp;&nbsp;&nbsp;3.您於使用本軟體時應遵守以下約定：</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a)不得侵害他人權利，包括但不限於名譽、隱私權、營業秘密、商標權、著作權、專利權等。</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b)不得有以下之行為，包括但不限於上載、張貼、公布或傳送任何誹謗、侮辱、攻擊性、猥褻、不實、違反公共秩序或善良風俗或其他不法之文字、圖片或任何形式的檔案於本軟體上等。</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c)未經本公司許可，不得利用本服務或本網站所提供資源，包括但不限於圖文資料庫、編寫製作網頁之軟體、檔案等，從事任何商業或非商業行為。</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;d)其他任何違反本「用戶條款」或本公司有正當理由認為不適當之行為。</p>
  <p>六、智慧財產權的保護</p>
  <p>&nbsp;&nbsp;&nbsp;1.本公司、本網站、本軟體所使用的任何包括但不限於程式、著作、圖片、檔案、資訊、資料、網站架構、網頁設計等，本公司享有權利（包括但不限於商標權、專利權、著作人格權、著作財產權等）。</p>
  <p>&nbsp;&nbsp;&nbsp;2.任何人不得逕行使用（包括但不限於改作、重製、使用、散步、發行、公開播送、公開發表等），必須取得本公司之書面同意。若致生本公司或第三人損害，須自行承擔責任。 </p>
  <p>七、授權</p>
  <p>&nbsp;&nbsp;&nbsp;1.您或您的授權人所上載、傳送、輸入或提供予本公司之任何資料，由您所有，但任何資料一經您上載、傳送、輸入或提供予本公司時，即表示您同意：</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a)本公司、本公司之合作夥伴或本公司及其合作夥伴之受雇人、受託人或任何與業務相關之第三人，得使用（包括但不限於蒐集、處理、保存、並利用等）用戶所留存或產生之資料及記錄。</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b)授權本公司、本公司之合作夥伴或本公司及其合作夥伴之受雇人、受託人或任何與業務相關之第三人，对用戶提供之資料或記錄進行包括但不限於改作、重製、使用、散布、發行、公開播送、改作、散布、發行、公開發表等，並得再轉授權他人。</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c)您提供之資料，如使本公司、本公司之合作夥伴、本公司及其合作夥伴之受雇人、受託人或任何與業務相關之第三人致任何第三人受用損害或受請求賠償，您自行承擔責任。</p>
  <p>八、行銷</p>
  <p>&nbsp;&nbsp;&nbsp;1.本軟體會給您推送一些產品或服務，以提供個人化的服務。這些產品或服務可能來自本網站，也可能來自本網站上其他業者。</p>
  <p>&nbsp;&nbsp;&nbsp;2.用戶若不接受行銷，應以書面形式或於電子郵件，通知本公司。</p>
  <p>九、購買行為</p>
  <p>&nbsp;&nbsp;&nbsp;1.用戶可以付費購買本公司之產品或服務。本公司提供多種付款方式，例如信用卡、匯款、便利商店方式等。</p>
  <p>&nbsp;&nbsp;&nbsp;2.用戶購買本公司產品或服務的，須按照約定付款。未付全款的，本公司不提供服務。付款後又違反約定的，本公司有終止、解除之權利。</p>
  <p>&nbsp;&nbsp;&nbsp;3.用戶可以通過本網站購買其他網站或其他廠商提供之商品、服務。本公司不負責一切闡釋、說明、保證責任。不負物或權利之瑕疵擔保責任。您應要求各該廠商或個人，就其商品、服務或其他交易標的物之品質、內容、運送、保證事項與瑕疵擔保責任等，事先詳細闡釋與說明。您因前述買賣、服務或其他交易行為與任何第三方產生之糾紛，本公司無涉。</p>
  <p>十、未成年人注意規定</p>
  <p>&nbsp;&nbsp;&nbsp;1.未成年人在註冊本軟體前，應取得其法定代理人之事前書面同意。</p>
  <p>&nbsp;&nbsp;&nbsp;2.未成年人在使用本軟體購買或升級本公司之產品或服務時，應取得其法定代理人之事先書面同意。</p>
  <p>&nbsp;&nbsp;&nbsp;3.未成年人違反前述第1、2項之約定，未經其法定代理人之同意之擅自行為，導致本公司或第三人之損害，須承擔損害賠償責任。</p>
  <p>十一、與第三人網站的連結</p>
  <p>&nbsp;&nbsp;&nbsp;1.本公司之服務或合作廠商可能會提供連結至其他網站或網路資源的連結。您可能會因此連結至其他業者經營之網站，但不表示本公司與該等業者有任何關係。本公司對任何檢索結果或外部連結，不擔保其合適性、可依賴性、即時性、有效性、正確性及完整性，本公司不負任何損害賠償之責任。</p>
  <p>十二、其他用戶之行為</p>
  <p>&nbsp;&nbsp;&nbsp;1.由其他用戶公開張貼或私下傳送的資訊、資料、文字、軟體、音樂、音訊、照片、圖形、視訊、信息或其他資料，均由其他用戶自負責任。本公司無法控制經由本服務而張貼之其他用戶行為，因此不保證其正確性、完整性或品質。您了解使用本服務時，可能會接觸到令人不快、不適當、令人厭惡之內容。在任何情況下，本公司均不負責任。</p>
  <p>&nbsp;&nbsp;&nbsp;2.本公司不針對其他用戶之行為加以審查，但本公司有權（但無義務）自行之考量，拒絕或移除經由本服務提供之任何用戶行為。本公司有權將有違反本服務條款或法令之虞、或令人厭惡之任何用戶行為加以移除。</p>
  <p>十三、免責聲明</p>
  <p>&nbsp;&nbsp;&nbsp;1.下列情形發生時，本公司有權可以暫停、停止、中斷本網站及其本軟體之服務：</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a)對本服務相關軟硬體設備進行更換、升級、保養或施工時。</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b)發生突發性之電子通信設備故障時。</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c)天災或其他不可抗力之因素致使本網站無法提供服務時。</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;d)其他重大事項。</p>
  <p>&nbsp;&nbsp;&nbsp;2.用戶對於上傳留言之文字、圖片及其它資料，應自行備份；本公司對於任何原因導致其內容全部或一部之滅失、毀損，不負任何責任。</p>
  <p>&nbsp;&nbsp;&nbsp;3.本公司對用戶能否確切執行服務條款，不負任何保證責任。用戶使用本服務所致生之損害，本公司不承擔責任。</p>
  <p>&nbsp;&nbsp;&nbsp;4.對於用戶在本站中的所有言論、意見或行為僅代表用戶個人，不代表本公司的立場，本公司不負任何責任。本公司對於用戶所自稱之身分，不擔保其正確性。</p>
  <p>&nbsp;&nbsp;&nbsp;5.本公司無須對發生於本服務或透過本服務所涉及之任何恐嚇、誹謗、淫穢或其他一切不法行為對您或任何人負責。</p>
  <p>&nbsp;&nbsp;&nbsp;6.用戶應自行了解本網站之信息以及本公司合作公司之訊息，以在對有關訊息充分理解和接受的基礎上下載、使用或購買本公司或合作公司之產品。對於用戶透過本服務所購買或取得，或透過本公司之贊助者或廣告商所刊登、銷售或交付之任何貨品或服務，您應自行承擔其可能風險或依法向商品或服務提供者交涉求償，與本公司完全無關，本公司均不做任何擔保，不負任何責任。</p>
  <p>十四、其他規定</p>
  <p>&nbsp;&nbsp;&nbsp;1.若因您使用本服務之任何行為，導致本公司遭第三人或行政機關之調查或追訴時，本公司有權向您請求損害賠償，包括但不限於訴訟費用、律師費及商譽損失等。</p>
  <p>&nbsp;&nbsp;&nbsp;2.凡因使用本服務所生之爭執，均以中華民國法律為準據法，以台灣台北市地方法院為第一審管轄法院。</p>
</body>

</html>`

export { LegalNoticeZh as LegalNotice, UserTermsZh as UserTerms }
