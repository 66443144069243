import _toConsumableArray from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import { Observable } from 'rxjs';
import { factory } from '../../services';
import './RxOperators';
import * as actions from '../actions';
import * as types from '../../store/types';
import _ from 'lodash';
export var getFactoryList = function getFactoryList(action$, store) {
  return action$.ofType(types.GET_FACTORY_LIST_CALL).switchMap(function (_ref) {
    var payload = _ref.payload;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(factory.getFactoryList(payload)).switchMap(function (res) {
      var factoryGroupsList = res.groups;

      var branches = _.concat.apply(_, _toConsumableArray(_.map(factoryGroupsList, function (_ref2) {
        var branches = _ref2.branches;
        return branches;
      })));

      return [actions.getFactoryListDone({
        factoryGroupsList: factoryGroupsList
      }), actions.getCompanyListDone({
        branches: branches
      })];
    }).catch(function (err) {
      return Observable.of(actions.getFactoryListFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var getFactoryCourse = function getFactoryCourse(action$, store) {
  return action$.ofType(types.GET_FACTORY_COURSE_CALL).switchMap(function (_ref3) {
    var payload = _ref3.payload;
    return Observable.fromPromise(factory.getFactoryCourse(payload)).map(function (res) {
      return actions.getFactoryCourseDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getFactoryCourseFail(err));
    });
  });
};
export var getCoachList = function getCoachList(action$, store) {
  return action$.ofType(types.GET_FACTORY_COACH_LIST_CALL).switchMap(function (_ref4) {
    var payload = _ref4.payload;
    return Observable.fromPromise(factory.getCoachList(payload)).map(function (res) {
      return actions.getFactoryCoachListDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getFactoryCoachListFail(err));
    });
  });
};
export var getFactoryClientInfo = function getFactoryClientInfo(action$, store) {
  return action$.ofType(types.GET_FACTORY_CLIENT_INFO_CALL).switchMap(function (_ref5) {
    var payload = _ref5.payload;
    return Observable.fromPromise(factory.getFactoryClientInfo(payload)).map(function (res) {
      return actions.getFactoryClientInfoDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getFactoryClientInfoFail(err));
    });
  });
};
export var getReserveClasses = function getReserveClasses(action$, store) {
  return action$.ofType(types.GET_RESERVE_CLASSES_CALL).switchMap(function (_ref6) {
    var payload = _ref6.payload;
    return Observable.fromPromise(factory.getReserveClasses(payload)).map(function (res) {
      return actions.getReserveClassesDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getReserveClassesFail(err));
    });
  });
};
export var reserveClasses = function reserveClasses(action$, store) {
  return action$.ofType(types.RESERVE_CLASSES_CALL).switchMap(function (_ref7) {
    var payload = _ref7.payload;
    return Observable.fromPromise(factory.reserveClasses(payload)).map(function (res) {
      return actions.reserveClassesDone(res);
    }).catch(function (err) {
      return Observable.of(actions.reserveClassesFail(err));
    });
  });
};
export var cancelReserveClasses = function cancelReserveClasses(action$, store) {
  return action$.ofType(types.CANCEL_RESERVE_CLASSES_CALL).switchMap(function (_ref8) {
    var payload = _ref8.payload;
    return Observable.fromPromise(factory.cancelReserveClasses(payload)).map(function (res) {
      return actions.cancelReserveClassesDone(res);
    }).catch(function (err) {
      return Observable.of(actions.cancelReserveClassesFail(err));
    });
  });
};