import { createAction } from 'redux-actions';
import * as types from '../types';
export var getShopIntegralIndexCall = createAction(types.GET_SHOP_INTEGRAL_INDEX_CALL);
export var getShopIntegralIndexDone = createAction(types.GET_SHOP_INTEGRAL_INDEX_DONE);
export var getShopIntegralIndexFail = createAction(types.GET_SHOP_INTEGRAL_INDEX_FAIL);
export var getIgGoodsCartCall = createAction(types.GET_IG_GOODS_CART_CALL);
export var getIgGoodsCartDone = createAction(types.GET_IG_GOODS_CART_DONE);
export var getIgGoodsCartFail = createAction(types.GET_IG_GOODS_CART_FAIL);
export var igSettlementCall = createAction(types.IG_SETTLEMENT_CALL);
export var igSettlementDone = createAction(types.IG_SETTLEMENT_DONE);
export var igSettlementFail = createAction(types.IG_SETTLEMENT_FAIL);
export var igAddOrderCall = createAction(types.IG_ADD_ORDER_CALL);
export var igAddOrderDone = createAction(types.IG_ADD_ORDER_DONE);
export var igAddOrderFail = createAction(types.IG_ADD_ORDER_FAIL);
export var getIntegralListCall = createAction(types.GET_INTEGRAL_INFO_LIST_CALL);
export var getIntegralListDone = createAction(types.GET_INTEGRAL_INFO_LIST_DONE);
export var getIntegralListFail = createAction(types.GET_INTEGRAL_INFO_LIST_FAIL);
export var getIntegralTaskCall = createAction(types.GET_INTEGRAL_TASK_CALL);
export var getIntegralTaskDone = createAction(types.GET_INTEGRAL_TASK_DONE);
export var getIntegralTaskFail = createAction(types.GET_INTEGRAL_TASK_FAIL);
export var getIntegralRuleCall = createAction(types.GET_INTEGRAL_RULE_CALL);
export var getIntegralRuleDone = createAction(types.GET_INTEGRAL_RULE_DONE);
export var getIntegralRuleFail = createAction(types.GET_INTEGRAL_RULE_FAIL);
export var addIntegralCall = createAction(types.ADD_INTEGRAL_CALL);
export var addIntegralDone = createAction(types.ADD_INTEGRAL_DONE);
export var addIntegralFail = createAction(types.ADD_INTEGRAL_FAIL);