export var GET_SHOP_INTEGRAL_INDEX_CALL = 'GET_SHOP_INTEGRAL_INDEX_CALL';
export var GET_SHOP_INTEGRAL_INDEX_DONE = 'GET_SHOP_INTEGRAL_INDEX_DONE';
export var GET_SHOP_INTEGRAL_INDEX_FAIL = 'GET_SHOP_INTEGRAL_INDEX_FAIL';
export var GET_IG_GOODS_CART_CALL = 'GET_IG_GOODS_CART_CALL';
export var GET_IG_GOODS_CART_DONE = 'GET_IG_GOODS_CART_DONE';
export var GET_IG_GOODS_CART_FAIL = 'GET_IG_GOODS_CART_FAIL';
export var IG_SETTLEMENT_CALL = 'IG_SETTLEMENT_CALL';
export var IG_SETTLEMENT_DONE = 'IG_SETTLEMENT_DONE';
export var IG_SETTLEMENT_FAIL = 'IG_SETTLEMENT_FAIL';
export var IG_ADD_ORDER_CALL = 'IG_ADD_ORDER_CALL';
export var IG_ADD_ORDER_DONE = 'IG_ADD_ORDER_DONE';
export var IG_ADD_ORDER_FAIL = 'IG_ADD_ORDER_FAIL';
export var GET_INTEGRAL_INFO_LIST_CALL = 'GET_INTEGRAL_INFO_LIST_CALL';
export var GET_INTEGRAL_INFO_LIST_DONE = 'GET_INTEGRAL_INFO_LIST_DONE';
export var GET_INTEGRAL_INFO_LIST_FAIL = 'GET_INTEGRAL_INFO_LIST_FAIL';
export var GET_INTEGRAL_TASK_CALL = 'GET_INTEGRAL_TASK_CALL';
export var GET_INTEGRAL_TASK_DONE = 'GET_INTEGRAL_TASK_DONE';
export var GET_INTEGRAL_TASK_FAIL = 'GET_INTEGRAL_TASK_FAIL';
export var GET_INTEGRAL_RULE_CALL = 'GET_INTEGRAL_RULE_CALL';
export var GET_INTEGRAL_RULE_DONE = 'GET_INTEGRAL_RULE_DONE';
export var GET_INTEGRAL_RULE_FAIL = 'GET_INTEGRAL_RULE_FAIL';
export var ADD_INTEGRAL_CALL = 'ADD_INTEGRAL_CALL';
export var ADD_INTEGRAL_DONE = 'ADD_INTEGRAL_DONE';
export var ADD_INTEGRAL_FAIL = 'ADD_INTEGRAL_FAIL';