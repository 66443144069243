export default {
  common: {
    all: '全部',
    button: {
      add: '新增',
      edit: '編輯',
      orderby: '排序',
      complete: '完成',
      completed: '已完成',
      next: '下一步',
      submit: '送出',
      ok: '確認',
      no: '否',
      close: '關閉',
      cancel: '取消',
      update: '更新',
      delete: '刪除',
      send: '傳送',
      join: '加入',
      back: '返回',
      skip: '略過',
      save: '儲存',
      select: '請選擇',
      list: '功能清單',
      copy: '複製',
      reload: '重新讀取',
      rotate: '旋轉',
      more: '查看更多',
      default: '預設',
      manage: '管理'
    },
    enterMessage: '輸入訊息...',
    foodseperator: '、',
    of: '的',
    inputPlaceholder: '請輸入{{content}}',
    loading: '讀取中',
    empty: '目前還沒有資料',
    localeNames: {
      zh_TW: '中文',
      en: 'English'
    },
    message: {
      title: '通知',
      serverError: '目前網路或伺服器可能有錯誤，請稍後再試',
      noNet: '目前無網路連線，請確認網路連線狀態',
      isNew: '您目前使用的是最新版程式',
      update: '有程式更新\n版本{{appVersion}}\n請按下確認立刻更新',
      backStep: '回上一步將無法保存目前編輯內容',
      postError: '您的內容含有敏感訊息，請重新編輯',
      deleteCheck: '確定要刪除嗎？',
      cancelCheck: '確定要取消嗎？'
    },
    time: {
      title: '時間',
      selectDate: '請選擇日期',
      select: '請選擇時間',
      second: '秒',
      minute: '分鐘',
      today: '今天'
    },
    tabs: {
      home: {
        title: '首頁',
        label: '首頁'
      },
      diary: {
        title: '日記',
        label: '日記'
      },
      information: {
        title: '消息',
        label: '消息'
      },
      coach: {
        title: '專家',
        label: '專家',
        anTitle: '朋友'
      },
      social: {
        title: '社群',
        label: '社群'
      },
      explore: {
        title: '探索',
        label: '探索'
      },
      notification: {
        title: '通知',
        label: '通知'
      },
      profile: {
        title: '我的',
        label: '我的'
      },
      client: {
        title: '我的客戶',
        label: '我的客戶',
        ffTitle: '我的會員'
      },
      dialog: {
        title: '客戶對話',
        label: '客戶對話',
        ffTitle: '會員對話'
      },
      setting: {
        title: '設定',
        label: '設定'
      },
      shop: {
        title: '商城',
        label: '商城'
      }
    },
    unit: {
      pound: '磅',
      kg: '公斤',
      cm: '公分',
      day: '天',
      kcal: '大卡',
      servings: '份',
      group: '組',
      times: '次',
      number: '{{unit}}數',
      ml: '毫升',
      gram: '公克',
      calorie: '熱量',
      each: '每{{unit}}'
    }
  },
  food: {
    normalServing: '標準份',
    weight: '重量',
    number: '數量',
    info: '食物明細',
    mealByOrder: {
      1: '早餐',
      2: '早上點心',
      3: '午餐',
      4: '下午點心',
      5: '晚餐',
      6: '宵夜',
      7: '點心'
    }
  },
  dataChart: {
    title: '數據紀錄',
    healthTitle: '身體數據',
    health: '身體',
    nutrition: '營養',
    move: '運動',
    week: '週',
    month: '月',
    year: '年',
    M: '月',
    Y: '年',
    addData: '新增數據',
    noData: '目前暫無數據',
    notShow: '不出現',
    entered: '輸入',
    notEntered: '未輸入',
    errorTitle: '輸入錯誤',
    errorText: '{{title}}數據請在{{inputMin}}~{{inputMax}}範圍內',
    list: '數據列表'
  },
  login: {
    login: '登入',
    register: '註冊',
    fbLogin: 'Facebook 登入',
    fbRegister: ' Facebook 註冊',
    wechatLogin: 'WeChat 登入',
    wechatRegister: 'WeChat 註冊',
    passwordLogin: '帳密登入',
    account: '帳號',
    password: '密碼',
    name: '暱稱',
    selectLanguage: '請選擇語言',
    or: '或',
    accountPlaceholder: '請輸入 Email',
    passwordPlaceholder: '至少八位數',
    namePlaceholder: '請輸入暱稱',
    toRegister: '還沒有帳號？ 註冊',
    toLogin: '已經有帳號了嗎？ 登入',
    loginErrorTitle: '驗證錯誤',
    loginErrorMessage: '請確認帳號或密碼是否正確',
    loginErrorMessageEmail: '請啟動Email驗證信',
    loginErrorOverDays: '您的帳號目前並非有效帳號',
    loginErrorEmailFormat: '請輸入有效的 Email',
    loginErrorPasswordMissing: '請輸入密碼',
    noPermission: '您的帳號目前並非有效帳號',
    investigation: '您的帳號正在審查中',
    registerTitle: '驗證信已寄出',
    registerMessage: '點擊Email中的連結\n就可以註冊成功嘍！',
    registerErrorTitle: '帳號已存在',
    registerErrorMessage: '此帳號已經被註冊了\n請使用其他帳號註冊',
    phone: {
      title: '手機號登入',
      codeLogin: '手機驗證碼登入',
      passwordLogin: '手機密碼登入',
      number: '手機號',
      code: '驗證碼',
      numberPlaceholder: '輸入手機號',
      codePlaceholder: '輸入驗證碼',
      pwdPlaceholder: '輸入密碼',
      countryCode: '手機區碼',
      getCode: '獲取驗證碼',
      codeError: '驗證碼錯誤',
      pwdError: '手機號或密碼錯誤',
      reGetCode: '重新獲取驗證碼',
      getCodeError: '獲取驗證碼錯誤'
    },
    bind: {
      title: '綁定',
      codeLogin: '手機驗證碼綁定',
      passwordLogin: '手機密碼綁定'
    }
  },
  forget: {
    title: '忘記密碼?',
    titleMessage: '請輸入您的Email，我們會將重置密碼連結寄至您的Email信箱。',
    ffTitleMessage: '請輸入您的身分證字號，我們會將重置密碼連結寄至您的Email信箱。',
    sendPassword: '重置密碼',
    sendTitle: '連結已寄出',
    sendMessage: '請依照郵件中的指示，\n重新設定您的密碼。',
    notExist: '此Email尚未被註冊'
  },
  target: {
    title: '目標設定',
    projectTarget: '計畫目標',
    end: '目標設定完成！',
    start: '開始',
    gender: '生理性別',
    male: '男',
    female: '女',
    birthday: '生日',
    nowWeight: '現在體重',
    nowHeight: '現在身高',
    water: '目標飲水',
    waterPlaceholder: '請輸入飲水目標或使用系統計算目標',
    reSetWater: '重新計算飲水目標',
    calorie: '目標熱量',
    targetWeight: '目標體重',
    warningWeight: '目標體重請小於當前體重',
    warningBmi: '目標體重太輕了！可能造成復胖或影響健康',
    activity: {
      title: '活動程度',
      low: {
        title: '低度',
        text: '主要從事靜態活動，睡覺、靜臥或悠閒的坐著。如：坐著看書、看電視...等。'
      },
      slow: {
        title: '稍低度',
        text: '主要從事輕度活動，且一天中約1小時從事不激烈的動態活動，如散步。'
      },
      mid: {
        title: '中度',
        text: '從事活動強度為中度的活動，如：遊戲、唱歌，且一天中約1小時從事較強動態活動，如：快步走。'
      },
      high: {
        title: '高度',
        text: '身體活動程度較正常快速或激烈、消耗熱量較多，一天至少一小時激烈的訓練運動。例如：打球、騎腳踏車、有氧運動、重量訓練等運動。'
      }
    },
    warningLoss: '減重速率太快了！可能造成復胖或影響健康',
    warningCalorie: '低於基礎代謝',
    calorieTitle: '每天需攝取熱量',
    monthKg: '每月減少',
    addMonthKg: '每月增加',
    weeklyKg: '每週減少',
    addWeeklyKg: '每週增加',
    targetDay: '預計需要時間',
    targetSet: '衝刺期間幫您設定',
    PlanCountdown: '計畫倒數',
    ResetPlan: '重新設定',
    targetIntake: '目標攝取',
    welcome: '歡迎',
    welcomeText: '在這短短的時間內，Cofit將會與你一起努力，讓你越吃越健康！',
    lossWeight: '減重',
    addMuscle: '增肌',
    addWeight: '增重',
    keep: '維持',
    select: '選擇計畫目標',
    warn: '連線期間的目標體重與攝取熱量，將由系統自動計算，如需修改請與營養師討論。',
    targetEnd: {
      default: {
        text1: '恭喜你輸入完基本資料，我們已經計算出來屬於你的個人化飲食目標囉！',
        text2: '熱量與六大類食物份量都是根據剛剛所填寫的所有資料演算而來，每天的完成度越高，距離自己的體重目標也越近，加油！'
      },
      child: {
        text1: '恭喜您輸入完寶貝的基本資料，我們已經計算出屬於孩子個人化的飲食目標唷！',
        text2: '熱量與六大類份量都是根據剛剛所填寫的資料演算而來的，每天飲食、運動、生活習慣等的完成率越高，對於提升長高潛力及體重的維持越有幫助喔！'
      },
      age65: {
        text1: '年長者身體的肌肉含量會直接影響到死亡風險，因此接下來計畫會著重在減少體脂率，或是增加肌肉量，讓您同時擁有身材與健康！',
        text2: '熱量與營養素目標都是根據剛剛所填寫的所有資料演算而來，每天的完成度越高，變健康的速度越快，加油！'
      },
      bmi18: {
        text1: '您的BMI與體重目前落在過瘦體位範圍，因此接下來計畫會著重在減少體脂率，或是增加肌肉量，讓您的身材曲線更加明顯！',
        text2: '熱量與營養素目標都是根據剛剛所填寫的所有資料演算而來，每天的完成度越高，達到目標的速度越快，加油！'
      },
      bmi24: {
        text1: '您的BMI與體重目前落在正常體位範圍，因此接下來計畫會著重在減少體脂率，對您的曲線雕塑會更加明顯哦！',
        text2: '熱量與營養素目標都是根據剛剛所填寫的所有資料演算而來，每天的完成度越高，達到目標的速度越快，加油！'
      }
    }
  },
  registrationCode: {
    title: '加入群組',
    titleMessage: '營養師將能瀏覽您以下資料：基本資料、身體、營養數據，並能夠與您對話、修改個人目標，以及針對您的飲食進行評論和計算。',
    inputPlaceholder: '請輸入群組ID',
    modalTitle: '加入成功！',
    modalMessage: '已經成功加入，準備好成為更好的自己了嗎？',
    modalText: '準備好了！',
    error: '群組ID錯誤',
    errorMessage: '群組ID錯誤'
  },
  setting: {
    account: '帳號',
    title: '設定',
    language: '語言',
    switchLanguage: '切換語言',
    eula: '使用者條款(EULA)',
    privacyPolicy: '隱私權政策',
    version: '程式版本',
    logout: '登出',
    phoneName: '手機名稱',
    system: '系統版本',
    update: '檢查程式更新',
    appSetting: '前往系統設定',
    appStore: '前往Store',
    web: '官方網站',
    blockList: '封鎖列表',
    defaultOpenImage: '預設新增日記開啟照片選擇器',
    defaultIsPost: '預設公開貼文到社群',
    defaultEditing: '預設開啟裁切照片功能',
    defaultIsTwoPost: '預設開啟社群雙頁模式',
    defaultOpenBarCode: '預設開App優先開啟條碼頁',
    defaultPedometer: '預設同步計步資料',
    issue: '問題回報',
    issuePlaceholder: '請簡述您遇到的問題',
    accountPlaceholder: '請輸入帳號(可空白)',
    agree: '繼續進行即表示您同意'
  },
  customerService: {
    title: '客服',
    ask: '我想詢問「{{what}}」'
  },
  bodyAttrs: {
    calorie: '攝取熱量',
    height: '身高',
    weight: '體重',
    body_fat_mass_percentage: '體脂率',
    waist_circumference: '腰圍',
    hip_circumference: '臀圍',
    muscle_mass: '肌肉重',
    bmi: 'BMI',
    waist_hip_ratio: '腰臀比',
    total_body_water_percentage: '身體水份率',
    systolic_blood_pressure: '收縮壓 SBP',
    diastolic_blood_pressure: '舒張壓 DBP',
    pulse: '脈搏',
    blood_sugar: '血糖',
    total_cholesterol: '總膽固醇 TC',
    high_density_lipoprotein: '高密度脂蛋白膽固醇 HDL-C',
    low_density_lipoprotein_cholesterol: '低密度脂蛋白膽固醇 LDL-C',
    triglyceride: '三酸甘油酯 TG',
    blood_pressure: '血壓',
    muscle_mass_percentage: '肌肉率',
    lean_body_mass: '去脂體重',
    fruit: '水果',
    fruits: '水果',
    vegetables: '蔬菜',
    grains: '全穀雜糧',
    meat: '豆魚蛋肉',
    milk: '乳品',
    oil: '油脂',
    meat_beans_low_fat: '低脂豆蛋魚肉',
    meat_beans_medium_fat: '中脂豆蛋魚肉',
    meat_beans_high_fat: '高脂豆蛋魚肉',
    meat_beans_super_high_fat: '超高脂豆蛋魚肉',
    milk_skim: '脫脂乳品',
    milk_low_fat: '低脂乳品',
    milk_whole_fat: '全脂乳品',
    measurement: '比例尺',
    water: '飲水',
    water_intake: '飲水'
  },
  moveAttrs: {
    training_volume: '訓練量',
    calorie: '運動消耗熱量'
  },
  self: {
    title: '個人資料',
    name: '姓名',
    preferredName: '暱稱',
    selfIntroduction: '自我介紹',
    noSelfIntroduction: '還沒有自我介紹喔～'
  },
  child: {
    basicTitle: '寶貝基本資料',
    estimateTitle: '預測寶貝身高',
    name: '寶貝姓名',
    namePlaceholder: '請輸入寶貝姓名',
    changeNote: '切換日記',
    targetDay: '成長課程將進行',
    targetHeight: '寶貝預測身高',
    father: '爸爸身高',
    mom: '媽媽身高'
  },
  chat: {
    loadMore: '載入更多訊息',
    send: '傳送給 {{name}}',
    disabled: '對話已關閉',
    members: '成員',
    lastOnlineAt: '最後上線 {{lastOnlineAt}}前',
    addChat: '新增聊天',
    addGroup: '新增群組',
    enterGroup: '加入群組',
    shopChat: '商城客服',
    leaveChats: '退出聊天',
    addMembers: '增加新成員',
    showChatId: '顯示群組ID',
    mute: '關閉通知',
    unMute: '開啟通知',
    isMute: '已關閉通知',
    isUnMute: '已開啟通知',
    chatId: '群组ID',
    leaveCheck: '確定要退出嗎？'
  },
  plan: {
    title: '計畫選擇',
    more: '更多計畫',
    isBuy: '已經加入了嗎？',
    freeTitle: '免費試用飲食紀錄功能',
    try: '先試用看看'
  },
  move: {
    train: '訓練',
    device: '訓練設備',
    duration: '持續時間',
    repeat: '重複',
    fire: '燃燒',
    routine: '計畫',
    aerobic: '有氧',
    workout: '肌力',
    custom: '自訂',
    addCustom: '新增自訂項目',
    addCustomPlaceholder: '請輸入自訂項目名稱',
    calorieInfo: '燃燒 {{calorie}}大卡',
    aerobicInfo: '持續{{dur}}分鐘, 燃燒 {{calorie}}大卡',
    workoutInfo: '{{set}}組, 重複{{rep}}次, 每組{{weight}}公斤',
    sessionInfo: '{{name}} {{reps}}次, 休息{{restTime}}秒, {{weight}}',
    sessionItem: '持續{{dur}}分鐘, 燃燒 {{calorie}}大卡, {{numericText}}',
    fatigue: {
      title: '疲勞程度',
      numeric4: '輕鬆寫意',
      numeric5: '超有感',
      numeric6: '瀕臨極限',
      select: '請選擇疲勞程度'
    },
    remark: {
      title: '備註',
      placeholder: '例如膝蓋發出聲音...'
    },
    plan: {
      title: '第 {{group}} 組',
      prev: '上一組',
      next: '下一組'
    },
    load: '負重',
    restTime: '休息時間',
    list: {
      title: '運動清單'
    }
  },
  diary: {
    move: '運動',
    life: '生活',
    diag: '診斷',
    info: '{{genderText}}, {{age}}歲, {{weight}} 公斤',
    over: '超過 / 千卡',
    remain: '剩下 / 千卡',
    haveEat: '已經攝取',
    moveReduction: '運動消耗',
    fullData: '詳細數據'
  },
  article: {
    list: '文章列表',
    title: '課程文章',
    newArticle: '最新文章',
    sixgroup: '六大類說明',
    readMore: '繼續閱讀'
  },
  note: {
    punch: {
      punchText: '在 {{name}}'
    },
    open: '展開',
    close: '收起',
    light: {
      red: '紅燈',
      green: '綠燈',
      yellow: '黃燈'
    },
    add: {
      addCard: '新增食物卡',
      addPost: '公開貼文到社群',
      postTo: '公開貼文到',
      addTags: '新增標籤',
      addTagName: '標註人名',
      addLocation: '新增地點',
      defaultName: '未命名食物',
      album: '相簿',
      openAlbum: '開啟相簿',
      openCamera: '開啟相機',
      history: '最近',
      photo: '照片',
      camera: '相機',
      upload: '上傳',
      video: '影片',
      draft: '草稿',
      saveDraft: '草稿',
      saveDraftSuccess: '儲存草稿成功',
      processing: '處理中',
      picker: '選取照片',
      fastAdd: '快速加入',
      custom: '自定義',
      noDataMessage: '請上傳照片或紀錄文字',
      diag: {
        placeholder: "描述一下您的診斷結果吧！",
        date: '紀錄日期',
        time: '紀錄時間'
      },
      life: {
        placeholder: "描述一下您的心情吧！",
        date: '紀錄日期',
        time: '紀錄時間'
      },
      move: {
        placeholder: "描述一下您的運動狀況吧！",
        date: '運動日期',
        time: '運動時間',
        addName: '請輸入運動名稱'
      },
      food: {
        placeholder: "描述一下您的飲食吧！",
        date: '用餐日期',
        time: '用餐時間',
        addName: '請輸入食物名稱',
        name: '食物名稱'
      },
      water: {
        placeholder: "描述一下您的飲水狀況吧！",
        date: '紀錄日期',
        time: '紀錄時間',
        inputPlaceholder: '請輸入飲水量'
      }
    },
    edit: {
      lightPlaceholder: '紅、黃燈請留下原因及具體改善方式唷！',
      save: '儲存並通知',
      allPlaceholder: '整餐建議'
    },
    delete: {
      title: '刪除這項食物紀錄',
      image: {
        title: '刪除照片',
        message: '確定要刪除這張照片嗎？'
      },
      food: {
        title: '刪除這張照片與食物紀錄'
      }
    }
  },
  comment: {
    noComment: '目前沒有任何留言！'
  },
  imagePicker: {
    noPermission: '無法讀取您的照片',
    remind: '請前往設定確認照片與相機權限是否開啟'
  },
  camera: {
    noPermission: '無法讀取您的相機',
    remind: '請前往設定確認相機與麥克風權限是否開啟'
  },
  location: {
    noPermission: '無法讀取您的位置',
    remind: '請前往設定確認位置權限是否開啟'
  },
  social: {
    title: '社群',
    likes: '{{likesCount}} 個人喜歡',
    kept: '收藏',
    keptPosts: '收藏列表',
    info: {
      add: {
        life: '生活紀錄'
      },
      articles: {
        move: '健身新知',
        nutrition: '運動營養'
      }
    },
    profile: {
      edit: '編輯個人資料',
      post: '貼文',
      unFollow: '取消追蹤',
      follower: '粉絲',
      following: '追蹤',
      latest: '最新'
    },
    tab: {
      hot: '熱門',
      interesting: '趣聞',
      activity: '活動',
      recommend: '好物'
    }
  },
  question: {
    title: '文章作答',
    multiple: '(複選)',
    success: '恭喜您答對本篇文章！',
    list: '問卷列表',
    test: '開始測試',
    newSend: '立即送出',
    next: '下一題',
    error: '請選擇答案',
    overchoice: '選擇過多的選項',
    maximun: '上限',
    choice_unit: '項',
    start: '開始',
    free_input_placehoder: '請填入您的想法'
  },
  nutrition: {
    sixgroup: '六大類',
    threeMacroSummary: '熱量及三大營養素',
    low: '低脂',
    medium: '中脂',
    high: '高脂',
    super: '超高脂',
    whole: '全脂',
    off: '脫脂',
    protein: ' 蛋白質',
    cho: '醣類',
    fat: '脂質',
    sugar: '糖',
    alcohol: '酒精'
  },
  database: {
    title: '資料庫',
    food: '食物資料庫',
    brand: '品牌',
    government: '政府',
    move: '運動資料庫'
  },
  client: {
    myClient: '我的客戶',
    star: '我的最愛',
    base: '基本資料',
    data: '客戶數據',
    chat: '開啟對話',
    namePlaceholder: '請輸入用戶姓名',
    setName: '姓名(客戶輸入)'
  },
  ffCommon: {
    title: '我的場館',
    login: {
      accountPlaceholder: '預設身分證字號',
      passwordPlaceholder: '預設生日，例如:19900101'
    },
    coach: {
      title: '教練',
      about: '關於我',
      expertise: '我的專業',
      license: '證照',
      male: '男性教練',
      female: '女性教練'
    },
    course: {
      title: '課程',
      group: '團課'
    },
    info: {
      title: '資訊',
      openMap: '是否要開啟地圖',
      time: '營業時間',
      address: '地址',
      phone: '電話'
    },
    profileInfo: {
      title: '會籍資訊',
      phone: '手機',
      venue: '會籍場館',
      memberCode: '會員編號',
      startTime: '開始時間',
      endTime: '結束時間',
      courseVenue: '課程場館',
      courseName: '課程名稱',
      remainingCourse: '剩餘堂數',
      lockerVenue: '置物櫃場館',
      lockerNumber: '置物櫃號碼'
    },
    card: {
      user: '會員卡',
      staff: '員工卡',
      codeRemind: '點擊條碼或QR Code可放大',
      remind: '功能說明',
      remindText1: '1.條碼頁面可透過搖動手機來顯示\n',
      remindText2: '2.條碼讀取後將會暫存在手機中，如果無網路或伺服器忙碌中還是可顯示暫存條碼\n',
      remindText3: '3.開啟條碼頁面會嘗試將螢幕亮度調到最大，關閉會回復原始設定\n',
      remindText4: '4.點擊條碼或QR Code可放大,再次點擊縮小\n',
      remindText5: '5.可至設定頁設定App優先開啟條碼頁功能\n'
    }
  },
  notification: {
    allRead: '全部已讀'
  },
  search: {
    title: '搜尋',
    user: '專家',
    client: '用戶',
    tag: '標籤',
    clearHistory: '清除歷史搜尋記錄',
    emptyHistory: '目前沒有搜尋紀錄'
  },
  group: {
    rank: '群組排行榜',
    greenLightRatios: '綠燈比例',
    movementNumbers: '運動標籤數',
    completedQuestionNumbers: '課程完成數',
    greenLightCheckInNumbers: '綠燈好食打卡數',
    exerciseCheckInNumbers: '運動打卡數',
    weightDifferences: '體重變化',
    social: '群組社群牆',
    tag: '群組標籤',
    survey: '問券'
  },
  program: {
    title: '方案'
  },
  invoice: {
    title: '電子發票',
    info: '電子發票明細'
  },
  share: {
    title: '分享',
    saveImage: '儲存到相簿'
  },
  report: {
    title: '檢舉',
    post: '貼文',
    placeholder: '請輸入檢舉的原因',
    subTitle: '檢舉後我們將會審核貼文內容\n封鎖用戶後在公開社群上將無法看到該用戶貼文',
    send: '送出檢舉',
    block: '封鎖',
    unBlock: '解除封鎖',
    success: '檢舉成功，我們將會審核貼文內容'
  },
  editPassword: {
    title: '修改密碼',
    setPassword: '設置密碼',
    inputOld: '輸入目前密碼',
    inputNew: '輸入新密碼',
    inputCheck: '再次輸入新密碼',
    button: '確定修改密碼',
    setButton: '確定設置密碼',
    inputCheckError: '兩次新密碼不同',
    oldPasswordError: '目前密碼錯誤',
    success: '修改密碼成功'
  },
  editEmail: {
    title: '修改Email',
    placeholder: '請輸入新的Email',
    error: '您輸入的Email已被使用',
    prompt: '請先修改您的Email'
  },
  editComment: {
    title: '修改留言',
    placeholder: '請輸入新的留言'
  },
  wechat: {
    noApp: '您尚未安裝WeChat App'
  },
  classOrders: {
    title: '我的課程',
    reserve: '已預約課程',
    nutrition: '營養課程',
    dateRange: '開始日期：{{start}}\n結束日期：{{end}}',
    end: '課程已結束',
    diffWeek: '課程第 {{diffWeek}} 週'
  },
  barCode: {
    title: '條碼',
    scanner: '掃描條碼'
  },
  classReserve: {
    title: '預約課程',
    timePlaceholder: '請選擇預約時間',
    add: '確定新增預約',
    date: '預約日期',
    time: '預約時間',
    reserve: '立即預約'
  },
  shop: {
    buyGift: {
      goods: '滿贈商品',
      item: '目前商品滿{{amount}}元即贈送商品',
      select: '點擊選擇贈送商品',
      price: '贈品,原價:{{priceText}}'
    },
    goods: {
      service: '服務',
      details: '商品詳情',
      all: '查看全部'
    },
    menu: {
      title: '更多',
      addMe: '加入我們',
      sellShare: '分銷推薦'
    },
    type: {
      recommend: '推荐',
      new: '新品',
      set: '套盒',
      deduce: '滿折',
      freegood: '免費試用',
      buyGift: '滿贈',
      lifegood: '團購-生活'
    },
    shoppingCart: {
      title: '購物車',
      success: '成功加入購物車',
      selectAll: '全選',
      settlement: '結算'
    },
    add: {
      title: '確認訂單',
      pay: '訂單支付',
      cart: '加入購物車',
      buy: '立即購買',
      send: '提交訂單',
      again: '重新支付',
      number: '購買數量',
      inventory: '庫存 {{inventory}}件',
      notInventory: '商品沒有庫存了'
    },
    pay: {
      title: '支付方式',
      wxPay: '微信支付',
      detail: '支付明細',
      sum: '總計',
      amount: '商品金額',
      freight: '運費',
      nofreight: '免運費',
      nopurchase: '免加價',
      purchase: '加價購',
      success: '支付成功',
      error: '支付失敗',
      viewOrder: '查看訂單',
      backShop: '返回商城',
      massage: '給商家留言',
      msgPlaceholder: '填寫內容'
    },
    purchaser: {
      title: '收貨地址',
      placeholder: '請填寫收貨地址',
      check: '請填寫完整地址',
      name: '收貨人',
      phone: '電話號碼',
      area: '所在區域',
      address: '詳細地址',
      setDefault: '設置為默認收貨資訊',
      contact: '联系人'
    },
    order: {
      title: '訂單',
      info: '訂單詳情',
      myOrder: '我的訂單',
      all: '查看全部訂單',
      cancel: '已取消',
      pay: '待付款',
      ship: '待發貨',
      receipt: '待收貨',
      evaluation: '待評價',
      time: '下單時間：',
      amountText: '共{{amount}}件商品',
      orderConfirm: '確認收貨',
      orderConfirmCheck: '確定已經收貨了嗎？',
      orderCancel: '取消訂單',
      apply: '申請開票中',
      applySuccess: '審核成功',
      applyFail: '審核失敗',
      reApply: '重新審核',
      applyAudit: '審核中',
      view: '查看',
      orderRefund: '退款',
      orderRefundCheck: '確認退款？',
      refunding: '退款中',
      refunded: '退款完成'
    },
    returnGoods: {
      title: '退貨申請',
      text: '退款/售後',
      cancel: '取消退貨',
      ship: '填寫退貨物流',
      check: '待商家確認',
      refund: '待退款',
      number: '退貨數量',
      content: '退貨申請說明',
      contentPlaceholder: '請填寫退貨申請說明',
      shipTitle: '物流公司',
      shipTitlePlaceholder: '請選擇物流公司',
      shipNumber: '物流單號',
      shipNumberPlaceholder: '請填寫物流單號',
      orderReturnList: '退貨申請',
      orderReturnLog: '退貨紀錄',
      complete: '退款完成',
      shopAddrsInfo: '商家收货信息'
    },
    transport: {
      company: '運送公司',
      code: '查詢號碼'
    },
    evaluate: {
      title: '評價',
      list: '評價列表',
      buyer: '整體評價：',
      description: '描述相符：',
      service: '服務態度：',
      ship: '發貨速度：',
      info: '評論：',
      check: '匿名評價',
      good: '好評',
      mid: '中評',
      bad: '差評'
    },
    integral: {
      myIntegral: '我的積分',
      order: '積分訂單',
      shop: '積分商城',
      title: '積分',
      balance: '餘額',
      error: '積分不足',
      buy: '立即兌換',
      rule: '積分規則',
      info: '積分明細',
      activity: '積分活動',
      get: '已獲得',
      cost: '已消耗',
      date: '日期',
      id: '序號',
      getName: '參與項目',
      value: '積分值',
      getValue: '獲得積分',
      remark: '備註',
      action: '動作',
      run: '前往進行',
      isSign: '您今天已經完成簽到了哦',
      ruleTitle: '獲得積分規則',
      tagMessage: '[有人@你]【拜託幫我點一下，你也🈶💰💰】'
    },
    invoice: {
      add: '申請發票',
      noNeed: '無需發票',
      person: '個人',
      general: '增值稅普票',
      special: '增值稅專票',
      order: '訂單編號',
      pay: '發票金額',
      addr: '發票寄送地址',
      company: '公司名稱',
      numberId: '納稅人識別號',
      companyAddr: '註冊地址',
      companyPhone: '註冊電話',
      bank: '開戶行',
      bankId: '銀行帳號',
      review: '我们会在一个工作日内完成审核'
    }
  },
  video: {
    play: '播放',
    recordStart: '按住 開始錄音',
    recordEnd: '鬆開 結束錄音',
    prompt: '往上滑取消錄音',
    cancel: '鬆開 取消錄音'
  },
  anApp: {
    companyPageTitle: '美聚',
    companyListTitle: '合作醫院',
    companyActivity: '精彩活動',
    questTitle: '肌膚評測'
  }
};