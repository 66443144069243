import { Observable } from 'rxjs';
import { food } from '../../services';
import * as actions from '../actions';
import * as types from '../../store/types';
export var getFoodStuffGroups = function getFoodStuffGroups(action$, store) {
  return action$.ofType(types.GET_FOOD_STUFF_GROUPS_CALL).switchMap(function (q) {
    return Observable.fromPromise(food.getFoodStuffGroups()).map(function (res) {
      return actions.getFoodStuffGroupsDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getFoodStuffGroupsFail(err));
    });
  });
};
export var getFoodStuffs = function getFoodStuffs(action$, store) {
  return action$.ofType(types.GET_FOOD_STUFFS_CALL).switchMap(function (_ref) {
    var _ref$payload = _ref.payload,
        type = _ref$payload.type,
        category = _ref$payload.category,
        page = _ref$payload.page,
        keyword = _ref$payload.keyword;
    var showLoading = !page || page === 1;
    return Observable.concat(showLoading ? Observable.of(actions.showLoading()) : Observable.empty(), Observable.fromPromise(food.searchFoodStuffs(type, category, page, keyword)).map(function (res) {
      return actions.getFoodStuffsDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getFoodStuffsFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var searchFoodStuffs = function searchFoodStuffs(action$, store) {
  return action$.ofType(types.SEARCH_FOOD_STUFFS_CALL).switchMap(function (_ref2) {
    var _ref2$payload = _ref2.payload,
        page = _ref2$payload.page,
        keyword = _ref2$payload.keyword;
    return Observable.fromPromise(food.searchFoodStuffs(undefined, undefined, page, keyword)).map(function (res) {
      return actions.searchFoodStuffsDone(res);
    }).catch(function (err) {
      return Observable.of(actions.searchFoodStuffsFail(err));
    });
  });
};