import { createAction } from 'redux-actions';
import * as types from '../../store/types';
export var changeDate = createAction(types.CHANGE_DATE);
export var selectMeal = createAction(types.SELECT_MEAL);
export var openMeal = createAction(types.OPEN_MEAL);
export var openMealCall = createAction(types.OPEN_MEAL_CALL);
export var openAssetCall = createAction(types.OPEN_ASSET_CALL);
export var setAsset = createAction(types.SET_ASSET);
export var openAssetPage = createAction(types.OPEN_ASSET_PAGE);
export var setDiaryClient = createAction(types.SET_DIARY_CLIENT);
export var openDiaryDetail = createAction(types.OPEN_DIARY_DETAIL);
export var openItemSummary = createAction(types.OPEN_ITEM_SUMMARY);
export var getDiaryCall = createAction(types.GET_DIARY_CALL);
export var getDiaryDone = createAction(types.GET_DIARY_DONE);
export var getDiaryFail = createAction(types.GET_DIARY_FAIL);
export var getDiariesCall = createAction(types.GET_DIARIES_CALL);
export var getDiariesDone = createAction(types.GET_DIARIES_DONE);
export var getDiariesFail = createAction(types.GET_DIARIES_FAIL);
export var getDiaryDatesCall = createAction(types.GET_DIARY_DATES_CALL);
export var getDiaryDatesDone = createAction(types.GET_DIARY_DATES_DONE);
export var getDiaryDatesFail = createAction(types.GET_DIARY_DATES_FAIL);
export var createCommentCall = createAction(types.CREATE_COMMENT_CALL);
export var insertComment = createAction(types.INSERT_COMMENT);
export var createCommentDone = createAction(types.CREATE_COMMENT_DONE);
export var createCommentFail = createAction(types.CREATE_COMMENT_FAIL);