export var OPEN_ARTICLE_CALL = 'OPEN_ARTICLE_CALL';
export var OPEN_ARTICLE_DONE = 'OPEN_ARTICLE_DONE';
export var OPEN_ARTICLE_FAIL = 'OPEN_ARTICLE_FAIL';
export var GET_ARTICLE_LIST_CALL = 'GET_ARTICLE_LIST_CALL';
export var GET_ARTICLE_LIST_DONE = 'GET_ARTICLE_LIST_DONE';
export var GET_ARTICLE_LIST_FAIL = 'GET_ARTICLE_LIST_FAIL';
export var GET_SIX_GROUP_DETAIL_CALL = 'GET_SIX_GROUP_DETAIL_CALL';
export var GET_SIX_GROUP_DETAIL_DONE = 'GET_SIX_GROUP_DETAIL_DONE';
export var GET_SIX_GROUP_DETAIL_FAIL = 'GET_SIX_GROUP_DETAIL_FAIL';
export var SET_ARTICLE_QUESTION_COMPLETE_CALL = 'SET_ARTICLE_QUESTION_COMPLETE_CALL';
export var SET_ARTICLE_QUESTION_COMPLETE_DONE = 'SET_ARTICLE_QUESTION_COMPLETE_DONE';
export var SET_ARTICLE_QUESTION_COMPLETE_FAIL = 'SET_ARTICLE_QUESTION_COMPLETE_FAIL';