import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
// import UserProfile from "./UserProfile";
// import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class SidebarContent extends Component {

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    const { navStyle, themeType, locale, pathname } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    return (<Auxiliary>

        <SidebarLogo/>
        <div className="gx-sidebar-content">
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
              mode="inline">
              <MenuItemGroup key="clinic" className="gx-menu-group" title={<IntlMessages id="sidebar.clinic"/>}>
                <Menu.Item key="sidebar.clinic.glucose">
                  <Link to="/glucose"><i className="icon icon-widgets"/>
                    <IntlMessages id="sidebar.clinic.glucose"/></Link>
                </Menu.Item>
              </MenuItemGroup>
              <MenuItemGroup key="rd" className="gx-menu-group" title={<IntlMessages id="sidebar.rd"/>}>
                <Menu.Item key="sidebar.rd.groupClass">
                  <Link to="/group_class"><i className="icon icon-widgets"/>
                    <IntlMessages id="sidebar.rd.groupClass"/></Link>
                </Menu.Item>
              </MenuItemGroup>
              <MenuItemGroup key="admin" className="gx-menu-group" title={<IntlMessages id="sidebar.admin"/>}>
                <Menu.Item key="sidebar.admin.groupClass">
                  <Link to="/group_class"><i className="icon icon-widgets"/>
                    <IntlMessages id="sidebar.admin.groupClass"/></Link>
                </Menu.Item>
                <Menu.Item key="sidebar.admin.rd">
                  <Link to="/sidebar.admin.rd"><i className="icon icon-widgets"/>
                    <IntlMessages id="sidebar.admin.rd"/></Link>
                </Menu.Item>
              </MenuItemGroup>
            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};

const mapStateToProps = ({settings}) => {
  const {navStyle, themeType, locale, pathname} = settings;
  return {navStyle, themeType, locale, pathname}
};

export default connect(mapStateToProps)(SidebarContent);
