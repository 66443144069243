export var CHECK_LOGIN_CALL = 'CHECK_LOGIN_CALL';
export var CHECK_LOGIN_DONE = 'CHECK_LOGIN_DONE';
export var CHECK_LOGIN_FAIL = 'CHECK_LOGIN_FAIL';
export var WEB_EMAIL_LOGIN_CALL = 'WEB_EMAIL_LOGIN_CALL';
export var EMAIL_LOGIN_CALL = 'EMAIL_LOGIN_CALL';
export var EMAIL_LOGIN_DONE = 'EMAIL_LOGIN_DONE';
export var EMAIL_LOGIN_FAIL = 'EMAIL_LOGIN_FAIL';
export var ACCOUNT_LOGIN_CALL = 'ACCOUNT_LOGIN_CALL';
export var ACCOUNT_LOGIN_DONE = 'ACCOUNT_LOGIN_DONE';
export var ACCOUNT_LOGIN_FAIL = 'ACCOUNT_LOGIN_FAIL';
export var WECHAT_LOGIN_CHECK_CALL = 'WECHAT_LOGIN_CHECK_CALL';
export var WECHAT_LOGIN_CHECK_DONE = 'WECHAT_LOGIN_CHECK_DONE';
export var WECHAT_LOGIN_CHECK_FAIL = 'WECHAT_LOGIN_CHECK_FAIL';
export var WECHAT_LOGIN_CALL = 'WECHAT_LOGIN_CALL';
export var WECHAT_LOGIN_DONE = 'WECHAT_LOGIN_DONE';
export var WECHAT_LOGIN_FAIL = 'WECHAT_LOGIN_FAIL';
export var BIND_WECHAT_ID_CALL = 'BIND_WECHAT_ID_CALL';
export var BIND_WECHAT_ID_DONE = 'BIND_WECHAT_ID_DONE';
export var BIND_WECHAT_ID_FAIL = 'BIND_WECHAT_ID_FAIL';
export var REGISTER_CALL = 'REGISTER_CALL';
export var REGISTER_DONE = 'REGISTER_DONE';
export var REGISTER_FAIL = 'REGISTER_FAIL';
export var FB_LOGIN_CALL = 'FB_LOGIN_CALL';
export var FB_LOGIN_DONE = 'FB_LOGIN_DONE';
export var FB_LOGIN_FAIL = 'FB_LOGIN_FAIL';
export var FB_TOKEN_LOGIN_CALL = 'FB_TOKEN_LOGIN_CALL';
export var APPLE_LOGIN_CALL = 'APPLE_LOGIN_CALL';
export var APPLE_LOGIN_DONE = 'APPLE_LOGIN_DONE';
export var APPLE_LOGIN_FAIL = 'APPLE_LOGIN_FAIL';
export var BIND_APPLE_ID_CALL = 'BIND_APPLE_ID_CALL';
export var BIND_APPLE_ID_DONE = 'BIND_APPLE_ID_DONE';
export var BIND_APPLE_ID_FAIL = 'BIND_APPLE_ID_FAIL';
export var WEB_LOGOUT_CALL = 'WEB_LOGOUT_CALL';
export var LOGOUT_CALL = 'LOGOUT_CALL';
export var LOGOUT_DONE = 'LOGOUT_DONE';
export var LOGOUT_FAIL = 'LOGOUT_FAIL';
export var SET_TOKEN = 'SET_TOKEN';
export var SET_SHOP_TOKEN = 'SET_SHOP_TOKEN';
export var STORE_TOKEN_CALL = 'STORE_TOKEN_CALL';
export var STORE_TOKEN_DONE = 'STORE_TOKEN_DONE';
export var STORE_TOKEN_FAIL = 'STORE_TOKEN_FAIL';
export var RESET_PASSWORD_CALL = 'RESET_PASSWORD_CALL';
export var RESET_PASSWORD_DONE = 'RESET_PASSWORD_DONE';
export var RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export var RESET_LOGIN = 'RESET_LOGIN';
export var CHANGE_PAGE_TYPE = 'CHANGE_PAGE_TYPE';
export var SHOP_LOGIN_CALL = 'SHOP_LOGIN_CALL';
export var SHOP_LOGIN_DONE = 'SHOP_LOGIN_DONE';
export var SHOP_LOGIN_FAIL = 'SHOP_LOGIN_FAIL';
export var MOBILE_LOGIN_CALL = 'MOBILE_LOGIN_CALL';
export var MOBILE_LOGIN_DONE = 'MOBILE_LOGIN_DONE';
export var MOBILE_LOGIN_FAIL = 'MOBILE_LOGIN_FAIL';
export var MOBILE_REGISTER_CALL = 'MOBILE_REGISTER_CALL';
export var MOBILE_REGISTER_DONE = 'MOBILE_REGISTER_DONE';
export var MOBILE_REGISTER_FAIL = 'MOBILE_REGISTER_FAIL';
export var MOBILE_SMS_LOGIN_CALL = 'MOBILE_SMS_LOGIN_CALL';
export var MOBILE_SMS_LOGIN_DONE = 'MOBILE_SMS_LOGIN_DONE';
export var MOBILE_SMS_LOGIN_FAIL = 'MOBILE_SMS_LOGIN_FAIL';
export var SET_MOBILE_PASSWOED_CALL = 'SET_MOBILE_PASSWOED_CALL';
export var SET_MOBILE_PASSWOED_DONE = 'SET_MOBILE_PASSWOED_DONE';
export var SET_MOBILE_PASSWOED_FAIL = 'SET_MOBILE_PASSWOED_FAIL';