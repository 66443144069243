import Env from '../Env'

const {
  scheme, appName, isProType, isFFApp, isProApp, isIos, isFFProApp, isDevelopment
} = Env

let config

switch (scheme) {
  case 'cofitpro':
    config = require('./cofitpro').default
    break
  case 'ffproapp':
    config = require('./ffproapp').default
    break
  default:
    break
}

let envApi = isDevelopment ? config.devApi : {}

config = {
  ...config,
  api: {
    ...config.api,
    ...envApi,
    appName,
  },
  shop: {},
  isProType,
  isFFApp,
  isProApp,
  isIos,
  isFFProApp,
  isWeb: true,
}

export default config
