import { Observable } from 'rxjs';
import { user } from '../../services';
import './RxOperators';
import * as actions from '../actions';
import * as types from '../../store/types';
import _ from 'lodash';
import lib from '../../platform';
var pfConfig = lib.pfConfig;
var isFFProApp = pfConfig.isFFProApp;
export var getUserMeCall = function getUserMeCall(action$, store) {
  return action$.ofType(types.GET_USER_ME_CALL).switchMap(function (_ref) {
    var _ref$payload = _ref.payload,
        isLogin = _ref$payload.isLogin,
        showLoaging = _ref$payload.showLoaging;
    return Observable.concat(Observable.of(showLoaging ? actions.showLoading() : actions.empty()), Observable.fromPromise(user.getMe()).concatMap(function (res) {
      var id = res.id,
          clients = res.clients;
      var newActions = [];

      var newClients = _.map(clients, function (client) {
        if (isFFProApp) {
          var identities = client.identities;

          var ffIdentity = _.find(identities, {
            provider: 'fitness_factory',
            position_name: '會員'
          });

          var ffStaffIdentity = _.find(identities, function (_ref2) {
            var provider = _ref2.provider,
                position_name = _ref2.position_name;
            return provider === 'fitness_factory' && position_name !== '會員';
          });

          client.ffIdentity = ffIdentity;
          client.ffStaffIdentity = ffStaffIdentity;
        }

        if (client.care_givers && client.care_givers.length !== 0) {
          client.isCareReceiver = true;
          client.parentName = client.care_givers[0].name;
        }

        return client;
      });

      newActions.push(actions.getUserDone(res));
      newActions.push(actions.setUserId(id));
      newActions.push(actions.getUserMeDone(res));
      newActions.push(actions.getClientsDone(newClients));
      return newActions;
    }).catch(function (err) {
      return Observable.of(actions.getUserMeFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var updateUser = function updateUser(action$, store) {
  return action$.ofType(types.UPDATE_USER_CALL).switchMap(function (_ref3) {
    var _ref3$payload = _ref3.payload,
        hiddenLogin = _ref3$payload.hiddenLogin,
        value = _ref3$payload.value;
    return Observable.concat(Observable.of(hiddenLogin ? actions.empty() : actions.showLoading()), Observable.fromPromise(user.updateMe(value)).map(function (res) {
      return actions.updateUserDone({
        user: res
      });
    }).catch(function (err) {
      return Observable.of(actions.updateUserFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};