import api from '../api';
export var getBodyData = function getBodyData(param) {
  return api.get('/v4/summaries', param);
};
export var getNutrition = function getNutrition(param) {
  return api.get('/v4/nutrition_summaries', param);
};
export var getMoveData = function getMoveData(param) {
  return api.get('/v4/move_summaries', param);
};
export var removeData = function removeData(param) {
  return api.put('/v4/summaries/update_by_date', param);
};