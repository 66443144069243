import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _handleActions;

import _ from 'lodash';
import produce from 'immer';
import * as types from '../../store/types';
import lib from '../../platform';
import { handleActions, combineActions } from 'redux-actions';
var pfConfig = lib.pfConfig;
var _pfConfig$store = pfConfig.store,
    defaultIsPost = _pfConfig$store.defaultIsPost,
    defaultIsOpenImagePicker = _pfConfig$store.defaultIsOpenImagePicker,
    defaultBarCodeIsRowStore = _pfConfig$store.defaultBarCodeIsRowStore,
    defaultEditing = _pfConfig$store.defaultEditing,
    defaultIsTwoPost = _pfConfig$store.defaultIsTwoPost,
    defaultIsOpenPedometer = _pfConfig$store.defaultIsOpenPedometer;
export default handleActions((_handleActions = {}, _defineProperty(_handleActions, combineActions(types.GET_POST_STORE_DONE, types.SET_POST_STORE_DONE), function (state, _ref) {
  var isPost = _ref.payload.isPost;
  return produce(state, function (draft) {
    draft.isPost = isPost;
  });
}), _defineProperty(_handleActions, combineActions(types.GET_GROUPIDS_STORE_DONE, types.SET_GROUPIDS_STORE_DONE), function (state, _ref2) {
  var groupIds = _ref2.payload.groupIds;
  return produce(state, function (draft) {
    draft.groupIds = groupIds;
  });
}), _defineProperty(_handleActions, combineActions(types.GET_OPEN_IMAGE_PICKER_STORE_DONE, types.SET_OPEN_IMAGE_PICKER_STORE_DONE), function (state, _ref3) {
  var isOpenImagePicker = _ref3.payload.isOpenImagePicker;
  return produce(state, function (draft) {
    draft.isOpenImagePicker = isOpenImagePicker;
  });
}), _defineProperty(_handleActions, combineActions(types.GET_SEARCH_HISTORY_LIST_STORE_DONE, types.SET_SEARCH_HISTORY_LIST_STORE_DONE), function (state, _ref4) {
  var searchHistoryList = _ref4.payload.searchHistoryList;
  return produce(state, function (draft) {
    draft.searchHistoryList = searchHistoryList;
  });
}), _defineProperty(_handleActions, combineActions(types.GET_BAR_CODE_IS_ROW_STORE_DONE, types.SET_BAR_CODE_IS_ROW_STORE_DONE), function (state, _ref5) {
  var barCodeIsRow = _ref5.payload.barCodeIsRow;
  return produce(state, function (draft) {
    draft.barCodeIsRow = barCodeIsRow;
  });
}), _defineProperty(_handleActions, combineActions(types.GET_ALLOWS_EDITING_STORE_DONE, types.SET_ALLOWS_EDITING_STORE_DONE), function (state, _ref6) {
  var allowsEditing = _ref6.payload.allowsEditing;
  return produce(state, function (draft) {
    draft.allowsEditing = allowsEditing;
  });
}), _defineProperty(_handleActions, combineActions(types.GET_IS_TWO_POST_STORE_DONE, types.SET_IS_TWO_POST_STORE_DONE), function (state, _ref7) {
  var isTwoPost = _ref7.payload.isTwoPost;
  return produce(state, function (draft) {
    draft.isTwoPost = isTwoPost;
  });
}), _defineProperty(_handleActions, combineActions(types.GET_IDENTITY_STORE_DONE, types.SET_IDENTITY_STORE_DONE), function (state, _ref8) {
  var identity = _ref8.payload.identity;
  return produce(state, function (draft) {
    draft.identityStore = identity;
  });
}), _defineProperty(_handleActions, combineActions(types.GET_STORE_BY_KEY_DONE, types.SET_STORE_BY_KEY_DONE), function (state, _ref9) {
  var _ref9$payload = _ref9.payload,
      key = _ref9$payload.key,
      value = _ref9$payload.value;
  return produce(state, function (draft) {
    draft[key] = value;
  });
}), _handleActions), {
  isPost: defaultIsPost,
  groupIds: [],
  isOpenImagePicker: defaultIsOpenImagePicker,
  searchHistoryList: [],
  activeCode: 'barCode',
  firstOpenBarCode: false,
  isOpenPedometer: defaultIsOpenPedometer,
  barCodeIsRow: defaultBarCodeIsRowStore,
  allowsEditing: defaultEditing,
  isTwoPost: defaultIsTwoPost,
  identityStore: {
    ffIdentity: {},
    ffStaffIdentity: {}
  },
  factoryDefaultCode: '',
  draftListStore: [],
  orderInvoiceStore: undefined
});