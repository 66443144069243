import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";

var _handleActions;

import { handleActions, combineActions } from 'redux-actions';
import * as types from '../../store/types';
import produce from 'immer';
var initialState = {
  isSignedIn: undefined,
  accessToken: undefined,
  checkLoginDone: false,
  isLoginPage: true
};
export default handleActions((_handleActions = {}, _defineProperty(_handleActions, types.GET_ME_DONE, function (state, _ref) {
  var payload = _ref.payload;
  return produce(state, function (draft) {
    draft.isSignedIn = true;
  });
}), _defineProperty(_handleActions, types.CHECK_LOGIN_DONE, function (state, _ref2) {
  var payload = _ref2.payload;
  return produce(state, function (draft) {
    draft.isSignedIn = true;
    draft.checkLoginDone = true;
  });
}), _defineProperty(_handleActions, types.CHECK_LOGIN_FAIL, function (state, _ref3) {
  var payload = _ref3.payload;
  return produce(state, function (draft) {
    draft.isSignedIn = false;
    draft.checkLoginDone = true;
  });
}), _defineProperty(_handleActions, types.LOGOUT_DONE, function (state, _ref4) {
  var payload = _ref4.payload;
  return initialState;
}), _defineProperty(_handleActions, types.SET_TOKEN, function (state, _ref5) {
  var accessToken = _ref5.payload.accessToken;
  return _objectSpread({}, state, {
    accessToken: accessToken
  });
}), _defineProperty(_handleActions, types.SET_SHOP_TOKEN, function (state, _ref6) {
  var shopToken = _ref6.payload.shopToken;
  return _objectSpread({}, state, {
    shopToken: shopToken
  });
}), _defineProperty(_handleActions, types.CHANGE_PAGE_TYPE, function (state, _ref7) {
  var payload = _ref7.payload;
  return produce(state, function (draft) {
    draft.isLoginPage = !draft.isLoginPage;
  });
}), _defineProperty(_handleActions, types.OPEN_SET_LANGUAGE_PAGE, function (state, _ref8) {
  var payload = _ref8.payload;
  return _objectSpread({}, state, {
    checkLoginDone: true
  });
}), _handleActions), initialState);