import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import api from '../api';
import _ from 'lodash';

var getQueryObj = function getQueryObj(_ref) {
  var type = _ref.type,
      category = _ref.category,
      keyword = _ref.keyword;
  if (!_.isEmpty(keyword)) return {
    q: keyword
  };
  if (type === 'brand') return {
    q: category
  };
  if (type === 'category') return {
    category: category
  };
  return {};
};

export var searchFoodStuffs = function searchFoodStuffs(type, category) {
  var page = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  var keyword = arguments.length > 3 ? arguments[3] : undefined;
  var queryObj = getQueryObj({
    type: type,
    category: category,
    keyword: keyword
  });
  return api.get('/v4/food_stuffs/search', _objectSpread({}, queryObj, {
    page: page
  }));
};
export var getFoodStuffGroups = function getFoodStuffGroups() {
  return api.get('/v4/food_stuffs/groups');
};