import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import { Observable } from 'rxjs';
import { shop, integral } from '../../services';
import './RxOperators';
import * as actions from '../actions';
import * as types from '../types';
import lib from '../../platform';
var config = lib.pfConfig;
var isAndroid = config.isAndroid;
export var getShopIndexCall = function getShopIndexCall(action$, store) {
  return action$.ofType(types.GET_SHOP_INDEX_CALL).switchMap(function (_ref) {
    var payload = _ref.payload,
        _ref$payload = _ref.payload,
        tabKey = _ref$payload.tabKey,
        loadingType = _ref$payload.loadingType;
    return Observable.fromPromise(shop.getShopIndex(payload)).map(function (res) {
      return actions.getShopIndexDone(_objectSpread({}, res, {
        tabKey: tabKey,
        loadingType: loadingType
      }));
    }).catch(function (err) {
      return Observable.of(actions.getShopIndexFail({
        message: err,
        tabKey: tabKey
      }));
    });
  });
};
export var getGoodsCartCall = function getGoodsCartCall(action$, store) {
  return action$.ofType(types.GET_GOODS_CART_CALL).switchMap(function (_ref2) {
    var _ref2$payload = _ref2.payload,
        selectId = _ref2$payload.selectId,
        loadingType = _ref2$payload.loadingType;
    return Observable.fromPromise(shop.getGoodsCart()).map(function (res) {
      return actions.getGoodsCartDone({
        res: res,
        selectId: selectId,
        loadingType: loadingType
      });
    }).catch(function (err) {
      return Observable.of(actions.getGoodsCartFail(err));
    });
  });
};
export var addGoodsCartCall = function addGoodsCartCall(action$, store) {
  return action$.ofType(types.ADD_GOODS_CART_CALL).switchMap(function (_ref3) {
    var payload = _ref3.payload,
        _ref3$payload = _ref3.payload,
        selectId = _ref3$payload.id,
        isBuy = _ref3$payload.isBuy,
        isIgType = _ref3$payload.isIgType;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(isIgType ? integral.addIgGoodsCart(payload) : shop.addGoodsCart(payload)).switchMap(function (res) {
      var _res$msg = res.msg,
          msg = _res$msg === void 0 ? '' : _res$msg;
      var newActions = [actions.addGoodsCartDone(res)];

      if (isIgType) {
        if (msg === 'error') {
          newActions.push(actions.showI18nMessageCall({
            message: 'shop:integral.error',
            type: 'danger',
            icon: 'warning'
          }));
        } else {
          if (isBuy) {
            newActions.push(actions.openShoppingCartPage({
              isIgType: isIgType,
              selectId: selectId
            }));
          } else {
            newActions.push(actions.showI18nMessageCall({
              message: 'shop:shoppingCart.success',
              type: 'success',
              icon: 'success'
            }));
          }
        }
      } else {
        if (isBuy) {
          newActions.push(actions.openShoppingCartPage({
            isIgType: isIgType,
            selectId: selectId
          }));
        } else {
          newActions.push(actions.showI18nMessageCall({
            message: 'shop:shoppingCart.success',
            type: 'success',
            icon: 'success'
          }));
        }
      }

      return newActions;
    }).catch(function (err) {
      return Observable.of(actions.addGoodsCartFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var settlementCall = function settlementCall(action$, store) {
  return action$.ofType(types.SETTLEMENT_CALL).switchMap(function (_ref4) {
    var payload = _ref4.payload;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(shop.settlement(payload)).map(function (res) {
      return actions.settlementDone(res);
    }).catch(function (err) {
      return Observable.of(actions.settlementFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var updateGoodsCartCall = function updateGoodsCartCall(action$, store) {
  return action$.ofType(types.UPDATE_GOODS_CART_CALL).switchMap(function (_ref5) {
    var _ref5$payload = _ref5.payload,
        param = _ref5$payload.param,
        shoppingCart = _ref5$payload.shoppingCart,
        isIgType = _ref5$payload.isIgType;
    return Observable.concat(Observable.of(actions.setGoodsCarts({
      shoppingCart: shoppingCart
    })), Observable.of(actions.showLoading()), Observable.fromPromise(isIgType ? integral.updateIgGoodsCart(param) : shop.updateGoodsCart(param)).map(function (res) {
      return actions.updateGoodsCartDone(res);
    }).catch(function (err) {
      return Observable.of(actions.updateGoodsCartFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var removeGoodsCartCall = function removeGoodsCartCall(action$, store) {
  return action$.ofType(types.REMOVE_GOODS_CART_CALL).switchMap(function (_ref6) {
    var _ref6$payload = _ref6.payload,
        param = _ref6$payload.param,
        newShoppingCart = _ref6$payload.newShoppingCart,
        isIgType = _ref6$payload.isIgType;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(isIgType ? integral.removeIgGoodsCart(param) : shop.removeGoodsCart(param)).map(function (res) {
      return actions.removeGoodsCartDone(_objectSpread({}, res, {
        newShoppingCart: newShoppingCart
      }));
    }).catch(function (err) {
      return Observable.of(actions.removeGoodsCartFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var addOrderCall = function addOrderCall(action$, store) {
  return action$.ofType(types.ADD_ORDER_CALL).switchMap(function (_ref7) {
    var payload = _ref7.payload;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(shop.addOrder(payload)).switchMap(function (res) {
      var data = res.data;
      var id = data.order.id;
      return [actions.addOrderDone(data), actions.getWxPayDataCall({
        id: id,
        type: 'goods'
      })];
    }).catch(function (err) {
      return Observable.of(actions.addOrderFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var getAddressCall = function getAddressCall(action$, store) {
  return action$.ofType(types.GET_ADDRESS_CALL).switchMap(function (_ref8) {
    var payload = _ref8.payload;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(shop.getAddress(payload)).map(function (res) {
      return actions.getAddressDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getAddressFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var saveAddressCall = function saveAddressCall(action$, store) {
  return action$.ofType(types.SAVE_ADDRESS_CALL).switchMap(function (_ref9) {
    var payload = _ref9.payload,
        gcs = _ref9.payload.gcs;
    return Observable.fromPromise(shop.saveAddress(payload)).switchMap(function (res) {
      return [actions.saveAddressDone(payload), gcs ? actions.settlementCall({
        gcs: gcs
      }) : actions.pageGoBack()];
    }).catch(function (err) {
      return Observable.of(actions.saveAddressFail(err));
    });
  });
};
export var getWxPayDataCall = function getWxPayDataCall(action$, store) {
  return action$.ofType(types.GET_WXPAY_DATA_CALL).switchMap(function (_ref10) {
    var payload = _ref10.payload,
        type = _ref10.payload.type;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(shop.getWxPayData(payload)).switchMap(function (res) {
      return [actions.getWxPayDataDone(res), actions.wxPayCall({
        param: res,
        isIgType: type === 'integral'
      })];
    }).catch(function (err) {
      return Observable.of(actions.getWxPayDataFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var wxPayCall = function wxPayCall(action$, store) {
  return action$.ofType(types.WXPAY_CALL).switchMap(function (_ref11) {
    var _ref11$payload = _ref11.payload,
        param = _ref11$payload.param,
        isIgType = _ref11$payload.isIgType;
    return Observable.fromPromise(shop.wxPay(param)).switchMap(function (res) {
      return [actions.openShopPayPage({
        isIgType: isIgType
      }), actions.wxPayDone(res)];
    }).catch(function (err) {
      return Observable.of(actions.wxPayFail(err));
    });
  });
};
export var wxPayFail = function wxPayFail(action$, store) {
  return action$.ofType(types.WXPAY_FAIL).map(function (_ref12) {
    var err = _ref12.payload;
    var errorCode = Number(err.code);
    var isCancel = errorCode === -2;
    return actions.setPayError({
      show: true,
      isCancel: isCancel,
      errorCode: errorCode
    });
  });
};
export var getOrderListCall = function getOrderListCall(action$, store) {
  return action$.ofType(types.GET_ORDER_LIST_CALL).switchMap(function (_ref13) {
    var payload = _ref13.payload,
        _ref13$payload = _ref13.payload,
        isIgType = _ref13$payload.isIgType,
        tabKey = _ref13$payload.tabKey,
        loadingType = _ref13$payload.loadingType;
    return Observable.fromPromise(isIgType ? integral.getIgOrderList(payload) : shop.getOrderList(payload)).map(function (res) {
      return actions.getOrderListDone(_objectSpread({}, res, {
        isIgType: isIgType,
        tabKey: tabKey,
        loadingType: loadingType
      }));
    }).catch(function (err) {
      return Observable.of(actions.getOrderListFail({
        err: err,
        tabKey: tabKey
      }));
    });
  });
};
export var getShipDetailCall = function getShipDetailCall(action$, store) {
  return action$.ofType(types.GET_SHIP_DETAIL_CALL).switchMap(function (_ref14) {
    var payload = _ref14.payload,
        isIgType = _ref14.payload.isIgType;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(isIgType ? integral.getIgShipDetail(payload) : shop.getShipDetail(payload)).map(function (res) {
      return actions.getShipDetailDone(_objectSpread({}, res, {
        isIgType: isIgType
      }));
    }).catch(function (err) {
      return Observable.of(actions.getShipDetailFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var getShopSearchCall = function getShopSearchCall(action$, store) {
  return action$.ofType(types.GET_SHOP_SEARCH_CALL).switchMap(function (_ref15) {
    var payload = _ref15.payload;
    return Observable.fromPromise(shop.getShopSearch(payload)).map(function (res) {
      return actions.getShopSearchDone(_objectSpread({}, res, payload));
    }).catch(function (err) {
      return Observable.of(actions.getShopSearchFail(err));
    });
  });
};
export var orderConfirmCall = function orderConfirmCall(action$, store) {
  return action$.ofType(types.ORDER_CONFIRM_CALL).switchMap(function (_ref16) {
    var payload = _ref16.payload,
        _ref16$payload = _ref16.payload,
        isIgType = _ref16$payload.isIgType,
        param = _ref16$payload.param,
        goBack = _ref16$payload.goBack;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(isIgType ? integral.orderIgConfirm(param) : shop.orderConfirm(param)).switchMap(function (res) {
      return [actions.orderConfirmDone(res), actions.orderStoreRemove(payload), goBack ? actions.pageGoBack() : actions.empty()];
    }).catch(function (err) {
      return Observable.of(actions.orderConfirmFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var orderCancelCall = function orderCancelCall(action$, store) {
  return action$.ofType(types.ORDER_CANCEL_CALL).switchMap(function (_ref17) {
    var payload = _ref17.payload,
        _ref17$payload = _ref17.payload,
        param = _ref17$payload.param,
        goBack = _ref17$payload.goBack;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(shop.orderCancel(param)).switchMap(function (res) {
      return [actions.orderCancelDone(res), actions.orderStoreRemove(payload), goBack ? actions.pageGoBack() : actions.empty()];
    }).catch(function (err) {
      return Observable.of(actions.orderCancelFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var getOrderCountCall = function getOrderCountCall(action$, store) {
  return action$.ofType(types.GET_ORDER_COUNT_CALL).switchMap(function (_ref18) {
    var payload = _ref18.payload;
    return Observable.fromPromise(shop.getOrderCount(payload)).map(function (res) {
      return actions.getOrderCountDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getOrderCountFail(err));
    });
  });
};
export var getSmsCodeCall = function getSmsCodeCall(action$, store) {
  return action$.ofType(types.GET_SMS_CODE_CALL).switchMap(function (_ref19) {
    var payload = _ref19.payload;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(shop.getSmsCode(payload)).switchMap(function (res) {
      var newActions = [actions.getSmsCodeDone(res)];

      if (res.code === 400) {
        newActions.push(actions.showI18nMessageCall({
          message: 'login:phone.getCodeError',
          type: 'danger',
          icon: 'warning'
        }));
      }

      return newActions;
    }).catch(function (err) {
      return Observable.of(actions.getSmsCodeFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var getOrderDiscussCall = function getOrderDiscussCall(action$, store) {
  return action$.ofType(types.GET_ORDER_DISCUSS_CALL).switchMap(function (_ref20) {
    var payload = _ref20.payload;
    return Observable.fromPromise(shop.getOrderDiscuss(payload)).map(function (res) {
      return actions.getOrderDiscussDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getOrderDiscussFail(err));
    });
  });
};
export var saveOrderDiscussCall = function saveOrderDiscussCall(action$, store) {
  return action$.ofType(types.SAVE_ORDER_DISCUSS_CALL).switchMap(function (_ref21) {
    var payload = _ref21.payload,
        param = _ref21.payload.param;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(shop.saveOrderDiscuss(param)).switchMap(function (res) {
      return [actions.saveOrderDiscussDone(res), actions.orderStoreRemove(payload), actions.openShopOrderTabPage()];
    }).catch(function (err) {
      return Observable.of(actions.saveOrderDiscussFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var getGoodsEvaCall = function getGoodsEvaCall(action$, store) {
  return action$.ofType(types.GET_GOODS_EVA_CALL).switchMap(function (_ref22) {
    var payload = _ref22.payload,
        loadingType = _ref22.payload.loadingType;
    return Observable.fromPromise(shop.getGoodsEva(payload)).map(function (res) {
      return actions.getGoodsEvaDone(_objectSpread({}, res, {
        loadingType: loadingType
      }));
    }).catch(function (err) {
      return Observable.of(actions.getGoodsEvaFail(err));
    });
  });
};
export var getOrderReturnListCall = function getOrderReturnListCall(action$, store) {
  return action$.ofType(types.GET_ORDER_RETURN_LIST_CALL).switchMap(function (_ref23) {
    var payload = _ref23.payload;
    return Observable.fromPromise(shop.getOrderReturnList(payload)).map(function (res) {
      return actions.getOrderReturnListDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getOrderReturnListFail(err));
    });
  });
};
export var getOrderReturnLogCall = function getOrderReturnLogCall(action$, store) {
  return action$.ofType(types.GET_ORDER_RETURN_LOG_CALL).switchMap(function (_ref24) {
    var payload = _ref24.payload;
    return Observable.fromPromise(shop.getOrderReturnLog(payload)).map(function (res) {
      return actions.getOrderReturnLogDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getOrderReturnLogFail(err));
    });
  });
};
export var addOrderReturnCall = function addOrderReturnCall(action$, store) {
  return action$.ofType(types.ADD_ORDER_RETURN_CALL).switchMap(function (_ref25) {
    var payload = _ref25.payload;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(shop.addOrderReturn(payload)).switchMap(function (res) {
      return [actions.addOrderReturnDone(res), actions.pageGoBack(), actions.getOrderReturnListCall({
        loadingType: 'refresh'
      })];
    }).catch(function (err) {
      return Observable.of(actions.addOrderReturnFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var cancelOrderReturnCall = function cancelOrderReturnCall(action$, store) {
  return action$.ofType(types.CANCEL_ORDER_RETURN_CALL).switchMap(function (_ref26) {
    var payload = _ref26.payload,
        isLogType = _ref26.payload.isLogType;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(shop.cancelOrderReturn(payload)).switchMap(function (res) {
      return [actions.cancelOrderReturnDone(res), isLogType ? actions.getOrderReturnLogCall({
        loadingType: 'refresh'
      }) : actions.getOrderReturnListCall({
        loadingType: 'refresh'
      })];
    }).catch(function (err) {
      return Observable.of(actions.cancelOrderReturnFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var getOrderReturnShipCall = function getOrderReturnShipCall(action$, store) {
  return action$.ofType(types.GET_ORDER_RETURN_SHIP_CALL).switchMap(function (_ref27) {
    var payload = _ref27.payload;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(shop.getOrderReturnShip(payload)).map(function (res) {
      return actions.getOrderReturnShipDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getOrderReturnShipFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var addOrderReturnShipCall = function addOrderReturnShipCall(action$, store) {
  return action$.ofType(types.ADD_ORDER_RETURN_SHIP_CALL).switchMap(function (_ref28) {
    var payload = _ref28.payload;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(shop.addOrderReturnShip(payload)).switchMap(function (res) {
      return [actions.addOrderReturnShipDone(res), actions.pageGoBack(), actions.getOrderReturnLogCall({
        loadingType: 'refresh'
      })];
    }).catch(function (err) {
      return Observable.of(actions.addOrderReturnShipFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var addOrderInvoiceCall = function addOrderInvoiceCall(action$, store) {
  return action$.ofType(types.ADD_ORDER_INVOICE_CALL).switchMap(function (_ref29) {
    var payload = _ref29.payload,
        _ref29$payload = _ref29.payload,
        orderParam = _ref29$payload.orderParam,
        param = _ref29$payload.param;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(shop.addOrderInvoice(param)).switchMap(function (res) {
      return [actions.addOrderInvoiceDone(res), actions.pageGoBack(), actions.getOrderListCall(orderParam)];
    }).catch(function (err) {
      return Observable.of(actions.addOrderInvoiceFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var getClientInvoiceInfoCall = function getClientInvoiceInfoCall(action$, store) {
  return action$.ofType(types.GET_CLIENT_INVOICE_INFO_CALL).switchMap(function (_ref30) {
    var payload = _ref30.payload,
        _ref30$payload = _ref30.payload,
        isView = _ref30$payload.isView,
        invoice = _ref30$payload.invoice;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.of(isView ? actions.getOrderInvoiceCall({
      id: invoice
    }) : actions.getStoreByKeyCall({
      key: 'orderInvoiceStore',
      type: 'json'
    })), Observable.of(actions.openOrderInvoicePage(payload)), Observable.of(actions.getClientInvoiceInfoDone()), Observable.of(actions.hideLoading())).catch(function (err) {
      return Observable.of(actions.getClientInvoiceInfoFail(err));
    });
  });
};
export var getOrderInvoiceCall = function getOrderInvoiceCall(action$, store) {
  return action$.ofType(types.GET_ORDER_INVOICE_CALL).switchMap(function (_ref31) {
    var payload = _ref31.payload;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(shop.getOrderInvoice(payload)).map(function (res) {
      return actions.getOrderInvoiceDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getOrderInvoiceFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var orderRefundCall = function orderRefundCall(action$, store) {
  return action$.ofType(types.ORDER_REFUND_CALL).switchMap(function (_ref32) {
    var payload = _ref32.payload,
        _ref32$payload = _ref32.payload,
        param = _ref32$payload.param,
        goBack = _ref32$payload.goBack;
    return Observable.concat(Observable.of(actions.showLoading()), Observable.fromPromise(shop.orderRefund(param)).switchMap(function (res) {
      return [actions.orderRefundDone(res), actions.orderStoreRemove(payload), goBack ? actions.pageGoBack() : actions.empty()];
    }).catch(function (err) {
      return Observable.of(actions.orderRefundFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var directSellShareCall = function directSellShareCall(action$, store) {
  return action$.ofType(types.DIRECT_SELL_SHARE_CALL).switchMap(function (_ref33) {
    var payload = _ref33.payload,
        message = _ref33.payload.message;
    return Observable.fromPromise(shop.getMyDirectSellUrl()).switchMap(function (res) {
      var data = res.data;
      var directUrl = data.directUrl;
      var newMessage = message;

      if (isAndroid && directUrl) {
        newMessage = "".concat(message, "\n").concat(directUrl);
      }

      var param = {
        message: newMessage,
        url: directUrl
      };
      return [actions.nativeShareCall(param), actions.directSellShareDone(param)];
    }).catch(function (err) {
      return Observable.of(actions.directSellShareFail(err));
    });
  });
};