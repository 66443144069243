// import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form';
import activity from './activity';
import chat from './chat';
import client from './client';
import device from './device';
import diary from './diary';
import explore from './explore';
import food from './food';
import member from './member';
import modal from './modal';
import imagePicker from './imagePicker';
import note from './note';
import notification from './notification';
import post from './post';
import profile from './profile';
import session from './session';
import target from './target';
import ui from './ui';
import user from './user';
import article from './article';
import imagesViewer from './imagesViewer';
import factory from './factory';
import netInfo from './netInfo';
import group from './group';
import invoice from './invoice';
import searchLocation from './searchLocation';
import storage from './storage';
import essays from './essays';
import shop from './shop';
import company from './company';
import question from './question';
import styles from './styles';
export default {
  article: article,
  activity: activity,
  chat: chat,
  client: client,
  device: device,
  diary: diary,
  explore: explore,
  food: food,
  form: form,
  member: member,
  modal: modal,
  imagePicker: imagePicker,
  note: note,
  notification: notification,
  post: post,
  profile: profile,
  session: session,
  target: target,
  ui: ui,
  user: user,
  imagesViewer: imagesViewer,
  factory: factory,
  netInfo: netInfo,
  group: group,
  invoice: invoice,
  searchLocation: searchLocation,
  storage: storage,
  essays: essays,
  shop: shop,
  company: company,
  question: question,
  styles: styles
};