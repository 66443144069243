export var GET_ACTIVITY_ITEMS_CALL = 'GET_ACTIVITY_ITEMS_CALL';
export var GET_ACTIVITY_ITEM_GROUPS_CALL = 'GET_ACTIVITY_ITEM_GROUPS_CALL';
export var GET_ACTIVITY_ITEM_GROUPS_DONE = 'GET_ACTIVITY_ITEM_GROUPS_DONE';
export var GET_ACTIVITY_ITEM_GROUPS_FAIL = 'GET_ACTIVITY_ITEM_GROUPS_FAIL';
export var GET_ROUTINES_CALL = 'GET_ROUTINES_CALL';
export var GET_ROUTINES_DONE = 'GET_ROUTINES_DONE';
export var GET_ROUTINES_FAIL = 'GET_ROUTINES_FAIL';
export var GET_MY_ACTIVITY_ITEMS_CALL = 'GET_MY_ACTIVITY_ITEMS_CALL';
export var GET_MY_ACTIVITY_ITEMS_DONE = 'GET_MY_ACTIVITY_ITEMS_DONE';
export var GET_MY_ACTIVITY_ITEMS_FAIL = 'GET_MY_ACTIVITY_ITEMS_FAIL';
export var ADD_MY_ACTIVITY_ITEM_CALL = 'ADD_MY_ACTIVITY_ITEM_CALL';
export var ADD_MY_ACTIVITY_ITEM_DONE = 'ADD_MY_ACTIVITY_ITEM_DONE';
export var ADD_MY_ACTIVITY_ITEM_FAIL = 'ADD_MY_ACTIVITY_ITEM_FAIL';
export var DELETE_MY_ACTIVITY_ITEM_CALL = 'DELETE_MY_ACTIVITY_ITEM_CALL';
export var DELETE_MY_ACTIVITY_ITEM_DONE = 'DELETE_MY_ACTIVITY_ITEM_DONE';
export var DELETE_MY_ACTIVITY_ITEM_FAIL = 'DELETE_MY_ACTIVITY_ITEM_FAIL';