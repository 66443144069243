import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";

var _handleActions;

import _ from 'lodash';
import produce from 'immer';
import moment from 'moment';
import { handleActions } from 'redux-actions';
import * as types from '../../store/types';

var addTypeAndSort = function addTypeAndSort(_ref) {
  var assets = _ref.assets,
      _type = _ref.type,
      _parentType = _ref.parentType,
      _parentId = _ref.parentId,
      _commentable = _ref.commentable,
      _showImageViewer = _ref.showImageViewer;
  return _.orderBy(_.map(assets, function (asset) {
    return _objectSpread({}, asset, {
      _type: _type,
      _parentType: _parentType,
      _parentId: _parentId,
      _commentable: _commentable,
      _showImageViewer: _showImageViewer,
      _time: asset.taken_at || asset.added_at || asset.created_at,
      mediaType: asset.media_type
    });
  }), ['id', 'asc']);
};

var processNoteAssets = function processNoteAssets(_ref2) {
  var id = _ref2.id,
      assets = _ref2.assets,
      type = _ref2.type;
  return addTypeAndSort({
    assets: assets,
    type: 'noteAsset',
    parentType: 'note',
    parentId: id,
    commentable: true,
    showImageViewer: _.includes(['MoveNote', 'LifeNote', 'DiagNote'], type)
  });
};

var processFoodRecordAssets = function processFoodRecordAssets(_ref3) {
  var id = _ref3.id,
      assets = _ref3.assets,
      items = _ref3.items;

  var photos = _.filter(assets, function (_ref4) {
    var url = _ref4.url;
    return url;
  });

  var notes = _.filter(assets, function (_ref5) {
    var url = _ref5.url;
    return !url;
  });

  var parentInfo = {
    parentType: 'foodRecord',
    parentId: id
  };
  return [].concat(_toConsumableArray(addTypeAndSort(_objectSpread({
    assets: photos,
    type: 'foodRecordPhoto'
  }, parentInfo, {
    showImageViewer: false,
    commentable: true
  }))), _toConsumableArray(addTypeAndSort(_objectSpread({
    assets: notes,
    type: 'foodRecordNote'
  }, parentInfo, {
    showImageViewer: false,
    commentable: true
  }))), _toConsumableArray(addTypeAndSort(_objectSpread({
    assets: items,
    type: 'foodRecordItem'
  }, parentInfo, {
    showImageViewer: false,
    commentable: false
  }))));
};

var findAndMerge = function findAndMerge(parent, element) {
  for (var _len = arguments.length, arrKeysAndMatchers = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
    arrKeysAndMatchers[_key - 2] = arguments[_key];
  }

  // recursively find matched (array) item, and finally merge the element
  if (_.isEmpty(arrKeysAndMatchers)) {
    return _objectSpread({}, parent, element);
  }

  var key = arrKeysAndMatchers[0],
      matcher = arrKeysAndMatchers[1],
      restKMs = arrKeysAndMatchers.slice(2);
  return _objectSpread({}, parent, _defineProperty({}, key, _.map(parent[key], function (record) {
    var matched = matcher(record);
    return matched ? findAndMerge.apply(void 0, [record, element].concat(_toConsumableArray(restKMs))) : record;
  })));
};

var findAndInsert = function findAndInsert(parent, element) {
  for (var _len2 = arguments.length, arrKeysAndMatchers = new Array(_len2 > 2 ? _len2 - 2 : 0), _key2 = 2; _key2 < _len2; _key2++) {
    arrKeysAndMatchers[_key2 - 2] = arguments[_key2];
  }

  // recursively find array in matched item, and finally insert the element
  var key = arrKeysAndMatchers[0],
      matcher = arrKeysAndMatchers[1],
      restKMs = arrKeysAndMatchers.slice(2);

  if (key && !matcher) {
    return _objectSpread({}, parent, _defineProperty({}, key, [].concat(_toConsumableArray(parent[key] || []), [element])));
  }

  return _objectSpread({}, parent, _defineProperty({}, key, _.map(parent[key], function (record) {
    var matched = matcher(record);
    return matched ? findAndInsert.apply(void 0, [record, element].concat(_toConsumableArray(restKMs))) : record;
  })));
};

var initialState = {
  foodRecords: [],
  notes: [],
  activity: {},
  eventDates: [],
  clientId: undefined,
  date: moment(),
  mealOrder: undefined,
  assetToOpen: undefined,
  showLoading: false,
  showRefreshing: false
};
export default handleActions((_handleActions = {}, _defineProperty(_handleActions, types.LOGOUT_DONE, function (state) {
  return initialState;
}), _defineProperty(_handleActions, types.CHANGE_DATE, function (state, _ref6) {
  var date = _ref6.payload.date;
  return _objectSpread({}, state, {
    date: date
  });
}), _defineProperty(_handleActions, types.GET_FOOD_RECORDS_DONE, function (state, _ref7) {
  var _ref7$payload = _ref7.payload,
      foodRecords = _ref7$payload.foodRecords,
      _ref7$payload$activit = _ref7$payload.activity,
      activity = _ref7$payload$activit === void 0 ? {} : _ref7$payload$activit;

  var newRecords = _.map(foodRecords, function (record) {
    return _objectSpread({}, record, {
      assets: processFoodRecordAssets(record),
      _type: 'foodRecord'
    });
  });

  return _objectSpread({}, state, {
    foodRecords: _.uniqBy([].concat(_toConsumableArray(newRecords), _toConsumableArray(state.foodRecords)), 'id'),
    activity: activity
  });
}), _defineProperty(_handleActions, types.GET_DIARY_CALL, function (state, _ref8) {
  var loadingType = _ref8.payload.loadingType;
  return produce(state, function (draft) {
    draft.showLoading = true;
    draft.showRefreshing = loadingType === 'refresh';
  });
}), _defineProperty(_handleActions, types.GET_NOTES_DONE, function (state, _ref9) {
  var notes = _ref9.payload.notes;
  return produce(state, function (draft) {
    var newRecords = _.map(notes, function (record) {
      return _objectSpread({}, record, {
        assets: processNoteAssets(record),
        _type: 'note'
      });
    });

    draft.showLoading = false;
    draft.showRefreshing = false;
    draft.notes = _.uniqBy([].concat(_toConsumableArray(newRecords), _toConsumableArray(state.notes)), 'id');
  });
}), _defineProperty(_handleActions, types.GET_DIARY_FAIL, function (state, _ref10) {
  var notes = _ref10.payload.notes;
  return produce(state, function (draft) {
    draft.showLoading = false;
    draft.showRefreshing = false;
  });
}), _defineProperty(_handleActions, types.DELETE_NOTE_CALL, function (state, _ref11) {
  var id = _ref11.payload.id;
  return produce(state, function (draft) {
    var note = _.find(draft.notes, {
      id: id
    }) || {};
    var date = note.date,
        client_id = note.client_id;
    draft.notes = _.reject(draft.notes, {
      id: id
    });
    var isEmptyDate = !_.some(draft.foodRecords, {
      date: date,
      client_id: client_id
    }) && !_.some(draft.notes, {
      date: date,
      client_id: client_id
    });

    if (isEmptyDate) {
      var clientEventDates = _.find(draft.eventDates, {
        client_id: client_id
      });

      if (clientEventDates) _.pull(clientEventDates.dates, date);
    }
  });
}), _defineProperty(_handleActions, types.CREATE_NOTE_DONE, function (state, _ref12) {
  var _ref12$payload$note = _ref12.payload.note,
      date = _ref12$payload$note.date,
      client_id = _ref12$payload$note.client_id;
  return produce(state, function (draft) {
    var clientEventDates = _.find(draft.eventDates, {
      client_id: client_id
    });

    if (clientEventDates && !_.includes(clientEventDates.dates, date)) clientEventDates.dates.push(date);
  });
}), _defineProperty(_handleActions, types.SELECT_MEAL, function (state, _ref13) {
  var _ref13$payload = _ref13.payload,
      order = _ref13$payload.order,
      noteIdToOpen = _ref13$payload.noteIdToOpen;
  return _objectSpread({}, state, {
    mealOrder: order,
    noteIdToOpen: noteIdToOpen
  });
}), _defineProperty(_handleActions, types.SET_ASSET, function (state, _ref14) {
  var asset = _ref14.payload.asset;
  return _objectSpread({}, state, {
    assetToOpen: asset
  });
}), _defineProperty(_handleActions, types.INSERT_COMMENT, function (state, _ref15) {
  var _ref15$payload = _ref15.payload,
      _ref15$payload$record = _ref15$payload.record,
      id = _ref15$payload$record.id,
      _type = _ref15$payload$record._type,
      _parentType = _ref15$payload$record._parentType,
      _parentId = _ref15$payload$record._parentId,
      comment = _ref15$payload.comment;
  var newState;

  if (_type === 'note') {
    newState = findAndInsert(state, comment, 'notes', function (note) {
      return note.id === id;
    }, 'comments');
  } else {
    // noteAsset, foodRecordPhoto, foodRecordNote
    var parentArrName = "".concat(_parentType, "s");
    newState = findAndInsert(state, comment, parentArrName, function (parent) {
      return parent.id === _parentId;
    }, 'assets', function (asset) {
      return asset.id === id && asset._type === _type;
    }, 'comments');
  }

  return newState;
}), _defineProperty(_handleActions, types.CREATE_COMMENT_DONE, function (state, _ref16) {
  var _ref16$payload = _ref16.payload,
      _ref16$payload$record = _ref16$payload.record,
      id = _ref16$payload$record.id,
      _type = _ref16$payload$record._type,
      _parentType = _ref16$payload$record._parentType,
      _parentId = _ref16$payload$record._parentId,
      comment = _ref16$payload.comment;
  var newState;

  if (_type === 'note') {
    newState = findAndMerge(state, comment, 'notes', function (note) {
      return note.id === id;
    }, 'comments', function (c) {
      return c._uid === comment._uid;
    });
  } else {
    // note -> assets with _type = 'noteAsset' OR
    // foodRecord -> assets with _type = 'foodRecordPhoto' OR
    // foodRecord -> assets with _type = 'foodRecordNote'
    var parentArrName = "".concat(_parentType, "s");
    newState = findAndMerge(state, comment, parentArrName, function (parent) {
      return parent.id === _parentId;
    }, 'assets', function (asset) {
      return asset.id === id && asset._type === _type;
    }, 'comments', function (c) {
      return c._uid === comment._uid;
    });
  }

  return newState;
}), _defineProperty(_handleActions, types.UPDATE_COMMENT_DONE, function (state, _ref17) {
  var _ref17$payload = _ref17.payload,
      id = _ref17$payload.id,
      content = _ref17$payload.content,
      recordId = _ref17$payload.recordId,
      recordType = _ref17$payload.recordType;

  if (recordType !== 'Post') {
    return produce(state, function (draft) {
      var assetToOpen = state.assetToOpen;
      var isNote = recordType === 'Note';
      var noteId = isNote ? recordId : assetToOpen.note_id || assetToOpen._parentId;
      var note = _.find(draft.notes, {
        id: noteId
      }) || {};
      var comments;

      if (isNote) {
        comments = note.comments;
      } else {
        var asset = _.find(note.assets, {
          id: recordId
        }) || {};
        comments = asset.comments;
      }

      var comment = _.find(comments, {
        id: id
      }) || {};
      comment.content = content;
    });
  }
}), _defineProperty(_handleActions, types.DELETE_COMMENT_DONE, function (state, _ref18) {
  var _ref18$payload = _ref18.payload,
      id = _ref18$payload.id,
      recordId = _ref18$payload.recordId,
      recordType = _ref18$payload.recordType;

  if (recordType !== 'Post') {
    return produce(state, function (draft) {
      var assetToOpen = state.assetToOpen;
      var isNote = recordType === 'Note';
      var noteId = isNote ? recordId : assetToOpen.note_id || assetToOpen._parentId;
      var note = _.find(draft.notes, {
        id: noteId
      }) || {};
      var comments;

      if (isNote) {
        comments = note.comments;
      } else {
        var asset = _.find(note.assets, {
          id: recordId
        }) || {};
        comments = asset.comments;
      }

      _.remove(comments, {
        id: id
      });
    });
  }
}), _defineProperty(_handleActions, types.MARK_DESTROY_ASSET, function (state, _ref19) {
  var _ref19$payload$asset = _ref19.payload.asset,
      id = _ref19$payload$asset.id,
      _type = _ref19$payload$asset._type,
      _parentType = _ref19$payload$asset._parentType,
      _parentId = _ref19$payload$asset._parentId;
  var newState = _type === 'noteAsset' ? findAndMerge(state, {
    _destroy: true
  }, "".concat(_parentType, "s"), function (parent) {
    return parent.id === _parentId;
  }, 'assets', function (asset) {
    return asset.id === id && asset._type === _type;
  }) : state;
  return newState;
}), _defineProperty(_handleActions, types.UPDATE_NOTE_DONE, function (state, _ref20) {
  var payload = _ref20.payload;

  var newNote = _objectSpread({}, payload, {
    assets: processNoteAssets(payload),
    _type: 'note'
  });

  return _objectSpread({}, state, {
    notes: _.uniqBy([newNote].concat(_toConsumableArray(state.notes)), 'id')
  });
}), _defineProperty(_handleActions, types.GET_DIARY_DATES_DONE, function (state, _ref21) {
  var _ref21$payload = _ref21.payload,
      client_id = _ref21$payload.clientId,
      dates = _ref21$payload.dates;

  var _ref22 = _.find(state.eventDates, {
    client_id: client_id
  }) || {
    dates: []
  },
      clientDates = _ref22.dates;

  var newDates = _.uniq([].concat(_toConsumableArray(dates), _toConsumableArray(clientDates)));

  var eventDates = [].concat(_toConsumableArray(_.reject(state.eventDates, {
    client_id: client_id
  })), [{
    client_id: client_id,
    dates: newDates,
    minEventDate: _.min(newDates)
  }]);
  return _objectSpread({}, state, {
    eventDates: eventDates
  });
}), _defineProperty(_handleActions, types.SET_DIARY_CLIENT, function (state, _ref23) {
  var payload = _ref23.payload;
  return _objectSpread({}, state, {
    clientId: payload
  });
}), _handleActions), initialState);