var lib = {};
export var setup = function setup(_ref) {
  var pfAnalytics = _ref.Analytics,
      pfAppearance = _ref.Appearance,
      pfAsset = _ref.Asset,
      pfCommonStyle = _ref.CommonStyle,
      pfConfig = _ref.config,
      pfDevice = _ref.Device,
      pfI18n = _ref.I18n,
      pfMedia = _ref.Media,
      pfMessage = _ref.Message,
      pfMonitor = _ref.Monitor,
      pfMiddlewares = _ref.Middlewares,
      pfCacheManager = _ref.myCacheManager,
      pfSession = _ref.Session,
      pfStorage = _ref.Storage,
      pfUpdate = _ref.Update,
      pfWechat = _ref.Wechat,
      pfShare = _ref.Share;
  Object.assign(lib, {
    pfAnalytics: pfAnalytics,
    pfAppearance: pfAppearance,
    pfAsset: pfAsset,
    pfCacheManager: pfCacheManager,
    pfCommonStyle: pfCommonStyle,
    pfConfig: pfConfig,
    pfDevice: pfDevice,
    pfI18n: pfI18n,
    pfMedia: pfMedia,
    pfMessage: pfMessage,
    pfMonitor: pfMonitor,
    pfMiddlewares: pfMiddlewares,
    pfSession: pfSession,
    pfStorage: pfStorage,
    pfUpdate: pfUpdate,
    pfWechat: pfWechat,
    pfShare: pfShare
  });
};
export var setupI18n = function setupI18n(i18n) {
  Object.assign(lib, {
    pfI18n: i18n
  });
};
export default lib;