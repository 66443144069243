import { createAction } from 'redux-actions';
import * as types from '../../store/types';
export var getClientCall = createAction(types.GET_CLIENT_CALL);
export var getClientDone = createAction(types.GET_CLIENT_DONE);
export var getClientFail = createAction(types.GET_CLIENT_FAIL);
export var getClientMeCall = createAction(types.GET_CLIENT_ME_CALL);
export var getClientMeDone = createAction(types.GET_CLIENT_ME_DONE);
export var getClientMeFail = createAction(types.GET_CLIENT_ME_FAIL);
export var getFFClientMeCall = createAction(types.GET_FF_CLIENT_ME_CALL);
export var getFFClientMeDone = createAction(types.GET_FF_CLIENT_ME_DONE);
export var getFFClientMeFail = createAction(types.GET_FF_CLIENT_ME_FAIL);
export var updateClientCall = createAction(types.UPDATE_CLIENT_CALL);
export var updateClientDone = createAction(types.UPDATE_CLIENT_DONE);
export var updateClientFail = createAction(types.UPDATE_CLIENT_FAIL);
export var setClientId = createAction(types.SET_CLIENT_ID);
export var getClientsDone = createAction(types.GET_CLIENTS_DONE);
export var getSocialClientMeCall = createAction(types.GET_SOCIAL_CLIENT_ME_CALL);
export var getSocialClientMeDone = createAction(types.GET_SOCIAL_CLIENT_ME_DONE);
export var getSocialClientMeFail = createAction(types.GET_SOCIAL_CLIENT_ME_FAIL);