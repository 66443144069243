export var OPEN_SERVICE_CHAT_CALL = 'OPEN_SERVICE_CHAT_CALL';
export var OPEN_CHAT_CALL = 'OPEN_CHAT_CALL';
export var OPEN_CHAT_DONE = 'OPEN_CHAT_DONE';
export var OPEN_CHAT_FAIL = 'OPEN_CHAT_FAIL';
export var INIT_MULTIPLE_CHAT_CALL = 'INIT_MULTIPLE_CHAT_CALL';
export var INIT_MULTIPLE_CHAT_DONE = 'INIT_MULTIPLE_CHAT_DONE';
export var INIT_MULTIPLE_CHAT_FAIL = 'INIT_MULTIPLE_CHAT_FAIL';
export var CLOSE_CHAT_CALL = 'CLOSE_CHAT_CALL';
export var CLOSE_CHAT_DONE = 'CLOSE_CHAT_DONE';
export var CLOSE_CHAT_FAIL = 'CLOSE_CHAT_FAIL';
export var INIT_SOCKET_CALL = 'INIT_SOCKET_CALL';
export var INIT_SOCKET_DONE = 'INIT_SOCKET_DONE';
export var INIT_SOCKET_FAIL = 'INIT_SOCKET_FAIL';
export var INIT_SOCKET_MULTIPLE_CALL = 'INIT_SOCKET_MULTIPLE_CALL';
export var INIT_SOCKET_MULTIPLE_DONE = 'INIT_SOCKET_MULTIPLE_DONE';
export var INIT_SOCKET_MULTIPLE_FAIL = 'INIT_SOCKET_MULTIPLE_FAIL';
export var OPEN_SOCKET_CALL = 'OPEN_SOCKET_CALL';
export var OPEN_SOCKET_DONE = 'OPEN_SOCKET_DONE';
export var OPEN_SOCKET_FAIL = 'OPEN_SOCKET_FAIL';
export var JOIN_CHATROOM_CALL = 'JOIN_CHATROOM_CALL';
export var JOIN_CHATROOM_DONE = 'JOIN_CHATROOM_DONE';
export var JOIN_CHATROOM_FAIL = 'JOIN_CHATROOM_FAIL';
export var JOIN_MULTIPLE_CALL = 'JOIN_MULTIPLE_CALL';
export var JOIN_MULTIPLE_DONE = 'JOIN_MULTIPLE_DONE';
export var JOIN_MULTIPLE_FAIL = 'JOIN_MULTIPLE_FAIL';
export var CLOSE_SOCKET_CALL = 'CLOSE_SOCKET_CALL';
export var CLOSE_SOCKET_DONE = 'CLOSE_SOCKET_DONE';
export var CLOSE_SOCKET_FAIL = 'CLOSE_SOCKET_FAIL';
export var SEND_MESSAGE_CALL = 'SEND_MESSAGE_CALL';
export var SEND_MESSAGE_DONE = 'SEND_MESSAGE_DONE';
export var SEND_MESSAGE_FAIL = 'SEND_MESSAGE_FAIL';
export var INSERT_MESSAGE = 'INSERT_MESSAGE';
export var FETCH_MESSAGES_CALL = 'FETCH_MESSAGES_CALL';
export var FETCH_MESSAGES_DONE = 'FETCH_MESSAGES_DONE';
export var FETCH_MESSAGES_FAIL = 'FETCH_MESSAGES_FAIL';
export var FETCHED_MULTIPLE_CHATS = 'FETCHED_MULTIPLE_CHATS';
export var FETCHED_MULTIPLE_CHAT_ROOMS = 'FETCHED_MULTIPLE_CHAT_ROOMS';
export var OPEN_CHAT_PAGE = 'OPEN_CHAT_PAGE';
export var RECEIVE_NEW_MESSAGE = 'RECEIVE_NEW_MESSAGE';
export var MULTIPLE_RECEIVE_NEW_MESSAGE = 'MULTIPLE_RECEIVE_NEW_MESSAGE';
export var MULTIPLE_UPDATE_READ_AT_CALL = 'MULTIPLE_UPDATE_READ_AT_CALL';
export var MULTIPLE_UPDATE_READ_AT_DONE = 'MULTIPLE_UPDATE_READ_AT_DONE';
export var MULTIPLE_UPDATE_READ_AT_FAIL = 'MULTIPLE_UPDATE_READ_AT_FAIL';
export var UPDATE_READ_AT_CALL = 'UPDATE_READ_AT_CALL';
export var UPDATE_READ_AT_DONE = 'UPDATE_READ_AT_DONE';
export var UPDATE_READ_AT_FAIL = 'UPDATE_READ_AT_FAIL';
export var UPDATE_CHATROOM_LOAD_MORE = 'UPDATE_CHATROOM_LOAD_MORE';
export var INCREASE_UNREAD_COUNT = 'INCREASE_UNREAD_COUNT';
export var RESET_UNREAD_COUNT = 'RESET_UNREAD_COUNT';
export var UPDATE_LOCAL_READ_AT = 'UPDATE_LOCAL_READ_AT';
export var GET_CHAT_ROOMS_CALL = 'GET_CHAT_ROOMS_CALL';
export var GET_CHAT_ROOMS_DONE = 'GET_CHAT_ROOMS_DONE';
export var GET_CHAT_ROOMS_FAIL = 'GET_CHAT_ROOMS_FAIL';
export var GET_CHAT_MEDIA_UPLOAD_INFO_CALL = 'GET_CHAT_MEDIA_UPLOAD_INFO_CALL';
export var GET_CHAT_MEDIA_UPLOAD_INFO_DONE = 'GET_CHAT_MEDIA_UPLOAD_INFO_DONE';
export var GET_CHAT_MEDIA_UPLOAD_INFO_FAIL = 'GET_CHAT_MEDIA_UPLOAD_INFO_FAIL';
export var FINISH_CHAT_SELECT_MEDIA = 'FINISH_CHAT_SELECT_MEDIA';
export var UPLOAD_CHAT_ASSET_CALL = 'UPLOAD_CHAT_ASSET_CALL';
export var UPLOAD_CHAT_ASSET_DONE = 'UPLOAD_CHAT_ASSET_DONE';
export var UPLOAD_CHAT_ASSET_FAIL = 'UPLOAD_CHAT_ASSET_FAIL';
export var CREATE_ATTACHMENT_CALL = 'CREATE_ATTACHMENT_CALL';
export var CREATE_ATTACHMENT_DONE = 'CREATE_ATTACHMENT_DONE';
export var CREATE_ATTACHMENT_FAIL = 'CREATE_ATTACHMENT_FAIL';
export var RECONNECT_SOCKET_CALL = 'RECONNECT_SOCKET_CALL';
export var RECONNECT_SOCKET_DONE = 'RECONNECT_SOCKET_DONE';
export var RECONNECT_SOCKET_FAIL = 'RECONNECT_SOCKET_FAIL';
export var UPDATE_CHATROOM = 'UPDATE_CHATROOM';
export var ISSUE_RETURN_CALL = 'ISSUE_RETURN_CALL';
export var ISSUE_RETURN_DONE = 'ISSUE_RETURN_DONE';
export var ISSUE_RETURN_FAIL = 'ISSUE_RETURN_FAIL';
export var ADD_CHATS_CALL = 'ADD_CHATS_CALL';
export var ADD_CHATS_DONE = 'ADD_CHATS_DONE';
export var ADD_CHATS_FAIL = 'ADD_CHATS_FAIL';
export var INIT_SOCKETEC_CALL = 'INIT_SOCKETEC_CALL';
export var INIT_SOCKETEC_DONE = 'INIT_SOCKETEC_DONE';
export var INIT_SOCKETEC_FAIL = 'INIT_SOCKETEC_CALL';
export var OPEN_SOCKETEC_CALL = 'OPEN_SOCKETEC_CALL';
export var OPEN_SOCKETEC_DONE = 'OPEN_SOCKETEC_DONE';
export var OPEN_SOCKETEC_FAIL = 'OPEN_SOCKETEC_FAIL';
export var LEAVE_CHATS_CALL = 'LEAVE_CHATS_CALL';
export var LEAVE_CHATS_DONE = 'LEAVE_CHATS_DONE';
export var LEAVE_CHATS_FAIL = 'LEAVE_CHATS_FAIL';
export var ADD_CHATS_MEMBERS_CALL = 'ADD_CHATS_MEMBERS_CALL';
export var ADD_CHATS_MEMBERS_DONE = 'ADD_CHATS_MEMBERS_DONE';
export var ADD_CHATS_MEMBERS_FAIL = 'ADD_CHATS_MEMBERS_FAIL';
export var ENTER_CHATS_CALL = 'ENTER_CHATS_CALL';
export var ENTER_CHATS_DONE = 'ENTER_CHATS_DONE';
export var ENTER_CHATS_FAIL = 'ENTER_CHATS_FAIL';
export var MUTE_CHATS_CALL = 'MUTE_CHATS_CALL';
export var MUTE_CHATS_DONE = 'MUTE_CHATS_DONE';
export var MUTE_CHATS_FAIL = 'MUTE_CHATS_FAIL';
export var UN_MUTE_CHATS_CALL = 'UN_MUTE_CHATS_CALL';
export var UN_MUTE_CHATS_DONE = 'UN_MUTE_CHATS_DONE';
export var UN_MUTE_CHATS_FAIL = 'UN_MUTE_CHATS_FAIL';
export var SELECT_CHAT = 'SELECT_CHAT';