import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import lib from '../platform';
import ApolloClient from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import * as queries from './queries';
var pfConfig = lib.pfConfig;
var apiUrl = pfConfig.api.apiUrl;
var store;
var authLink = setContext(function (_, _ref) {
  var headers = _ref.headers;
  // get the authentication token from store if it exists
  var token = '';

  try {
    var state = store.getState();

    if (state.session.accessToken) {
      token = state.session.accessToken;
    } //改token
    //token = ''

  } catch (e) {
    token = '';
  } // return the headers to the context so httpLink can read them


  return {
    headers: _objectSpread({}, headers, {
      authorization: token ? "Bearer ".concat(token) : ""
    })
  };
});
export var setStore = function setStore(reduxStore) {
  store = reduxStore;
};
var client = new ApolloClient({
  link: authLink.concat(new HttpLink({
    uri: "".concat(apiUrl, "/graphql")
  })),
  cache: new InMemoryCache()
});

var request = function request(q, v) {
  return client.query({
    query: q,
    variables: v
  }).then(function (res) {
    console.log(res);
    return Promise.resolve(res.data);
  }).catch(function (err) {
    return Promise.reject(err);
  });
};

export default {
  setStore: setStore,
  query: function query(q, v) {
    return request(q, v);
  },
  client: client,
  queries: queries
};