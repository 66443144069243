import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";

var _handleActions;

import { handleActions } from 'redux-actions';
import * as types from '../../store/types';
import produce from 'immer';
export default handleActions((_handleActions = {}, _defineProperty(_handleActions, types.UPDATE_BROKEN_CAMERA_DEVICE_CALL, function (state, _ref) {
  var payload = _ref.payload;
  return _objectSpread({}, state, {
    isBrokenCameraDevice: payload
  });
}), _defineProperty(_handleActions, types.WECHAT_LOGIN_CHECK_DONE, function (state, _ref2) {
  var payload = _ref2.payload;
  return produce(state, function (draft) {
    var isWXAppInstalled = payload.isWXAppInstalled,
        isWXAppSupportApi = payload.isWXAppSupportApi;
    draft.isWXAppInstalled = isWXAppInstalled;
    draft.isWXAppSupportApi = isWXAppSupportApi;
  });
}), _handleActions), {
  isBrokenCameraDevice: undefined,
  isWXAppInstalled: undefined,
  isWXAppSupportApi: undefined
});