import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _ from 'lodash';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import * as types from '../../store/types';
export default handleActions(_defineProperty({}, types.SET_IS_CONNECTED, function (state, _ref) {
  var isConnected = _ref.payload.isConnected;
  return {
    isConnected: isConnected
  };
}), {
  isConnected: true
});