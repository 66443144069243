const country = 'TW'

const Env = {
  appName: 'pro_app',
  scheme: 'cofitpro',
  isWeb: true,
  isFFApp: false,
  isFFProApp: true,
  isProType: true,
  isProApp: true,
  isIos: false,
  isAndroid: false,
  isProduction: process.env.NODE_ENV === 'production',
  isDevelopment: process.env.NODE_ENV === 'development',
  isCnApp: false,
  isDevice: false,
  country,
  isTwCountry: country === 'TW',
  isCnCountry: country === 'CN',
}

export default Env
