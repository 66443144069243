export var GET_BODY_DATA_CALL = 'GET_BODY_DATA_CALL';
export var GET_BODY_DATA_DONE = 'GET_BODY_DATA_DONE';
export var GET_BODY_DATA_FAIL = 'GET_BODY_DATA_FAIL';
export var GET_BODY_DATA_MORE_CALL = 'GET_BODY_DATA_MORE_CALL';
export var GET_BODY_DATA_MORE_DONE = 'GET_BODY_DATA_MORE_DONE';
export var GET_BODY_DATA_MORE_FAIL = 'GET_BODY_DATA_MORE_FAIL';
export var GET_NUTRITION_CALL = 'GET_NUTRITION_CALL';
export var GET_NUTRITION_DONE = 'GET_NUTRITION_DONE';
export var GET_NUTRITION_FAIL = 'GET_NUTRITION_FAIL';
export var GET_NUTRITION_MORE_CALL = 'GET_NUTRITION_MORE_CALL';
export var GET_NUTRITION_MORE_DONE = 'GET_NUTRITION_MORE_DONE';
export var GET_NUTRITION_MORE_FAIL = 'GET_NUTRITION_MORE_FAIL';
export var GET_MOVE_DATA_CALL = 'GET_MOVE_DATA_CALL';
export var GET_MOVE_DATA_DONE = 'GET_MOVE_DATA_DONE';
export var GET_MOVE_DATA_FAIL = 'GET_MOVE_DATA_FAIL';
export var GET_MOVE_DATA_MORE_CALL = 'GET_MOVE_DATA_MORE_CALL';
export var GET_MOVE_DATA_MORE_DONE = 'GET_MOVE_DATA_MORE_DONE';
export var GET_MOVE_DATA_MORE_FAIL = 'GET_MOVE_DATA_MORE_FAIL';
export var SET_SCROLL_ENABLED = 'SET_SCROLL_ENABLED';
export var SET_DATE_TYPE = 'SET_DATE_TYPE';
export var SET_PROFILE_CLIENT = 'SET_PROFILE_CLIENT';
export var REMOVE_DATA_CALL = 'REMOVE_DATA_CALL';
export var REMOVE_DATA_DONE = 'REMOVE_DATA_DONE';
export var REMOVE_DATA_FAIL = 'REMOVE_DATA_FAIL';