import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectWithoutProperties from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";

var _handleActions;

import _ from 'lodash';
import { handleActions, combineActions } from 'redux-actions';
import * as types from '../../store/types';
import produce from 'immer';

var isLargerBy = function isLargerBy(arr1, arr2, key) {
  var arr1Max = (_.maxBy(arr1, key) || {})[key];
  var arr2Max = (_.maxBy(arr2, key) || {})[key];
  if (arr2Max === undefined) return true;
  if (arr1Max === undefined) return false;
  return arr1Max > arr2Max;
};

var decoECMessages = function decoECMessages(messages) {
  return _.map(messages, function (msg) {
    return _objectSpread({}, msg, {
      _id: msg.id,
      user: msg.send_from == 'service' ? {
        _id: 'service'
      } : {
        _id: 'user'
      },
      text: msg.content,
      fromService: msg.send_from == 'service' ? true : false,
      createdAt: new Date(msg.addTime) || new Date(),
      sender: msg.send_from == 'service' ? 'service' : 'user'
    });
  });
};

var mergeECMessages = function mergeECMessages(chatrooms, id) {
  return _.map(chatrooms, function (cr) {
    return _.toString(cr.id) === _.toString(id) ? _objectSpread({}, cr, {
      messages: _.chain(cr.messages).filter(function (m) {
        return !!m.id;
      }).orderBy(['createdAt'], ['desc']).uniqBy('id').value()
    }) : cr;
  });
};

var mergeMessages = function mergeMessages(chatrooms, id, messages) {
  var _ref = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {},
      noid = _ref.id,
      others = _objectWithoutProperties(_ref, ["id"]);

  return _.map(chatrooms, function (cr) {
    return _.toString(cr.id) === _.toString(id) ? _objectSpread({}, cr, others, {
      messages: _.chain([].concat(_toConsumableArray(cr.messages || []), _toConsumableArray(messages))).orderBy(['createdAt'], ['desc']).uniqBy('_id').value()
    }) : cr;
  });
};

var updateMessageID = function updateMessageID(chatrooms, id, tempID, newID) {
  return _.map(chatrooms, function (cr) {
    return cr.id === id ? _objectSpread({}, cr, {
      messages: _.map(cr.messages, function (msg) {
        return msg._id === tempID ? _objectSpread({}, msg, {
          _id: newID
        }) : msg;
      })
    }) : cr;
  });
};

var updateReadAt = function updateReadAt(chatrooms, id) {
  return _.map(chatrooms, function (cr) {
    return cr.id === id && _.first(cr.messages) ? _objectSpread({}, cr, {
      readAt: _.first(cr.messages).createdAt.toISOString()
    }) : cr;
  });
};

var updateLoadMore = function updateLoadMore(chatrooms, id) {
  return _.map(chatrooms, function (cr) {
    return cr.id === id ? _objectSpread({}, cr, {
      loadMore: false
    }) : cr;
  });
};

export default handleActions((_handleActions = {}, _defineProperty(_handleActions, combineActions(types.GET_CLIENT_ME_DONE, types.GET_USER_ME_DONE, types.GET_CHAT_ROOMS_DONE), function (state, _ref2) {
  var payload = _ref2.payload;
  var crs = payload.chat_rooms,
      serviceCrs = payload.service_chat_rooms,
      archivedCrs = payload.archived_chat_rooms;
  return produce(state, function (draft) {
    var incomingChatrooms = _.compact(_.concat(serviceCrs, crs, _.map(archivedCrs, function (r) {
      return _objectSpread({}, r, {
        replyDisabled: true
      });
    })));

    var chatroomMessages = _.map(incomingChatrooms, function (cr) {
      return _.find(state.chatroomMessages, {
        id: cr.id
      }) || {
        id: cr.id,
        messages: []
      };
    });

    var chatrooms = _.map(incomingChatrooms, function (cr) {
      var matched = {
        messages: []
      };
      var title = '';
      var isGroup = false;
      var isGroupClass = false;
      var isService = false;

      if (cr && cr.id) {
        var id = cr.id,
            name = cr.name,
            members = cr.members,
            is_service = cr.is_service,
            group_class_id = cr.group_class_id;
        isGroupClass = !!group_class_id;

        if (is_service) {
          isService = true;
          title = name;
        } else {
          var membersNumber = members.length;
          isGroup = membersNumber > 2;
          title = isGroup ? "".concat(name, " (").concat(membersNumber, ")") : name;
        }

        matched = _.find(state.chatrooms, {
          id: id
        }) || {
          messages: []
        };
      }

      return _objectSpread({}, matched, cr, {
        loadMore: true,
        title: title,
        isGroup: isGroup,
        isGroupClass: isGroupClass,
        isService: isService
      });
    });

    draft.chatrooms = chatrooms;
    draft.chatroomMessages = chatroomMessages;
  });
}), _defineProperty(_handleActions, types.OPEN_CHAT_DONE, function (state, _ref3) {
  var _ref3$payload = _ref3.payload,
      id = _ref3$payload.id,
      isShopChat = _ref3$payload.isShopChat;
  return produce(state, function (draft) {
    var selectRooms = _.find(draft.chatrooms, {
      id: id
    }) || {
      name: ''
    };
    var name = selectRooms.name;

    if (isShopChat) {
      draft.chatroomMessages = mergeECMessages(state.chatroomMessages, id || state.id);
      draft.chatrooms = mergeECMessages(state.chatrooms, id || state.id);
    }

    draft.id = id;
    draft.name = name;
  });
}), _defineProperty(_handleActions, types.CLOSE_CHAT_DONE, function (state) {
  return _objectSpread({}, state, {
    id: undefined,
    name: ''
  });
}), _defineProperty(_handleActions, combineActions(types.RECEIVE_NEW_MESSAGE, types.MULTIPLE_RECEIVE_NEW_MESSAGE), function (state, _ref4) {
  var _ref4$payload = _ref4.payload,
      _ref4$payload$id = _ref4$payload.id,
      id = _ref4$payload$id === void 0 ? undefined : _ref4$payload$id,
      messages = _ref4$payload.messages;
  var decoMessages = id === 'shopchat' ? decoECMessages(messages) : messages;
  return _objectSpread({}, state, {
    chatroomMessages: mergeMessages(state.chatroomMessages, id || state.id, decoMessages),
    chatrooms: mergeMessages(state.chatrooms, id || state.id, decoMessages)
  });
}), _defineProperty(_handleActions, types.FETCHED_MULTIPLE_CHATS, function (state, _ref5) {
  var chats = _ref5.payload.chats;
  return _objectSpread({}, state, {
    chatroomMessages: _.reduce(chats, function (memo, chat) {
      return mergeMessages(memo, chat.chatRoomId, chat.messages);
    }, state.chatroomMessages),
    chatrooms: _.reduce(chats, function (memo, chat) {
      return mergeMessages(memo, chat.chatRoomId, chat.messages);
    }, state.chatrooms)
  });
}), _defineProperty(_handleActions, types.FETCHED_MULTIPLE_CHAT_ROOMS, function (state, _ref6) {
  var chatRooms = _ref6.payload.chatRooms;
  // console.log(chatRooms)
  return _objectSpread({}, state, {
    chatrooms: _.reduce(chatRooms, function (memo, chatRoom) {
      return mergeMessages(memo, chatRoom.id, [], chatRoom);
    }, state.chatrooms)
  });
}), _defineProperty(_handleActions, types.INSERT_MESSAGE, function (state, _ref7) {
  var _ref7$payload = _ref7.payload,
      _ref7$payload$id = _ref7$payload.id,
      id = _ref7$payload$id === void 0 ? undefined : _ref7$payload$id,
      messages = _ref7$payload.messages;
  return _objectSpread({}, state, {
    chatroomMessages: mergeMessages(state.chatroomMessages, id || state.id, messages),
    chatrooms: mergeMessages(state.chatrooms, id || state.id, messages)
  });
}), _defineProperty(_handleActions, types.SEND_MESSAGE_DONE, function (state, _ref8) {
  var _ref8$payload = _ref8.payload,
      id = _ref8$payload.id,
      tempID = _ref8$payload.tempID,
      newID = _ref8$payload.newID;
  return _objectSpread({}, state, {
    chatroomMessages: updateMessageID(state.chatroomMessages, id || state.id, tempID, newID),
    chatrooms: updateMessageID(state.chatrooms, id || state.id, tempID, newID)
  });
}), _defineProperty(_handleActions, types.UPDATE_CHATROOM_LOAD_MORE, function (state, _ref9) {
  var chatRoomId = _ref9.payload.chatRoomId;
  return _objectSpread({}, state, {
    chatrooms: updateLoadMore(state.chatrooms, chatRoomId)
  });
}), _defineProperty(_handleActions, types.FETCH_MESSAGES_CALL, function (state) {
  return _objectSpread({}, state, {
    isLoading: true
  });
}), _defineProperty(_handleActions, types.INIT_SOCKETEC_DONE, function (state, _ref10) {
  var shopRooms = _ref10.payload.shopRooms;
  return produce(state, function (draft) {
    draft.chatrooms.unshift(shopRooms);
    draft.chatroomMessages.unshift({
      id: 'shopchat',
      messages: []
    });
  });
}), _defineProperty(_handleActions, combineActions(types.RECEIVE_NEW_MESSAGE, types.SEND_MESSAGE_DONE), function (state, _ref11) {
  var id = _ref11.payload.id;
  return produce(state, function (draft) {
    if (id !== 'shopchat') {
      draft.chatroomMessages = updateReadAt(state.chatroomMessages, id);
      draft.chatrooms = updateReadAt(state.chatrooms, id);
    }
  });
}), _defineProperty(_handleActions, combineActions(types.RECEIVE_NEW_MESSAGE, types.FETCH_MESSAGES_FAIL), function (state) {
  return _objectSpread({}, state, {
    isLoading: false
  });
}), _defineProperty(_handleActions, types.UPDATE_CHATROOM, function (state, _ref12) {
  var _ref12$payload = _ref12.payload,
      chatRoomId = _ref12$payload.chatRoomId,
      members = _ref12$payload.members;
  return produce(state, function (draft) {
    var chatroom = _.find(draft.chatrooms, {
      id: chatRoomId
    });

    if (chatroom) {
      chatroom.members = _.merge(_.keyBy(chatroom.members, 'gid'), _.keyBy(members, 'gid'));
    }
  });
}), _defineProperty(_handleActions, types.OPEN_SOCKETEC_DONE, function (state, _ref13) {
  var data = _ref13.payload.data;
  return produce(state, function (draft) {
    draft.shopChatData = data;
  });
}), _defineProperty(_handleActions, types.LEAVE_CHATS_DONE, function (state, _ref14) {
  var chat_room_id = _ref14.payload.chat_room_id;
  return produce(state, function (draft) {
    _.remove(draft.chatrooms, {
      id: chat_room_id
    });
  });
}), _defineProperty(_handleActions, types.SELECT_CHAT, function (state, _ref15) {
  var id = _ref15.payload.id;
  return produce(state, function (draft) {
    draft.selectedChatRoomId = id;
  });
}), _defineProperty(_handleActions, types.INCREASE_UNREAD_COUNT, function (state, _ref16) {
  var _ref16$payload = _ref16.payload,
      id = _ref16$payload.id,
      _ref16$payload$increm = _ref16$payload.increment,
      increment = _ref16$payload$increm === void 0 ? 1 : _ref16$payload$increm;
  return produce(state, function (draft) {
    var chatroom = _.find(draft.chatrooms, {
      id: id
    });

    chatroom.unreadCount += increment;
  });
}), _defineProperty(_handleActions, types.RESET_UNREAD_COUNT, function (state, _ref17) {
  var id = _ref17.payload.id;
  return produce(state, function (draft) {
    var chatroom = _.find(draft.chatrooms, {
      id: id
    });

    chatroom.unreadCount = 0;
  });
}), _defineProperty(_handleActions, types.UPDATE_LOCAL_READ_AT, function (state, _ref18) {
  var id = _ref18.payload.id;
  return produce(state, function (draft) {
    draft.chatroomMessages = updateReadAt(state.chatroomMessages, id);
    draft.chatrooms = updateReadAt(state.chatrooms, id);
  });
}), _defineProperty(_handleActions, types.INIT_SOCKET_MULTIPLE_CALL, function (state, _ref19) {
  var payload = _ref19.payload;
  return produce(state, function (draft) {
    draft.socketInitialized = true;
  });
}), _handleActions), {
  id: undefined,
  chatrooms: [],
  chatroomMessages: [],
  name: '',
  isLoading: false,
  shopChatData: {},
  socketInitialized: false
});