import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";

var _handleActions;

import _ from 'lodash';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import * as types from '../../store/types';
var initialArticle = {
  groupArticleList: [],
  sixGroupDetail: [],
  showLoading: false
};
export default handleActions((_handleActions = {}, _defineProperty(_handleActions, types.GET_ARTICLE_LIST_CALL, function (state, _ref) {
  var groupArticleList = _ref.payload.groupArticleList;
  return produce(state, function (draft) {
    draft.showLoading = true;
  });
}), _defineProperty(_handleActions, types.GET_ARTICLE_LIST_DONE, function (state, _ref2) {
  var groupArticleList = _ref2.payload.groupArticleList;
  return produce(state, function (draft) {
    draft.showLoading = false;
    draft.groupArticleList = groupArticleList;
  });
}), _defineProperty(_handleActions, types.GET_ARTICLE_LIST_FAIL, function (state, _ref3) {
  var payload = _ref3.payload;
  return produce(state, function (draft) {
    draft.showLoading = false;
  });
}), _defineProperty(_handleActions, types.GET_SIX_GROUP_DETAIL_DONE, function (state, _ref4) {
  var courses = _ref4.payload.courses;
  return _objectSpread({}, state, {
    sixGroupDetail: courses
  });
}), _defineProperty(_handleActions, types.SET_ARTICLE_QUESTION_COMPLETE_DONE, function (state, _ref5) {
  var payload = _ref5.payload;
  return _objectSpread({}, state);
}), _defineProperty(_handleActions, types.LOGOUT_DONE, function (state) {
  return initialArticle;
}), _handleActions), initialArticle);