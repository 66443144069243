import _ from 'lodash';
import lib from '../platform';
var pfConfig = lib.pfConfig;
var moveIsFirst = pfConfig.common.moveIsFirst,
    isProType = pfConfig.isProType;
var noteOptions = [{
  type: 'food',
  order: 1,
  iconName: 'breakfast'
}, {
  type: 'food',
  order: 3,
  iconName: 'lunch'
}, {
  type: 'food',
  order: 5,
  iconName: 'dinner'
}, {
  type: 'food',
  order: 7,
  iconName: 'snacks'
}, {
  type: 'move',
  iconName: 'exercise'
}, {
  type: 'life',
  iconName: 'life'
}, {
  type: 'water',
  iconName: 'cup-water'
}];

if (isProType) {
  noteOptions.push({
    type: 'diag',
    iconName: 'ic_playlist_add_check_24px'
  });
} else {
  noteOptions.push({
    type: 'addData',
    iconName: 'ic_insert_chart_24px'
  });
}

if (moveIsFirst) {
  var itemIndex = _.findIndex(noteOptions, {
    type: 'move'
  });

  noteOptions.splice(0, 0, noteOptions.splice(itemIndex, 1)[0]);
}

export var getNoteName = function getNoteName(_ref) {
  var type = _ref.type,
      order = _ref.order,
      t = _ref.t;
  var name = '';

  switch (true) {
    case type === 'FoodNote' || type === 'food':
      name = t("food:mealByOrder.".concat(order));
      break;

    case type === 'MoveNote' || type === 'move':
      name = t('diary:move');
      break;

    case type === 'LifeNote' || type === 'life':
      name = t('diary:life');
      break;

    case type === 'WaterNote' || type === 'water':
      name = t('bodyAttrs:water');
      break;

    case type === 'DiagNote' || type === 'diag':
      name = t('diary:diag');
      break;

    case type === 'addData':
      name = t('dataChart:healthTitle');
      break;

    default:
      break;
  }

  return name;
};
export var getNoteOptions = function getNoteOptions() {
  return noteOptions;
};