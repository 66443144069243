import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { Observable } from 'rxjs';
import { client } from '../../services';
import './RxOperators';
import * as actions from '../actions';
import * as types from '../../store/types';
import _ from 'lodash';
import lib from '../../platform';
var config = lib.pfConfig;
var isExpo = config.isExpo,
    _config$shop = config.shop,
    shopShow = _config$shop.shopShow,
    storeId = _config$shop.storeId,
    showShopChat = config.common.showShopChat;
export var getClient = function getClient(action$, store) {
  return action$.ofType(types.GET_CLIENT_CALL).switchMap(function (_ref) {
    var id = _ref.payload.id;
    return Observable.fromPromise(client.getClient(id)).map(function (res) {
      return actions.getClientDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getClientFail(err));
    });
  });
};
export var getClientMeCall = function getClientMeCall(action$, store) {
  return action$.ofType(types.GET_CLIENT_ME_CALL).switchMap(function (_ref2) {
    var _ref2$payload = _ref2.payload,
        isLogin = _ref2$payload.isLogin,
        showLoaging = _ref2$payload.showLoaging;
    return Observable.concat(Observable.of(showLoaging ? actions.showLoading() : actions.empty()), Observable.fromPromise(client.getMe()).concatMap(function (res) {
      var _$find, _$find2;

      var show_intro = res.show_intro,
          body_attrs = res.body_attrs,
          care_receivers = res.care_receivers,
          identities = res.identities,
          can_change_password = res.can_change_password,
          shopToken = res.pq_store_token;
      var newActions = [];
      var isCaregiver = _.isArray(care_receivers) && care_receivers.length !== 0;
      var id = res.id;
      res.hiddenEmail = !!_.find(identities, (_$find = {
        provider: 'facebook'
      }, _defineProperty(_$find, "provider", 'wechat'), _defineProperty(_$find, "provider", 'mobile'), _defineProperty(_$find, "provider", 'apple'), _$find)) || !can_change_password;
      res.hiddenEditPassword = !!_.find(identities, (_$find2 = {
        provider: 'facebook'
      }, _defineProperty(_$find2, "provider", 'wechat'), _defineProperty(_$find2, "provider", 'apple'), _$find2)) || !can_change_password;
      var isChild = false;
      var isOpenTargetBasicPage = false;
      var isOpenSetPasswordPage = false;
      var mobileNumber = '000';

      if (isLogin) {
        if (isCaregiver) {
          isChild = true;
          var careReceiverNumber = care_receivers.length; //切換到最後一個小孩

          var careReceiver = care_receivers[careReceiverNumber - 1];
          id = careReceiver.id;

          if (careReceiver.body_attrs) {
            var _careReceiver$body_at = careReceiver.body_attrs,
                weight = _careReceiver$body_at.weight,
                ee = _careReceiver$body_at.ee,
                height = _careReceiver$body_at.height,
                af = _careReceiver$body_at.af,
                dads_height = _careReceiver$body_at.dads_height,
                moms_height = _careReceiver$body_at.moms_height,
                predict_height = _careReceiver$body_at.predict_height;

            if (!weight || !height || !af || !ee || !dads_height || !moms_height || !predict_height) {
              isOpenTargetBasicPage = true;
            }
          } else {
            isOpenTargetBasicPage = true;
          }

          var careReceivers = _.map(care_receivers, function (care_receiver) {
            return _objectSpread({}, care_receiver, {
              isCareReceiver: true
            });
          });

          newActions.push(actions.getClientsDone(careReceivers));
        } else {
          if (show_intro) {
            //newActions.push(actions.openIntroPage())
            isOpenTargetBasicPage = true;
          } else if (!body_attrs) {
            isOpenTargetBasicPage = true;
          } else {
            var _weight = body_attrs.weight,
                _ee = body_attrs.ee,
                _height = body_attrs.height,
                _af = body_attrs.af;

            if (!_weight || !_height || !_af || !_ee) {
              // 無資料去重新設定
              isOpenTargetBasicPage = true;
            }
          }
        }

        if (shopShow) {
          newActions.push(actions.setShopToken({
            shopToken: shopToken
          }));

          if (showShopChat) {
            newActions.push(actions.initSocketECCall());
          }

          var shopIdentities = _.find(identities, {
            provider: 'pq_store'
          }) || {
            id: '',
            uid: ''
          };
          res.shopIdentities = shopIdentities;
          res.mobile = _.find(identities, {
            provider: 'mobile'
          }); //res.mobile = { uid: '', password_set: false, }

          res.isMobile = !!res.mobile;

          if (res.isMobile) {
            var _res$mobile = res.mobile,
                password_set = _res$mobile.password_set,
                uid = _res$mobile.uid;
            mobileNumber = uid;

            if (!password_set) {
              isOpenSetPasswordPage = true;
            }
          }
          /*
            if (isExpo) {
              const { avatar_thumbnail_url, nick_name, } = res
              const { uid } = shopIdentities
              const shopParam = {
                "icon": avatar_thumbnail_url,
                "token": shopToken,
                "cofit_uid": uid,
                "store_id": storeId,
                "mobile": mobileNumber,
                nick_name,
              }
               newActions.push(actions.shopLoginCall(shopParam))
            }
          */

        }

        if (isOpenSetPasswordPage) {
          newActions.push(actions.openSetPasswordPage({
            isOpenTargetBasicPage: isOpenTargetBasicPage,
            isChild: isChild,
            mobileNumber: mobileNumber
          }));
        } else if (isOpenTargetBasicPage) {
          newActions.push(actions.openTargetBasicPage({
            fromIntro: true,
            isChild: isChild
          }));
        }

        newActions.push(actions.getPricingsCall());
        newActions.push(actions.setClientId(id));
        newActions.push(actions.setDiaryClient(id));
        newActions.push(actions.setProfileClient(id));
        newActions.push(actions.setTargetClient(id));
      }

      newActions.push(actions.getClientDone(res));
      newActions.push(actions.getClientMeDone(res));
      return newActions;
    }).catch(function (err) {
      return Observable.of(actions.getClientMeFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var getSocialClientMeCall = function getSocialClientMeCall(action$, store) {
  return action$.ofType(types.GET_SOCIAL_CLIENT_ME_CALL).switchMap(function (_ref3) {
    var _ref3$payload = _ref3.payload,
        isLogin = _ref3$payload.isLogin,
        showLoaging = _ref3$payload.showLoaging;
    return Observable.concat(Observable.of(showLoaging ? actions.showLoading() : actions.empty()), Observable.fromPromise(client.getMe()).concatMap(function (res) {
      var _$find3, _$find4;

      var identities = res.identities,
          shopToken = res.pq_store_token,
          can_change_password = res.can_change_password;
      var newActions = [];
      var id = res.id;
      res.hiddenEmail = !!_.find(identities, (_$find3 = {
        provider: 'facebook'
      }, _defineProperty(_$find3, "provider", 'wechat'), _defineProperty(_$find3, "provider", 'mobile'), _defineProperty(_$find3, "provider", 'apple'), _$find3)) || !can_change_password;
      res.hiddenEditPassword = !!_.find(identities, (_$find4 = {
        provider: 'facebook'
      }, _defineProperty(_$find4, "provider", 'wechat'), _defineProperty(_$find4, "provider", 'apple'), _$find4)) || !can_change_password;

      if (isLogin) {
        if (shopShow) {
          newActions.push(actions.setShopToken({
            shopToken: shopToken
          }));

          if (showShopChat) {
            newActions.push(actions.initSocketECCall());
          }

          var shopIdentities = _.find(identities, {
            provider: 'pq_store'
          }) || {
            id: '',
            uid: ''
          };
          res.shopIdentities = shopIdentities;
          var mobileNumber = '000';
          res.mobile = _.find(identities, {
            provider: 'mobile'
          });
          res.isMobile = !!res.mobile;

          if (res.isMobile) {
            var _res$mobile2 = res.mobile,
                password_set = _res$mobile2.password_set,
                uid = _res$mobile2.uid;
            mobileNumber = uid;

            if (!password_set) {
              newActions.push(actions.openSetPasswordPage({
                mobileNumber: mobileNumber,
                isOpenTargetBasicPage: false,
                isChild: false
              }));
            }
          }
          /*
          if (isExpo) {
            const { avatar_thumbnail_url, nick_name, } = res
            const { uid } = shopIdentities
            const shopParam = {
              "icon": avatar_thumbnail_url,
              "token": shopToken,
              "cofit_uid": uid,
              "store_id": storeId,
              "mobile": mobileNumber,
              nick_name,
            }
             newActions.push(actions.shopLoginCall(shopParam))
          }
          */

        }

        newActions.push(actions.setClientId(id));
        newActions.push(actions.setDiaryClient(id));
        newActions.push(actions.setProfileClient(id));
        newActions.push(actions.setTargetClient(id));
        newActions.push(actions.setTargetClient(id));
      }

      newActions.push(actions.getClientDone(res));
      newActions.push(actions.getClientMeDone(res));
      return newActions;
    }).catch(function (err) {
      return Observable.of(actions.getSocialClientMeFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var getFFClientMeCall = function getFFClientMeCall(action$, store) {
  return action$.ofType(types.GET_FF_CLIENT_ME_CALL).switchMap(function (_ref4) {
    var _ref4$payload = _ref4.payload,
        isLogin = _ref4$payload.isLogin,
        showLoaging = _ref4$payload.showLoaging;
    return Observable.concat(Observable.of(showLoaging ? actions.showLoading() : actions.empty()), Observable.fromPromise(client.getMe()).concatMap(function (res) {
      var id = res.id,
          identities = res.identities;
      var factoryDefaultCode = store.value.storage.factoryDefaultCode;
      var newActions = [];

      var ffIdentity = _.find(identities, {
        provider: 'fitness_factory',
        position_name: '會員'
      });

      var ffStaffIdentity = _.find(identities, function (_ref5) {
        var provider = _ref5.provider,
            position_name = _ref5.position_name;
        return provider === 'fitness_factory' && position_name !== '會員';
      });

      var branchCode = '';

      if (factoryDefaultCode !== '') {
        branchCode = factoryDefaultCode;
      } else {
        if (ffStaffIdentity) {
          var _ffStaffIdentity$bran = ffStaffIdentity.branch_code,
              branch_code = _ffStaffIdentity$bran === void 0 ? 'PW000' : _ffStaffIdentity$bran;
          branchCode = branch_code;
        } else {
          var _branch_code = ffIdentity.branch_code;
          branchCode = _branch_code;
        }

        newActions.push(actions.setStoreByKeyCall({
          key: 'factoryDefaultCode',
          value: branchCode
        }));
      }

      var identity = {
        id: id,
        ffIdentity: ffIdentity,
        ffStaffIdentity: ffStaffIdentity
      };

      if (isLogin) {
        newActions.push(actions.setCompanyInfo({
          branchCode: branchCode
        }));
        newActions.push(actions.getFactoryListCall());
        newActions.push(actions.getTargetsCall());
        newActions.push(actions.setClientId(id));
        newActions.push(actions.setDiaryClient(id));
        newActions.push(actions.setProfileClient(id));
        newActions.push(actions.setTargetClient(id));
        newActions.push(actions.setIdentityStoreCall({
          identity: identity
        }));
      }

      newActions.push(actions.getClientDone(_objectSpread({}, res, {
        ffIdentity: ffIdentity,
        ffStaffIdentity: ffStaffIdentity
      })));
      newActions.push(actions.getClientMeDone(_objectSpread({}, res, {
        ffIdentity: ffIdentity,
        ffStaffIdentity: ffStaffIdentity
      })));
      return newActions;
    }).catch(function (err) {
      return Observable.of(actions.getFFClientMeFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};
export var updateClient = function updateClient(action$, store) {
  return action$.ofType(types.UPDATE_CLIENT_CALL).switchMap(function (_ref6) {
    var _ref6$payload = _ref6.payload,
        key = _ref6$payload.key,
        goBack = _ref6$payload.goBack,
        hiddenLogin = _ref6$payload.hiddenLogin,
        value = _ref6$payload.value;
    return Observable.concat(Observable.of(hiddenLogin ? actions.empty() : actions.showLoading()), Observable.fromPromise(client.updateClient(value)).switchMap(function (res) {
      var bodyDataReload = key === 'body_attrs';
      var getBodyActions = bodyDataReload ? [actions.setDateType({
        dateIndex: 0,
        dateType: 'week'
      }), actions.getBodyDataCall({
        dateType: 'week'
      })] : [];
      var goBackAction = goBack ? actions.pageGoBack() : actions.empty();
      return [actions.updateClientDone({
        client: res
      })].concat(getBodyActions, [goBackAction]);
    }).catch(function (err) {
      return Observable.of(actions.updateClientFail(err));
    }), Observable.of(actions.hideLoading()));
  });
};