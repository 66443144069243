import { Observable } from 'rxjs';
import { company } from '../../services';
import * as actions from '../actions';
import * as types from '../types';
export var getCompanyListCall = function getCompanyListCall(action$) {
  return action$.ofType(types.GET_COMPANY_LIST_CALL).switchMap(function (_ref) {
    var payload = _ref.payload;
    return Observable.fromPromise(company.getCompanyList(payload)).map(function (res) {
      return actions.getCompanyListDone(res);
    }).catch(function (err) {
      return Observable.of(actions.getCompanyListFail(err));
    });
  });
};