import { createAction } from 'redux-actions';
import * as types from '../../store/types';
export var openArticleCall = createAction(types.OPEN_ARTICLE_CALL);
export var openArticleDone = createAction(types.OPEN_ARTICLE_DONE);
export var openArticleFail = createAction(types.OPEN_ARTICLE_FAIL);
export var getArticleListCall = createAction(types.GET_ARTICLE_LIST_CALL);
export var getArticleListDone = createAction(types.GET_ARTICLE_LIST_DONE);
export var getArticleListFail = createAction(types.GET_ARTICLE_LIST_FAIL);
export var getSixGroupDetailCall = createAction(types.GET_SIX_GROUP_DETAIL_CALL);
export var getSixGroupDetailDone = createAction(types.GET_SIX_GROUP_DETAIL_DONE);
export var getSixGroupDetailFail = createAction(types.GET_SIX_GROUP_DETAIL_FAIL);
export var setArticleQuestionCompleteCall = createAction(types.SET_ARTICLE_QUESTION_COMPLETE_CALL);
export var setArticleQuestionCompleteDone = createAction(types.SET_ARTICLE_QUESTION_COMPLETE_DONE);
export var setArticleQuestionCompleteFail = createAction(types.SET_ARTICLE_QUESTION_COMPLETE_FAIL);