export var GET_SHOP_INDEX_CALL = 'GET_SHOP_INDEX_CALL';
export var GET_SHOP_INDEX_DONE = 'GET_SHOP_INDEX_DONE';
export var GET_SHOP_INDEX_FAIL = 'GET_SHOP_INDEX_FAIL';
export var GET_WXPAY_DATA_CALL = 'GET_WXPAY_DATA_CALL';
export var GET_WXPAY_DATA_DONE = 'GET_WXPAY_DATA_DONE';
export var GET_WXPAY_DATA_FAIL = 'GET_WXPAY_DATA_FAIL';
export var GET_GOODS_CART_CALL = 'GET_GOODS_CART_CALL';
export var GET_GOODS_CART_DONE = 'GET_GOODS_CART_DONE';
export var GET_GOODS_CART_FAIL = 'GET_GOODS_CART_FAIL';
export var ADD_GOODS_CART_CALL = 'ADD_GOODS_CART_CALL';
export var ADD_GOODS_CART_DONE = 'ADD_GOODS_CART_DONE';
export var ADD_GOODS_CART_FAIL = 'ADD_GOODS_CART_FAIL';
export var UPDATE_GOODS_CART_CALL = 'UPDATE_GOODS_CART_CALL';
export var UPDATE_GOODS_CART_DONE = 'UPDATE_GOODS_CART_DONE';
export var UPDATE_GOODS_CART_FAIL = 'UPDATE_GOODS_CART_FAIL';
export var REMOVE_GOODS_CART_CALL = 'REMOVE_GOODS_CART_CALL';
export var REMOVE_GOODS_CART_DONE = 'REMOVE_GOODS_CART_DONE';
export var REMOVE_GOODS_CART_FAIL = 'REMOVE_GOODS_CART_FAIL';
export var SET_GOODS_CARTS = 'SET_GOODS_CARTS';
export var SET_GOODS_CART = 'SET_GOODS_CART';
export var WXPAY_CALL = 'WXPAY_CALL';
export var WXPAY_DONE = 'WXPAY_DONE';
export var WXPAY_FAIL = 'WXPAY_FAIL';
export var SET_SHOP_TAB_INDEX = 'SET_SHOP_TAB_INDEX';
export var SETTLEMENT_CALL = 'SETTLEMENT_CALL';
export var SETTLEMENT_DONE = 'SETTLEMENT_DONE';
export var SETTLEMENT_FAIL = 'SETTLEMENT_FAIL';
export var ADD_ORDER_CALL = 'ADD_ORDER_CALL';
export var ADD_ORDER_DONE = 'ADD_ORDER_DONE';
export var ADD_ORDER_FAIL = 'ADD_ORDER_FAIL';
export var GET_ADDRESS_CALL = 'GET_ADDRESS_CALL';
export var GET_ADDRESS_DONE = 'GET_ADDRESS_DONE';
export var GET_ADDRESS_FAIL = 'GET_ADDRESS_FAIL';
export var SAVE_ADDRESS_CALL = 'SAVE_ADDRESS_CALL';
export var SAVE_ADDRESS_DONE = 'SAVE_ADDRESS_DONE';
export var SAVE_ADDRESS_FAIL = 'SAVE_ADDRESS_FAIL';
export var GET_ORDER_LIST_CALL = 'GET_ORDER_LIST_CALL';
export var GET_ORDER_LIST_DONE = 'GET_ORDER_LIST_DONE';
export var GET_ORDER_LIST_FAIL = 'GET_ORDER_LIST_FAIL';
export var GET_SHIP_DETAIL_CALL = 'GET_SHIP_DETAIL_CALL';
export var GET_SHIP_DETAIL_DONE = 'GET_SHIP_DETAIL_DONE';
export var GET_SHIP_DETAIL_FAIL = 'GET_SHIP_DETAIL_FAIL';
export var OPEN_SHOP_ITEM_PAGE = 'OPEN_SHOP_ITEM_PAGE';
export var OPEN_SHOP_TAB = 'OPEN_SHOP_TAB';
export var OPEN_SHOP_PAY_PAGE = 'OPEN_SHOP_PAY_PAGE';
export var OPEN_ORDER_EVALUATE_PAGE = 'OPEN_ORDER_EVALUATE_PAGE';
export var OPEN_SHOPPING_CART_PAGE = 'OPEN_SHOPPING_CART_PAGE';
export var OPEN_SHOP_ORDER_TAB_PAGE = 'OPEN_SHOP_ORDER_TAB_PAGE';
export var OPEN_SHOP_INTEGRAL_PAGE = 'OPEN_SHOP_INTEGRAL_PAGE';
export var OPEN_GOODS_EVA_PAGE = 'OPEN_GOODS_EVA_PAGE';
export var OPEN_ORDER_INVOICE_PAGE = 'OPEN_ORDER_INVOICE_PAGE';
export var SET_PAY_ERROR = 'SET_PAY_ERROR';
export var GET_SHOP_SEARCH_CALL = 'GET_SHOP_SEARCH_CALL';
export var GET_SHOP_SEARCH_DONE = 'GET_SHOP_SEARCH_DONE';
export var GET_SHOP_SEARCH_FAIL = 'GET_SHOP_SEARCH_FAIL';
export var ORDER_CONFIRM_CALL = 'ORDER_CONFIRM_CALL';
export var ORDER_CONFIRM_DONE = 'ORDER_CONFIRM_DONE';
export var ORDER_CONFIRM_FAIL = 'ORDER_CONFIRM_FAIL';
export var ORDER_CANCEL_CALL = 'ORDER_CANCEL_CALL';
export var ORDER_CANCEL_DONE = 'ORDER_CANCEL_DONE';
export var ORDER_CANCEL_FAIL = 'ORDER_CANCEL_FAIL';
export var ORDER_REFUND_CALL = 'ORDER_REFUND_CALL';
export var ORDER_REFUND_DONE = 'ORDER_REFUND_DONE';
export var ORDER_REFUND_FAIL = 'ORDER_REFUND_FAIL';
export var ORDER_STORE_REMOVE = 'ORDER_STORE_REMOVE';
export var GET_ORDER_COUNT_CALL = 'GET_ORDER_COUNT_CALL';
export var GET_ORDER_COUNT_DONE = 'GET_ORDER_COUNT_DONE';
export var GET_ORDER_COUNT_FAIL = 'GET_ORDER_COUNT_FAIL';
export var GET_SMS_CODE_CALL = 'GET_SMS_CODE_CALL';
export var GET_SMS_CODE_DONE = 'GET_SMS_CODE_DONE';
export var GET_SMS_CODE_FAIL = 'GET_SMS_CODE_FAIL';
export var GET_ORDER_DISCUSS_CALL = 'GET_ORDER_DISCUSS_CALL';
export var GET_ORDER_DISCUSS_DONE = 'GET_ORDER_DISCUSS_DONE';
export var GET_ORDER_DISCUSS_FAIL = 'GET_ORDER_DISCUSS_FAIL';
export var SAVE_ORDER_DISCUSS_CALL = 'SAVE_ORDER_DISCUSS_CALL';
export var SAVE_ORDER_DISCUSS_DONE = 'SAVE_ORDER_DISCUSS_DONE';
export var SAVE_ORDER_DISCUSS_FAIL = 'SAVE_ORDER_DISCUSS_FAIL';
export var GET_GOODS_EVA_CALL = 'GET_GOODS_EVA_CALL';
export var GET_GOODS_EVA_DONE = 'GET_GOODS_EVA_DONE';
export var GET_GOODS_EVA_FAIL = 'GET_GOODS_EVA_FAIL';
export var GET_ORDER_RETURN_LIST_CALL = 'GET_ORDER_RETURN_LIST_CALL';
export var GET_ORDER_RETURN_LIST_DONE = 'GET_ORDER_RETURN_LIST_DONE';
export var GET_ORDER_RETURN_LIST_FAIL = 'GET_ORDER_RETURN_LIST_FAIL';
export var GET_ORDER_RETURN_LOG_CALL = 'GET_ORDER_RETURN_LOG_CALL';
export var GET_ORDER_RETURN_LOG_DONE = 'GET_ORDER_RETURN_LOG_DONE';
export var GET_ORDER_RETURN_LOG_FAIL = 'GET_ORDER_RETURN_LOG_FAIL';
export var ADD_ORDER_RETURN_CALL = 'ADD_ORDER_RETURN_CALL';
export var ADD_ORDER_RETURN_DONE = 'ADD_ORDER_RETURN_DONE';
export var ADD_ORDER_RETURN_FAIL = 'ADD_ORDER_RETURN_FAIL';
export var CANCEL_ORDER_RETURN_CALL = 'CANCEL_ORDER_RETURN_CALL';
export var CANCEL_ORDER_RETURN_DONE = 'CANCEL_ORDER_RETURN_DONE';
export var CANCEL_ORDER_RETURN_FAIL = 'CANCEL_ORDER_RETURN_FAIL';
export var ADD_ORDER_RETURN_SHIP_CALL = 'ADD_ORDER_RETURN_SHIP_CALL';
export var ADD_ORDER_RETURN_SHIP_DONE = 'ADD_ORDER_RETURN_SHIP_DONE';
export var ADD_ORDER_RETURN_SHIP_FAIL = 'ADD_ORDER_RETURN_SHIP_FAIL';
export var GET_ORDER_RETURN_SHIP_CALL = 'GET_ORDER_RETURN_SHIP_CALL';
export var GET_ORDER_RETURN_SHIP_DONE = 'GET_ORDER_RETURN_SHIP_DONE';
export var GET_ORDER_RETURN_SHIP_FAIL = 'GET_ORDER_RETURN_SHIP_FAIL';
export var GET_ORDER_INVOICE_CALL = 'GET_ORDER_INVOICE_CALL';
export var GET_ORDER_INVOICE_DONE = 'GET_ORDER_INVOICE_DONE';
export var GET_ORDER_INVOICE_FAIL = 'GET_ORDER_INVOICE_FAIL';
export var ADD_ORDER_INVOICE_CALL = 'ADD_ORDER_INVOICE_CALL';
export var ADD_ORDER_INVOICE_DONE = 'ADD_ORDER_INVOICE_DONE';
export var ADD_ORDER_INVOICE_FAIL = 'ADD_ORDER_INVOICE_FAIL';
export var REMOVE_ORDER_INVOICE_CALL = 'REMOVE_ORDER_INVOICE_CALL';
export var REMOVE_ORDER_INVOICE_DONE = 'REMOVE_ORDER_INVOICE_DONE';
export var REMOVE_ORDER_INVOICE_FAIL = 'REMOVE_ORDER_INVOICE_FAIL';
export var GET_CLIENT_INVOICE_INFO_CALL = 'GET_CLIENT_INVOICE_INFO_CALL';
export var GET_CLIENT_INVOICE_INFO_DONE = 'GET_CLIENT_INVOICE_INFO_DONE';
export var GET_CLIENT_INVOICE_INFO_FAIL = 'GET_CLIENT_INVOICE_INFO_FAIL';
export var DIRECT_SELL_SHARE_CALL = 'DIRECT_SELL_SHARE_CALL';
export var DIRECT_SELL_SHARE_DONE = 'DIRECT_SELL_SHARE_DONE';
export var DIRECT_SELL_SHARE_FAIL = 'DIRECT_SELL_SHARE_FAIL';