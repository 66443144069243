import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";

import "assets/vendors/style";
import "styles/wieldy.less";
import App from "./containers/App/index";
import configureStore, { history } from './store'
import api from './api'
import graphql from './graphql'
import { ApolloProvider } from '@apollo/react-hooks';
const { client } = graphql

const store = configureStore()
api.setStore(store)
graphql.setStore(store)


const NextApp = () =>
  <Provider store={store} >
    <ApolloProvider client={client}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={App}/>
        </Switch>
      </ConnectedRouter>
    </ApolloProvider>
  </Provider>;

export default NextApp;
