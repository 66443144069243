import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectDestructuringEmpty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectDestructuringEmpty";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _toConsumableArray from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";

var _itemHandlers;

import _ from 'lodash';
import moment from 'moment';
import produce from 'immer';
import * as types from '../../../store/types';
import { NutritionConstant, Calculator } from '../../../utils';
import { combineActions } from 'redux-actions';
var FOOD_STUFF_TYPE = 'FoodStuff';
var QUICK_ADD_TYPE = 'QuickAdd';
var ACTIVITY_ITEM_TYPE = 'Activity::Item';
var ASSET_KEYS_TO_OMIT = ['id', 'client_id', 'taken_at', 'date', 'meal_order'];
var ITEM_KEYS_TO_OMIT = ['id', 'added_at', 'meal', 'meal_order', 'nutrient', 'photo_id', 'note_id', 'quickaddtype'];
var defaultSixGroup = {
  fruits: '',
  vegetables: '',
  grains: '',
  meat_beans_low_fat: '',
  meat_beans_medium_fat: '',
  meat_beans_high_fat: '',
  meat_beans_super_high_fat: '',
  milk_skim: '',
  milk_low_fat: '',
  milk_whole_fat: '',
  oil: '',
  item_name: ''
};
var defaultCalorie = {
  item_name: '',
  item_amount: '',
  calorie: '',
  kind: ''
};

var filterFoodStuff = function filterFoodStuff(items) {
  return _.filter(items, {
    item_type: FOOD_STUFF_TYPE
  });
};

var filterSixGroup = function filterSixGroup(items) {
  return _.filter(items, function (item) {
    return item.item_type === QUICK_ADD_TYPE && (item.quick_add_type === 'six_group' || item.quick_add_type === 'named_six_group');
  });
};

var rejectSixGroup = function rejectSixGroup(items) {
  return _.reject(items, function (item) {
    return item.item_type === QUICK_ADD_TYPE && (item.quick_add_type === 'six_group' || item.quick_add_type === 'named_six_group');
  });
};

var mergeSixGroupItems = function mergeSixGroupItems(items) {
  var defaultVals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var sixGroupItems = filterSixGroup(items);
  var restItems = rejectSixGroup(items);

  var sixGroups = _.reduce(sixGroupItems, function (result, item) {
    _.each(NutritionConstant.SIXGROUPS, function (key) {
      return result[key] += item[key] ? Number(item[key]) : 0;
    });

    return result;
  }, _.fromPairs(_.map(NutritionConstant.SIXGROUPS, function (key) {
    return [key, 0];
  })));

  var sixGroupItem = _.merge({
    item_id: 1,
    item_type: QUICK_ADD_TYPE,
    quick_add_type: 'six_group',
    amount: 1,
    added_at: moment().format('YYYY-MM-DD HH:mm:ss')
  }, defaultVals, Calculator.calcC63PBySixGroups(sixGroups));

  return [sixGroupItem].concat(_toConsumableArray(restItems));
};

var getUploadableAsset = function getUploadableAsset(asset) {
  var _fromHistory = asset._fromHistory,
      items = asset.items;
  if (!_fromHistory) return asset;
  return _objectSpread({}, _.omit(asset, ASSET_KEYS_TO_OMIT), {
    items: _.map(items, function (item) {
      return _.omit(item, ITEM_KEYS_TO_OMIT);
    })
    /*
    items: mergeSixGroupItems(
      _.map(items, item => _.omit(item, ITEM_KEYS_TO_OMIT)),
      { item_name: asset.text },
    ),
    */

  });
};

var mergeC63P = function mergeC63P(item) {
  return _objectSpread({}, item, Calculator.calcC63PByFoodStuffs(item.amount, item.item_details));
};

var updateItemsWithFoodStuffs = function updateItemsWithFoodStuffs(items, foodStuffs) {
  var ids = _.map(foodStuffs, 'item_id');

  var mergedFoodstuffs = _.map(foodStuffs, function (f) {
    var matched = _.find(items, {
      item_type: FOOD_STUFF_TYPE,
      item_id: f.item_id
    });

    if (matched) {
      return matched._destroy ? _objectSpread({}, matched, {
        amount: f.amount,
        _destroy: false
      }) : _objectSpread({}, matched, {
        amount: f.amount + matched.amount
      });
    }

    return f;
  });

  return [].concat(_toConsumableArray(_.reject(items, function (item) {
    return item.item_type === FOOD_STUFF_TYPE && _.includes(ids, item.item_id);
  })), _toConsumableArray(_.map(mergedFoodstuffs, mergeC63P)));
};

var updateItemsWithSixGroup = function updateItemsWithSixGroup() {
  var items = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var sixGroup = arguments.length > 1 ? arguments[1] : undefined;

  var index = _.findIndex(items, {
    added_at: sixGroup.added_at
  });

  var itemsToUpdate;

  if (index > -1) {
    itemsToUpdate = [].concat(_toConsumableArray(items.slice(0, index)), [_objectSpread({}, items[index], sixGroup, {
      _destroy: false,
      _dirty: true
    })], _toConsumableArray(items.slice(index + 1)));
  } else {
    itemsToUpdate = [].concat(_toConsumableArray(items), [_objectSpread({
      item_type: 'QuickAdd',
      item_id: 1,
      quick_add_type: 'six_group'
    }, sixGroup, {
      added_at: moment().format('YYYY-MM-DD HH:mm:ss'),
      amount: 1
    })]);
  }

  return itemsToUpdate;
};

var updateItemsWithCalorie = function updateItemsWithCalorie() {
  var items = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var calorieItem = arguments.length > 1 ? arguments[1] : undefined;

  var index = _.findIndex(items, {
    added_at: calorieItem.added_at
  });

  var itemsToUpdate;

  if (index > -1) {
    itemsToUpdate = [].concat(_toConsumableArray(items.slice(0, index)), [_objectSpread({}, items[index], calorieItem, {
      _destroy: false,
      _dirty: true
    })], _toConsumableArray(items.slice(index + 1)));
  } else {
    itemsToUpdate = [].concat(_toConsumableArray(items), [_objectSpread({
      item_type: 'QuickAdd',
      item_id: 1,
      quick_add_type: 'calorie'
    }, calorieItem, {
      added_at: moment().format('YYYY-MM-DD HH:mm:ss')
    })]);
  }

  return itemsToUpdate;
};

var itemHandlers = (_itemHandlers = {}, _defineProperty(_itemHandlers, types.INIT_SELECTED_FOOD_STUFFS, function (state) {
  return _objectSpread({}, state, {
    selectedFoodStuffs: []
  });
}), _defineProperty(_itemHandlers, types.ADD_FOOD_STUFF, function (state, _ref) {
  var _ref$payload = _ref.payload,
      foodStuff = _ref$payload.foodStuff,
      amount = _ref$payload.amount;
  var selectedFoodStuffs = state.selectedFoodStuffs;

  var index = _.findIndex(selectedFoodStuffs, {
    item_id: foodStuff.id
  });

  if (index > -1) {
    selectedFoodStuffs = [].concat(_toConsumableArray(selectedFoodStuffs.slice(0, index)), [_objectSpread({}, selectedFoodStuffs[index], {
      amount: amount,
      _destroy: false
    })], _toConsumableArray(selectedFoodStuffs.slice(index + 1)));
  } else {
    selectedFoodStuffs = [].concat(_toConsumableArray(selectedFoodStuffs), [{
      item_type: FOOD_STUFF_TYPE,
      item_id: foodStuff.id,
      item_name: foodStuff.name,
      text: foodStuff.name,
      item_details: foodStuff,
      added_at: moment().format('YYYY-MM-DD HH:mm:ss'),
      amount: amount
    }]);
  }

  return _objectSpread({}, state, {
    selectedFoodStuffs: selectedFoodStuffs
  });
}), _defineProperty(_itemHandlers, types.UPDATE_FOOD_STUFF, function (state, _ref2) {
  var _ref2$payload = _ref2.payload,
      item = _ref2$payload.item,
      amount = _ref2$payload.amount;
  return produce(state, function (draft) {
    var assets = draft.assets,
        currentAssetIndex = draft.currentAssetIndex;
    var asset = assets[currentAssetIndex] || {};
    var itemToupdate = _.find(asset.items, {
      item_id: item.id
    }) || {};
    itemToupdate.amount = amount;

    _.merge(itemToupdate, mergeC63P(itemToupdate));
  });
}), _defineProperty(_itemHandlers, types.REMOVE_FOOD_STUFF, function (state, _ref3) {
  var item = _ref3.payload.item;
  return produce(state, function (draft) {
    var assets = draft.assets,
        currentAssetIndex = draft.currentAssetIndex;
    var asset = assets[currentAssetIndex] || {};
    var itemToRemove = _.find(asset.items, {
      item_id: item.id
    }) || {};
    itemToRemove._destroy = true;
  });
}), _defineProperty(_itemHandlers, types.CONFIRM_NEW_NOTE_FOODSTUFF, function (state) {
  var assets = state.assets,
      selectedFoodStuffs = state.selectedFoodStuffs,
      currentAssetIndex = state.currentAssetIndex;
  var asset = assets[currentAssetIndex] || {
    items: []
  };
  var items = updateItemsWithFoodStuffs(asset.items, selectedFoodStuffs);
  return _objectSpread({}, state, {
    assets: [].concat(_toConsumableArray(assets.slice(0, currentAssetIndex)), [_objectSpread({}, asset, {
      items: items
    })], _toConsumableArray(assets.slice(currentAssetIndex + 1)))
  });
}), _defineProperty(_itemHandlers, types.REMOVE_SIX_GROUP, function (state, _ref4) {
  var item = _ref4.payload.item;
  return produce(state, function (draft) {
    var assets = draft.assets,
        currentAssetIndex = draft.currentAssetIndex;

    var _ref5 = assets[currentAssetIndex] || {
      items: []
    },
        items = _ref5.items;

    var itemToRemove = _.find(items, {
      added_at: item.added_at
    }) || {};
    itemToRemove._destroy = true;
  });
}), _defineProperty(_itemHandlers, types.INIT_SELECTED_SIX_GROUP, function (state, _ref6) {
  var item = _ref6.payload.item;
  var assets = state.assets,
      currentAssetIndex = state.currentAssetIndex;
  var currentAsset = assets[currentAssetIndex];

  var isEmptySixgroup = _.isEmpty(item);

  var selectedSixGroup = currentAsset && !_.isEmpty(item) ? item : defaultSixGroup;
  return _objectSpread({}, state, {
    isEmptySixgroup: isEmptySixgroup,
    selectedSixGroup: selectedSixGroup
  });
}), _defineProperty(_itemHandlers, types.ADD_NEW_NOTE_SIXGROUP, function (state, _ref7) {
  var item = _ref7.payload.item;
  var assets = state.assets,
      currentAssetIndex = state.currentAssetIndex;
  var asset = assets[currentAssetIndex] || {};
  return _objectSpread({}, state, {
    assets: [].concat(_toConsumableArray(assets.slice(0, currentAssetIndex)), [_objectSpread({}, asset, {
      items: updateItemsWithSixGroup(asset.items, item)
    })], _toConsumableArray(assets.slice(currentAssetIndex + 1)))
  });
}), _defineProperty(_itemHandlers, types.ADD_NEW_NOTE_CALORIE, function (state, _ref8) {
  var item = _ref8.payload.item;
  var assets = state.assets,
      currentAssetIndex = state.currentAssetIndex;
  var asset = assets[currentAssetIndex] || {};
  return _objectSpread({}, state, {
    assets: [].concat(_toConsumableArray(assets.slice(0, currentAssetIndex)), [_objectSpread({}, asset, {
      items: updateItemsWithCalorie(asset.items, item)
    })], _toConsumableArray(assets.slice(currentAssetIndex + 1)))
  });
}), _defineProperty(_itemHandlers, types.REMOVE_CALORIE, function (state, _ref9) {
  var item = _ref9.payload.item;
  return produce(state, function (draft) {
    var assets = draft.assets,
        currentAssetIndex = draft.currentAssetIndex;
    var asset = assets[currentAssetIndex] || {};
    var itemToRemove = _.find(asset, {
      added_at: item.added_at
    }) || {};
    itemToRemove._destroy = true;
  });
}), _defineProperty(_itemHandlers, types.INIT_SELECTED_CALORIE, function (state, _ref10) {
  var _ref10$payload = _ref10.payload,
      item = _ref10$payload.item,
      kind = _ref10$payload.kind,
      item_name = _ref10$payload.item_name;
  var assets = state.assets,
      currentAssetIndex = state.currentAssetIndex;
  var currentAsset = assets[currentAssetIndex];

  var isEmptyCalorie = _.isEmpty(item);

  var selectedCalorie;

  if (currentAsset && !_.isEmpty(item)) {
    selectedCalorie = _objectSpread({}, item, {
      kind: item.kind || 'custom'
    });
  } else {
    selectedCalorie = _objectSpread({}, defaultCalorie, {
      kind: kind,
      item_name: item_name
    });

    if (kind !== 'custom') {
      selectedCalorie.calorie = '0';
    }
  }

  return _objectSpread({}, state, {
    isEmptyCalorie: isEmptyCalorie,
    selectedCalorie: selectedCalorie
  });
}), _defineProperty(_itemHandlers, combineActions(types.FINISH_SELECT_MEDIA, types.FINISH_CHAT_SELECT_MEDIA), function (state, _ref11) {
  _objectDestructuringEmpty(_ref11);

  var assets = state.assets,
      selectedAssets = state.selectedAssets;

  var newAssets = _.map(selectedAssets, function (asset, index) {
    return _.merge(assets[index] || {}, getUploadableAsset(asset), {
      _type: 'noteAsset'
    });
  });

  return _objectSpread({}, state, {
    assets: newAssets
  });
}), _defineProperty(_itemHandlers, combineActions(types.CLOSE_IMAGE_PICKER_MODAL), function (state) {
  return _objectSpread({}, state, {
    selectedAssets: []
  });
}), _defineProperty(_itemHandlers, types.ADD_ACTIVITY_ITEMS, function (state, _ref12) {
  var _ref12$payload = _ref12.payload,
      activityItem = _ref12$payload.activityItem,
      attr = _ref12$payload.attr;
  var _state$selectedActivi = state.selectedActivityItems,
      selectedActivityItems = _state$selectedActivi === void 0 ? [] : _state$selectedActivi;
  selectedActivityItems = [].concat(_toConsumableArray(selectedActivityItems), [Object.assign({
    item_type: ACTIVITY_ITEM_TYPE,
    item_id: activityItem.id,
    item_name: activityItem.display_name,
    text: activityItem.display_name,
    added_at: moment().format('YYYY-MM-DD HH:mm:ss'),
    isWorkout: activityItem.isWorkout,
    item_numeric: activityItem.item_numeric
  }, attr)]);
  return _objectSpread({}, state, {
    selectedActivityItems: selectedActivityItems
  });
}), _defineProperty(_itemHandlers, types.UPDATE_ACTIVITY_ITEMS, function (state, _ref13) {
  var _ref13$payload = _ref13.payload,
      item = _ref13$payload.item,
      attr = _ref13$payload.attr;
  var _state$selectedActivi2 = state.selectedActivityItems,
      selectedActivityItems = _state$selectedActivi2 === void 0 ? [] : _state$selectedActivi2;

  var index = _.findIndex(selectedActivityItems, {
    added_at: item.added_at
  });

  if (index > -1) {
    selectedActivityItems = [].concat(_toConsumableArray(selectedActivityItems.slice(0, index)), [_objectSpread({}, selectedActivityItems[index], attr, {
      _destroy: false
    })], _toConsumableArray(selectedActivityItems.slice(index + 1)));
  }

  return _objectSpread({}, state, {
    selectedActivityItems: selectedActivityItems
  });
}), _defineProperty(_itemHandlers, types.REMOVE_ACTIVITY_ITEMS, function (state, _ref14) {
  var payload = _ref14.payload;
  var selectedActivityItems = state.selectedActivityItems;

  var index = _.findIndex(state.selectedActivityItems, {
    added_at: payload
  });

  if (index > -1) {
    selectedActivityItems = [].concat(_toConsumableArray(selectedActivityItems.slice(0, index)), _toConsumableArray(selectedActivityItems.slice(index + 1)), [_objectSpread({}, selectedActivityItems[index], {
      _destroy: true
    })]);
  }

  return _objectSpread({}, state, {
    selectedActivityItems: selectedActivityItems
  });
}), _defineProperty(_itemHandlers, types.CONFIRM_NEW_NOTE_ACTIVITY_ITEMS, function (state) {
  var selectedActivityItems = state.selectedActivityItems,
      selectedActivitySessions = state.selectedActivitySessions;
  return _objectSpread({}, state, {
    items: selectedActivityItems,
    sessions: selectedActivitySessions,
    calorie: _.reduce(_.reject(selectedActivityItems, ['_destroy', true]), function (sum, n) {
      return sum + (n.calorie || 0);
    }, 0)
  });
}), _itemHandlers);
export default itemHandlers;