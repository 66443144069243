import _defineProperty from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "/opt/build/repo/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";

var _handleActions;

import { handleActions, combineActions } from 'redux-actions';
import * as types from '../../store/types';
import produce from 'immer';
import _ from 'lodash';
export default handleActions((_handleActions = {}, _defineProperty(_handleActions, types.SET_MODAL, function (state, _ref) {
  var payload = _ref.payload;
  return _objectSpread({}, payload);
}), _defineProperty(_handleActions, types.CLOSE_MODAL, function (state, _ref2) {
  var payload = _ref2.payload;
  return {
    isVisible: false
  };
}), _defineProperty(_handleActions, types.SET_MODAL_MESSAGE, function (state, _ref3) {
  var message = _ref3.payload.message;
  return {
    isVisible: !state.isVisible,
    type: 'close',
    titleKey: 'message.title',
    message: message,
    closeTextKey: 'button.close',
    actionName: undefined,
    params: {}
  };
}), _defineProperty(_handleActions, types.SET_MODAL_NET_ERROR, function (state, _ref4) {
  var payload = _ref4.payload;
  return {
    isVisible: !state.isVisible,
    type: 'close',
    titleKey: 'message.title',
    messageKey: 'message.noNet',
    closeTextKey: 'button.close',
    actionName: undefined,
    params: {}
  };
}), _defineProperty(_handleActions, types.EMAIL_LOGIN_FAIL, function (state, _ref5) {
  var _ref5$payload$respons = _ref5.payload.response,
      response = _ref5$payload$respons === void 0 ? {} : _ref5$payload$respons;
  var data = response.data,
      status = response.status;
  var messageKey = '';

  if (status === 400 || status === 404) {
    messageKey = data.message === 'unconfirmed' ? 'login:loginErrorMessageEmail' : 'login:loginErrorMessage';
  } else {
    messageKey = 'message.serverError';
  }

  return {
    isVisible: !state.isVisible,
    type: 'close',
    titleKey: 'login:loginErrorTitle',
    messageKey: messageKey,
    closeTextKey: 'button.close',
    actionName: undefined,
    params: {}
  };
}), _defineProperty(_handleActions, types.ACCOUNT_LOGIN_FAIL, function (state, _ref6) {
  var _ref6$payload$respons = _ref6.payload.response,
      response = _ref6$payload$respons === void 0 ? {} : _ref6$payload$respons;
  var status = response.status,
      _response$data = response.data,
      data = _response$data === void 0 ? {} : _response$data;
  var message = data.message;
  var messageKey = '';

  if (status === 400) {
    switch (message) {
      case 'you_have_been_left_over_regulation_days':
        messageKey = 'login:loginErrorOverDays';
        break;

      case 'no_permission_to_login':
        messageKey = 'login:noPermission';
        break;

      case 'under_investigation':
        messageKey = 'login:investigation';
        break;

      default:
        messageKey = 'login:loginErrorMessage';
        break;
    }
  } else {
    messageKey = 'message.serverError';
  }

  return {
    isVisible: !state.isVisible,
    type: 'close',
    titleKey: 'login:loginErrorTitle',
    messageKey: messageKey,
    closeTextKey: 'button.close',
    actionName: undefined,
    params: {}
  };
}), _defineProperty(_handleActions, types.REGISTER_DONE, function (state, _ref7) {
  var payload = _ref7.payload;
  return {
    isVisible: !state.isVisible,
    type: 'check',
    titleKey: 'login:registerTitle',
    messageKey: 'login:registerMessage',
    closeTextKey: 'button.ok',
    actionName: undefined,
    params: {}
  };
}), _defineProperty(_handleActions, types.REGISTER_FAIL, function (state, _ref8) {
  var _ref8$payload$respons = _ref8.payload.response,
      response = _ref8$payload$respons === void 0 ? {} : _ref8$payload$respons;
  var status = response.status;
  var messageKey = '';

  if (status === 400) {
    messageKey = 'login:registerErrorMessage';
  } else {
    messageKey = 'message.serverError';
  }

  return {
    isVisible: !state.isVisible,
    type: 'close',
    titleKey: 'login:loginErrorTitle',
    messageKey: messageKey,
    closeTextKey: 'button.close',
    actionName: undefined,
    params: {}
  };
}), _defineProperty(_handleActions, combineActions(types.CHECK_REGISTER_CODE_FAIL, types.ENTER_CHATS_FAIL), function (state, _ref9) {
  var payload = _ref9.payload;
  return {
    isVisible: !state.isVisible,
    type: 'close',
    titleKey: 'registrationCode:error',
    messageKey: 'registrationCode:errorMessage',
    closeTextKey: 'button.close',
    actionName: undefined,
    params: {}
  };
}), _defineProperty(_handleActions, types.RESET_PASSWORD_DONE, function (state, _ref10) {
  var payload = _ref10.payload;
  return {
    isVisible: !state.isVisible,
    type: 'check',
    titleKey: 'forget:sendTitle',
    messageKey: 'forget:sendMessage',
    closeTextKey: 'button.ok',
    actionName: 'pageGoBack',
    params: {}
  };
}), _defineProperty(_handleActions, types.RESET_PASSWORD_FAIL, function (state, _ref11) {
  var payload = _ref11.payload;
  return {
    isVisible: true,
    type: 'close',
    titleKey: 'login:loginErrorTitle',
    messageKey: 'forget:notExist',
    closeTextKey: 'button.close',
    actionName: undefined,
    params: {}
  };
}), _defineProperty(_handleActions, types.REGISTER_CODE_DONE, function (state, _ref12) {
  var type = _ref12.payload.type;
  var actionName = _.includes(['chat', 'connection', 'pro_web'], type) ? 'pageGoBack' : 'openTargetHelloPage';
  return {
    isVisible: !state.isVisible,
    type: 'check',
    titleKey: 'registrationCode:modalTitle',
    messageKey: 'registrationCode:modalMessage',
    closeTextKey: 'registrationCode:modalText',
    actionName: actionName,
    params: {}
  };
}), _defineProperty(_handleActions, types.CHANGE_PASSWORD_DONE, function (state, _ref13) {
  var payload = _ref13.payload;
  return {
    isVisible: !state.isVisible,
    type: 'check',
    titleKey: 'message.title',
    messageKey: 'editPassword:success',
    closeTextKey: 'button.ok',
    actionName: 'pageGoBack',
    params: {}
  };
}), _defineProperty(_handleActions, types.CHANGE_PASSWORD_FAIL, function (state, _ref14) {
  var _ref14$payload$respon = _ref14.payload.response,
      response = _ref14$payload$respon === void 0 ? {} : _ref14$payload$respon;
  var status = response.status;
  var messageKey = '';

  if (status === 400) {
    messageKey = 'editPassword:oldPasswordError';
  } else {
    messageKey = 'message.serverError';
  }

  return {
    isVisible: !state.isVisible,
    type: 'close',
    titleKey: 'message.title',
    messageKey: messageKey,
    closeTextKey: 'button.close',
    actionName: undefined,
    params: {}
  };
}), _defineProperty(_handleActions, types.WECHAT_LOGIN_CHECK_DONE, function (state, _ref15) {
  var token = _ref15.payload.token;
  return {
    isVisible: !token,
    type: 'close',
    titleKey: 'message.title',
    messageKey: 'wechat:noApp',
    closeTextKey: 'button.close',
    actionName: undefined,
    params: {}
  };
}), _defineProperty(_handleActions, combineActions(types.GET_CLIENT_ME_FAIL, types.GET_USER_ME_FAIL, types.GET_FF_CLIENT_ME_FAIL), function (state, _ref16) {
  var payload = _ref16.payload;
  return {
    isVisible: !state.isVisible,
    type: 'check',
    titleKey: 'login:loginErrorTitle',
    messageKey: 'message.serverError',
    closeTextKey: 'button.reload',
    actionName: 'getMeCall',
    params: {
      isLogin: true
    }
  };
}), _defineProperty(_handleActions, combineActions(types.UPDATE_CLIENT_FAIL, types.UPDATE_USER_FAIL), function (state, _ref17) {
  var _ref17$payload$respon = _ref17.payload.response,
      response = _ref17$payload$respon === void 0 ? {} : _ref17$payload$respon;
  var status = response.status;
  var messageKey = '';

  if (status === 400) {
    messageKey = 'editEmail:error';
  } else {
    messageKey = 'message.serverError';
  }

  return {
    isVisible: !state.isVisible,
    type: 'close',
    titleKey: 'message.title',
    messageKey: messageKey,
    closeTextKey: 'button.close',
    actionName: undefined,
    params: {}
  };
}), _handleActions), {
  isVisible: false,
  type: 'close',
  title: '',
  message: '',
  closeText: '',
  actionName: undefined,
  params: {}
});