export var OPEN_NOTIFICATION_TAB = 'OPEN_NOTIFICATION_TAB';
export var OPEN_NOTIFICATION_PAGE = 'OPEN_NOTIFICATION_PAGE';
export var GET_NOTIFICATIONS_CALL = 'GET_NOTIFICATIONS_CALL';
export var GET_NOTIFICATIONS_DONE = 'GET_NOTIFICATIONS_DONE';
export var GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';
export var START_GETTING_NOTIFICATIONS = 'START_GETTING_NOTIFICATIONS';
export var STOP_GETTING_NOTIFICATIONS = 'STOP_GETTING_NOTIFICATIONS';
export var OPEN_NOTIFICATION_CALL = 'OPEN_NOTIFICATION_CALL';
export var OPEN_NOTIFICATION_DONE = 'OPEN_NOTIFICATION_DONE';
export var OPEN_NOTIFICATION_FAIL = 'OPEN_NOTIFICATION_FAIL';
export var SET_NOTIFICATIONS_READ = 'SET_NOTIFICATIONS_READ';
export var READ_ALL_NOTIFICATIONS_CALL = 'READ_ALL_NOTIFICATIONS_CALL';
export var READ_ALL_NOTIFICATIONS_DONE = 'READ_ALL_NOTIFICATIONS_DONE';
export var READ_ALL_NOTIFICATIONS_FAIL = 'READ_ALL_NOTIFICATIONS_FAIL';